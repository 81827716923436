import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";

import Button from "../button/Button";
import TCModal from "./TCModal";

import styleNames from "./deleteModal.local.css";

const cx = classNames.bind(styleNames);

const DeleteInstanceMode = {
  DELETE: "DELETE",
  CANCEL: "CANCEL"
};

Object.freeze(DeleteInstanceMode);

class DeleteModal extends Component {
  static propTypes = {
    showModal: PropTypes.bool.isRequired,
    handleAction: PropTypes.func,
    body: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    cancelText: PropTypes.string,
    confirmText: PropTypes.string
  };

  static defaultProps = {
    handleAction: () => {},
    cancelText: "Abbrechen",
    confirmText: "Löschen"
  };

  state = {
    showModal: false
  };

  componentWillMount() {
    const { showModal } = this.props;

    this.toggleModal(showModal);
  }

  componentWillReceiveProps(nextProps) {
    const { showModal } = nextProps;

    this.toggleModal(showModal);
  }

  toggleModal(show) {
    if (this.state.showModal !== show) {
      this.setState({ showModal: show });
    }
  }

  handleAction = e => {
    this.setState({ showModal: false });
    this.props.handleAction(e.target.id);
  };

  render() {
    const { title, body, cancelText, confirmText } = this.props;
    const { showModal } = this.state;

    return (
      <TCModal
        show={showModal}
        subForm
        footer={
          <div>
            <Button id={DeleteInstanceMode.CANCEL} onClick={this.handleAction}>
              {cancelText}
            </Button>
            <Button
              id={DeleteInstanceMode.DELETE}
              onClick={this.handleAction}
              color="danger"
            >
              {confirmText}
            </Button>
          </div>
        }
        classNameWrapper={cx("repetitiveModalContainer")}
      >
        <div className="row">
          <div className={cx("col-sm-12")}>
            <div className={cx("form-group", "titlecontainer")}>
              <span className={cx("title")}>{title}</span>
            </div>

            <div className={cx("repetitiveModalBody")}>{body}</div>
          </div>
        </div>
      </TCModal>
    );
  }
}

export default DeleteModal;
export { DeleteInstanceMode };
