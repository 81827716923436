import React from "react";
import PropTypes from "prop-types";
import RBDropdownButton from "react-bootstrap/lib/DropdownButton";
import borderDistance from "react-border-distance";

import Icon from "../icon/Icon";

const DropdownButton = ({
  id,
  color,
  size,
  loading,
  iconLeft,
  iconRight,
  text,
  children,
  distance,
  menuHeight,
  ...otherProps
}) => {
  const title = (
    <span>
      {iconLeft && <Icon name={iconLeft} />}
      {text && { text }}
      {iconRight && <Icon name={iconRight} />}
    </span>
  );

  return (
    <RBDropdownButton
      id={id}
      title={title}
      bsStyle={color}
      bsSize={size}
      loading={loading}
      dropup={menuHeight && distance && distance.bottom < menuHeight}
      {...otherProps}
    >
      {children}
    </RBDropdownButton>
  );
};

DropdownButton.propTypes = {
  id: PropTypes.string.isRequired,
  color: PropTypes.oneOf([
    "default",
    "primary",
    "info",
    "success",
    "warning",
    "danger"
  ]),
  size: PropTypes.oneOf(["lg", "sm", "xs"]),
  open: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  noCaret: PropTypes.bool,
  dropup: PropTypes.bool,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  text: PropTypes.node,
  // Set menuHeight to handle Dropdown direction (down or up)
  menuHeight: PropTypes.number,
  distance: PropTypes.object,
  children: PropTypes.any
};

DropdownButton.defaultProps = {
  color: "default"
};

export default borderDistance()(DropdownButton);
