import { LOGISTICCOMPANY_PREPARE_CHANGE } from "state/auth";
import {
  FOLDERS_GET_REQUEST,
  FOLDERS_GET_RECEIVE,
  FOLDERS_ERROR,
  FOLDER_SET_MEMBER_RECEIVE
} from "./constants";
import {
  TASKS_ASSIGN_RECEIVE,
  TASKS_UNASSIGN_RECEIVE
} from "../tasks/constants";

const initialState = {
  entities: {
    // 1: { id: 1, label: "Arbeitsmappe", jobs: [1,2,3], ... }
  },
  loaded: false,
  errors: {}
};

export default function foldersReducer(state = initialState, action = {}) {
  const { type, payload, error } = action;

  switch (type) {
    case FOLDERS_GET_REQUEST: {
      return {
        ...state,
        loaded: false,
        errors: {}
      };
    }

    case FOLDERS_GET_RECEIVE: {
      // These action will also handled by tasks reducer.
      const entities = payload.reduce((acc, folder) => {
        acc[folder.id] = { ...folder, tasks: folder.tasks.map(({ id }) => id) };
        return acc;
      }, {});
      return {
        ...state,
        entities,
        loaded: true,
        errors: {}
      };
    }

    case TASKS_ASSIGN_RECEIVE: {
      const { entities } = state;

      const updatedIDs = payload.map(({ id }) => id);
      // Using the first entry to get the folderID
      const [{ folderID: newFolderID }] = payload;

      const folderMap = Object.keys(entities).reduce(
        (map, folderID) => {
          const folder = map[folderID];
          // filter out tasks
          folder.tasks = folder.tasks.filter(
            taskID => !updatedIDs.includes(taskID)
          );

          if (String(newFolderID) === folderID) {
            folder.tasks = [...folder.tasks, ...updatedIDs];
          }

          map[folderID] = folder;
          return map;
        },
        { ...entities }
      );

      return {
        ...state,
        entities: folderMap
      };
    }

    case TASKS_UNASSIGN_RECEIVE: {
      const unassignedTaskIDs = payload.map(({ id }) => id);
      const { entities } = state;

      const folderMap = Object.keys(entities).reduce(
        (map, folderID) => {
          const folder = map[folderID];
          // filter out unassigned tasks
          folder.tasks = folder.tasks.filter(
            taskID => !unassignedTaskIDs.includes(taskID)
          );
          map[folderID] = folder;
          return map;
        },
        { ...entities }
      );
      return {
        ...state,
        entities: folderMap
      };
    }

    case FOLDER_SET_MEMBER_RECEIVE: {
      const { entities } = state;
      const { folderID, memberID } = payload;
      const folder = { ...entities[folderID] };
      folder.memberID = memberID;
      return {
        ...state,
        entities: {
          ...entities,
          [folderID]: folder
        }
      };
    }

    case FOLDERS_ERROR: {
      return {
        ...state,
        loaded: false,
        errors: error
      };
    }

    case LOGISTICCOMPANY_PREPARE_CHANGE:
      return initialState;

    default:
      return state;
  }
}
