import { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getShipments } from "state/entities/shipmentsV2/selectors";
import { getTasks } from "state/entities/tasks/selectors";
import { TASK_TYPES } from "state/entities/tasks/constants";
import { mapActions } from "features/map";

export const TasksOnMap = ({ selectedTaskIDs }) => {
  const dispatch = useDispatch();
  const tasks = useSelector(getTasks);
  const shipments = useSelector(getShipments);

  const pins = useMemo(
    () =>
      selectedTaskIDs
        .map(taskID => {
          const task = tasks[taskID];
          if (!task) {
            return null;
          }
          const shipment = shipments[task.shipmentID];
          if (!shipment) {
            return null;
          }
          const destination =
            task.type === TASK_TYPES.PICKUP ||
            task.type === TASK_TYPES.PICKUP_DIRECT
              ? shipment.sender
              : shipment.recipient;

          if (!destination) {
            return null;
          }

          return {
            key: taskID,
            label: "",
            position: {
              lat: destination.address_latitude,
              lng: destination.address_longitude
            }
          };
        })
        .filter(Boolean),
    [selectedTaskIDs, tasks, shipments]
  );

  useEffect(() => {
    if (pins.length > 0) {
      // show pins on map
      dispatch(
        mapActions.set(
          "scheduling",
          {
            pins
          },
          {
            fitBounds: true
          }
        )
      );
    }
    return () => {
      // clear pins and show drivers
      dispatch(mapActions.clear("scheduling"));
      dispatch(
        mapActions.setDriversOptions({
          fitBounds: true
        })
      );
    };
  }, [pins, dispatch]);

  return null;
};
