import { createSelector } from "reselect";
import { getFolders } from "state/entities/folders/selectors";
import { getMembers } from "state/entities/members/selectors";
import { getTasks } from "state/entities/tasks/selectors";

/**
 * @param {object} state
 * @return {number[]}
 */
const getSelectedFolderIds = state => state.folder.selectedFolderIds;
/**
 * @param {object} state
 * @return {number[]}
 */
const getSelectedDriverIds = state => state.folder.selectedDriverIds;

export const getUnassignedTasks = createSelector(
  [getTasks],
  tasks => {
    return Object.values(tasks).filter(task => !task.folderID);
  }
);

export const getFolderWithTaskByID = id =>
  createSelector(
    [getFolders, getTasks],
    (folders, tasks) => {
      let folder = { ...folders[id] };
      folder.tasks = folder.tasks.map(taskID => tasks[taskID]);
      return folder;
    }
  );

export const getFoldersWithMember = createSelector(
  [getFolders, getMembers],
  (folders, members) => {
    const foldersWithMember = Object.values(folders).map(folder => ({
      ...folder,
      member: members[folder.memberID]
    }));
    return foldersWithMember;
  }
);

/**
 * Filters for given status Ids
 * @param {Object} shipment
 * @param {Array[]} folderIds
 * @return {boolean}
 */
const folderFilter = (folder, folderIds) =>
  folderIds.length === 0 || folderIds.includes(folder.id);

/**
 * Filters for given tour Ids
 * @param {Object} shipment shipment
 * @param {Array[]} driverIds
 * @return {boolean}
 */
const driverFilter = (folder, driverIds) =>
  driverIds.length === 0 || driverIds.includes(folder.memberID);

export const getSortedAndFilteredFolders = createSelector(
  [getFolders, getSelectedFolderIds, getSelectedDriverIds],
  (foldersMap, selectedFolderIds, selectedDiverIds) =>
    Object.values(foldersMap)
      .filter(
        folder =>
          Object.keys(folder.tasks).length > 0 &&
          folderFilter(folder, selectedFolderIds) &&
          driverFilter(folder, selectedDiverIds)
      )
      .sort((a, b) => {
        const labelA = a.label.toLowerCase();
        const labelB = b.label.toLowerCase();
        if (labelA < labelB) {
          return -1;
        }
        if (labelA > labelB) {
          return 1;
        }
        return 0;
      })
      .map(({ id }) => String(id))
);
