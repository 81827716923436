import { createSelector } from "reselect";

/**
 * Returns driverId list
 * @param {Object} state the redux store
 * @return {Array} list of driverIds
 */
export const getDriverIds = state => state.entities.members.driverIds;

/**
 * Returns member map
 * @param {Object} state the redux store
 * @return {Object} id -> member
 */
export const getMembers = state => state.entities.members.entities;

/**
 * selects driver objects from members
 * @param {Array} driverIds List of driver Ids
 * @param {Object} members map of id -> member
 * @return {Array}
 */
export const getDrivers = createSelector(
  [getDriverIds, getMembers],
  (driverIds, members) =>
    driverIds
      .map(driverId => members[driverId])
      .reduce(
        (acc, driver) => ({
          ...acc,
          [driver.id]: driver
        }),
        {}
      )
);
