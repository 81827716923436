/**
 * Created by kim on 08.01.17.
 */
import { PASSWORD_CREATE_REQUEST, PASSWORD_RESET_REQUEST } from "./index";

export const resetPassword = (email, filter) => ({
  type: PASSWORD_RESET_REQUEST,
  payload: {
    email,
    ...filter
  }
});

export const createPassword = (token, password) => ({
  type: PASSWORD_CREATE_REQUEST,
  payload: {
    token,
    password
  }
});
