import { FETCH_TRACK_REQUEST, RELOAD_CURRENT_TRACK } from "./index";

export const loadTrack = (token, filter) => ({
  type: FETCH_TRACK_REQUEST,
  payload: {
    token,
    ...filter
  }
});

/**
 * Reload current track.
 */
export const reloadCurrentTrack = () => ({
  type: RELOAD_CURRENT_TRACK
});
