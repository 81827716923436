import * as apiV2 from "common/apiV2";
import {
  TASKS_GET_REQUEST,
  TASKS_GET_RECEIVE,
  TASKS_ERROR,
  TASKS_ASSIGN_RECEIVE,
  TASKS_ASSIGN_REQUEST,
  TASKS_UNASSIGN_RECEIVE,
  TASKS_UNASSIGN_REQUEST
} from "./constants";

/**
 * Get all folders with tasks.
 */
export const getUnassignedTasks = () => async dispatch => {
  const onSuccess = payload => {
    dispatch({ type: TASKS_GET_RECEIVE, payload });
    return payload;
  };

  dispatch({ type: TASKS_GET_REQUEST });
  try {
    const { data: unassignedTasks } = await apiV2.getUnassignedTasks();

    return onSuccess(unassignedTasks);
  } catch (error) {
    console.error(error);
    dispatch({ type: TASKS_ERROR, error });
    return null;
  }
};

/**
 * @param {number} folderID
 * @param {number[]} taskIDs
 */
export const assignTasks = (folderID, taskIDs) => async dispatch => {
  const onSuccess = payload => {
    dispatch({ type: TASKS_ASSIGN_RECEIVE, payload });
    return payload;
  };

  dispatch({ type: TASKS_ASSIGN_REQUEST });
  try {
    const { data: updatedTasks } = await apiV2.assignTasks(folderID, taskIDs);

    return onSuccess(updatedTasks);
  } catch (error) {
    console.log(error);
    dispatch({ type: TASKS_ERROR, error });
    return null;
  }
};

/**
 * @param {number[]} taskIDs
 */
export const unassignTasks = taskIDs => async dispatch => {
  const onSuccess = payload => {
    dispatch({ type: TASKS_UNASSIGN_RECEIVE, payload });
    return payload;
  };

  dispatch({ type: TASKS_UNASSIGN_REQUEST });
  try {
    const { data: updatedTasks } = await apiV2.unassignTasks(taskIDs);

    return onSuccess(updatedTasks);
  } catch (error) {
    console.log(error);
    dispatch({ type: TASKS_ERROR, error });
    return null;
  }
};
