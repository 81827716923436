import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Autosuggest from "react-autosuggest";

import "./shipmentAddressComplete.css";

class ShipmentAddressComplete extends Component {
  static propTypes = {
    addresses: PropTypes.array,
    addressItem: PropTypes.object,
    name: PropTypes.string,
    value: PropTypes.string,
    addressRel: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    token: PropTypes.string,
    addressContext: PropTypes.string.isRequired,
    onSelected: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    dispatch: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.selected = {};
  }

  state = {
    value: "",
    suggestions: [],
    status: "off",
    focus: "blur"
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.addresses !== null && nextProps.addresses.length > 0) {
      this.reloadSuggestions();
    }

    if (nextProps.value && nextProps.value !== this.state.value) {
      this.setState({ value: nextProps.value, status: "on" });
    }
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });

    if (this.props.onChange && this.props.name) {
      const e = { target: { name: this.props.name, value: newValue } };
      this.props.onChange(e);
    }
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    if (value.length > 1) {
      // this.props.dispatch(loadAddress(this.props.token, this.props.addressContext, value));
    } else {
      this.setState({
        suggestions: []
      });
    }
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  getSuggestionValue = suggestion => {
    const { addressRel, onSelected } = this.props;
    onSelected(addressRel, suggestion);

    return suggestion[this.props.addressContext];
  };

  reloadSuggestions() {
    const { addresses } = this.props;

    if (addresses !== null) {
      this.setState({
        suggestions: addresses
      });
    }
  }

  toggleFocus() {
    if (this.state.focus === "focus") {
      this.setState({ focus: "blur" });
    } else {
      this.setState({ focus: "focus" });
    }
  }

  handleOnFocus = () => {
    this.toggleFocus();

    if (this.state.status === "off") {
      this.setState({ status: "on" });
    } else if (this.state.value === null || this.state.value === "") {
      this.setState({ status: "off" });
    }
    this.toggleFocus();
  };

  renderSuggestion = suggestion => (
    <span>{suggestion[this.props.addressContext]}</span>
  );

  render() {
    const { placeholder, className, disabled, ...otherProps } = this.props;
    const { suggestions, value, status, focus } = this.state;

    return (
      <div
        className={`form-group ${status} floatingtextfield ${focus}`}
        onFocus={this.handleOnFocus}
        onBlur={this.handleOnFocus}
      >
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={{
            className,
            value,
            onChange: this.onChange,
            disabled
          }}
          {...otherProps}
        />
        <div className="hiddenlabel-sup"> {placeholder} </div>
        <div className="hiddenlabel-main"> {placeholder} </div>
      </div>
    );
  }
}

function mapStateToProps({ auth, address }) {
  return {
    token: auth.token,
    addresses: address.addresses
  };
}

export default connect(mapStateToProps)(ShipmentAddressComplete);
