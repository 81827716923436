const STANDARD_DATE_PATTERN = "DD.MM.YYYY";
const STANDARD_TIME_PATTERN = "HH:mm";
const STANDARD_DATETIME_PATTERN = `${STANDARD_DATE_PATTERN} ${STANDARD_TIME_PATTERN}`;

const API_DATE_PATTERN = "YYYY-MM-DD";
const API_DATETIME_PATTERN = `${API_DATE_PATTERN} ${STANDARD_TIME_PATTERN}`;

export default class DateService {
  static formatTime(moment) {
    return moment.format(STANDARD_TIME_PATTERN);
  }

  static formatDate(moment) {
    return moment.format(STANDARD_DATE_PATTERN);
  }

  static formatDatetime(moment) {
    return moment.format(STANDARD_DATETIME_PATTERN);
  }

  static formatDateRange(startMoment, endMoment) {
    return `${this.formatDate(startMoment)} - ${this.formatDate(endMoment)}`;
  }

  static formatDateForApi(moment) {
    return moment.format(API_DATE_PATTERN);
  }

  static formatDatetimeForApi(moment) {
    return moment.format(API_DATETIME_PATTERN);
  }
}
