import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";

import Button from "../button/Button";
import TCModal from "./TCModal";

import styleNames from "./recurrenceInstanceSwitchModal.local.css";

const cx = classNames.bind(styleNames);

const InstanceMode = {
  INSTANCE: "INSTANCE",
  SERIES: "SERIES",
  CANCEL: "CANCEL"
};

Object.freeze(InstanceMode);

class RecurrenceInstanceSwitchModal extends Component {
  static propTypes = {
    showRecurrenceModal: PropTypes.bool.isRequired,
    handleRecurrenceAction: PropTypes.func,
    body: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  };

  state = {
    showModal: false
  };

  componentWillMount() {
    const { showRecurrenceModal } = this.props;

    this.toggleModal(showRecurrenceModal);
  }

  componentWillReceiveProps(nextProps) {
    const { showRecurrenceModal } = nextProps;

    this.toggleModal(showRecurrenceModal);
  }

  toggleModal(show) {
    if (this.state.showModal !== show) {
      this.setState({ showModal: show });
    }
  }

  handleRecurrenceAction = e => {
    this.setState({ showModal: false });
    this.props.handleRecurrenceAction(e.target.id);
  };

  render() {
    const { showModal } = this.state;

    const { title, body } = this.props;

    return (
      <TCModal
        show={showModal}
        subForm
        footer={
          <div>
            <Button
              id={InstanceMode.CANCEL}
              onClick={this.handleRecurrenceAction}
            >
              Abbrechen
            </Button>
            <Button
              id={InstanceMode.SERIES}
              onClick={this.handleRecurrenceAction}
            >
              Alle Ereignisse
            </Button>
            <Button
              id={InstanceMode.INSTANCE}
              onClick={this.handleRecurrenceAction}
              color="success"
            >
              Nur dieses Ereigniss
            </Button>
          </div>
        }
        classNameWrapper={cx("repetitiveModalContainer")}
      >
        <div className="row">
          <div className={cx("col-sm-12")}>
            <div className={cx("form-group", "titlecontainer")}>
              <span className={cx("title")}>{title}</span>
            </div>

            <div className={cx("repetitiveModalBody")}>{body}</div>
          </div>
        </div>
      </TCModal>
    );
  }
}

export default RecurrenceInstanceSwitchModal;
export { InstanceMode };
