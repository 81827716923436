/**
 * Created by kim on 23.11.16.
 */
import { call, put, takeLatest } from "redux-saga/effects";

import ApiService from "../../service/ApiService";
import {
  SHIPMENT_CRON_READ_REQUEST,
  SHIPMENT_CRON_READ,
  SHIPMENT_CRON_CREATE,
  SHIPMENT_CRON_CREATE_REQUEST
} from "./index";

function* readShipmentCron({ payload }) {
  try {
    const result = yield call(
      ApiService.getShipmentCron,
      payload.token,
      payload.shipment_id
    );
    yield put({
      type: SHIPMENT_CRON_READ,
      payload: result.scheduling
    });
  } catch (error) {
    yield put({
      type: SHIPMENT_CRON_READ,
      payload: error,
      error: true
    });
  }
}

function* createShipmentCron({ payload }) {
  try {
    const scheduling = yield call(
      ApiService.createShipmentCron,
      payload.token,
      payload.scheduling
    );
    yield put({
      type: SHIPMENT_CRON_CREATE,
      payload: scheduling
    });
  } catch (error) {
    yield put({
      type: SHIPMENT_CRON_CREATE,
      payload: error,
      error: true
    });
  }
}

export const readShipmentCronSaga = function* readShipmentCronSaga() {
  yield takeLatest(SHIPMENT_CRON_READ_REQUEST, readShipmentCron);
};

export const createShipmentCronSaga = function* createShipmentCronSaga() {
  yield takeLatest(SHIPMENT_CRON_CREATE_REQUEST, createShipmentCron);
};
