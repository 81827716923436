import ApiService from "service/ApiService";
import {
  STATUS_GET_REQUEST,
  STATUS_GET_RECEIVE,
  STATUS_GET_ERROR,
  STATUS_AVAILABLE_GET_RECEIVE,
  STATUS_GROUPS_GET_RECEIVE
} from "./constants";

/**
 * Get all status translations, available status and status groups.
 */
export const getStatus = () => async (dispatch, getState) => {
  dispatch({ type: STATUS_GET_REQUEST });
  try {
    const {
      auth: { token }
    } = getState();
    const [{ status }, { status: available }, { groups }] = await Promise.all([
      ApiService.getAppInfo(),
      ApiService.getShipmentStatus(token),
      ApiService.getShipmentStatusGroups(token)
    ]);
    dispatch({ type: STATUS_GET_RECEIVE, payload: status });
    dispatch({ type: STATUS_AVAILABLE_GET_RECEIVE, payload: available });
    dispatch({ type: STATUS_GROUPS_GET_RECEIVE, payload: groups });
    return { status, available, groups };
  } catch (error) {
    dispatch({ type: STATUS_GET_ERROR, error });
    return null;
  }
};
