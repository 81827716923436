class UserService {
  static getName(member) {
    if (!member) {
      return "";
    }
    return `${member.firstname} ${member.lastname}`;
  }

  static getId(member) {
    return member.id;
  }

  static getDriverLabel(driver) {
    return UserService.getName(driver);
  }

  static getExtendedDriverLabel(driver) {
    return [
      UserService.getName(driver),
      driver.ext_id && `ID: ${driver.ext_id}`,
      driver.tour && `Tour ${driver.tour}`
    ]
      .filter(e => e)
      .join(", ");
  }

  static getTourLabel(tour) {
    return tour.label;
  }

  static getDriverLabelForTour(tour) {
    if (tour.member_id) {
      return `${tour.firstname} ${tour.lastname}`;
    }

    return null;
  }
}

export default UserService;
