import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import classNames from "classnames/bind";

import Button from "../element/button";
import { loadDrivers } from "../../state/driver/driverAction";
import AddressService from "../../service/AddressService";
import DriverTourSelect from "../tc/element/select/DriverTourSelect";
import styleNames from "./shipmentActionBar.local.css";

const cx = classNames.bind(styleNames);

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const ContainerSelect = styled.div`
  flex: 1;
  margin-right: 4px;
`;

const ContainerAction = styled.div`
  flex: 1;
`;

class ShipmentActionBar extends Component {
  static propTypes = {
    token: PropTypes.string,
    handleAssignment: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    selectedShipments: PropTypes.array,
    selectedAssignment: PropTypes.object,
    focus: PropTypes.bool,
    onBlur: PropTypes.func,
    drivers: PropTypes.array,
    dispatch: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.select = null;
  }

  getPositionFromShipment() {
    let position = null;
    const { selectedShipments } = this.props;

    if (selectedShipments.length) {
      const latLng = AddressService.getLatLongFromShipment(
        selectedShipments[0]
      );
      if (latLng.latitude && latLng.longitude) {
        position = latLng;
      }
    }

    return position;
  }

  handleKeyDown = e => {
    switch (e.keyCode) {
      case 13: {
        // Enter
        const { handleSubmit } = this.props;

        handleSubmit();
        break;
      }
      default:
        break;
    }
  };

  handleKeyUp = e => {
    // handle escape pressed, there is no other chance
    if (e.keyCode === 27) {
      const { onBlur } = this.props;

      e.target.blur();
      onBlur();
    }
  };

  updateDrivers(filter) {
    const { token, dispatch } = this.props;
    // load shipments
    dispatch(loadDrivers(token, filter));
  }

  render() {
    const {
      handleSubmit,
      handleAssignment,
      selectedAssignment,
      focus,
      ...otherProps
    } = this.props;

    return (
      <Container
        {...otherProps}
        onKeyDown={this.handleKeyDown}
        onKeyUp={this.handleKeyUp}
      >
        <ContainerSelect>
          <DriverTourSelect
            placeholder="Fahrer / Tour wählen"
            calcDistanceFrom={this.getPositionFromShipment()}
            handleAssignment={handleAssignment}
            selectedItem={selectedAssignment}
            focus={focus}
          />
        </ContainerSelect>
        <ContainerAction>
          <span className={cx("filter-display")} />
          <Button
            color="default"
            text="Auftrag zuweisen"
            onClick={handleSubmit}
            className={cx("assign-btn")}
          />
        </ContainerAction>
      </Container>
    );
  }
}

function mapStateToProps({ auth, driver }) {
  return {
    token: auth.token,
    drivers: driver.drivers
  };
}

export default connect(mapStateToProps)(ShipmentActionBar);
