import { useState, useEffect, useCallback } from "react";

const getRange = range => {
  const lower = Math.min(...range);
  const upper = Math.max(...range);
  return [lower, upper];
};

const buildDefaultIdMap = idList => {
  let obj = {};
  idList.forEach(id => {
    obj[id] = false;
  });
  return obj;
};

const generateUniqueFingerprint = () => {
  const id = Math.random()
    .toString(36)
    .slice(-5);
  const timestamp = +new Date();
  return `${timestamp}_${id}`;
};

/**
 * A custom hook to manage a list of IDs
 * It maintains a map of ids with their selection status, e.g.: { 123: true, 123: false, ...}
 * @param ids {Array.<Number|String>}
 * @return Array the selectionMap and a function to select an item by id
 */
const useSelectIds = ids => {
  const itemCount = ids.length;

  const [selectionMap, setMap] = useState(buildDefaultIdMap(ids));
  const [isRangeSelect, setIsRangeSelect] = useState(false);
  const [isMultiSelect, setIsMultiSelect] = useState(false);
  const [lastCheckedIndex, setLastCheckedIndex] = useState(-1);
  const [selectionAction, setAction] = useState({
    id: null,
    fingerprint: null
  });

  useEffect(() => {
    const { id } = selectionAction;
    if (!id) {
      return;
    }
    const index = ids.indexOf(id);
    let newMap = {};
    if (isRangeSelect && lastCheckedIndex > -1) {
      const [lower, upper] = getRange([index, lastCheckedIndex]);
      newMap = ids.slice(lower, upper + 1).reduce(
        (mapX, item) => ({
          ...mapX,
          [item]: true
        }),
        selectionMap
      );
    } else if (isMultiSelect) {
      newMap = { ...selectionMap };
      newMap[id] = !newMap[id];
    } else {
      const checkValue = lastCheckedIndex === index ? !selectionMap[id] : true;
      newMap = buildDefaultIdMap(ids);
      newMap[id] = checkValue;
    }
    setLastCheckedIndex(index);
    setMap(newMap);
  }, [selectionAction.fingerprint]);

  const reset = ids => {
    setMap(buildDefaultIdMap(ids));
    setLastCheckedIndex(-1);
    setAction({ id: null, fingerprint: generateUniqueFingerprint() });
  };

  useEffect(() => {
    reset(ids);
  }, [itemCount]);

  // perf optimization: selection function needs to stay the same
  const selectItemById = useCallback(id => {
    setAction({ id, fingerprint: generateUniqueFingerprint() });
  }, []);

  const selectNext = () => {
    setAction({
      id: ids[lastCheckedIndex + 1],
      fingerprint: generateUniqueFingerprint()
    });
  };

  const selectPrevious = () => {
    setAction({
      id: ids[lastCheckedIndex - 1],
      fingerprint: generateUniqueFingerprint()
    });
  };

  const resetSelection = () => reset(ids);

  return [
    selectionMap,
    selectItemById,
    selectNext,
    selectPrevious,
    setIsMultiSelect,
    setIsRangeSelect,
    resetSelection,
    isRangeSelect
  ];
};

export default useSelectIds;
