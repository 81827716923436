import axios from "axios";
import { apiHost, apiKey } from "common/config";

const HEADER_AUTHORIZATION = "Authorization";
const HEADER_API_KEY = "X-TC-API-Key";
const HEADER_LOGISTIC_COMPANY = "X-Logisticcompany-ID";

// Axios instance with preset api key header
const apiV2 = axios.create({
  baseURL: `${apiHost}/v2`,
  headers: {
    [HEADER_API_KEY]: apiKey
  }
});

/**
 * Set token for the common 'Authorization' header.
 * @param {string} token
 */
export const setApiV2Token = token => {
  apiV2.defaults.headers.common[HEADER_AUTHORIZATION] = `Bearer ${token}`;
};

/**
 * Set logisticCompanyId for the common `X-TC-API-Key` header.
 * @param {number} logisticCompanyId
 */
export const setApiV2LogisticCompany = (logisticCompanyId = 0) => {
  if (!logisticCompanyId) {
    delete apiV2.defaults.headers.common[HEADER_LOGISTIC_COMPANY];
    return;
  }
  apiV2.defaults.headers.common[HEADER_LOGISTIC_COMPANY] = logisticCompanyId;
};

/**
 * Clear all common headers.
 */
export const clearApiV2Headers = () => {
  delete apiV2.defaults.headers.common[HEADER_AUTHORIZATION];
  delete apiV2.defaults.headers.common[HEADER_LOGISTIC_COMPANY];
};

/**
 * Get shipments.
 * @param {object} params
 * @param {number[]} params.ids
 * @param {Date} params.start
 * @param {Date} params.end
 */
export const getShipments = params => {
  const { ids = [], ...otherParams } = params;

  const parsedIds = ids.length > 0 ? ids.join(",") : undefined;

  return apiV2.get("/shipments", {
    params: { ids: parsedIds, ...otherParams }
  });
};

/**
 * Get folders and their jobs.
 */
export const getFolders = () => apiV2.get("/folders");

export const createExternalDriver = body =>
  apiV2.post("/users/external-driver", body);

export const createExternalDriverInviteLink = externalDriverId =>
  apiV2.post(`/users/external-drivers/${externalDriverId}/invite/link`, null);

export const setFolderMember = (folderId, memberID) =>
  apiV2.put(`/folders/${folderId}`, { memberID });

/**
 * Get unassigned jobs
 */
export const getUnassignedTasks = () => apiV2.get("/tasks?unassigned=true");

/**
 * Assign jobs to a folder
 */
export const assignTasks = (folderID, taskIDs) =>
  apiV2.post(`/folders/${folderID}/tasks`, {
    taskIDs
  });

/**
 * Unassign jobs
 */
export const unassignTasks = taskIDs =>
  apiV2.delete(`/tasks`, {
    data: { taskIDs }
  });

export const getAuthLoginTokens = ({ memberId = 0 }) =>
  apiV2.get(`/auth/login-token?memberId=${memberId}`);

export const getAuthLoginTokenInfo = ({ loginToken = "" }) =>
  apiV2.get(`/auth/login-token/${loginToken}/info`);
