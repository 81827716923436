import React, { Component } from "react";
import PropTypes from "prop-types";
import { AppBar } from "features/appBar";
import { Map } from "features/map";
import styled from "styled-components";
import ModalPage from "containers/modal/ModalPage";
// TODO: Refactor SidebarRight and put it in chat feature
import SiderbarRightChat from "components/layout/sidebarRight/SiderbarRight";
import Footer from "components/layout/footer/Footer";
import LayoutContext from "./LayoutContext";
import { ActionBarRenderer, Loading } from "./components";

const initialState = {
  driver: {},
  showShipmentModal: false,
  showAdditionalModal: null,
  hide: false,
  addJobButtonDisabled: false,
  actionBar: null
};

export default class App extends Component {
  static propTypes = {
    /** indicates a reload while switching to another logisticcompany */
    reload: PropTypes.bool.isRequired,
    children: PropTypes.any,
    layoutV2: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
    this.state = initialState;
    this.actionBarPropHandler = () => {};
  }

  componentDidUpdate(prevProps) {
    const { reload } = this.props;
    // Reset state after switching to another logisticcompany.
    if (prevProps.reload === true && reload === false) {
      // componentWillReceiveProps is deprecated and in this case
      // it makes sense to use setState for this life cycle function.
      // See: https://reactjs.org/docs/react-component.html#componentdidupdate
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(initialState);
    }
  }

  /**
   * Context function to render ActionBar props from everywhere.
   */
  setActionBarProps = props => {
    this.actionBarPropHandler(props);
  };

  handleDriverClicked = (driver, hide) => {
    this.setState({
      driver,
      hide
    });
  };

  handleCreateShipment = () => {
    this.setState({ showShipmentModal: true, addJobButtonDisabled: true });
  };

  handleModalOnClose = () => {
    this.setState({
      showShipmentModal: false,
      showAdditionalModal: null,
      addJobButtonDisabled: false
    });
  };

  handleMenuItemClicked = name => {
    switch (name) {
      case "settings":
        this.setState({ showAdditionalModal: name });
        break;
      default:
        console.log("coming soon");
        break;
    }
  };

  render() {
    const { setActionBarProps } = this;
    const { reload, children, layoutV2 } = this.props;

    const {
      driver,
      hide,
      showShipmentModal,
      showAdditionalModal,
      addJobButtonDisabled
    } = this.state;

    // backward compatibility, just render the children
    let layout = children;

    // render new layout with global ActionBar and Map
    if (layoutV2) {
      layout = (
        <PageWrapper>
          <ActionBarRenderer
            handler={handler => {
              this.actionBarPropHandler = handler;
            }}
          />
          <SplitScreen>
            <SplitScreenContent flex={1.2}>
              {/*
              Using React Context API to control rendering
              of the global component ActionBar.

              - setActionBarProps will be called by <ActionBar />
              */}
              <LayoutContext.Provider value={{ setActionBarProps }}>
                {children}
              </LayoutContext.Provider>
            </SplitScreenContent>
            <SplitScreenContent>
              <Map handleScopes={["drivers", "scheduling"]} />
            </SplitScreenContent>
          </SplitScreen>
        </PageWrapper>
      );
    }

    return (
      <div>
        <AppBar
          handleCreateShipment={this.handleCreateShipment}
          handleMenuItemClicked={this.handleMenuItemClicked}
          shipmentButtonDisabled={addJobButtonDisabled}
        />
        <Wrapper navbarHeight={51}>
          <Content className="modal-container">
            {reload ? <Loading /> : layout}
            {reload === false && (
              <ModalPage
                showShipmentModal={showShipmentModal}
                showAdditionalModal={showAdditionalModal}
                handleOnClose={this.handleModalOnClose}
              />
            )}
          </Content>
          <SidebarRight>
            {reload === false && (
              <SiderbarRightChat
                handleDriverClicked={this.handleDriverClicked}
              />
            )}
          </SidebarRight>
        </Wrapper>

        {reload === false && <Footer chatPartner={driver} hide={hide} />}
      </div>
    );
  }
}

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  top: ${props => props.navbarHeight || 0}px;
  right: 0;
  bottom: 0;
  left: 0;
`;

const Content = styled.div`
  display: flex;
  flex: 5;
`;

const SidebarRight = styled.div`
  flex: 1;
  max-width: 220px;
  background: #f2f2f2;
  border-left: 1px solid #d6d7da;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SplitScreen = styled.div`
  display: flex;
  flex: 1;
  min-height: 0;
`;

export const SplitScreenContent = styled.div`
  display: flex;
  flex: ${props => props.flex || 1};
  flex-direction: column;
  border-right: 1px solid #d6d7da;
  height: 100%;
`;
