import { IMPORT_CREATE } from "./index";

const initialState = {
  imported: {},
  loaded: false,
  error: null
};

export default function auth(state = initialState, action = {}) {
  const { type, payload, error } = action;

  if (error) {
    return {
      ...state,
      error: payload
    };
  }

  switch (type) {
    case IMPORT_CREATE:
      return {
        ...state,
        loaded: true,
        error: null
      };

    default:
      return state;
  }
}
