import {
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
  LOGISTICCOMPANY_PREPARE_CHANGE,
  LOGISTICCOMPANY_CHANGED
} from "./index";

export const login = (username, password) => ({
  type: LOGIN_REQUEST,
  payload: {
    username,
    password
  }
});

export const logout = () => ({
  type: LOGOUT_REQUEST
});

/**
 * Change logisticcompany_id.
 * @param {number} logisticCompanyId
 */
export const changeLogisticCompany = logisticCompanyId => ({
  type: LOGISTICCOMPANY_PREPARE_CHANGE,
  logisticCompanyId
});

export const logisticCompanyChanged = () => ({ type: LOGISTICCOMPANY_CHANGED });
