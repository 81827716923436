import { call, put, takeLatest } from "redux-saga/effects";

import ApiService from "../../service/ApiService";
import { GROUP_READ_REQUEST, GROUP_READ } from "./index";

function* fetchGroup({ payload }) {
  try {
    const result = yield call(ApiService.getGroups, payload.token);
    yield put({
      type: GROUP_READ,
      payload: result.groups
    });
  } catch (error) {
    yield put({
      type: GROUP_READ,
      payload: error,
      error: true
    });
  }
}

export default function* loadGroupSaga() {
  yield takeLatest(GROUP_READ_REQUEST, fetchGroup);
}
