import React, { useEffect, useState, useMemo, useLayoutEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import styled, { css } from "styled-components";
import { Flex, Box } from "reflexbox";
import PropTypes from "prop-types";
import CI from "@trackcode/ci";
import {
  Icon,
  MultiSelection,
  StandardButton,
  StandardDropdown
} from "@trackcode/ui";
import { useSelector, useDispatch } from "react-redux";
import { endOfDay, startOfDay, subDays, addDays } from "date-fns";
import { HotKeys } from "react-hotkeys";
import Helmet from "react-helmet";
import { getMembers } from "state/entities/members/actions";
import * as membersSelectors from "state/entities/members/selectors";
import { getDrivers } from "state/entities/members/selectors";
import { getStatus } from "state/entities/shipmentStatus/actions";
import * as folderSelectors from "state/entities/folders/selectors";
import { ActionBar } from "../app/components/ActionBar";
import {
  getFoldersAndShipments,
  setFilterFolders,
  setFilterDriver,
  setScrollTop
} from "./state/actions";
import {
  getUnassignedTasks,
  getFolderWithTaskByID,
  getSortedAndFilteredFolders
} from "./state/selectors";
import { useSelectIds } from "./hooks";
import { TaskItem, TaskItemHeader, Assignment } from "./components";
import { ExternalAssignment } from "./ExternalAssignment";

export const Folders = () => {
  const dispatch = useDispatch();

  const folders = useSelector(folderSelectors.getFolders);
  const sortedAndFilteredFolderIds = useSelector(getSortedAndFilteredFolders);
  const drivers = useSelector(getDrivers);

  const { filters, scrollTop } = useSelector(({ folder }) => ({
    filters: {
      statusIds: folder.selectedStatusIds,
      folderIds: folder.selectedFolderIds,
      driverIds: folder.selectedDriverIds
    },
    scrollTop: folder.scrollTop
  }));

  const selectFolders = folderIds => dispatch(setFilterFolders(folderIds));
  const selectDrivers = driverIds => dispatch(setFilterDriver(driverIds));

  const folderItems = Object.values(folders).map(folder => ({
    value: folder.id,
    content: folder.label
  }));

  const driverItems = Object.values(drivers).map(driver => ({
    value: driver.id,
    content: `${driver.firstname} ${driver.lastname}`
  }));

  useEffect(() => {
    // load data
    const start = startOfDay(subDays(new Date(), 7));
    const end = endOfDay(addDays(new Date(), 7));
    dispatch(getFoldersAndShipments(start, end));
    dispatch(getMembers());
    dispatch(getStatus());
  }, [dispatch]);

  const listWrapperRef = React.useRef(null);

  useLayoutEffect(() => {
    // restore and save scroll position
    const { current } = listWrapperRef;
    if (current) {
      current.scrollTop = scrollTop;
    }
    return () => {
      if (current) {
        dispatch(setScrollTop(current.scrollTop));
      }
    };
  }, [listWrapperRef, dispatch, scrollTop]);

  return (
    <>
      <Route
        path="/folders/:folderID/jobs/external"
        render={() => <ExternalAssignment />}
      />
      <ListWrapper ref={listWrapperRef}>
        <Helmet title="Arbeitsmappen" />
        <ActionBar style={{ backgroundColor: "#F2F2F2" }}>
          <Box width={2 / 3}>
            <MultiSelection
              placeholder="Arbeitsmappen: Alle"
              items={folderItems}
              allowClear
              onChange={selectedFolderItems =>
                selectFolders(selectedFolderItems.map(item => item.value))
              }
              selectedItems={folderItems.filter(item =>
                filters.folderIds.includes(item.value)
              )}
              buttonTextWrap="ellipsis"
              buttonStyle={{ maxWidth: "250px" }}
            />
            <MultiSelection
              placeholder="Fahrer: Alle"
              items={driverItems}
              allowClear
              onChange={selectedDriverItems =>
                selectDrivers(selectedDriverItems.map(item => item.value))
              }
              selectedItems={driverItems.filter(item =>
                filters.driverIds.includes(item.value)
              )}
              buttonTextWrap="ellipsis"
              buttonStyle={{ maxWidth: "250px" }}
            />
          </Box>
          <Box width={1 / 3} style={{ textAlign: "right" }}>
            0 von 0
          </Box>
        </ActionBar>
        <UnassignedFolder></UnassignedFolder>
        {sortedAndFilteredFolderIds.map(folderId => (
          <Folder key={folderId} folderId={folderId} />
        ))}
      </ListWrapper>
    </>
  );
};

const TaskList = ({ tasks }) => {
  const taskIDs = tasks.map(({ id }) => id);
  const taskCount = taskIDs.length;

  const {
    prevPage,
    nextPage,
    currentPage,
    numberOfPages,
    pageStartIndex,
    pageEndIndex
  } = usePagination({
    numberOfEntries: taskCount,
    currentPageInitial: 1
  });

  const [
    selectedIdsMap,
    selectById,
    selectNext,
    selectPrevious,
    setMultiSelect,
    setRangeSelect,
    resetSelection,
    isRangeSelect
  ] = useSelectIds(taskIDs);
  const selectedTaskIDs = useMemo(() => {
    return Object.keys(selectedIdsMap).filter(id => selectedIdsMap[id]);
  }, [selectedIdsMap]);

  const isSelectionMode = selectedTaskIDs.length > 0;

  const hotKeyMap = {
    RESET_SELECTION: "esc",
    SELECT_NEXT: ["down", "shift+down"],
    SELECT_PREVIOUS: ["up", "shift+up"],
    ENABLE_MULTI_SELECT: { sequence: "command", action: "keydown" },
    DISABLE_MULTI_SELECT: { sequence: "command", action: "keyup" },
    ENABLE_RANGE_SELECT: { sequence: "shift", action: "keydown" },
    DISABLE_RANGE_SELECT: { sequence: "shift", action: "keyup" }
  };

  const hotKeyHandlers = {
    RESET_SELECTION: resetSelection,
    SELECT_NEXT: selectNext,
    SELECT_PREVIOUS: selectPrevious,
    ENABLE_MULTI_SELECT: () => setMultiSelect(true),
    DISABLE_MULTI_SELECT: () => setMultiSelect(false),
    ENABLE_RANGE_SELECT: () => setRangeSelect(true),
    DISABLE_RANGE_SELECT: () => setRangeSelect(false)
  };

  return (
    <>
      {isSelectionMode && (
        <ActionBar style={{ backgroundColor: "#98C566" }}>
          <Assignment
            selectedTaskIDs={selectedTaskIDs}
            onCancel={() => resetSelection()}
          />
        </ActionBar>
      )}
      {taskCount > 0 && (
        <FolderBody>
          <HotKeys
            keyMap={hotKeyMap}
            handlers={hotKeyHandlers}
            component={FocusDiv}
          >
            {tasks.slice(pageStartIndex, pageEndIndex).map((task, index) => (
              <TaskItem
                taskID={task.id}
                isSelected={selectedIdsMap[task.id] || false}
                select={selectById}
                setMultiSelect={setMultiSelect}
                style={{ userSelect: isRangeSelect ? "none" : "auto" }}
              />
            ))}
            <Flex style={{ borderTop: `1px solid ${CI.color.gray}` }}>
              <Box flex={1} style={{ textAlign: "center" }}>
                <StandardButton
                  appearance="subtle"
                  disabled={currentPage === 1}
                  onClick={prevPage}
                  spaceAfter={false}
                >
                  <Icon name="angle-up" fill="#333" width="20" height="20" />
                </StandardButton>
                &nbsp;
                <span style={{ color: "#777777", fontSize: "11px" }}>
                  {pageStartIndex + 1}-{pageEndIndex} von {taskCount}
                </span>
                &nbsp;
                <StandardButton
                  appearance="subtle"
                  disabled={currentPage === numberOfPages}
                  onClick={nextPage}
                  spaceAfter={false}
                >
                  <Icon name="angle-down" fill="#333" width="20" height="20" />
                </StandardButton>
              </Box>
            </Flex>
          </HotKeys>
        </FolderBody>
      )}
    </>
  );
};

const UnassignedFolder = () => {
  const folder = {
    tasks: useSelector(getUnassignedTasks),
    label: "Keine Zuweisung",
    folderID: 0
  };

  const taskIDs = folder.tasks.map(({ id }) => id);
  const taskCount = taskIDs.length;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <FolderContainer isOpen={isOpen}>
        <FolderHeader isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
          <Flex>
            <Flex pt="6px" pl="16px" alignItems="center">
              <FolderOpenIcon width="22" height="22" fill="#666" />
              <span style={{ marginLeft: "6px" }}>{folder.label}</span>
            </Flex>
            <Flex flex={1} justifyContent="flex-end"></Flex>
          </Flex>
          <Flex pl="37px" pt="3px">
            <Box flex={2}>
              <GroupTab
                count={taskCount}
                isSelected={isOpen}
                onSelect={() => setIsOpen(!isOpen)}
              >
                Aufgaben
              </GroupTab>
            </Box>
          </Flex>
          {isOpen && <TaskItemHeader />}
        </FolderHeader>
        {isOpen && <TaskList tasks={folder.tasks}></TaskList>}
      </FolderContainer>
    </>
  );
};

const Folder = ({ folderId }) => {
  // TODO: Factory function should only be used if the parameter is not dynamic.
  // See: https://github.com/reduxjs/reselect/blob/master/README.md#q-how-do-i-create-a-selector-that-takes-an-argument
  const folder = useSelector(getFolderWithTaskByID(folderId));
  const tasks = folder.tasks;
  const membersMap = useSelector(membersSelectors.getMembers);

  const history = useHistory();
  const taskCount = tasks.length;

  const [isOpen, setIsOpen] = useState(false);

  const member = folder ? membersMap[folder.memberID] : null;

  return (
    <>
      <FolderContainer isOpen={isOpen}>
        <FolderHeader isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
          <Flex>
            <Flex pt="6px" pl="16px" alignItems="center">
              {member ? (
                <FolderSharedIcon width="22" height="22" fill="#666" />
              ) : (
                <FolderIcon width="22" height="22" fill="#666" />
              )}

              <span style={{ marginLeft: "6px" }}>{folder.label}</span>
            </Flex>
            <Flex flex={1} justifyContent="flex-end">
              {folderId > 0 && (
                <StandardDropdown
                  placement="bottomRight"
                  renderButton={({ toggle, isOpen }) => (
                    <StandardButton
                      appearance="subtle"
                      style={{
                        borderRadius: "0",
                        borderTopRightRadius: "3px",
                        borderBottomLeftRadius: "2px",
                        padding: "2px 8px",
                        minHeight: "auto"
                      }}
                      spaceAfter={false}
                      onClick={e => {
                        e.stopPropagation();
                        toggle();
                      }}
                    >
                      <AlignedIcon name="more-vertical" size="md" />
                    </StandardButton>
                  )}
                >
                  {Item => [
                    <Item
                      onClick={e => {
                        e.stopPropagation();
                        history.push(`/folders/${folderId}/jobs/external`);
                      }}
                      key="external"
                    >
                      Extern vergeben
                    </Item>
                  ]}
                </StandardDropdown>
              )}
            </Flex>
          </Flex>
          <Flex pl="37px" pt="3px">
            <Box flex={2}>
              <GroupTab
                count={taskCount}
                isSelected={isOpen}
                onSelect={() => setIsOpen(!isOpen)}
              >
                Aufgaben
              </GroupTab>
            </Box>
            {member && (
              <Box flex={1} textAlign="right" pr="16px">
                {member.firstname} {member.lastname}
              </Box>
            )}
          </Flex>
          {isOpen && <TaskItemHeader />}
        </FolderHeader>
        {isOpen && <TaskList tasks={folder.tasks}></TaskList>}
      </FolderContainer>
    </>
  );
};

Folder.propTypes = {
  folderId: PropTypes.number.isRequired,
  shipmentIds: PropTypes.arrayOf(PropTypes.number).isRequired
};

const FolderContainer = styled.div`
  margin: ${({ isOpen }) => (isOpen ? "18px 11px" : "6px 11px")};
  background: #fff;
  border-radius: 3px;
`;

const FolderHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
  background: #fff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.35);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  cursor: pointer;
  ${({ isOpen }) =>
    !isOpen
      ? css`
          border-radius: 3px;
        `
      : ""}
`;

const FolderBody = styled.div`
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.35);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
`;

const GroupTabButton = styled(StandardButton)`
  min-height: auto;
  padding: 2px 6px;
  border-bottom-width: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  ${({ isSelected }) =>
    isSelected
      ? css`
          border-color: ${CI.color.gray} !important;
          background: none !important;
        `
      : ""}
`;
const GroupTabDivider = styled.div`
  display: inline-block;
  margin-left: 4px;
  margin-right: 4px;
  width: 1px;
  height: 10px;
  background-color: ${CI.color.gray};
`;
const GroupTab = ({ count, children, isSelected = false, onSelect }) => {
  let opacity = 0.85;
  if (isSelected) {
    opacity = 1.0;
  }
  if (count === 0) {
    opacity = 0.5;
  }
  return (
    <GroupTabButton
      appearance="subtle"
      disabled={count <= 0}
      onClick={onSelect}
      isSelected={isSelected}
      style={{
        opacity,
        cursor: count === 0 ? "inherit" : "pointer"
      }}
    >
      {children}
      {count >= 0 && (
        <>
          <GroupTabDivider />
          {count}
        </>
      )}
    </GroupTabButton>
  );
};

const ListWrapper = styled.div`
  position: relative;
  flex: 1;
  overflow-y: scroll;
  min-width: 650px;
  background: ${CI.color.grayLight};
`;

// see https://github.com/greena13/react-hotkeys#blue-border-appears-around-children-of-hotkeys
const FocusDiv = styled.div`
  &:focus {
    outline: 0;
  }
`;

// Source: https://material.io/resources/icons/?search=folder&icon=folder&style=baseline
const FolderIcon = props => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z" />
    </svg>
  );
};

// Source: https://material.io/resources/icons/?search=folder&icon=folder_shared&style=baseline
const FolderSharedIcon = props => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M20 6h-8l-2-2H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-5 3c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm4 8h-8v-1c0-1.33 2.67-2 4-2s4 .67 4 2v1z" />
    </svg>
  );
};

// Source: https://material.io/resources/icons/?search=folder&icon=folder_open&style=baseline
const FolderOpenIcon = props => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M20 6h-8l-2-2H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm0 12H4V8h16v10z" />
    </svg>
  );
};

const AlignedIcon = props => (
  // eslint-disable-next-line react/prop-types
  <Icon {...props} style={{ verticalAlign: "middle", ...props.style }} />
);

const usePagination = ({
  numberOfEntries = 0,
  entriesPerPage = 10,
  currentPageInitial = 1
}) => {
  const [currentPage, setCurrentPage] = useState(currentPageInitial);

  const numberOfPages = Math.ceil(numberOfEntries / entriesPerPage);
  const pageStartIndex = (currentPage - 1) * entriesPerPage;
  const pageEndIndex =
    currentPage === numberOfPages
      ? numberOfEntries
      : currentPage * entriesPerPage;

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const nextPage = () => {
    if (currentPage < numberOfPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return {
    prevPage,
    nextPage,
    currentPage,
    numberOfPages,
    pageStartIndex,
    pageEndIndex
  };
};
