import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import styled from "styled-components";
import { Map, mapActions, mapUtils } from "features/map";
import { SplitScreen, SplitScreenContent } from "components";

import Loading from "../../components/Loading/Loading";
import { loadTrack } from "../../state/track/trackAction";
import TrackingDetails from "../../components/tracking/TrackingDetails";
import ActionBarContainer from "../../components/scheduling/ActionBarContainer";
import TrackingActionBar from "../../components/tracking/TrackingActionBar";
import CreateEditShowShipment from "../../containers/shipment/CreateEditShowShipment";

const SplitScreenContentTracking = styled(SplitScreenContent)`
  display: flex;
  flex-direction: column;
  border-right: 1px solid #d6d7da;
`;

class Tracking extends Component {
  static propTypes = {
    tracking: PropTypes.object,
    token: PropTypes.string.isRequired,
    match: PropTypes.object,
    dispatch: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.trackingCode = "";
  }

  state = {
    showShipmentDetailModal: false
  };

  componentDidMount() {
    const {
      match: {
        params: { code }
      }
    } = this.props;
    this.track(code);
  }

  componentDidUpdate(prevProps) {
    const {
      tracking,
      match: {
        params: { code }
      }
    } = this.props;
    this.showOnMap(tracking);

    if (prevProps.match.params.code !== code) {
      this.track(code);
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(mapActions.clear("tracking"));
  }

  showOnMap() {
    const { tracking, dispatch } = this.props;

    if (!tracking) return;

    const { pins } = mapUtils.shipmentToMapElements(tracking);

    if (pins) {
      dispatch(
        mapActions.set(
          "tracking",
          { pins },
          {
            fitBounds: true,
            zoom: 15
          }
        )
      );
    }
  }

  track(code) {
    const { token, dispatch } = this.props;
    this.trackingCode = code;
    dispatch(loadTrack(token, { track: code }));
  }

  openShipmentDetailModal = () => {
    this.setState(() => ({ showShipmentDetailModal: true }));
  };

  closeShipmentDetailModal = () => {
    this.setState(() => ({ showShipmentDetailModal: false }));
  };

  render() {
    const { tracking, token } = this.props;

    const { showShipmentDetailModal } = this.state;

    return (
      <Fragment>
        <Helmet title="Tracking" />

        {showShipmentDetailModal && (
          <CreateEditShowShipment
            showModal
            shipment={tracking}
            handleClose={this.closeShipmentDetailModal}
            editMode={false}
            willBeEdited
          />
        )}

        <SplitScreen>
          <SplitScreenContentTracking flex={1.2}>
            {!tracking && <Loading />}
            {tracking && (
              <Fragment>
                <ActionBarContainer>
                  <TrackingActionBar
                    trackcode={tracking.trackcode}
                    tracking={tracking}
                    openShipmentDetailModal={this.openShipmentDetailModal}
                    token={token}
                  />
                </ActionBarContainer>
                <TrackingDetails shipment={tracking} />
              </Fragment>
            )}
          </SplitScreenContentTracking>
          <SplitScreenContent>
            <Map handleScopes={["tracking"]} />
          </SplitScreenContent>
        </SplitScreen>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ auth, track }) => ({
  token: auth.token,
  tracking: track.tracking
});

export default connect(mapStateToProps)(Tracking);
