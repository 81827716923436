import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Map } from "@trackcode/map";
import { Scopes, ScopeDrivers } from "./";
import mapActions from "../state/mapActions";

class MapWrapper extends Component {
  static propTypes = {
    /** clear scope options */
    clearOptions: PropTypes.func.isRequired,
    /** list of handle scopes, default all scopes */
    handleScopes: PropTypes.arrayOf(PropTypes.string),
    companyLat: PropTypes.number.isRequired,
    companyLng: PropTypes.number.isRequired,
    logisticCompanyId: PropTypes.number.isRequired,
    managedLogisticCompanies: PropTypes.shape({
      id: PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number
      })
    }).isRequired
  };

  static defaultProps = {
    handleScopes: []
  };

  constructor(props) {
    super(props);
    this.driversBounds = [];
  }

  state = {
    driversBounds: [],
    loadScopes: false
  };

  setMapRef = ref => {
    if (!this.mapRef) {
      this.mapRef = ref;
      this.forceUpdate();
    } else {
      this.mapRef = ref;
    }
  };

  setDriversBounds = driversBounds => this.setState({ driversBounds });

  getCompanyLatLng() {
    const {
      companyLat,
      companyLng,
      logisticCompanyId,
      managedLogisticCompanies
    } = this.props;
    if (managedLogisticCompanies[logisticCompanyId]) {
      const { latitude: lat, longitude: lng } = managedLogisticCompanies[
        logisticCompanyId
      ];
      if (lat && lng) {
        return { lat, lng };
      }
    }
    // coordinates of the default logisticcompany
    return {
      lat: companyLat,
      lng: companyLng
    };
  }

  handleOptions = (scopeNames, scopes) => {
    const { clearOptions, handleScopes } = this.props;
    const { driversBounds } = this.state;

    if (!scopeNames.length) return;

    // only support options for one scope simultaneously
    const [scopeName] = scopeNames.filter(
      name => scopes[name] && scopes[name].options
    );

    if (!scopeName) return;
    if (handleScopes.length > 0 && !handleScopes.includes(scopeName)) return;

    const { options } = scopes[scopeName];

    if (options.fitBounds) {
      const bounds = scopeName === "drivers" ? driversBounds : options.bounds;
      if (bounds && bounds.length > 0) {
        this.mapRef.fitBounds(bounds);
      }
    }
    if (options.zoom >= 0) {
      this.mapRef.setZoom(options.zoom);
    }

    clearOptions(scopeName);
  };

  loadScopes = () => {
    this.setState({ loadScopes: true });
  };

  render() {
    const { handleScopes } = this.props;
    const { loadScopes } = this.state;

    return (
      <Map
        defaultZoom={12}
        defaultCenter={this.getCompanyLatLng()}
        ref={this.setMapRef}
      >
        {this.mapRef && (
          <Fragment>
            <ScopeDrivers
              driverLocationsLoaded={this.loadScopes}
              getBounds={this.setDriversBounds}
            />
            {loadScopes && (
              <Scopes
                handleOptions={this.handleOptions}
                handleScopes={handleScopes}
              />
            )}
          </Fragment>
        )}
      </Map>
    );
  }
}

const mapStateToProps = ({
  auth: {
    user: { companylatitude: companyLat, companylongitude: companyLng },
    logisticCompanyId = 0,
    managedLogisticCompanies = {}
  }
}) => ({
  companyLat,
  companyLng,
  logisticCompanyId,
  managedLogisticCompanies
});

const mapDispatchToProps = {
  clearOptions: mapActions.clearOptions
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MapWrapper);
