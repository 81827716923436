import React, { useEffect, useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMembers } from "state/entities/members/actions";
import { getStatus } from "state/entities/shipmentStatus/actions";
import styled from "styled-components";
import {
  format,
  getYear,
  getMonth,
  getDate,
  setYear,
  setMonth,
  setDate,
  setHours,
  setMinutes,
  setSeconds,
  isBefore,
  addMinutes
} from "date-fns";
import { getAvailableShipmentStatus } from "state/entities/shipmentStatus/selectors";
import {
  StandardButton,
  StandardSelection,
  StandardInput,
  NestedSelection,
  MultiSelection
} from "@trackcode/ui";
import { StandardDateInput } from "@trackcode/datepicker";
import { ProgressMedia } from "features/shipment/components";
import ApiService from "service/ApiService";
import { formatToStatusVariationsNested } from "./utils/statusVariations";

const TIME_FORMAT = "HH:mm";

const DateTimePicker = ({ value, onChange }) => {
  const [timeValue, setTimeValue] = useState(format(new Date(), TIME_FORMAT));

  const resetValue = () => {
    const current = new Date();
    setTimeValue(format(current, TIME_FORMAT));
    onChange(current);
  };

  /**
   * Update year, month, date (day of month).
   * @param {Date} dateValue
   */
  const updateDate = date => {
    const newDateTime = setYear(
      setMonth(setDate(new Date(value), getDate(date)), getMonth(date)),
      getYear(date)
    );
    onChange(newDateTime);
  };

  /**
   * Update hours, minutes based on `timeValue` state.
   */
  const updateTime = () => {
    // Allow following formats:
    // `HH:mm`, `H:m`, `HH`, `H`, ...
    if (!/^[0-9]{1,2}(:[0-9]{1,2})?$/.test(timeValue)) {
      resetValue();
      return;
    }

    const [hours = "0", minutes = "0"] = timeValue.split(":");
    const newDateTime = setHours(
      setMinutes(setSeconds(value, 0), minutes),
      hours
    );

    const isTimeInPast = isBefore(newDateTime, addMinutes(new Date(), 1));
    if (isTimeInPast) {
      setTimeValue(format(newDateTime, TIME_FORMAT));
      onChange(newDateTime);
    } else {
      resetValue();
    }
  };

  return (
    <>
      <div style={{ display: "inline-block" }}>
        <StandardDateInput
          style={{ width: "115px" }}
          lookAndFeel="button"
          iconBefore={{
            name: "calendar-outline"
          }}
          maxDate={new Date()}
          selected={value}
          onChange={updateDate}
        />
      </div>
      <StandardInput
        style={{ width: "80px" }}
        lookAndFeel="button"
        iconBefore={{
          name: "clock-outline"
        }}
        value={timeValue}
        onChange={({ target }) => setTimeValue(target.value)}
        onBlur={() => updateTime()}
        onKeyDown={({ key }) => key === "Enter" && updateTime()}
      />
    </>
  );
};

/**
 * Hook to load status variations and to get transformed status variations
 * in nested format for `<NestedSelection />`.
 * @param {Array} initial
 * @returns {[Array, func]}
 */
const useStatusVariationsNested = initial => {
  const [payload, setPayload] = useState(initial);

  /**
   * Load status variations.
   * @param {string} token
   */
  const load = useCallback(async token => {
    try {
      const { variations } = await ApiService.getStatusVariations(token);
      const nested = formatToStatusVariationsNested(variations);
      setPayload(nested);
    } catch (err) {
      console.error("Could not load tracking information", err);
    }
  }, []);

  /**
   * Get items for a statusId.
   * @param {number} selectedStatusId
   */
  const byStatusId = selectedStatusId => {
    const data = payload.filter(
      ({ statusId }) => statusId === selectedStatusId
    );
    // add custom logic:
    if (selectedStatusId === 6) {
      // ability to set "keine Abweichung"
      return [
        {
          content: "keine Abweichung",
          parentId: null,
          signatureRequired: 0,
          statusId: 6,
          value: 0,
          variationType: "listitem"
        },
        ...data
      ];
    }
    return data;
  };

  return [byStatusId, load];
};

/**
 * Hook to manage selected shipment ids and to get selection items
 * which depends on pieces or the shipment object itself.
 * @param {Array} pieces
 * @param {Object} shipment
 * @returns {[Array, Array, func]}
 */
const useShipmentReferences = (pieces, shipment) => {
  const [selectedIds, setSelectedIds] = useState([]);

  const items = useMemo(() => {
    if (pieces.length > 0) {
      return pieces.map(({ shipment_id: value, label: content }) => ({
        value,
        content
      }));
    }
    if (shipment) {
      return [
        {
          value: shipment.id,
          content: shipment.ext_reference || shipment.trackcode
        }
      ];
    }
    return [];
  }, [pieces, shipment]);

  useEffect(() => {
    // select all items by default
    setSelectedIds(items.map(({ value }) => value));
  }, [items]);

  return [selectedIds, items, setSelectedIds];
};

/**
 * Hook to create shipment progress and
 * with utility to validate form data.
 * @returns {[boolean, boolean, func, func]}
 */
const useCreateShipmentProgress = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  /**
   * Check payload validation.
   * @param {Object} payload
   */
  const validate = ({
    selectedShipmentIds,
    selectedStatusId,
    selectedMemberId
  }) =>
    selectedShipmentIds.length > 0 &&
    selectedStatusId > 0 &&
    selectedMemberId > 0;

  /**
   * Save shipment progress.
   * @param {object} payload
   * @param {string} token
   */
  const create = async (
    token,
    {
      selectedShipmentIds,
      selectedStatusId,
      selectedVariationId,
      selectedMemberId,
      selectedDateTime,
      mediaType,
      mediaObj,
      mediaFiles
    }
  ) => {
    setIsLoading(false);
    setHasError(false);
    const media = { ...mediaObj };
    try {
      if (mediaFiles) {
        const {
          grid: { files_id: uuid }
        } = await ApiService.uploadGrid(token, mediaFiles);
        media.imagenode.images[0].uuid = uuid;
      }
      for (const key in selectedShipmentIds) {
        const shipmentId = selectedShipmentIds[key];
        await ApiService.createShipmentProgress(token, shipmentId, {
          statusId: selectedStatusId,
          statusVariationId: selectedVariationId,
          memberId: selectedMemberId,
          clientDatetime: selectedDateTime.toISOString(),
          media: mediaType ? media : null
        });
      }
      setIsLoading(true);
      return true;
    } catch (err) {
      setHasError(true);
      console.error("Could not save shipment progress", err);
      return false;
    }
  };

  return [isLoading, hasError, validate, create];
};

const ProgressAdd = ({
  shipmentId,
  shipmentPieces,
  onClose,
  onSuccess,
  token,
  shipment,
  members,
  status,
  dispatch
}) => {
  const [selectedStatusId, setSelectedStatusIdState] = useState(null);
  const [selectedVariationId, setSelectedVariationId] = useState(null);
  const [mediaType, setMediaType] = useState("");
  const [mediaObj, setMediaObj] = useState(null);
  const [mediaFiles, setMediaFiles] = useState(null);
  const [selectedDateTime, setSelectedDateTime] = useState(new Date());
  const [selectedMemberId, setSelectedMemberId] = useState(null);

  const [
    selectedShipmentIds,
    shipmentItems,
    setSelectedShipmentIds
  ] = useShipmentReferences(shipmentPieces, shipment);

  /**
   * Set statusId and manage variation and media states.
   * @param {number} statusId
   */
  const setSelectedStatusId = statusId => {
    if (selectedStatusId !== statusId) {
      // reset
      setSelectedVariationId(null);
    }
    // set custom mediaType for status
    const mediaTypeByStatusId = {
      6: "signature",
      20: "imagepicker"
    };
    const mediaType = mediaTypeByStatusId[statusId];
    if (mediaType) {
      setMediaType(mediaType);
    }
    // update status
    setSelectedStatusIdState(statusId);
  };

  const statusItems = status.map(({ id, label }) => ({
    value: id,
    content: label
  }));

  const [
    getStatusVariationsByStatusId,
    fetchStatusVariations
  ] = useStatusVariationsNested([]);
  const variationItems = getStatusVariationsByStatusId(selectedStatusId);

  const memberItems = Object.values(members.entities).map(
    ({ id, firstname, lastname }) => ({
      value: id,
      content: `${firstname} ${lastname}`
    })
  );

  useEffect(() => {
    // load data
    dispatch(getStatus());
    dispatch(getMembers());
    fetchStatusVariations(token);
  }, [dispatch, fetchStatusVariations, token]);

  const [isLoading, hasError, validate, create] = useCreateShipmentProgress();

  const formData = {
    selectedShipmentIds,
    selectedStatusId,
    selectedVariationId,
    selectedMemberId,
    selectedDateTime,
    mediaType,
    mediaObj,
    mediaFiles
  };

  const isValid = validate(formData);

  const createProgress = async () => {
    const success = await create(token, formData);
    if (success) {
      onSuccess();
    }
  };

  return (
    <div>
      <h4>Auftragsstatus hinzufügen</h4>
      <br />
      {hasError && (
        <Row>
          Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.
        </Row>
      )}
      <Row>
        <ColLabel>Referenz</ColLabel>
        <Col>
          <MultiSelection
            placeholder="Referenz auswählen..."
            noSuggestionsText="Keine Ergebnisse..."
            items={shipmentItems}
            selectedItems={shipmentItems.filter(({ value }) =>
              selectedShipmentIds.includes(value)
            )}
            onChange={selected =>
              setSelectedShipmentIds(selected.map(item => item.value))
            }
            buttonTextWrap="ellipsis"
            buttonStyle={{ maxWidth: "380px" }}
          />
        </Col>
      </Row>
      <Row>
        <ColLabel>Status</ColLabel>
        <Col>
          <StandardSelection
            placeholder="Status auswählen..."
            noSuggestionsText="Keine Ergebnisse..."
            items={statusItems}
            selectedItem={{ value: selectedStatusId }}
            onChange={({ value }) => setSelectedStatusId(value)}
          />
        </Col>
      </Row>
      {variationItems.length > 0 && (
        <Row>
          <ColLabel />
          <Col>
            <NestedSelection
              // WORKAROUND: uncontrolled selection
              key={`status-${selectedStatusId}`}
              placeholder={
                selectedStatusId === 6
                  ? "keine Abweichung"
                  : "Begründung auswählen..."
              }
              noSuggestionsText="Keine Ergebnisse..."
              items={variationItems}
              onChange={({ value, variationType }) => {
                setSelectedVariationId(value);
                setMediaType(variationType);
              }}
            />
          </Col>
        </Row>
      )}
      {mediaType.length > 0 && (
        <Row>
          <ColLabel />
          <Col>
            <ProgressMedia
              type={mediaType}
              getMedia={mediaObj => setMediaObj(mediaObj)}
              getFiles={mediaFiles => setMediaFiles(mediaFiles)}
            />
          </Col>
        </Row>
      )}
      <Row>
        <ColLabel>Datum, Uhrzeit</ColLabel>
        <Col>
          <DateTimePicker
            value={selectedDateTime}
            onChange={value => setSelectedDateTime(value)}
          />
        </Col>
      </Row>
      <Row>
        <ColLabel>Ausgeführt von</ColLabel>
        <Col>
          <StandardSelection
            placeholder="Person auswählen..."
            noSuggestionsText="Keine Ergebnisse..."
            items={memberItems}
            selectedItem={{ value: selectedMemberId }}
            onChange={({ value }) => setSelectedMemberId(value)}
          />
        </Col>
      </Row>
      {isValid && (
        <Note>
          Hinweis: Der hinzugefügte Status wird automatisch an die angebundenen
          Netzwerke übertragen.
        </Note>
      )}
      <br />
      <StandardButton onClick={onClose}>Abbrechen</StandardButton>
      <StandardButton
        disabled={!isValid || isLoading}
        appearance="primary"
        onClick={createProgress}
      >
        Auftragsstatus speichern{isLoading ? "..." : ""}
      </StandardButton>
    </div>
  );
};

ProgressAdd.propTypes = {
  shipmentPieces: PropTypes.arrayOf(
    PropTypes.shape({
      shipment_id: PropTypes.number,
      label: PropTypes.string
    })
  ).isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  shipment: PropTypes.shape({ trackcode: PropTypes.string }).isRequired,
  members: PropTypes.shape({}).isRequired,
  status: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, label: PropTypes.string })
  ).isRequired
};

const mapStateToProps = state => {
  const {
    auth: { token },
    entities: { members }
  } = state;
  return {
    token,
    members,
    status: getAvailableShipmentStatus(state)
  };
};

export default connect(mapStateToProps)(ProgressAdd);

const Row = styled.div`
  display: flex;
  flex: 1;
  padding-bottom: 13px;
  align-items: center;
`;

const ColLabel = styled.div`
  width: 150px;
`;

const Col = styled.div`
  flex: 3;
`;

const Note = styled.div`
  color: #777;
  font-size: 13px;
`;
