import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import styleNames from "./icon.local.css";

const cx = classNames.bind(styleNames);

const Icon = ({ name, className, size, ...otherProps }) => (
  <span
    className={cx(
      "mdi",
      `mdi-${name}`,
      "test",
      `ci-icon-size-${size}`,
      className
    )}
    {...otherProps}
  />
);

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  spin: PropTypes.bool,
  size: PropTypes.oneOf(["lg", "md", "sm", "xs"])
};

Icon.defaultProps = {
  size: "md"
};

export default Icon;
