import { LOGISTICCOMPANY_PREPARE_CHANGE } from "state/auth";
import {
  TASKS_GET_REQUEST,
  TASKS_GET_RECEIVE,
  TASKS_ERROR,
  TASKS_ASSIGN_REQUEST,
  TASKS_ASSIGN_RECEIVE,
  TASKS_UNASSIGN_RECEIVE,
  TASKS_UNASSIGN_REQUEST
} from "./constants";
import { FOLDERS_GET_RECEIVE } from "../folders/constants";

const initialState = {
  entities: {
    // 1: { id: 1, shipmentID: 2, folderID }
  },
  loaded: false,
  errors: {}
};

const arrayToObject = array =>
  array.reduce((acc, curr) => {
    acc[curr.id] = curr;
    return acc;
  }, {});

const flatten = array => [].concat(...array);

export default function tasksReducer(state = initialState, action = {}) {
  const { type, payload, error } = action;

  switch (type) {
    case TASKS_UNASSIGN_REQUEST:
    case TASKS_ASSIGN_REQUEST:
    case TASKS_GET_REQUEST: {
      return {
        ...state,
        loaded: false,
        errors: {}
      };
    }

    case TASKS_GET_RECEIVE: {
      const { entities } = state;
      const tasksMap = arrayToObject(payload);
      return {
        ...state,
        entities: {
          ...entities,
          ...tasksMap
        },
        loaded: true,
        errors: {}
      };
    }

    case FOLDERS_GET_RECEIVE: {
      const tasks = flatten(payload.map(folder => folder.tasks));
      const { entities } = state;
      const tasksMap = arrayToObject(tasks);
      return {
        ...state,
        entities: {
          ...entities,
          ...tasksMap
        },
        loaded: true,
        errors: {}
      };
    }

    case TASKS_UNASSIGN_RECEIVE:
    case TASKS_ASSIGN_RECEIVE: {
      const taskMap = arrayToObject(payload);
      return {
        ...state,
        entities: {
          ...state.entities,
          ...taskMap
        },
        loaded: true,
        errors: {}
      };
    }

    case TASKS_ERROR: {
      return {
        ...state,
        loaded: false,
        errors: error
      };
    }

    case LOGISTICCOMPANY_PREPARE_CHANGE:
      return initialState;

    default:
      return state;
  }
}
