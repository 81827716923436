/* eslint no-constant-condition: 0 */
import {
  take,
  call,
  put,
  fork,
  select,
  takeLatest,
  all
} from "redux-saga/effects";

import ApiService from "../../service/ApiService";
import {
  SHIPMENTS_CREATE_REQUEST,
  SHIPMENTS_CREATE,
  SHIPMENTS_READ_REQUEST,
  SHIPMENTS_READ,
  FILTER_SHIPMENTS_REQUEST,
  FILTER_SHIPMENTS,
  SHIPMENT_UPDATE_REQUEST,
  SHIPMENT_UPDATE,
  SHIPMENT_DELETE_REQUEST,
  SHIPMENT_DELETE,
  SHIPMENT_STATUS_REQUEST,
  SHIPMENT_STATUS,
  SHIPMENT_STATUS_GROUPS_REQUEST,
  SHIPMENT_STATUS_GROUPS,
  SHIPMENT_ASSIGNMENT_DELETE_REQUEST,
  SHIPMENT_ASSIGNMENT_DELETE,
  SHIPMENT_STATUS_VARIATIONS_REQUEST,
  SHIPMENT_STATUS_VARIATIONS
} from "./index";

function* fetchShipments({ dateRange, token }) {
  try {
    const shipments = yield call(ApiService.getShipments, token, dateRange);
    yield put({
      type: SHIPMENTS_READ,
      payload: {
        shipments: shipments.shipments,
        dateRange
      }
    });
  } catch (error) {
    yield put({
      type: SHIPMENTS_READ,
      payload: error,
      error: true
    });
  }
}

function* loadShipments() {
  /*
   * Watch actions with `while (true) { }`
   * See: https://github.com/redux-saga/redux-saga/issues/684
   */
  while (true) {
    const { payload } = yield take(SHIPMENTS_READ_REQUEST);
    yield call(fetchShipments, payload);
  }
}

function* filterShipments({ payload }) {
  yield put({
    type: FILTER_SHIPMENTS,
    payload
  });
}

function* createShipment({ payload }) {
  try {
    const shipment = yield call(
      ApiService.createShipment,
      payload.token,
      payload.shipments
    );
    yield put({
      type: SHIPMENTS_CREATE,
      payload: shipment
    });
  } catch (error) {
    yield put({
      type: SHIPMENTS_CREATE,
      payload: error,
      error: true
    });
  }
}

function* updateShipment({ payload }) {
  try {
    const shipment = yield call(
      ApiService.updateShipment,
      payload.token,
      payload.shipments
    );
    yield put({
      type: SHIPMENT_UPDATE,
      payload: shipment
    });
  } catch (error) {
    yield put({
      type: SHIPMENT_UPDATE,
      payload: error,
      error: true
    });
  }
}

function* deleteShipment({ shipments }) {
  const token = yield select(state => state.auth.token);
  try {
    yield call(ApiService.deleteShipment, token, shipments);
    yield put({
      type: SHIPMENT_DELETE,
      payload: shipments
    });
  } catch (error) {
    yield put({
      type: SHIPMENT_DELETE,
      payload: error,
      error: true
    });
  }
}

function* deleteShipmentAssignment({ shipmentId }) {
  const token = yield select(state => state.auth.token);
  try {
    yield call(ApiService.deleteShipmentAssignment, token, shipmentId);
    yield put({
      type: SHIPMENT_ASSIGNMENT_DELETE,
      payload: { shipmentId }
    });
  } catch (error) {
    yield put({
      type: SHIPMENT_ASSIGNMENT_DELETE,
      payload: error,
      error: true
    });
  }
}

function* getStatus({ payload: { token } }) {
  const { filterStatusLoaded } = yield select(state => state.shipment);
  if (!filterStatusLoaded) {
    try {
      const { status } = yield call(ApiService.getShipmentStatus, token);
      yield put({
        type: SHIPMENT_STATUS,
        payload: status
      });
    } catch (error) {
      yield put({
        type: SHIPMENT_STATUS,
        payload: error,
        error: true
      });
    }
  }
}

function* getStatusGroups({ payload: { token } }) {
  const { filterStatusGroupsLoaded } = yield select(state => state.shipment);
  if (!filterStatusGroupsLoaded) {
    try {
      const { groups } = yield call(ApiService.getShipmentStatusGroups, token);
      yield put({
        type: SHIPMENT_STATUS_GROUPS,
        payload: groups
      });
    } catch (error) {
      yield put({
        type: SHIPMENT_STATUS_GROUPS,
        payload: error,
        error: true
      });
    }
  }
}

function* getStatusVariations() {
  const { statusVariationsLoaded } = yield select(state => state.shipment);
  const { token } = yield select(state => state.auth);
  if (!statusVariationsLoaded) {
    try {
      const { variations } = yield call(ApiService.getStatusVariations, token);
      yield put({
        type: SHIPMENT_STATUS_VARIATIONS,
        payload: variations
      });
    } catch (error) {
      yield put({
        type: SHIPMENT_STATUS_VARIATIONS,
        payload: error,
        error: true
      });
    }
  }
}

export default function* sagas() {
  yield all([
    fork(loadShipments),
    takeLatest(FILTER_SHIPMENTS_REQUEST, filterShipments),
    takeLatest(SHIPMENTS_CREATE_REQUEST, createShipment),
    takeLatest(SHIPMENT_UPDATE_REQUEST, updateShipment),
    takeLatest(SHIPMENT_DELETE_REQUEST, deleteShipment),
    takeLatest(SHIPMENT_STATUS_REQUEST, getStatus),
    takeLatest(SHIPMENT_STATUS_GROUPS_REQUEST, getStatusGroups),
    takeLatest(SHIPMENT_ASSIGNMENT_DELETE_REQUEST, deleteShipmentAssignment),
    takeLatest(SHIPMENT_STATUS_VARIATIONS_REQUEST, getStatusVariations)
  ]);
}
