import { LOGISTICCOMPANY_PREPARE_CHANGE } from "state/auth";
import {
  TOURS_GET_REQUEST,
  TOURS_GET_RECEIVE,
  TOURS_GET_ERROR
} from "./constants";

const initialState = {
  entities: {
    // 1: { id: 1, label: "Tour", ... }
  },
  memberIdToTourId: {
    // [memberId]: tourId
  },
  loaded: false,
  errors: {}
};

const arrayToObject = array =>
  array.reduce((acc, curr) => {
    acc[curr.id] = curr;
    return acc;
  }, {});

export default function membersReducer(state = initialState, action = {}) {
  const { type, payload, error } = action;

  switch (type) {
    case TOURS_GET_REQUEST: {
      return {
        ...state,
        loaded: false,
        error: {}
      };
    }

    case TOURS_GET_RECEIVE: {
      const entities = arrayToObject(payload);
      const memberIdToTourId = payload.reduce(
        (acc, { id: tourId, member_id: memberId }) => {
          // memberId is 0 by default
          if (memberId) {
            acc[memberId] = tourId;
          }
          return acc;
        },
        {}
      );
      return {
        ...state,
        entities,
        memberIdToTourId,
        loaded: true,
        errors: {}
      };
    }

    case TOURS_GET_ERROR: {
      return {
        ...state,
        loaded: false,
        errors: error
      };
    }

    case LOGISTICCOMPANY_PREPARE_CHANGE:
      return initialState;

    default:
      return state;
  }
}
