/**
 * Generic service for all Rest calls.
 * Includes exception handling and logging.
 */
import "isomorphic-fetch";

const defaultSettings = {
  // mode: "no-cors"
};

class RestService {
  /**
   * Register a middleware which is calling before fetch.
   * @param {string} name
   * @param {function} fn
   */
  static registerMiddleware(name, fn) {
    this.middlewares[name] = fn;
  }

  /**
   * Unregister an existing middleware.
   * @param {string} name
   */
  static unregisterMiddleware(name) {
    delete this.middlewares[name];
  }

  static rest(orgUrl, orgSettings = {}) {
    const fetchParams = {
      url: orgUrl,
      settings: { ...defaultSettings, ...orgSettings }
    };

    // use reduce to run middlewares
    const { url, settings } = Object.values(this.middlewares).reduce(
      (acc, fn) => (typeof fn === "function" ? fn(acc) : acc),
      fetchParams
    );

    // Promise based
    return new Promise((resolve, reject) => {
      fetch(url, settings)
        .then(response => {
          const { status } = response;
          // response is OK
          if (status >= 200 && status < 300) {
            const contentType = response.headers.get("content-type");

            // content type is JSON
            if (contentType && contentType.indexOf("application/json") !== -1) {
              response
                .json()
                // return data of response
                .then(data => {
                  resolve(data.result);
                })
                // parsing failed
                .catch(error => {
                  console.error(
                    `Parsing JSON failed for url ${url}. Error: ${error}`
                  );
                  // eslint-disable-next-line prefer-promise-reject-errors
                  reject(
                    500,
                    `Parsing JSON failed for url ${url}. Error: ${error}`
                  );
                });
            } else {
              // no JSON response
              console.error(`No JSON response for url ${url}!`);
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(500, `No JSON response for url ${url}!`);
            }
          } else if (status === 401) {
            // Access denied
            console.error("Session timed out!");
            reject(status, `Access denied for url ${url}`);
          } else {
            // any other response code
            console.error(
              `Webservice call failed. Response code: ${status}. Url: ${url}`
            );
            reject(status, `Webservice call failed for url ${url}!`);
          }
        })
        .catch(error => {
          console.error(
            `Webservice call failed completely for url ${url}. Error: ${error}`
          );
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(500, `Webservice call failed for url ${url}! Error: ${error}`);
        });
    });
  }

  static get(url, settings = {}) {
    return this.rest(url, settings);
  }

  static post(url, parameters, settings = {}) {
    const mergedSettings = {
      method: "post",
      body: JSON.stringify(parameters),
      ...settings,
      headers: {
        "Content-Type": "application/json",
        ...(settings.headers || null)
      }
    };
    return this.rest(url, mergedSettings);
  }

  static upload(url, files, settings = {}) {
    const formData = new FormData();
    files.forEach(file => {
      formData.append(file.name, file);
    });

    const mergedSettings = {
      method: "POST",
      body: formData,
      ...settings
    };
    return this.rest(url, mergedSettings);
  }

  static put(url, parameters, settings = {}) {
    const mergedSettings = {
      method: "put",
      body: JSON.stringify(parameters),
      ...settings,
      headers: {
        "Content-Type": "application/json",
        ...(settings.headers || null)
      }
    };

    return this.rest(url, mergedSettings);
  }

  static delete(url, parameters, settings = {}) {
    const mergedSettings = {
      method: "delete",
      body: JSON.stringify(parameters),
      ...settings,
      headers: {
        "Content-Type": "application/json",
        ...(settings.headers || null)
      }
    };

    return this.rest(url, mergedSettings);
  }
}

RestService.middlewares = {};

export default RestService;
