import { call, put, takeLatest } from "redux-saga/effects";

import ApiService from "../../service/ApiService";
import {
  FETCH_ADDRESS_REQUEST,
  FETCH_ADDRESS,
  CREATE_ADDRESS,
  CREATE_ADDRESS_REQUEST
} from "./index";

function* fetchAddress({ payload }) {
  try {
    const address = yield call(
      ApiService.getAddresses,
      payload.token,
      payload.context,
      payload.value
    );

    yield put({
      type: FETCH_ADDRESS,
      payload: address
    });
  } catch (error) {
    yield put({
      type: FETCH_ADDRESS,
      payload: error,
      error: true
    });
  }
}

function* createAddress({ payload }) {
  try {
    const address = yield call(
      ApiService.createAddress,
      payload.token,
      payload.address
    );
    yield put({
      type: CREATE_ADDRESS,
      payload: address
    });
  } catch (error) {
    yield put({
      type: CREATE_ADDRESS,
      payload: error,
      error: true
    });
  }
}

export const loadAddressSaga = function* loadAddressSaga() {
  yield takeLatest(FETCH_ADDRESS_REQUEST, fetchAddress);
};

export const createAddressSaga = function* createAddressSaga() {
  yield takeLatest(CREATE_ADDRESS_REQUEST, createAddress);
};
