import { LOGISTICCOMPANY_PREPARE_CHANGE } from "state/auth";
import { FETCH_STATUS } from "./index";

const initialState = {
  status: [],
  loaded: false,
  error: null
};

export default function auth(state = initialState, action = {}) {
  const { type, payload, error } = action;

  switch (type) {
    case FETCH_STATUS:
      if (error) {
        return {
          ...state,
          error: payload
        };
      }

      return {
        status: payload.status,
        loaded: true,
        error: null
      };

    case LOGISTICCOMPANY_PREPARE_CHANGE:
      return initialState;

    default:
      return state;
  }
}
