import React from "react";
import PropTypes from "prop-types";
import RBOverlayTrigger from "react-bootstrap/lib/OverlayTrigger";
import RBTooltip from "react-bootstrap/lib/Tooltip";

const Tooltip = ({ id, children, position, content, ...otherProps }) => {
  const tooltip = <RBTooltip id={id}>{content}</RBTooltip>;

  return (
    <RBOverlayTrigger placement={position} overlay={tooltip} {...otherProps}>
      {children}
    </RBOverlayTrigger>
  );
};

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  content: PropTypes.any.isRequired,
  position: PropTypes.string.isRequired
};

export default Tooltip;
