// TODO: Rewrite
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { Component } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import moment from "moment";

// TODO: Add this import globally
import "react-datepicker/dist/react-datepicker.min.css";

import "./floatingdatepicker.css";
import dateIcon from "../../../asset/image/ui/date.svg";

const CalendarContainer = ({ children }) => {
  const el = document.getElementById("calendar-root");

  return createPortal(children, el);
};

class FloatingDatePicker extends Component {
  static propTypes = {
    dateRange: PropTypes.bool,
    startMoment: PropTypes.object,
    startLabel: PropTypes.string,
    endMoment: PropTypes.object,
    endLabel: PropTypes.string,
    startMomentChanged: PropTypes.func,
    endMomentChanged: PropTypes.func,
    editMode: PropTypes.bool
  };

  static defaultProps = {
    dateRange: false,
    editMode: true
  };

  static transferPropsToState(props) {
    const { startMoment, endMoment } = props;

    const newState = {};

    newState.startMoment = startMoment;
    newState.startActive = !!(startMoment && startMoment.isValid());

    // Only show end dates if they are reasonably soon
    // This is needed as an unlimited recurrence is implemented as a recurrence
    // ending on '9999-01-01'
    if (endMoment && endMoment.isValid() && endMoment.years() <= 3000) {
      newState.endMoment = endMoment;
      newState.endActive = !!(endMoment && endMoment.isValid());
    }

    return newState;
  }

  state = {
    dateRange: false,
    startMoment: null,
    endMoment: null,
    startActive: false,
    endActive: false
  };

  componentWillMount() {
    const { dateRange } = this.props;

    const newState = {};

    if (dateRange !== this.state.dateRange) {
      newState.dateRange = dateRange;
    }

    Object.assign(
      newState,
      FloatingDatePicker.transferPropsToState(this.props)
    );

    this.setState(newState);
  }

  componentWillReceiveProps(nextProps) {
    const newState = FloatingDatePicker.transferPropsToState(nextProps);

    this.setState(newState);
  }

  startOnChange = _moment => {
    this.setState({ startMoment: _moment });
    this.props.startMomentChanged(_moment);
  };

  endOnChange = _moment => {
    this.setState({ endMoment: _moment });
    this.props.endMomentChanged(_moment);
  };

  startOnBlur = () => {
    const { startMoment } = this.state;

    if (!startMoment || !startMoment.isValid) {
      this.setState({ startActive: false });
    }
  };

  endOnBlur = () => {
    const { endMoment } = this.state;

    if (!endMoment || !endMoment.isValid) {
      this.setState({ endActive: false });

      if (!this.props.dateRange) {
        this.toggleDateRange();
      }
    }
  };

  clearStart = () => {
    this.setState({ startMoment: null, startActive: false });
    this.props.startMomentChanged(null);
  };

  clearEnd = () => {
    const newState = {
      endMoment: null,
      endActive: false
    };

    if (!this.props.dateRange) {
      newState.dateRange = false;
    }

    this.setState(newState);
    this.props.endMomentChanged(null);
  };

  toggleDateRange = () => {
    const newState = {};
    newState.dateRange = !this.state.dateRange;

    if (newState.dateRange) {
      newState.endActive = true;
      this.setState(newState);
    }
  };

  activateStart = () => {
    this.setState({ startActive: true });
  };

  activateEnd = () => {
    this.setState({ endActive: true });
  };

  render() {
    const {
      dateRange,
      startMoment,
      startActive,
      endMoment,
      endActive
    } = this.state;

    const { startLabel, endLabel, editMode } = this.props;

    const combined = !dateRange ? "combined" : "";

    return (
      <div
        className={`datepicker-wrapper ${editMode ? "enabled" : "disabled"}`}
      >
        {startActive && (
          <div className={`datepicker ${combined}`}>
            <DatePicker
              popperContainer={CalendarContainer}
              dateFormat="DD.MM.YYYY"
              className="startDate"
              todayButton="Heute"
              selected={startMoment}
              onChange={this.startOnChange}
              onBlur={this.startOnBlur}
              minDate={moment()}
              selectsStart={dateRange}
              startDate={startMoment}
              endDate={endMoment}
              disabled={!editMode}
              autoFocus={!startMoment}
            />
            <div className="hiddenlabel-sup">{startLabel}</div>
            <span className="clearbutton" onClick={this.clearStart}>
              ×
            </span>
          </div>
        )}
        {!startActive && (
          <div
            className={`link small start ${combined} ${
              editMode ? "enabled" : "disabled otherEmpty allEmpty"
            }`}
          >
            <img src={dateIcon} width="14" height="14" alt="date icon" />
            <span
              onClick={this.activateStart}
              tabIndex="0"
              onFocus={this.activateStart}
            >
              {startLabel}
            </span>
          </div>
        )}
        {!dateRange && !endActive && (
          <div
            className={`link small ${combined} ${
              editMode ? "enabled" : "disabled otherEmpty allEmpty"
            }`}
          >
            <img src={dateIcon} width="14" height="14" alt="date icon" />
            <span
              onClick={this.activateEnd}
              tabIndex="0"
              onFocus={this.activateEnd}
            >
              + {"Datumsspanne"}
            </span>
          </div>
        )}
        {endActive && (
          <div className={`datepicker ${combined}`}>
            <DatePicker
              popperContainer={CalendarContainer}
              dateFormat="DD.MM.YYYY"
              className="endDate"
              todayButton="Heute"
              selected={endMoment}
              onChange={this.endOnChange}
              onBlur={this.endOnBlur}
              minDate={startMoment || moment()}
              selectsEnd={dateRange}
              startDate={startMoment}
              endDate={endMoment}
              disabled={!editMode}
              autoFocus={!endMoment}
            />
            <div className="hiddenlabel-sup">{endLabel}</div>
            <span className="clearbutton" onClick={this.clearEnd}>
              ×
            </span>
          </div>
        )}
        {dateRange && !endActive && (
          <div
            className={`link small ${combined} ${
              editMode ? "enabled" : "disabled otherEmpty allEmpty"
            }`}
          >
            <img src={dateIcon} width="14" height="14" alt="date icon" />
            <span
              onClick={this.activateEnd}
              tabIndex="0"
              onFocus={this.activateEnd}
            >
              {endLabel}
            </span>
          </div>
        )}
      </div>
    );
  }
}

export default FloatingDatePicker;
