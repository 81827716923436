import { isValid } from "date-fns";
import { LOGISTICCOMPANY_PREPARE_CHANGE } from "state/auth";
import {
  SHIPMENTS_V2_GET_ERROR,
  SHIPMENTS_V2_GET_RECEIVE,
  SHIPMENTS_V2_GET_REQUEST
} from "./constants";

const initialState = {
  entities: {},
  loaded: false,
  errors: {}
};

/**
 * Parse a shipment time to a valid day if possible
 * @param value
 * @return {null|Date}
 */
const getDate = value => {
  if (!value) {
    return null;
  }
  const d = new Date(value);
  if (!isValid(d)) {
    return null;
  }
  return d;
};

/**
 * @param shipment
 * @return {object}
 */
export const prepareShipment = shipment => ({
  ...shipment,
  sender: {
    ...shipment.sender,
    appointment_from: getDate(shipment.sender.appointment_from),
    appointment_till: getDate(shipment.sender.appointment_till)
  },
  recipient: {
    ...shipment.recipient,
    appointment_from: getDate(shipment.recipient.appointment_from),
    appointment_till: getDate(shipment.recipient.appointment_till)
  }
});

export default function shipmentV2Reducer(state = initialState, action = {}) {
  const { type, payload, error } = action;

  switch (type) {
    case SHIPMENTS_V2_GET_ERROR: {
      return {
        ...state,
        loaded: false,
        errors: error
      };
    }

    case SHIPMENTS_V2_GET_REQUEST: {
      return {
        ...state,
        loaded: false
      };
    }

    case SHIPMENTS_V2_GET_RECEIVE: {
      const shipmentList = payload || [];
      const shipmentMap = shipmentList.reduce((map, rawShipment) => {
        const shipment = prepareShipment(rawShipment);
        return {
          ...map,
          [shipment.id]: prepareShipment(shipment)
        };
      }, {});

      return {
        ...state,
        entities: {
          ...state.entities,
          ...shipmentMap
        },
        loaded: true,
        errors: {}
      };
    }

    case LOGISTICCOMPANY_PREPARE_CHANGE:
      return initialState;

    default: {
      return state;
    }
  }
}
