import { call, put, takeLatest } from "redux-saga/effects";

import ApiService from "../../service/ApiService";
import { IMPORT_CREATE, IMPORT_CREATE_REQUEST } from "./index";

function* createImport({ payload }) {
  try {
    const imported = yield call(
      ApiService.createImport,
      payload.token,
      payload.shipments
    );
    yield put({
      type: IMPORT_CREATE,
      payload: imported
    });
  } catch (error) {
    yield put({
      type: IMPORT_CREATE,
      payload: error,
      error: true
    });
  }
}

export default function* createImportSaga() {
  yield takeLatest(IMPORT_CREATE_REQUEST, createImport);
}
