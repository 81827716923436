import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames/bind";
import Button from "../../components/element/button";
import Checkbox from "../../components/element/form/Checkbox";
import { MultiSelect } from "../../components/element/select";
import MappingItem from "./MappingItem";
import Alert from "../../components/element/alert/Alert";
import { createMapping } from "../../state/mapping/mappingAction";

import "./panel.css";
import styleNames from "./importShipmentMapping.local.css";

const cx = classNames.bind(styleNames);

const shipmentFields = [
  {
    label: "ext_reference",
    name: "ext_reference",
    placeholder: "Sendungsnummer"
  },
  { label: "barcode_label", name: "barcode_label", placeholder: "Barcode" }
];

const senderFields = [
  { label: "sender_name", name: "sender_name", placeholder: "Name" },
  { label: "sender_phone", name: "sender_phone", placeholder: "Telefon" },
  { label: "sender_company", name: "sender_company", placeholder: "Firma" },
  {
    label: "sender_address_street",
    name: "sender_address_street",
    placeholder: "Straße"
  },
  {
    label: "sender_address_zipcode",
    name: "sender_address_zipcode",
    placeholder: "PLZ"
  },
  {
    label: "sender_address_city",
    name: "sender_address_city",
    placeholder: "Ort"
  },
  {
    value: "pickup_appointment_from",
    name: "pickup_appointment_from",
    placeholder: "Abholtermin von"
  },
  {
    value: "pickup_appointment_till",
    name: "pickup_appointment_till",
    placeholder: "Abholtermin bis"
  },
  {
    label: "sender_address_additional",
    name: "sender_address_additional",
    placeholder: "Bemerkung"
  }
];

const recipientFields = [
  { label: "recipient_name", name: "recipient_name", placeholder: "Name" },
  { label: "recipient_phone", name: "recipient_phone", placeholder: "Telefon" },
  {
    label: "recipient_company",
    name: "recipient_company",
    placeholder: "Firma"
  },
  {
    label: "recipient_address_street",
    name: "recipient_address_street",
    placeholder: "Straße"
  },
  {
    label: "recipient_address_zipcode",
    name: "recipient_address_zipcode",
    placeholder: "PLZ"
  },
  {
    label: "recipient_address_city",
    name: "recipient_address_city",
    placeholder: "Ort"
  },
  {
    label: "delivery_appointment_from",
    name: "delivery_appointment_from",
    placeholder: "Zustelltermin von"
  },
  {
    label: "delivery_appointment_till",
    name: "delivery_appointment_till",
    placeholder: "Zustelltermin bis"
  },
  {
    label: "recipient_address_additional",
    name: "recipient_address_additional",
    placeholder: "Bemerkung"
  }
];

const hiddenFields = [
  { label: "sender_name", name: "sender_name", placeholder: "Name" },
  {
    label: "sender_address_street",
    name: "sender_address_street",
    placeholder: "Straße"
  },
  {
    label: "sender_address_zipcode",
    name: "sender_address_zipcode",
    placeholder: "PLZ"
  },
  {
    label: "sender_address_city",
    name: "sender_address_city",
    placeholder: "Ort"
  },
  { label: "sender_phone", name: "sender_phone", placeholder: "Telefon" },
  {
    label: "sender_address_additional",
    name: "sender_address_additional",
    placeholder: "Bemerkung"
  },
  { label: "sender_email", name: "sender_email", placeholder: "E-Mail" },
  {
    label: "pickup_appointment_from",
    name: "pickup_appointment_from",
    placeholder: "Abholtermin von"
  },
  {
    label: "pickup_appointment_till",
    name: "pickup_appointment_till",
    placeholder: "Abholtermin bis"
  },
  {
    label: "recipient_company",
    name: "recipient_company",
    placeholder: "Firma"
  },
  { label: "recipient_email", name: "recipient_email", placeholder: "E-Mail" },
  {
    label: "payment_on_delivery",
    name: "payment_on_delivery",
    placeholder: "Nachnahme"
  },
  { label: "length", name: "length", placeholder: "Länge" },
  { label: "width", name: "width", placeholder: "Breite" },
  { label: "height", name: "height", placeholder: "Höhe" },
  { label: "content", name: "content", placeholder: "Inhalt" },
  { label: "vehicle_type", name: "vehicle_type", placeholder: "Fahrzeug" }
];

const shipmentAdditionals = [
  { value: "area", label: "Gebiet" },
  { value: "sender_email", label: "Absende E-Mail" },
  { value: "recipient_email", label: "Empfänger E-Mail" },
  { value: "payment_on_delivery", label: "Nachnahme" },
  { value: "length", label: "Länge" },
  { value: "width", label: "Breite" },
  { value: "height", label: "Höhe" },
  { value: "content", label: "Inhalt" },
  { value: "vehicle_type", label: "Fahrzeug" }
];

class ImportShipmentMapping extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    exampleMapItem: PropTypes.array,
    preMapping: PropTypes.object,
    externalCompanies: PropTypes.object,
    mappingKey: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    handleAbort: PropTypes.func.isRequired
  };

  static externalCompanieRelationOptionItems(external) {
    const options = [];
    for (let i = 0; i < external.length; i += 1) {
      options.push({
        value: external[i].id,
        label: external[i].name,
        shortName: external[i].short_name
      });
    }

    return options;
  }

  state = {
    externalCompanies: [],
    selectedExternalCompany: [],
    error: false,
    mapping: {},
    isDirect: 0,
    isPickedUp: 1
  };

  componentWillMount() {
    const { preMapping, externalCompanies } = this.props;

    if (preMapping && preMapping.mapping_value) {
      const additionals = [];
      Object.keys(preMapping.mapping_value).forEach(key => {
        const item = shipmentAdditionals.filter(
          additioanl => key === additioanl.value
        );
        if (item.length > 0) {
          additionals.push(item[0].value);
        }
      });
    }

    if (externalCompanies) {
      const externalTemp = [];
      Object.keys(externalCompanies).forEach(key => {
        externalTemp.push(externalCompanies[key]);
      });

      const selectedOption = [];
      if (externalTemp.length > 0) {
        selectedOption.push({
          value: externalTemp[0].id,
          label: externalTemp[0].name,
          shortName: externalTemp[0].short_name
        });
      }

      this.setState({
        externalCompanies: externalTemp,
        selectedExternalCompany: selectedOption
      });
    }
  }

  onChangeExtraFields = e => {
    const hiddenField = hiddenFields.find(
      field => field.name === e.target.getAttribute("value")
    );
    const shipmentField = shipmentFields.find(
      field => field.name === e.target.getAttribute("value")
    );
    const shipmentFieldIndex = shipmentFields.findIndex(
      field => field.name === e.target.getAttribute("value")
    );

    if (shipmentField) {
      shipmentFields.splice(shipmentFieldIndex, 1);
    } else {
      shipmentFields.push(hiddenField);
    }
  };

  onAbort = () => {
    this.props.handleAbort();
  };

  onSubmit = () => {
    const {
      mapping,
      isDirect,
      isPickedUp,
      selectedExternalCompany
    } = this.state;

    const { mappingKey } = this.props;

    // console.log("ImportShipmentMapping mappingKey", mappingKey);
    if (Object.keys(mapping).length >= 1) {
      // this.setState({ error: false });
      // TODO save header as key
      this.props.dispatch(createMapping(this.props.token, mappingKey, mapping));
      this.props.handleSubmit(
        mapping,
        isDirect ? 0 : isPickedUp,
        isDirect,
        selectedExternalCompany
      );
    } else {
      alert("Sie haben weniger als 5 Felder zugeordnet");
      // this.setState({ error: true });
    }
  };

  getMappingValues(name) {
    const { preMapping } = this.props;

    if (preMapping && preMapping.id) {
      return preMapping.mapping_value[name];
    }

    return null;
  }

  isChecked = name => {
    if (shipmentFields.find(field => field.name === name)) {
      return true;
    }

    return false;
  };

  handleOnSelectedMappingItem = (name, selected) => {
    const { mapping } = this.state;
    const values = [];
    for (let i = 0; i < selected.length; i += 1) {
      values.push(selected[i].value);
    }
    mapping[name] = values;

    this.setState({ mapping });
  };

  handlePickupChanged = e => {
    this.setState({
      isPickedUp: !e.target.checked,
      isDirect: false
    });
  };

  handleDeliveryChanged = e => {
    this.setState({
      isPickedUp: e.target.checked,
      isDirect: false
    });
  };

  handleDirectChanged = e => {
    this.setState({ isDirect: e.target.checked });
  };

  handleOnExternalCompanyItemChanged = selected => {
    this.setState({ selectedExternalCompany: selected });
  };

  render() {
    const { exampleMapItem } = this.props;

    const {
      isPickedUp,
      isDirect,
      externalCompanies,
      selectedExternalCompany,
      error
    } = this.state;

    return (
      <form className={cx("mapping-form")}>
        {error && (
          <Alert color="danger">
            Sie haben weniger als 5 Felder zugeordnet
          </Alert>
        )}
        {exampleMapItem.length >= 0 && (
          <React.Fragment>
            <div className="panel panel-default">
              <div className="panel-heading">
                <h3 className="panel-title">Sendung</h3>
              </div>
              <div className="panel-body">
                <div className="form-inline">
                  <div className={`${cx("shipment-type")} col-sm-9`}>
                    <Checkbox
                      label="Abholung"
                      checked={isDirect ? false : !isPickedUp}
                      onChange={this.handlePickupChanged}
                    />
                    <Checkbox
                      label="Zustellung"
                      checked={isDirect ? false : isPickedUp}
                      onChange={this.handleDeliveryChanged}
                    />
                    <Checkbox
                      label="Direktfahrt"
                      checked={isDirect}
                      onChange={this.handleDirectChanged}
                    />
                  </div>
                </div>
                {externalCompanies && externalCompanies.length > 0 && (
                  <div className="form-inline">
                    {/* eslint-disable jsx-a11y/label-has-for */}
                    <label className="col-sm-3 control-label">Export</label>
                    {/* eslint-enable jsx-a11y/label-has-for */}
                    <div className="col-sm-9">
                      <MultiSelect
                        name="import_id"
                        placeholder="Exportieren nach"
                        options={ImportShipmentMapping.externalCompanieRelationOptionItems(
                          externalCompanies
                        )}
                        onChange={this.handleOnExternalCompanyItemChanged}
                        value={selectedExternalCompany}
                      />
                    </div>
                  </div>
                )}
                {shipmentFields.map(sfield => (
                  <MappingItem
                    key={sfield.name}
                    name={sfield.name}
                    label={sfield.label}
                    placeholder={sfield.placeholder}
                    exampleMapItem={exampleMapItem}
                    handleOnChange={this.handleOnSelectedMappingItem}
                    preselectValues={this.getMappingValues(sfield.name)}
                  />
                ))}
              </div>
            </div>

            <div className="panel panel-default">
              <div className="panel-heading">
                <h3 className="panel-title">Absender</h3>
              </div>
              <div className="panel-body">
                {senderFields.map(senderfield => (
                  <MappingItem
                    key={senderfield.name}
                    name={senderfield.name}
                    label={senderfield.label}
                    placeholder={senderfield.placeholder}
                    exampleMapItem={exampleMapItem}
                    handleOnChange={this.handleOnSelectedMappingItem}
                    preselectValues={this.getMappingValues(senderfield.name)}
                  />
                ))}
              </div>
            </div>

            <div className="panel panel-default">
              <div className="panel-heading">
                <h3 className="panel-title">Empfänger</h3>
              </div>
              <div className="panel-body">
                {recipientFields.map(recipientfield => (
                  <MappingItem
                    key={recipientfield.name}
                    name={recipientfield.name}
                    label={recipientfield.label}
                    placeholder={recipientfield.placeholder}
                    exampleMapItem={exampleMapItem}
                    handleOnChange={this.handleOnSelectedMappingItem}
                    preselectValues={this.getMappingValues(recipientfield.name)}
                  />
                ))}
              </div>
            </div>
            <div
              className={`${cx(
                "shipment-form-footer"
              )} shipment-form-footer modal-footer`}
            >
              <Button onClick={this.onAbort}>Abbrechen</Button>
              <Button color="success" onClick={this.onSubmit}>
                Importieren
              </Button>
            </div>
          </React.Fragment>
        )}
      </form>
    );
  }
}

function mapStateToProps({ auth, mapping }) {
  return {
    mapping,
    token: auth.token
  };
}

export default connect(mapStateToProps)(ImportShipmentMapping);
