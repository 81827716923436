/**
 * Format an address field.
 */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";

import Icon from "../element/icon/Icon";
import styleNames from "./directAddress.local.css";

const cx = classNames.bind(styleNames);

// @Note: don't use address field
// => https://developer.mozilla.org/de/docs/Web/HTML/Element/address
const DirectAddress = ({
  senderAddress,
  recipientAddress,
  isPickedUp,
  shortenName,
  className,
  addExtra,
  addLocationIcon,
  children,
  ...otherProps
}) => (
  <div className={cx("ci-address", className)} {...otherProps}>
    <div className={cx({ "ci-address-data": addLocationIcon })}>
      {addLocationIcon && (
        <Icon name="map-marker" className={cx("ci-icon")} size="xs" />
      )}
      <div className={cx("ci-address-sender", "ci-ellipsis", "ci-icon-width")}>
        &nbsp;
        {senderAddress.street}, {senderAddress.zipcode} {senderAddress.city}
      </div>
    </div>
    <div className={cx({ "ci-address-data": addLocationIcon })}>
      <div
        className={cx(
          "ci-address-recipient",
          isPickedUp ? " text-muted" : "",
          "ci-ellipsis"
        )}
      >
        <Icon
          name="subdirectory-arrow-right"
          className={cx("ci-icon")}
          size="xs"
        />
        &nbsp;
        {recipientAddress.street}, {recipientAddress.zipcode}{" "}
        {recipientAddress.city}
      </div>
    </div>

    {addExtra && (
      <Fragment>
        {senderAddress.additional && (
          <div className={cx("ci-address-additional")}>
            {senderAddress.additional}
          </div>
        )}
        {senderAddress.avis && (
          <div className={cx("ci-address-avis")}>{senderAddress.avis}</div>
        )}
        {senderAddress.country && (
          <div className={cx("ci-address-country")}>
            {senderAddress.country}
          </div>
        )}
      </Fragment>
    )}

    {children}
  </div>
);

DirectAddress.propTypes = {
  senderAddress: PropTypes.object.isRequired,
  recipientAddress: PropTypes.object.isRequired,
  isPickedUp: PropTypes.bool,
  shortenName: PropTypes.bool,
  addExtra: PropTypes.bool,
  addLocationIcon: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node
};

export default DirectAddress;
