import React from "react";
import PropTypes from "prop-types";
import RBNav from "react-bootstrap/lib/Nav";

const Navigation = ({ style, onSelect, children, ...otherProps }) => (
  <RBNav bsStyle={style} onSelect={onSelect} {...otherProps}>
    {children}
  </RBNav>
);

Navigation.propTypes = {
  style: PropTypes.oneOf(["pills", "tabs"]),
  onSelect: PropTypes.func.isRequired,
  children: PropTypes.any
};

Navigation.defaultProps = {
  style: "tabs"
};

export default Navigation;
