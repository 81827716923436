import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Flex, Box } from "reflexbox";
import { StandardButton, StandardSelection, Icon } from "@trackcode/ui";
import { assignTasks, unassignTasks } from "state/entities/tasks/actions";
import { getFoldersWithMember } from "../state/selectors";
import { TasksOnMap } from "./TasksOnMap";

const UNASSIGNED_VALUE = -1;

export const Assignment = ({ selectedTaskIDs, onCancel }) => {
  const [value, setValue] = useState(undefined);

  const dispatch = useDispatch();
  const folders = useSelector(getFoldersWithMember);

  const save = () => {
    if (value === UNASSIGNED_VALUE) {
      dispatch(unassignTasks(selectedTaskIDs));
    } else {
      dispatch(assignTasks(value, selectedTaskIDs));
    }
    onCancel();
  };

  const selectableFolderItems = Object.values(folders).map(({ id, label }) => ({
    value: id,
    content: label
  }));

  return (
    <>
      <TasksOnMap selectedTaskIDs={selectedTaskIDs} />
      <Flex width="100%">
        <Box flex={1.2}>
          <Flex>
            <Box flex={1}>
              <span style={{ color: "#ffffff", paddingRight: "16px" }}>
                {selectedTaskIDs.length}&nbsp; Aufgaben
              </span>
              <StandardSelection
                placeholder="Arbeitsmappe wählen"
                noSuggestionsText="Keine Auswahl möglich"
                allowClear
                menuProps={{
                  minWidth: "325px",
                  maxWidth: "325px"
                }}
                items={[
                  { value: UNASSIGNED_VALUE, content: "Keine Zuweisung" },
                  ...selectableFolderItems
                ]}
                onChange={item => setValue(item.value)}
                selectedItem={value ? { value } : null}
                renderButton={renderStandardSelectionButton(!!value)}
              />
              &nbsp;
              <StandardButton
                disabled={!value}
                onClick={save}
                style={{
                  backgroundColor: "#ffffff",
                  borderColor: "#ffffff"
                }}
              >
                Speichern
              </StandardButton>
            </Box>
            <Box flex={1} style={{ textAlign: "right" }}>
              <StandardButton
                appearance="subtle"
                style={{
                  padding: "4px",
                  marginLeft: "-5px",
                  color: "#ffffff"
                }}
                onClick={onCancel}
              >
                <Icon name="close" size="md" fill="#ffffff" />
              </StandardButton>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

/**
 * Render a custom Button for StandardSelection assignment.
 * @param {Boolean} isSelected An option is selected.
 * @return {function} For render prop: `renderButton`
 *
 */
const renderStandardSelectionButton = isSelected => ({ toggle, children }) => {
  const baseColor = isSelected ? "#ffffff" : "#ededed";
  return (
    <StandardButton
      appearance="default"
      onClick={toggle}
      iconAfter={{ name: "angle-down", fill: baseColor }}
      style={{
        color: baseColor,
        borderColor: baseColor,
        background: "none",
        fontWeight: isSelected ? "700" : "normal"
      }}
    >
      {children}
    </StandardButton>
  );
};
