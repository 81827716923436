import { call, put, takeLatest } from "redux-saga/effects";

import ApiService from "../../service/ApiService";
import { FETCH_STATUS_REQUEST, FETCH_STATUS } from "./index";

function* fetchStatus({ payload }) {
  try {
    const status = yield call(
      ApiService.getStatus,
      payload.token,
      payload.filter
    );
    yield put({
      type: FETCH_STATUS,
      payload: status
    });
  } catch (error) {
    yield put({
      type: FETCH_STATUS,
      payload: error,
      error: true
    });
  }
}

export default function* loadStatusSaga() {
  yield takeLatest(FETCH_STATUS_REQUEST, fetchStatus);
}
