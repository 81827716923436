import React, { useEffect, useState } from "react";
import { Flex, Box } from "reflexbox";
import { StandardButton } from "@trackcode/ui";
import ContentLoader from "react-content-loader";
import * as apiV2 from "common/apiV2";
import { ProgressInformation } from "./ProgressTimeline";

const useLoginTokens = memberId => {
  const [url, setUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const load = async () => {
      try {
        const {
          data: [appLoginToken]
        } = await apiV2.getAuthLoginTokens({ memberId });
        if (!appLoginToken || !appLoginToken.token) {
          return;
        }
        const { data: loginTokenInfo } = await apiV2.getAuthLoginTokenInfo({
          loginToken: appLoginToken.token
        });
        if (loginTokenInfo && loginTokenInfo.url) {
          setUrl(loginTokenInfo.url);
        }
      } catch (err) {
        // ignore error
        console.error("Unable to load login token", err);
      }
      setIsLoading(false);
    };
    if (memberId > 0) {
      load();
    } else {
      setIsLoading(false);
    }
  }, [memberId, setUrl, setIsLoading]);

  return [isLoading, url];
};

export const ProgressTimelineOneTime = ({
  driverMemberId = 0,
  trackingMemberId = 0
}) => {
  const [loadingDriverUrl, driverUrl] = useLoginTokens(driverMemberId);
  const [loadingTrackingUrl, trackingUrl] = useLoginTokens(trackingMemberId);

  return (
    <>
      {driverMemberId > 0 && (
        <LinkInformation
          title="App erstellt"
          url={driverUrl}
          isLoading={loadingDriverUrl}
        />
      )}
      {trackingMemberId > 0 && (
        <LinkInformation
          title="Seite zur Auftragsverfolgung erstellt"
          url={trackingUrl}
          isLoading={loadingTrackingUrl}
        />
      )}
    </>
  );
};

const LinkInformation = ({ title = "", url, isLoading }) => {
  const [hasError, setHasError] = useState(false);

  const copy = async () => {
    try {
      await navigator.clipboard.writeText(url);
    } catch (err) {
      console.error("Unable to copy text", err);
      setHasError(true);
    }
  };

  return (
    <ProgressInformation title={title}>
      {isLoading ? (
        <LoadingLink />
      ) : (
        <>
          <Flex alignItems="center">
            <Box>{url}</Box>
            <Box ml="3px">
              <StandardButton
                style={{ padding: "1px 4px", minHeight: "28px" }}
                appearance="subtle"
                spaceAfter={false}
                onClick={copy}
              >
                <ContentCopyIcon />
              </StandardButton>
            </Box>
          </Flex>
          {hasError ? (
            <Flex>
              <Box style={{ fontSize: "12px", color: "red" }}>
                Dein Browser scheint veraltet zu sein und unterstützt das
                kopieren von Inhalten nicht.
              </Box>
            </Flex>
          ) : null}
        </>
      )}
    </ProgressInformation>
  );
};

const LoadingLink = () => (
  <Box minWidth="300px" mt="5px" mb="-5px">
    <ContentLoader
      style={{
        width: "100%",
        height: "25px"
      }}
      viewBox="0 0 400 60"
      primaryColor="rgb(238, 238, 238)"
      secondaryColor="rgb(221, 221, 221)"
    >
      <rect x="0" y="10" width="280" height="30" />
      <rect x="300" y="3" width="30" height="45" />
    </ContentLoader>
  </Box>
);

/**
 * Source: https://fonts.google.com/icons?selected=Material%20Icons%3Acontent_copy%3A
 */
const ContentCopyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={18}
      viewBox="0 0 24 24"
      width={18}
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
    </svg>
  );
};
