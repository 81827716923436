export const TOURS_GET_REQUEST = "TOURS_GET_REQUEST";
export const TOURS_GET_RECEIVE = "TOURS_GET_RECEIVE";
export const TOURS_GET_ERROR = "TOURS_GET_ERROR";

export const TOUR_CREATE_REQUEST = "TOUR_CREATE_REQUEST";
export const TOUR_CREATE_RECEIVE = "TOUR_CREATE_RECEIVE";
export const TOUR_CREATE_ERROR = "TOUR_CREATE_ERROR";

export const TOUR_UPDATE_REQUEST = "TOUR_UPDATE_REQUEST";
export const TOUR_UPDATE_RECEIVE = "TOUR_UPDATE_RECEIVE";
export const TOUR_UPDATE_ERROR = "TOUR_UPDATE_ERROR";

export const TOUR_DELETE_REQUEST = "TOUR_DELETE_REQUEST";
export const TOUR_DELETE_RECEIVE = "TOUR_DELETE_RECEIVE";
export const TOUR_DELETE_ERROR = "TOUR_DELETE_ERROR";
