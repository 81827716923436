import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import { getMembers } from "state/entities/members/actions";
import { getTours } from "state/entities/tours/actions";
import LocationService from "../../../../service/LocationService";
import UserService from "../../../../service/UserService";

class DriverTourSelect extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    drivers: PropTypes.array,
    tours: PropTypes.shape({}).isRequired,
    focus: PropTypes.bool,
    members: PropTypes.shape({}).isRequired,
    selectedItem: PropTypes.object,
    calcDistanceFrom: PropTypes.object,
    locations: PropTypes.object,
    shipmentsAll: PropTypes.array,
    handleAssignment: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.select = null;
  }

  state = {
    selectedItem: null,
    focus: false
  };

  componentDidMount() {
    this.props.dispatch(getMembers());
    this.props.dispatch(getTours());
  }

  componentWillReceiveProps(nextProps) {
    const { focus, selectedItem } = nextProps;

    this.switchSelectBoxFocus(focus);
    //    console.log("selectedItem", selectedItem);

    if (selectedItem !== this.state.selectedItem) {
      this.setState({ selectedItem });
    }
  }

  /**
   * Count jobs of all drivers.
   * @param {object} drivers
   * @return {object}
   */
  getJobsCount(drivers) {
    /* eslint-disable camelcase */
    const { shipmentsAll, tours } = this.props;

    // { driverId: jobsCount }
    const driverJobsMapping = drivers.reduce((acc, { id }) => {
      acc[id] = 0;
      return acc;
    }, {});

    // { tourId: memberId }
    const tourDriverMapping = Object.values(tours.entities).reduce(
      (acc, { id, member_id }) => {
        if (member_id) {
          acc[id] = member_id;
        }
        return acc;
      },
      {}
    );

    // Count on status: pickup, loaded, direct, pending, assigned
    const status = [4, 5, 14, 22, 23];

    shipmentsAll
      .filter(({ status_id }) => status.includes(status_id))
      .filter(
        ({ responsible_member_id, responsible_tour_id }) =>
          responsible_member_id || responsible_tour_id
      )
      .forEach(({ responsible_member_id, responsible_tour_id }) => {
        let countMemberId = responsible_member_id;
        if (responsible_tour_id && tourDriverMapping[responsible_tour_id]) {
          // set member id of tour
          countMemberId = tourDriverMapping[responsible_tour_id];
        }
        // increment existing member or set it
        driverJobsMapping[countMemberId] = driverJobsMapping[countMemberId]
          ? driverJobsMapping[countMemberId] + 1
          : 1;
      });

    return driverJobsMapping;
    /* eslint-enable camelcase */
  }

  switchSelectBoxFocus(focus) {
    if (focus !== this.state.focus) {
      this.setState({ focus });

      setTimeout(() => {
        if (focus) {
          this.select.focus();
        } else {
          // this.select.blur();
        }
      }, 100);
    }
  }

  handleDriverTourSelected = e => {
    const selectedId = e ? e.value : 0;
    this.selectDriverTourById(selectedId);
  };

  selectDriverTourById(selectedId) {
    const { drivers, tours, handleAssignment } = this.props;

    let currentItem = null;

    if (selectedId) {
      /* eslint-disable */
      // TODO: Add === operator
      currentItem = drivers.filter(
        driver => selectedId.replace("m_", "") == driver.id
      )[0];
      if (!currentItem && tours.loaded) {
        currentItem = Object.values(tours.entities).filter(
          tourItem => selectedId.replace("t_", "") == tourItem.id
        )[0];
        currentItem.isDriver = false;
      } else {
        currentItem.isDriver = true;
      }
      /* eslint-enable */
    }

    //    console.log("currentItem", currentItem);

    this.setState({ selectedItem: currentItem });
    handleAssignment(currentItem);
  }

  calculateDistance(driverId) {
    const { locations } = this.props;
    let distance = null;
    const { calcDistanceFrom } = this.props;
    const [driverLocation] = locations[driverId];

    if (
      calcDistanceFrom &&
      calcDistanceFrom.latitude &&
      calcDistanceFrom.longitude &&
      driverLocation
    ) {
      distance = LocationService.getDistanceFromLatLonInKm(
        calcDistanceFrom.latitude,
        calcDistanceFrom.longitude,
        driverLocation.latitude,
        driverLocation.longitude
      );
    }

    return Number.isNaN(distance) ? 0 : distance;
  }

  renderDriverTourLabel = option => (
    <div>
      <span>{option.label} </span>
    </div>
  );

  renderDriverTourOptions(drivers) {
    const driverTourOptions = [];
    const { members, tours } = this.props;

    const driversJobsCount = this.getJobsCount(drivers);

    if (drivers && drivers.length) {
      driverTourOptions.push({
        label: "Fahrer",
        value: "",
        disabled: true
      });
      for (let i = 0; i < drivers.length; i += 1) {
        const distance = this.calculateDistance(drivers[i].id);
        const jobCount = driversJobsCount[drivers[i].id]
          ? driversJobsCount[drivers[i].id]
          : 0;
        driverTourOptions.push({
          label: `${UserService.getExtendedDriverLabel(
            drivers[i]
          )} (${jobCount}) ${distance ? ` | ↦ ${distance} km` : ""}`,
          count: drivers[i].job_count,
          distance,
          value: `m_${drivers[i].id}`
        });
      }
    }

    driverTourOptions.sort((a, b) => {
      const aDistance = parseFloat(a.distance);
      const bDistance = parseFloat(b.distance);

      if (aDistance > bDistance) {
        return 1;
      }

      if (aDistance < bDistance) {
        return -1;
      }
      return 0;
    });

    if (tours.loaded && members.loaded) {
      driverTourOptions.push({
        label: "Touren",
        value: "",
        disabled: true
      });

      Object.values(tours.entities).forEach(tour => {
        driverTourOptions.push({
          label: UserService.getTourLabel(tour),
          count: 0,
          distance: 0,
          value: `t_${tour.id}`
        });
      });
    }
    return driverTourOptions;
  }

  render() {
    const { selectedItem } = this.state;

    const { drivers, placeholder, ...otherProps } = this.props;

    // TODO: Refactoring
    // eslint-disable-next-line no-nested-ternary
    const itemId = selectedItem
      ? selectedItem.isDriver
        ? `m_${selectedItem.id}`
        : `t_${selectedItem.id}`
      : 0;
    //    console.log("itemId", itemId);
    //    console.log("tour", tour);

    return (
      <Select
        ref={select => {
          this.select = select;
        }}
        placeholder={placeholder}
        value={itemId}
        optionRenderer={this.renderDriverTourLabel}
        options={this.renderDriverTourOptions(drivers)}
        onChange={this.handleDriverTourSelected}
        openOnFocus
        {...otherProps}
      />
    );
  }
}

const mapStateToProps = ({
  auth: { token },
  driver: { drivers, locations },
  shipment: { shipmentsAll },
  entities: { members, tours }
}) => ({
  token,
  drivers,
  locations,
  members,
  tours,
  shipmentsAll
});

export default connect(mapStateToProps)(DriverTourSelect);
