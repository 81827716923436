import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import uuid from "uuid";
import Icon from "../element/icon/Icon";
import Tooltip from "../element/tooltip/Tooltip";
import styleNames from "./shipmentItem.local.css";

const cx = classNames.bind(styleNames);

const AppointmentRow = ({
  showRecurring,
  muteClock = false,
  appointment,
  address
}) => {
  const tooltipId = uuid.v1();

  return (
    <div className={cx("shipment-notes-row")}>
      {showRecurring && (
        <Icon className="text-muted" name="autorenew" size="xs" />
      )}
      {!showRecurring && <div className={cx("whitespace-recurring")} />}
      {appointment && (
        <span>
          <Icon
            className={muteClock ? "text-muted" : ""}
            name="clock"
            size="xs"
          />{" "}
          <span className="text-muted">{appointment} </span>
        </span>
      )}
      {(address.phone || address.avis) && (
        <Tooltip
          id={`shipment-tooltip-${tooltipId}`}
          position="top"
          content={[address.phone, address.avis].filter(val => val).join(", ")}
        >
          <Icon className="text-muted" name="message-text" size="xs" />
        </Tooltip>
      )}
      &nbsp;
    </div>
  );
};

AppointmentRow.propTypes = {
  showRecurring: PropTypes.bool,
  muteClock: PropTypes.bool,
  appointment: PropTypes.string,
  address: PropTypes.shape({}).isRequired
};

AppointmentRow.defaultProps = {
  showRecurring: false,
  muteClock: false,
  appointment: ""
};

export default AppointmentRow;
