import {
  SHIPMENTS_SORT_BY,
  SHIPMENTS_SET_FILTER_DATE,
  SHIPMENTS_SET_FILTER_STATUS,
  SHIPMENTS_SET_FILTER_TOURS,
  SHIPMENTS_SET_FILTER_DRIVERS,
  SHIPMENTS_SHOW_EDIT,
  SHIPMENTS_HIDE_EDIT,
  SHIPMENTS_HIDE_PROGRESS_ADD,
  SHIPMENTS_SHOW_PROGRESS_ADD,
  SHIPMENTS_SET_SCROLL_TOP
} from "./constants";

const initialState = {
  sortBy: "time",
  selectedDate: new Date(),
  selectedStatusIds: [],
  selectedTourIds: [],
  selectedDriverIds: [],
  scrollTop: 0,
  editShipment: {
    active: false,
    shipmentId: null
  },
  addProgress: {
    active: false,
    shipmentId: null
  }
};

export default function shipmentReducer(state = initialState, action = {}) {
  const { type, payload, error } = action;

  if (error) {
    return {
      ...state,
      error: payload
    };
  }

  switch (type) {
    case SHIPMENTS_SET_FILTER_DRIVERS: {
      return {
        ...state,
        selectedDriverIds: payload
      };
    }

    case SHIPMENTS_SET_FILTER_TOURS: {
      return {
        ...state,
        selectedTourIds: payload
      };
    }

    case SHIPMENTS_SET_FILTER_STATUS: {
      return {
        ...state,
        selectedStatusIds: payload
      };
    }

    case SHIPMENTS_SORT_BY: {
      return {
        ...state,
        sortBy: payload.sortBy
      };
    }

    case SHIPMENTS_SET_FILTER_DATE: {
      return {
        ...state,
        selectedDate: payload
      };
    }

    case SHIPMENTS_SHOW_EDIT: {
      return {
        ...state,
        editShipment: {
          active: true,
          shipmentId: payload
        }
      };
    }

    case SHIPMENTS_HIDE_EDIT: {
      return {
        ...state,
        editShipment: {
          active: false,
          shipmentId: null
        }
      };
    }

    case SHIPMENTS_SHOW_PROGRESS_ADD: {
      return {
        ...state,
        addProgress: {
          active: true,
          shipmentId: payload
        }
      };
    }

    case SHIPMENTS_HIDE_PROGRESS_ADD: {
      return {
        ...state,
        addProgress: {
          active: false,
          shipmentId: null
        }
      };
    }

    case SHIPMENTS_SET_SCROLL_TOP: {
      return {
        ...state,
        scrollTop: payload
      };
    }

    default: {
      return state;
    }
  }
}
