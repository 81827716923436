import React, { Component } from "react";
import PropTypes from "prop-types";
import { Provider, connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";

import { App, AppV2 } from "features/app";
import { ShipmentList } from "features/shipment";
import { Folders } from "features/folder/Folders";
import Login from "./containers/login/Login";
import PasswordProcess from "./containers/password/PasswordProcess";
import SchedulingList from "./containers/scheduling/SchedulingList";
import Tracking from "./containers/tracking/Tracking";
import TrackingV2 from "./features/tracking/Tracking";
import NotFound from "./containers/misc/NotFound";

const FEATURE_V2_LOGISTICCOMPANIES = [
  7, // TrackCode
  27, // GO! München
  84, // TNT MWL One-Time
  86, // Safe Pack
  89, // TrackCode Teaser-Umgebung
  200, // PWA Test
  201, // PWA Test
  202, // PWA Test
  203, // PWA Test
  204, // PWA Test
  205 // PWA Test
];
const FEATURE_FLAG_V2 = "feature_flag_v2";

// Ability to manually set the feature flag.
const featureV2 = localStorage.getItem(FEATURE_FLAG_V2) === "true";

const mapDispatchToProps = ({ auth: token }) => ({ token });

const PrivateRoute = connect(mapDispatchToProps)(
  ({ token, component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props =>
        token ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  )
);

/**
 * Component to handle V1 and V2 route rendering based on the logisticCompanyId
 * (also considers the logisticcompany switch) or the feature flag.
 */
const FeatureSwitch = connect(({ auth: { user, logisticCompanyId } }) => ({
  user,
  logisticCompanyId
}))(({ user, logisticCompanyId, v1, v2 }) => {
  if (!user) {
    return v1;
  }
  // because of the logisticcompany switch
  const id = logisticCompanyId || user.logisticcompany_id;
  if (featureV2 || FEATURE_V2_LOGISTICCOMPANIES.includes(id)) {
    return v2;
  }
  return v1;
});

// eslint-disable-next-line react/prefer-stateless-function
export default class Root extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired
  };

  render() {
    const { store } = this.props;

    return (
      <Provider store={store}>
        <Router>
          <Switch>
            <Route path="/setPassword" component={PasswordProcess} />
            <Route path="/resetPassword" component={PasswordProcess} />
            <Route path="/login" component={Login} />
            <FeatureSwitch v1={<RoutesV1 />} v2={<RoutesV2 />} />
          </Switch>
        </Router>
      </Provider>
    );
  }
}

const RoutesV2 = () => (
  <AppV2>
    <Switch>
      <PrivateRoute
        path={["/track/:code/progress", "/track/:code", "/track"]}
        component={TrackingV2}
      />
      <PrivateRoute path="/scheduling" component={ShipmentList} />
      <PrivateRoute path="/folders" component={Folders} />
      <Route exact path="/" render={() => <Redirect to="/scheduling" />} />
      <Route path="*" component={NotFound} />
    </Switch>
  </AppV2>
);

const RoutesV1 = () => (
  <App>
    <Switch>
      <PrivateRoute path={["/track/:code", "/track"]} component={Tracking} />
      <PrivateRoute path="/scheduling" component={SchedulingList} />
      <PrivateRoute exact path="/" component={SchedulingList} />
      <Route path="*" component={NotFound} />
    </Switch>
  </App>
);
