/**
 * Created by kim on 08.01.17.
 */
import {
  PASSWORD_CREATE,
  PASSWORD_CREATE_REQUEST,
  PASSWORD_RESET,
  PASSWORD_RESET_REQUEST
} from "./index";

const initialState = {
  result: {},
  loaded: false,
  refresh: false,
  error: null
};

export default function auth(state = initialState, action = {}) {
  const { type, payload, error } = action;

  if (error) {
    return {
      ...state,
      error: payload
    };
  }

  switch (type) {
    case PASSWORD_CREATE_REQUEST:
      return {
        ...state,
        result: payload,
        refresh: false,
        loaded: false,
        error: null
      };

    case PASSWORD_CREATE:
      return {
        ...state,
        result: payload,
        refresh: false,
        loaded: true,
        error: null
      };

    case PASSWORD_RESET_REQUEST:
      return {
        ...state,
        result: payload,
        refresh: false,
        loaded: false,
        error: null
      };

    case PASSWORD_RESET:
      return {
        ...state,
        result: payload,
        refresh: false,
        loaded: true,
        error: null
      };

    default:
      return state;
  }
}
