import React, { Component } from "react";
import PropTypes from "prop-types";

import FloatingTextField from "../../components/element/form/FloatingTextField";
import TCCheckbox from "./../element/form/TCCheckbox";

import "./password.css";

class CreatePassword extends Component {
  static propTypes = {
    sendPassword: PropTypes.func.isRequired,
    title: PropTypes.string,
    desc: PropTypes.string
  };

  state = {
    password: "",
    showPassword: false
  };

  setPassword = e => {
    this.setState({ password: e.target.value });
  };

  handleKeyPress = e => {
    if (e.key === "Enter") {
      this.sendResetPassword();
    }
  };

  sendResetPassword = () => {
    const { sendPassword } = this.props;

    sendPassword(this.state.password);
  };

  showPasswordChanged = (e, t) => {
    this.setState({ showPassword: t.checked });
  };

  render() {
    const { title, desc } = this.props;

    const { password, showPassword } = this.state;

    return (
      <div className="form-group">
        <div className="introtext">{title}</div>
        <div className="infotext">{desc}</div>
        <FloatingTextField
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={this.setPassword}
          onKeyPress={this.handleKeyPress}
          floatingLabel="Passwort"
          required
        />
        <TCCheckbox
          checked={this.showPassword}
          label="Passwort sichtbar machen"
          onChange={this.showPasswordChanged}
        />
        {/* eslint-disable jsx-a11y/interactive-supports-focus */}
        <div
          role="button"
          className="button btn btn-success"
          onClick={this.sendResetPassword}
        >
          Einrichten
        </div>
        {/* eslint-enable jsx-a11y/interactive-supports-focus */}
      </div>
    );
  }
}

export default CreatePassword;
