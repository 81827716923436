const ENV = process.env.APP_ENV || "development";

export default class Tools {
  static log(...log) {
    if (ENV === "development") {
      console.log(...log);
    }
  }

  static clone(obj) {
    if (obj == null || typeof obj !== "object") return obj;
    const copy = obj.constructor();
    // TODO: Better remove this function!
    // eslint-disable-next-line no-restricted-syntax
    for (const attr in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
    }
    return copy;
  }
}
