import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ChatList from "../../chat/ChatList";
import { loadDrivers } from "../../../state/driver/driverAction";

class SiderbarRight extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    drivers: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })),
    dispatch: PropTypes.func.isRequired,
    handleDriverClicked: PropTypes.func.isRequired
  };

  static defaultProps = {
    drivers: []
  };

  constructor(props) {
    super(props);
    this.init = false;
  }

  componentDidMount() {
    this.updateDrivers({
      filterTags: null
    });
  }

  updateDrivers(filter) {
    const { token, dispatch } = this.props;
    // load shipments
    dispatch(loadDrivers(token, filter));
  }

  render() {
    const { drivers, handleDriverClicked } = this.props;

    return (
      <ChatList drivers={drivers} handleDriverClicked={handleDriverClicked} />
    );
  }
}

const mapStateToProps = ({ auth, driver }) => ({
  token: auth.token,
  drivers: driver.drivers
});

export default connect(mapStateToProps)(SiderbarRight);
