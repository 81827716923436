import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { Box } from "reflexbox";
import * as apiV2 from "common/apiV2";
import { StandardModal, StandardButton, StandardInput } from "@trackcode/ui";
import { setFolderMember } from "state/entities/folders/actions";

export const ExternalAssignment = () => {
  const { companyname } = useSelector(({ auth: { user } }) => user);

  const { folderID } = useParams();
  const history = useHistory();

  const [createLink, link, isLoading, error] = useCreateExternalLink();
  const [name, setName] = useState("");

  const submitForm = () => {
    if (!name) {
      return;
    }
    // extract first and lastname, if no space exits
    // the name will be saved in lastname
    const firstname = name.substring(0, name.lastIndexOf(" "));
    const lastname = name.substring(name.lastIndexOf(" ") + 1, name.length);

    const externalDriver = {
      firstname,
      lastname
    };

    createLink(externalDriver, folderID);
  };

  const onClose = () => history.push("/folders");

  const messengerText = generateMessengerText(companyname, link);

  return (
    <StandardModal
      size="small"
      heading="Arbeitsmappe extern vergeben"
      confirmElement={action => (
        <StandardButton onClick={action}>Schließen</StandardButton>
      )}
      onClose={onClose}
    >
      {error && (
        <Box py="12px">
          Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.
          <br />
          {error}
        </Box>
      )}
      {!link && (
        <Box pt="12px">
          <Row>
            <ColLabel>Logistikpartner*</ColLabel>
            <Col>
              <StandardInput
                placeholder="Max Mustermann"
                value={name}
                onChange={e => {
                  setName(e.target.value);
                }}
                style={{ width: "220px" }}
              />
            </Col>
          </Row>
          <Box pt="12px">
            <StandardButton onClick={onClose}>Abbrechen</StandardButton>
            <StandardButton
              disabled={isLoading}
              appearance="primary"
              onClick={submitForm}
            >
              {isLoading ? "Link wird erstellt..." : "Link erstellen"}
            </StandardButton>
          </Box>
        </Box>
      )}
      {link && (
        <Box pt="12px" maxWidth="620px">
          <Box>
            Bitte kopiere und versende den nachfolgenden Link mit einem
            Messenger deiner Wahl (SMS, Threema, Signal, Telegram, WhatsApp
            o.ä.) und bestätige anschließend, dass du ihn versendet hast.
            <br />
            <br />
            <textarea
              disabled
              style={{
                width: "100%",
                height: "105px",
                background: "none",
                border: "1px dashed #666",
                resize: "none",
                padding: "12px 18px"
              }}
            >
              {messengerText}
            </textarea>
          </Box>
          <Box>
            <StandardButton
              onClick={() => navigator.clipboard.writeText(messengerText)}
            >
              Text kopieren
            </StandardButton>
            <StandardButton onClick={() => navigator.clipboard.writeText(link)}>
              Nur den Link kopieren
            </StandardButton>
          </Box>
          <Box pt="24px">
            <StandardButton appearance="primary" onClick={onClose}>
              Bestätigen & schließen
            </StandardButton>
          </Box>
        </Box>
      )}
    </StandardModal>
  );
};

/**
 * @param {string} companyname
 * @param {string} link
 */
const generateMessengerText = (companyname, link) => {
  return `Hallo lieber Logistikpartner! Wir von ${companyname} haben einen neuen Auftrag für dich. Du kannst ihn unter folgendem Link aufrufen, einsehen und bearbeiten: "${link}"`;
};

const useCreateExternalLink = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [link, setLink] = useState("");

  const create = useCallback(
    /**
     * @param {string} token
     */
    async (externalDriver, folderID) => {
      try {
        setError("");
        setIsLoading(true);
        const {
          data: { id: memberID }
        } = await apiV2.createExternalDriver(externalDriver);
        await dispatch(setFolderMember(folderID, memberID));
        const {
          data: { link }
        } = await apiV2.createExternalDriverInviteLink(memberID);
        setLink(link);
      } catch (err) {
        setLink("");
        // only the status code...
        setError(String(err));
      }
      setIsLoading(false);
    },
    [dispatch, setLink, setIsLoading, setError]
  );

  return [create, link, isLoading, error];
};

const Row = styled.div`
  display: flex;
  flex: 1;
  padding-bottom: 13px;
  align-items: center;
`;

const ColLabel = styled.div`
  width: 150px;
`;

const Col = styled.div`
  flex: 3;
`;
