import { call, put, takeLatest } from "redux-saga/effects";

import ApiService from "../../service/ApiService";
import {
  DIALOG_READ_REQUEST,
  DIALOG_READ,
  MESSAGE_CREATE,
  MESSAGE_CREATE_REQUEST
} from "./index";

function* fetchDialog({ payload }) {
  try {
    const dialog = yield call(
      ApiService.getDialog,
      payload.token,
      payload.member_id
    );
    yield put({
      type: DIALOG_READ,
      payload: dialog.messages
    });
  } catch (error) {
    yield put({
      type: DIALOG_READ,
      payload: error,
      error: true
    });
  }
}

function* createMessage({ payload }) {
  try {
    const message = yield call(
      ApiService.createMessage,
      payload.token,
      payload.memberId,
      payload.text
    );
    yield put({
      type: MESSAGE_CREATE,
      payload: message
    });
  } catch (error) {
    yield put({
      type: MESSAGE_CREATE,
      payload: error,
      error: true
    });
  }
}

export const loadDialogSaga = function* loadDialogSaga() {
  yield takeLatest(DIALOG_READ_REQUEST, fetchDialog);
};

export const createMessageSaga = function* createMessageSaga() {
  yield takeLatest(MESSAGE_CREATE_REQUEST, createMessage);
};
