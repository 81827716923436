import {
  FOLDER_SET_FILTER_FOLDERS,
  FOLDER_SET_FILTER_DRIVERS,
  FOLDER_SET_SCROLL_TOP
} from "./constants";

const initialState = {
  selectedFolderIds: [],
  selectedDriverIds: [],
  scrollTop: 0
};

export default function folderReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FOLDER_SET_FILTER_FOLDERS: {
      return {
        ...state,
        selectedFolderIds: payload
      };
    }

    case FOLDER_SET_FILTER_DRIVERS: {
      return {
        ...state,
        selectedDriverIds: payload
      };
    }

    case FOLDER_SET_SCROLL_TOP: {
      return {
        ...state,
        scrollTop: payload
      };
    }

    default: {
      return state;
    }
  }
}
