import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import classNames from "classnames/bind";

import Loading from "../Loading/Loading";
import ShipmentItem from "./ShipmentItem";

import styleNames from "./shipmentList.local.css";

const cx = classNames.bind(styleNames);

const Container = styled.div`
  flex: 5;
  display: flex;
  min-height: 0px;
`;

const ContainerScroll = styled.div`
  flex: 1;
  overflow: auto;
`;

export default class ShipmentList extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    shipmentsLoadError: PropTypes.shape({}),
    shipments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    handleShipmentSelected: PropTypes.func.isRequired,
    handleShipmentAction: PropTypes.func.isRequired,
    selectedShipments: PropTypes.arrayOf(PropTypes.shape({})),
    handleShipmentKeyEvents: PropTypes.func
  };

  static defaultProps = {
    selectedShipments: [],
    handleShipmentKeyEvents: () => {},
    shipmentsLoadError: null
  };

  constructor() {
    super();
    this.list = null;
  }

  setList = ref => {
    this.list = ref;
  };

  isSelected(trackcode) {
    const { selectedShipments } = this.props;

    if (
      selectedShipments.filter(shipment => trackcode === shipment.trackcode)
        .length
    ) {
      return true;
    }
    return false;
  }

  handleShipmentItemOutsideSelection = element => {
    this.currentCheckbox = element;
    const itemHeight = this.currentCheckbox.parentElement.parentElement
      .offsetHeight;

    if (
      this.currentCheckbox.getBoundingClientRect().bottom >
      this.list.getBoundingClientRect().bottom
    ) {
      this.list.scrollTop += itemHeight;
    } else if (
      this.currentCheckbox.getBoundingClientRect().top <
      this.list.getBoundingClientRect().top
    ) {
      this.list.scrollTop -= itemHeight;
    }
  };

  render() {
    const {
      loading,
      shipmentsLoadError,
      shipments,
      handleShipmentSelected,
      handleShipmentAction,
      handleShipmentKeyEvents
    } = this.props;

    return (
      <Container>
        <ContainerScroll ref={this.setList}>
          {loading && <Loading className={cx("ci-spinner-spacing")} />}
          {shipmentsLoadError && (
            <div className={cx("ci-empty-shipments", "alert", "alert-error")}>
              <h3>Es ist ein Fehler aufgetreten</h3>
              <p>Laden der Sendungen ist fehlgeschlagen.</p>
              <p>{shipmentsLoadError}</p>
            </div>
          )}
          {!loading && !shipments.length && (
            <div className={cx("ci-empty-shipments", "alert", "alert-info")}>
              <h3>Keine Sendungen</h3>
              <p>
                Für den gewählten Zeitraum und die spezifizierten Filter sind
                keine Sendungen vorhanden.
              </p>
            </div>
          )}

          {!loading &&
            !shipmentsLoadError &&
            shipments.map(shipment => (
              <ShipmentItem
                key={`sitem-${
                  shipment.trackcode ? shipment.trackcode : shipment.socket_id
                }`}
                shipment={shipment}
                handleShipmentSelected={handleShipmentSelected}
                handleShipmentKeyEvents={handleShipmentKeyEvents}
                handleOutsideSelect={this.handleShipmentItemOutsideSelection}
                handleShipmentAction={handleShipmentAction}
                selected={this.isSelected(shipment.trackcode)}
              />
            ))}
        </ContainerScroll>
      </Container>
    );
  }
}
