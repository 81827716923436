import { LOGISTICCOMPANY_PREPARE_CHANGE } from "state/auth";
import {
  FETCH_DRIVER,
  MEMBER_CONNECTIVITY_CHANGE,
  DRIVER_LOCATION_UPDATE,
  DRIVERS_LOCATIONS
} from "./index";

const initialState = {
  drivers: [],
  // driver locations `{ 1: [{ ...location }], ... }`
  locations: {},
  locationsLoaded: false,
  loaded: false,
  error: null
};

export default function auth(state = initialState, action = {}) {
  const { type, payload, error } = action;

  let allDriver;

  if (error) {
    return {
      ...state,
      error: payload
    };
  }

  switch (type) {
    case FETCH_DRIVER:
      allDriver = payload.drivers;
      return {
        ...state,
        drivers: allDriver.sort((a, b) =>
          a.firstname.localeCompare(b.firstname)
        ),
        loaded: true,
        error: null
      };

    case MEMBER_CONNECTIVITY_CHANGE: {
      const newDrivers = state.drivers.map(driver => {
        if (driver.id === payload.memberId) {
          return { ...driver, online: payload.online };
        }
        return driver;
      });

      return {
        ...state,
        drivers: newDrivers,
        error: null
      };
    }

    case DRIVERS_LOCATIONS: {
      return {
        ...state,
        locationsLoaded: true,
        locations: action.locations.reduce((acc, driver) => {
          acc[driver.driverId] = driver.locations;
          return acc;
        }, {})
      };
    }

    case DRIVER_LOCATION_UPDATE: {
      return {
        ...state,
        locations: {
          ...state.locations,
          [payload.member_id]: [
            payload,
            // persist the only the newest location data
            ...(state.locations[payload.member_id] || []).slice(0, 5)
          ]
        }
      };
    }

    case LOGISTICCOMPANY_PREPARE_CHANGE:
      return initialState;

    default:
      return state;
  }
}
