import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isValid, format } from "date-fns";
import styled from "styled-components";
import { Flex, Box } from "reflexbox";
import CI from "@trackcode/ci";
import {
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds
} from "date-fns";

import ApiService from "../../service/ApiService";
import { ProgressTimelineOneTime } from "./ProgressTimelineOneTime";

/**
 * Format "YYYY-MM-DD HH:mm:ss" to a german format.
 * @param {string} dateStr
 * @return {string}
 */
const formatDate = dateStr => {
  const parsedDate = new Date(dateStr);
  if (!isValid(parsedDate)) {
    return "";
  }
  return format(parsedDate, "DD.MM.YYYY, HH:mm:ss");
};

const formatCount = count => `0${count > 0 ? count : 0}`.slice(-2);

const getFormattedCounter = frame => {
  const { start, end } = frame;
  if (!start) {
    return "00:00:00";
  }

  const now = end || new Date();

  const hours = differenceInHours(now, start);
  const minutes = differenceInMinutes(now, start) - hours * 60;
  const seconds = differenceInSeconds(now, start) - minutes * 60;

  return `${formatCount(hours)}:${formatCount(minutes)}:${formatCount(
    seconds
  )}`;
};

const mapProgressForTimeline = (progress, token) => {
  return progress.map(scan => {
    const clientDateTime = formatDate(scan.client_datetime);
    const serverDateTime = formatDate(scan.datetime);

    const scanItem = {
      statusId: scan.status_id,
      memberId: scan.member_id,
      title: scan.translation,
      datetime: clientDateTime || serverDateTime,
      name: scan.name
    };

    if (scan.variation_label) {
      scanItem.variation = scan.variation_label;
    }

    switch (scan.label) {
      case "delivered":
        scanItem.class = "success";
        scanItem.classState = "success";
        scanItem.icon = "check";
        break;
      case "barrier":
      case "damage_documentation":
        scanItem.classState = "warning";
        break;
      default:
        scanItem.classState = "default";
        break;
    }

    scanItem.images = [];
    scanItem.signature = {};

    if (scan.media) {
      scan.media.forEach(media => {
        switch (media.label) {
          case "imagenode":
            (media.data.images || []).forEach(image => {
              scanItem.images.push({
                full: ApiService.getGridUrl(token, "", image.uuid),
                medium: ApiService.getGridUrl(token, "MEDIUM", image.uuid),
                thumb: ApiService.getGridUrl(token, "THUMB", image.uuid),
                name: image.name
              });
            });
            if (media.data.description) {
              scanItem.image_description = media.data.description;
            }
            break;
          case "signature_salutation":
            // TODO translate media.data
            break;
          case "signature":
            scanItem.signature.image = ApiService.getSignatureUrl(
              token,
              media.id
            );
            break;
          case "signature_name":
            scanItem.signature.name = media.data;
            break;
          case "packing_unit_counter":
            scanItem.packingUnits = media.data;
            break;
          case "total_weight":
            scanItem.weight = media.data;
            break;
          case "packing_unit_counter_description":
            scanItem.packingUnitReason = media.data;
            break;
          case "start_vehicle_mileage_km":
            scanItem.startVehicleMileageKm = media.data;
            break;
          case "end_vehicle_mileage_km":
            scanItem.endVehicleMileageKm = media.data;
            break;
          case "v2_wait":
            scanItem.wait = media.data;
            break;
          case "v2_external_members":
            scanItem.externalMembers = media.data;
            break;
          case "data_correction":
            scanItem.data_correction = media.data;
            break;
          default:
            break;
        }
      });
    }

    if (Object.keys(scanItem.signature).length === 0) {
      delete scanItem.signature;
    }

    return scanItem;
  });
};

const ProgressEntry = styled.div`
  padding: 30px;
  box-sizing: border-box;
  border-bottom: 1.5px dotted ${CI.color.gray};
`;

// copied from ShipmentList
const StatusBadge = styled.div`
  background-color: #777777;
  padding: 3px 6px;
  font-size: 11px;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  border-radius: 0.25em;
  text-transform: uppercase;
  /* custom: */
  display: inline-block;
  background-color: ${({ arrived }) =>
    arrived ? CI.color.brand : CI.color.grayDark};
`;

export const ProgressInformation = ({ title, children }) => (
  <div style={{ marginTop: "15px", paddingLeft: "6px" }}>
    <div>{title}</div>
    <div style={{ marginLeft: "12px" }}>{children}</div>
  </div>
);

/* eslint-disable react/no-array-index-key */
const ProgressTimeline = ({ progress, token }) => {
  if (!progress) return null;
  const items = mapProgressForTimeline(progress, token);
  return (
    <Box>
      {items.map((item, i) => (
        <ProgressEntry key={i}>
          <Flex>
            <Box flex={2} alignItems="center">
              <StatusBadge arrived={item.title === "Zugestellt"}>
                {item.title}
              </StatusBadge>
            </Box>
            <Box flex={2}>
              <div>{item.datetime}</div>
            </Box>
            <Box flex={5}>
              <div>{item.name}</div>
            </Box>
          </Flex>
          <Flex>
            <Box>
              {item.variation && (
                <ProgressInformation title="Begründung">
                  {item.variation}
                </ProgressInformation>
              )}
              {(item.images.length > 0 || item.image_description) && (
                <ProgressInformation title="Bilder">
                  <>
                    {item.image_description && (
                      <div>{item.image_description}</div>
                    )}
                    {item.images.map(image => (
                      <a
                        href={image.full}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          display: "inline-block",
                          margin: "6px 6px 0 0"
                        }}
                      >
                        <img
                          src={image.thumb}
                          title={image.name}
                          alt="parcel"
                        />
                      </a>
                    ))}
                  </>
                </ProgressInformation>
              )}
              {item.signature && (
                <ProgressInformation title="Signatur">
                  <>
                    {item.signature.name}
                    <br />
                    {item.signature.image && (
                      <img
                        style={{
                          width: "250px",
                          height: "250px",
                          background: "#fdfdfd"
                        }}
                        src={item.signature.image}
                        alt="signature"
                      />
                    )}
                  </>
                </ProgressInformation>
              )}
              {item.packingUnits && Object.keys(item.packingUnits).length > 0 && (
                <ProgressInformation title="Lademittel">
                  {Object.entries(item.packingUnits).map(packingUnit => (
                    <Flex>
                      <Box width="40px">{packingUnit[1]}</Box>
                      <Box>{packingUnit[0]}</Box>
                    </Flex>
                  ))}
                  {item.packingUnitReason &&
                    `Begründung: ${item.packingUnitReason}`}
                </ProgressInformation>
              )}
              {item.data_correction &&
              (item.data_correction.length ||
                item.data_correction.width ||
                item.data_correction.height) ? (
                <ProgressInformation title="Maße">
                  L:&nbsp;
                  {item.data_correction.length
                    ? `${item.data_correction.length} cm`
                    : "-"}
                  <br />
                  B:&nbsp;
                  {item.data_correction.width
                    ? `${item.data_correction.width} cm`
                    : "-"}
                  <br />
                  H:&nbsp;
                  {item.data_correction.height
                    ? `${item.data_correction.height} cm`
                    : "-"}
                </ProgressInformation>
              ) : null}
              {item.data_correction && item.data_correction.weight && (
                <ProgressInformation title="Gewicht">
                  {String(item.data_correction.weight || "").replace(".", ",")}
                  &nbsp;kg
                </ProgressInformation>
              )}
              {item.weight && (
                <ProgressInformation title="Gewicht">
                  {item.weight} kg
                </ProgressInformation>
              )}
              {Number.isInteger(item.startVehicleMileageKm) &&
                Number.isInteger(item.endVehicleMileageKm) && (
                  <ProgressInformation title="Gefahrene Wegstrecke">
                    {item.endVehicleMileageKm - item.startVehicleMileageKm} km
                  </ProgressInformation>
                )}
              {item.wait && item.wait.start && item.wait.end && (
                <ProgressInformation title="Dauer">
                  {getFormattedCounter(item.wait)} (Stunden:Minuten:Sekunden)
                </ProgressInformation>
              )}
              {item.externalMembers ? (
                <ProgressTimelineOneTime
                  driverMemberId={item.externalMembers.driverMemberId}
                  trackingMemberId={item.externalMembers.trackingMemberId}
                />
              ) : null}
            </Box>
          </Flex>
        </ProgressEntry>
      ))}
    </Box>
  );
};

ProgressTimeline.propTypes = {
  items: PropTypes.array,
  token: PropTypes.string
};

const mapStateToProps = state => {
  return {
    token: state.auth.token
  };
};

export default connect(mapStateToProps)(ProgressTimeline);
