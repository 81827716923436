import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { SHIPMENT_TYPES } from "state/entities/shipments/constants";
import { getShipmentJobsByDriver } from "state/entities/shipments/action";
import { mapActions } from "features/map";

/**
 * Get a list of pins for feature/map based on shipments.
 * @param {Array} shipments
 * @return {Array} list of pins
 */
export const getPinsFromShipments = shipments => {
  const pins = shipments
    .map(
      ({
        id,
        type,
        sender: {
          address: { latitude: latA, longitude: lngA }
        },
        recipient: {
          address: { latitude: latB, longitude: lngB }
        }
      }) => {
        switch (type) {
          case SHIPMENT_TYPES.PICKUP:
            return {
              key: id,
              position: { lat: latA, lng: lngA },
              label: " "
            };
          case SHIPMENT_TYPES.STOP:
          case SHIPMENT_TYPES.DELIVERY:
            return {
              key: id,
              position: { lat: latB, lng: lngB },
              label: " "
            };
          case SHIPMENT_TYPES.DIRECT:
            return [
              {
                key: `${id}A`,
                position: { lat: latA, lng: lngA },
                label: "A"
              },
              {
                key: `${id}B`,
                position: { lat: latB, lng: lngB },
                label: "B"
              }
            ];
          default:
            return false;
        }
      }
    )
    .filter(Boolean);
  // flatten array and remove NaN positions (@trackcode/map could not handle this)
  return []
    .concat(...pins)
    .filter(
      ({ position: { lat, lng } }) => !Number.isNaN(lat) && !Number.isNaN(lng)
    );
};

const ShipmentsOnMap = ({
  selectedShipmentIds,
  selectedDriverId,
  shipments,
  driverJobsMapping,
  dispatch
}) => {
  useEffect(() => {
    if (selectedDriverId !== null) {
      // load shipment jobs
      dispatch(getShipmentJobsByDriver(selectedDriverId));
    }
  }, [dispatch, selectedDriverId]);

  useEffect(() => {
    // get driver shipments for polygon
    const driverShipmentIds = driverJobsMapping[selectedDriverId] || [];
    const driverShipments = driverShipmentIds
      .map(shipmentId => shipments[shipmentId])
      .filter(Boolean);
    const pinsDriver = getPinsFromShipments(driverShipments).map(pin => ({
      ...pin,
      appearance: "subtle"
    }));

    // get selected shipments with the exception of the driver pins
    const selectedShipmentIdsExceptDriver = selectedShipmentIds.filter(
      shipmentId => !driverShipmentIds.includes(shipmentId)
    );
    const pinsSelected = getPinsFromShipments(
      selectedShipmentIdsExceptDriver.map(shipmentId => shipments[shipmentId])
    );

    if (pinsSelected.length > 0 || pinsDriver.length > 0) {
      // show pins on map
      dispatch(
        mapActions.set(
          "scheduling",
          {
            pins: [...pinsSelected, ...pinsDriver],
            polygons: [
              { positions: pinsDriver.map(({ position }) => position) }
            ]
          },
          {
            fitBounds: true
          }
        )
      );
    }
    return () => {
      // clear pins and show drivers
      dispatch(mapActions.clear("scheduling"));
      dispatch(
        mapActions.setDriversOptions({
          fitBounds: true
        })
      );
    };
  }, [
    shipments,
    selectedDriverId,
    driverJobsMapping,
    selectedShipmentIds,
    dispatch
  ]);

  return null;
};

ShipmentsOnMap.propTypes = {
  selectedShipmentIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedDriverId: PropTypes.number,
  shipments: PropTypes.shape({}).isRequired,
  driverJobsMapping: PropTypes.shape({}).isRequired,
  dispatch: PropTypes.func.isRequired
};

ShipmentsOnMap.defaultProps = {
  selectedDriverId: null
};

const mapStateToProps = (
  {
    entities: {
      shipments: { entities: shipments, driverJobsMapping }
    }
  },
  props
) => ({
  shipments: props.shipments || shipments,
  driverJobsMapping
});

// export component for testing
export const ShipmentsOnMapComponent = ShipmentsOnMap;

export default React.memo(connect(mapStateToProps)(ShipmentsOnMap));
