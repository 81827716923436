import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMembers } from "state/entities/members/actions";
import {
  getTours,
  updateTour,
  createTour,
  deleteTour
} from "state/entities/tours/actions";

import TCModal from "../../components/element/overlay/TCModal";
import CreateEditTourSubForm from "./CreateEditTourSubForm";

import Loading from "../Loading/Loading";
import ScrollList from "../element/scrollList/ScrollList";
import UserService from "../../service/UserService";

class CreateEditTourForm extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    handleOnAdd: PropTypes.func,
    handleOnEdit: PropTypes.func,
    tours: PropTypes.shape({}).isRequired,
    members: PropTypes.shape({}).isRequired
  };

  static getSubTitle(tour) {
    const driver = UserService.getDriverLabelForTour(tour);
    if (driver) {
      return `Fest ${driver} zugewiesen`;
    }

    return null;
  }

  /*
  area == future
  */
  static getToursByArea(entities) {
    const currentTours = [];
    Object.values(entities).forEach(tour => {
      currentTours.push({
        ...tour,
        title: tour.label,
        subTitle: CreateEditTourForm.getSubTitle(tour)
      });
    });
    return currentTours.sort((a, b) => a.label.localeCompare(b.label));
  }

  state = {
    showModal: false,
    clearSelection: false,
    editTourItem: null
  };

  componentDidMount() {
    this.refreshTour();
  }

  onAddClicked = () => {
    this.setState({ editTourItem: null, showModal: true });
  };

  onEditClicked = entry => {
    this.setState({ editTourItem: entry, showModal: true });
  };

  refreshTour() {
    this.props.dispatch(getTours());
    this.props.dispatch(getMembers());
  }

  handleClose = () => {
    this.setState({ showModal: false });
  };

  handleSubmit = tourItem => {
    this.setState({ showModal: false });
    if (tourItem.id) {
      this.props.dispatch(updateTour(tourItem)).then(() => {
        this.refreshTour();
      });
    } else {
      this.props.dispatch(createTour(tourItem)).then(() => {
        this.refreshTour();
      });
    }
  };

  handleDelete = tourItem => {
    this.setState({ showModal: false });
    this.props.dispatch(deleteTour(tourItem)).then(() => {
      this.refreshTour();
    });
  };

  handleAbort = () => {
    this.setState({ showModal: false, clearSelection: true });
  };

  render() {
    const { editTourItem, showModal, clearSelection } = this.state;
    const { tours, members, token, ...otherProps } = this.props;

    const membersArray = Object.values(members.entities);
    return (
      <div {...otherProps}>
        {!(tours.loaded && members.loaded) && <Loading />}
        {tours.loaded && membersArray.length > 0 && (
          <React.Fragment>
            <ScrollList
              height={400 - 33} // except button height
              entries={CreateEditTourForm.getToursByArea(tours.entities)}
              onEdit={this.onEditClicked}
              onAdd={this.onAddClicked}
              clear={clearSelection}
              token={token}
            />
            <TCModal show={showModal} subForm>
              <CreateEditTourSubForm
                editTourItem={editTourItem}
                employees={membersArray}
                handleSubmit={this.handleSubmit}
                handleReset={this.handleReset}
                handleAbort={this.handleAbort}
                handleDelete={this.handleDelete}
              />
            </TCModal>
          </React.Fragment>
        )}
      </div>
    );
  }
}

function mapStateToProps({ auth, entities: { members, tours } }) {
  return {
    token: auth.token,
    members,
    tours
  };
}

export default connect(mapStateToProps)(CreateEditTourForm);
