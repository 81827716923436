import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import LayoutContext from "../LayoutContext";

/**
 * Render `ActionBar` properties via handler function from parent.
 *
 * The parent component renders the context provider.
 * To prevent a re-render we passing properties with that handler.
 */
export const ActionBarRenderer = ({ handler }) => {
  const [props, setProps] = React.useState(null);
  handler(setProps);
  return props && <ActionBarWrapper {...props} />;
};

ActionBarRenderer.propTypes = {
  /** handler to use setProps from parent */
  handler: PropTypes.func.isRequired
};

const ActionBarWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 10px 6px 10px;
  min-height: 48px;
  background: ${props => (props.activeBackground ? "#f2f2f2" : "#fffff")};
  border-bottom: 1px solid #d6d7da;
`;

/**
 * Component to control `ActionBar` with `LayoutContext`
 *
 * It is possible to have multiple `ActionBar` components mounted at the same
 * time. The props will be merged in the order the `ActionBar` components were
 * mounted.
 *
 * ```
 *  <div>
 *    <ActionBar>
 *      <span>Parent</span>
 *    </ActionBar>
 *    {selectedItems > 0 &&
 *      <ActionBar>
 *        Selected: {selectedItems}
 *      </ActionBar>
 *    }
 *  </div>
 * ```
 *
 * Each `ActionBar` properties are stored in a stack.
 *
 * Inspirations:
 * - https://github.com/react-native-community/react-native-statusbar
 */
export class ActionBar extends Component {
  static contextType = LayoutContext;
  // property stack
  static propsStack = [];

  /**
   * Push props to stack.
   * @param {object} props
   */
  static pushProps(props) {
    const entry = { ...props };
    ActionBar.propsStack.push(entry);
    return entry;
  }

  /**
   * Replace props in stack.
   * @param {object} entry
   * @param {object} props
   */
  static replaceProps(entry, props) {
    const newEntry = { ...props };
    const index = ActionBar.propsStack.indexOf(entry);
    if (index !== -1) {
      ActionBar.propsStack[index] = newEntry;
    }
    return newEntry;
  }

  /**
   * Pop stack props.
   * @param {object} props
   */
  static popProps(entry) {
    const index = ActionBar.propsStack.indexOf(entry);
    if (index !== -1) {
      ActionBar.propsStack.splice(index, 1);
    }
  }

  constructor() {
    super();
    this.stackEntry = null;
  }

  componentWillMount() {
    this.stackEntry = ActionBar.pushProps(this.props);
    this.update();
  }

  componentDidUpdate() {
    this.stackEntry = ActionBar.replaceProps(this.stackEntry, this.props);
    this.update();
  }

  componentWillUnmount() {
    ActionBar.popProps(this.stackEntry);
    this.update();
  }

  /**
   * Update props with a function in `LayoutContext`.
   */
  update() {
    const { setActionBarProps } = this.context;
    const propsStackLength = ActionBar.propsStack.length;
    if (propsStackLength === 0) {
      setActionBarProps(null);
      return;
    }
    const lastProps = ActionBar.propsStack[propsStackLength - 1];
    setActionBarProps(lastProps);
  }

  render() {
    // this component communicates only via context
    return null;
  }
}
