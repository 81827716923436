import { DIALOG_READ_REQUEST, MESSAGE_CREATE_REQUEST } from "./index";

export const loadDialog = (token, filter) => ({
  type: DIALOG_READ_REQUEST,
  payload: {
    token,
    ...filter
  }
});

export const createMessage = (token, memberId, text) => ({
  type: MESSAGE_CREATE_REQUEST,
  payload: {
    token,
    memberId,
    text
  }
});
