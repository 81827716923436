import React from "react";
import PropTypes from "prop-types";
import RBNavItem from "react-bootstrap/lib/NavItem";

const NavigationItem = ({ eventKey, href, children, ...otherProps }) => (
  <RBNavItem eventKey={eventKey} href={href} {...otherProps}>
    {children}
  </RBNavItem>
);

NavigationItem.propTypes = {
  eventKey: PropTypes.any,
  href: PropTypes.string,
  children: PropTypes.any
};

export default NavigationItem;
