import { call, put, takeLatest } from "redux-saga/effects";

import ApiService from "../../service/ApiService";
import {
  MAPPING_READ_REQUEST,
  MAPPING_READ,
  MAPPING_CREATE,
  MAPPING_CREATE_REQUEST
} from "./index";

function* fetchMapping({ payload }) {
  try {
    const result = yield call(
      ApiService.getMapping,
      payload.token,
      payload.key
    );
    yield put({
      type: MAPPING_READ,
      payload: result.mapping
    });
  } catch (error) {
    yield put({
      type: MAPPING_READ,
      payload: error,
      error: true
    });
  }
}

function* createMapping({ payload }) {
  try {
    const mapping = yield call(
      ApiService.createMapping,
      payload.token,
      payload.key,
      payload.mapping
    );
    yield put({
      type: MAPPING_CREATE,
      payload: mapping
    });
  } catch (error) {
    yield put({
      type: MAPPING_CREATE,
      payload: error,
      error: true
    });
  }
}

export const loadMappingSaga = function* loadMappingSaga() {
  yield takeLatest(MAPPING_READ_REQUEST, fetchMapping);
};

export const createMappingSaga = function* createMappingSaga() {
  yield takeLatest(MAPPING_CREATE_REQUEST, createMapping);
};
