export const FOLDER_GET_FOLDERS_SHIPMENTS_REQUEST =
  "FOLDER_GET_FOLDERS_SHIPMENTS_REQUEST";
export const FOLDER_GET_FOLDERS_SHIPMENTS_RECEIVE =
  "FOLDER_GET_FOLDERS_SHIPMENTS_RECEIVE";
export const FOLDER_GET_FOLDERS_SHIPMENTS_ERROR =
  "FOLDER_GET_FOLDERS_SHIPMENTS_ERROR";

export const FOLDER_SET_FILTER_FOLDERS = "FOLDER_SET_FILTER_FOLDERS";
export const FOLDER_SET_FILTER_DRIVERS = "FOLDER_SET_FILTER_DRIVERS";

export const FOLDER_SET_SCROLL_TOP = "FOLDER_SET_SCROLL_TOP";
