import React from "react";
import PropTypes from "prop-types";
import UserService from "../../service/UserService";
import Icon from "../element/icon/Icon";

const MessageBoxItemHeader = ({ interlocutor, onClose }) => (
  <div className="box-header with-border">
    <h3 className="box-title">
      {interlocutor.online === 1 && (
        <span className="chat-icon-box">
          <Icon name="checkbox-blank-circle" />
        </span>
      )}
      {UserService.getName(interlocutor)}
    </h3>
    <div className="box-tools pull-right">
      {/* TODO Button remove */}
      <button type="button" className="btn btn-box-tool" data-widget="collapse">
        {/* <Icon name="minus" /> */}
      </button>
      {/* TODO Button remove */}
      <button
        type="button"
        className="btn btn-box-tool"
        data-toggle="tooltip"
        title="Contacts"
        data-widget="chat-pane-toggle"
      >
        {/* <Icon name="map-marker" /> */}
      </button>
      {/* TODO Button remove */}
      <button
        type="button"
        className="btn btn-box-tool"
        data-widget="remove"
        onClick={onClose}
      >
        <Icon name="close" />
      </button>
    </div>
  </div>
);

MessageBoxItemHeader.propTypes = {
  interlocutor: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
};

export default MessageBoxItemHeader;
