import { createSelector } from "reselect";

/**
 * Returns status map
 * @param {Object} state the redux store
 * @return {Object} id -> status
 */
export const getStatus = state => state.entities.shipmentStatus.entities;

/**
 * Available status id's based on the logisticcompany configuration.
 * @param {Object} state the redux store
 * @return {Number[]} status id's
 */
export const getAvailableShipmentStatusIds = state =>
  state.entities.shipmentStatus.availableIds;

/**
 * Get filter status.
 * Using available status for the current logisticcompany (see TC-322).
 * @param {Object} status
 * @param {Object} availableStatus
 * @return {Object[]}
 */
const filterStatus = (status, availableStatus) =>
  availableStatus
    .map(
      statusId =>
        status[statusId] && {
          id: status[statusId].id,
          label: status[statusId].label
        }
    )
    .filter(Boolean)
    .sort((a, b) => {
      // sort ASC by label
      if (a.label > b.label) return 1;
      if (b.label > a.label) return -1;
      return 0;
    });

export const getAvailableShipmentStatus = createSelector(
  [getStatus, getAvailableShipmentStatusIds],
  filterStatus
);
