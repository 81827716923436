import React from "react";
import PropTypes from "prop-types";
import RBBadge from "react-bootstrap/lib/Badge";

const Badge = ({ text, ...otherProps }) => (
  <RBBadge {...otherProps}>{text}</RBBadge>
);

Badge.propTypes = {
  text: PropTypes.any,
  type: PropTypes.string
};

Badge.defaultProps = {
  type: "text"
};

export default Badge;
