import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import hash from "object-hash";
import moment from "moment";
import ImportShipmentUpload from "./ImportShipmentUpload";
import ImportShipmentMapping from "./ImportShipmentMapping";
import ImportTransmit from "./ImportTransmit";
import { loadMapping } from "../../state/mapping/mappingAction";
import { loadExternalCompaniesRel } from "../../state/external/externalAction";

class ImportProgress extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    mapping: PropTypes.object,
    external: PropTypes.object,
    handleOnReady: PropTypes.func.isRequired,
    handleAbort: PropTypes.func.isRequired
  };

  state = {
    mapItems: [],
    uploadItems: [],
    csvproperties: {},
    mappingKey: "",
    preMapping: {},
    externalCompanies: {},
    step: 1
  };

  componentWillReceiveProps(nextProps) {
    // show mapping, if upload && get mapping result from api
    if (
      this.state.step === 1 &&
      (nextProps.mapping.loaded && nextProps.external.loaded)
    ) {
      this.setState({
        step: 2,
        preMapping: nextProps.mapping.result,
        externalCompanies: nextProps.external.result
      });
    }
  }

  handleDataUploaded = csvData => {
    if (csvData.items.length > 0) {
      this.setState({
        mapItems: csvData.items,
        csvproperties: csvData.properties,
        mappingKey: csvData.properties.hashedHeader
      });

      if (csvData.properties.hashedHeader) {
        this.props.dispatch(
          loadMapping(this.props.token, csvData.properties.hashedHeader)
        );
        this.props.dispatch(loadExternalCompaniesRel(this.props.token));
      } else {
        this.setState({ step: 2 });
      }
    }
  };

  handleOnSubmit = (mapping, isPickedUp, isDirect, externalCompany) => {
    const { mapItems } = this.state;

    const shipments = [];
    for (let i = 1; i < mapItems.length; i += 1) {
      const shipment = {};

      Object.keys(mapping).forEach(key => {
        shipment[key] = "";
        for (let j = 0; j < mapping[key].length; j += 1) {
          const mKey = mapping[key][j];
          shipment[key] += (shipment[key] ? " " : "") + mapItems[i][mKey];
        }

        if (
          key.indexOf("appointment_from") >= 0 &&
          shipment[key].indexOf(":") >= 0
        ) {
          shipment[key] = shipment[key].substr(
            0,
            shipment[key].indexOf(":") + 3
          );
          shipment[key] = moment(shipment[key], "DD.MM.YYYY HH:mm").format(
            "YYYY-MM-DD HH:mm:ss"
          );
        } else if (
          key.indexOf("appointment_till") >= 0 &&
          (shipment[key].match(/:/g) || []).length > 1
        ) {
          shipment[key] =
            shipment[key].substr(0, shipment[key].indexOf(":") - 2) +
            shipment[key].substr(
              shipment[key].length - 5,
              shipment[key].length
            );
          shipment[key] = moment(shipment[key], "DD.MM.YYYY HH:mm").format(
            "YYYY-MM-DD HH:mm:ss"
          );
        }
      });

      shipment.isPickedUp = isPickedUp;
      shipment.isDirect = isDirect;
      if (externalCompany && externalCompany.length) {
        shipment.import_logisticcompany_external_label =
          externalCompany[0].shortName;
        shipment.import_logisticcompany_external_id = externalCompany[0].value;
      }
      shipment.ext_id = hash(shipment);

      shipments.push(shipment);
    }

    this.setState({ step: 3, uploadItems: shipments });
  };

  handleOnRestart = () => {
    this.setState({ step: 1 });
  };

  render() {
    const {
      mapItems,
      mappingKey,
      externalCompanies,
      preMapping,
      uploadItems,
      step
    } = this.state;

    const { handleOnReady, handleAbort } = this.props;

    return (
      <div className="import-progress">
        {step === 1 && (
          <ImportShipmentUpload handleUploaded={this.handleDataUploaded} />
        )}
        {step === 2 && (
          <ImportShipmentMapping
            mappingKey={mappingKey}
            preMapping={preMapping}
            externalCompanies={externalCompanies}
            exampleMapItem={mapItems[0]}
            handleAbort={handleAbort}
            handleSubmit={this.handleOnSubmit}
          />
        )}
        {step === 3 && (
          <ImportTransmit
            uploadItems={uploadItems}
            onReady={handleOnReady}
            onRestart={this.handleOnRestart}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps({ auth, mapping, external }) {
  return {
    mapping,
    external,
    token: auth.token
  };
}

export default connect(mapStateToProps)(ImportProgress);
