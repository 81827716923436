import { getShipments } from "state/entities/shipmentsV2/actions";
import { getUnassignedTasks } from "state/entities/tasks/actions";
import { getFolders } from "state/entities/folders/actions";
import {
  FOLDER_GET_FOLDERS_SHIPMENTS_REQUEST,
  FOLDER_GET_FOLDERS_SHIPMENTS_RECEIVE,
  FOLDER_GET_FOLDERS_SHIPMENTS_ERROR,
  FOLDER_SET_FILTER_FOLDERS,
  FOLDER_SET_FILTER_DRIVERS,
  FOLDER_SET_SCROLL_TOP
} from "./constants";

const flatten = arr => [].concat(...arr);

/**
 * Get folders and shipments.
 * @param {Date} start
 * @param {Date} end
 */
export const getFoldersAndShipments = (start, end) => async dispatch => {
  dispatch({ type: FOLDER_GET_FOLDERS_SHIPMENTS_REQUEST });
  try {
    const [unassignedTasks, folders] = await Promise.all([
      dispatch(getUnassignedTasks()),
      dispatch(getFolders())
    ]);
    const tasks = flatten([
      unassignedTasks,
      ...folders.map(folder => folder.tasks)
    ]);
    const shipmentIDs = tasks.map(task => task.shipmentID);
    await dispatch(getShipments({ ids: shipmentIDs }));

    dispatch({ type: FOLDER_GET_FOLDERS_SHIPMENTS_RECEIVE });
    return true;
  } catch (error) {
    dispatch({ type: FOLDER_GET_FOLDERS_SHIPMENTS_ERROR, error });
    return false;
  }
};

/**
 * @param {number[]} folderIds
 */
export const setFilterFolders = folderIds => ({
  type: FOLDER_SET_FILTER_FOLDERS,
  payload: folderIds
});

/**
 * @param {number[]} driverIds
 */
export const setFilterDriver = driverIds => ({
  type: FOLDER_SET_FILTER_DRIVERS,
  payload: driverIds
});

/**
 * @param {number} scrollTop
 */
export const setScrollTop = scrollTop => ({
  type: FOLDER_SET_SCROLL_TOP,
  payload: scrollTop
});
