import { DIALOG_READ, MESSAGE_CREATE, MESSAGE_UPDATE } from "./index";

const initialState = {
  messages: [],
  loaded: false,
  error: null
};

function appendMessages(currentMessages, newMessages) {
  if (!currentMessages.length && !newMessages) {
    return [];
  } else if (!currentMessages.length) {
    return newMessages;
  } else if (!newMessages || !newMessages.length) {
    return currentMessages;
  }

  return currentMessages.concat(...newMessages);
}

function mergeMessages(currentMessages, newMessage) {
  // console.log(newMessage);

  // console.log("currentMessages", currentMessages);
  // copy
  const result = [];
  let merged = false;
  for (let i = 0; i < newMessage.content.length; i += 1) {
    for (let j = 0; j < currentMessages.length; j += 1) {
      /* eslint-disable */
      if (newMessage.content[i].socket_id == currentMessages[j].socket_id) {
        /* eslint-enable */
        merged = true;
        const message = Object.assign(newMessage.content[i], {
          from: newMessage.from
        });
        result.push(message);
      } else {
        result.push(currentMessages[j]);
      }
    }
  }

  if (!merged) {
    const message = Object.assign(newMessage.content[0], {
      from: newMessage.from
    });
    result.push(message);
  }

  // console.log("result", result);

  return result;
}

export default function auth(state = initialState, action = {}) {
  const { type, payload, error } = action;

  let messages;

  if (error) {
    return {
      ...state,
      error: payload
    };
  }

  switch (type) {
    case DIALOG_READ:
      messages = appendMessages(state.messages, payload);
      return {
        ...state,
        messages,
        loaded: true,
        error: null
      };

    case MESSAGE_CREATE:
      return {
        ...state,
        loaded: true,
        error: null
      };

    case MESSAGE_UPDATE:
      messages = mergeMessages(state.messages, payload);
      return {
        ...state,
        messages,
        loaded: true,
        error: null
      };

    default:
      return state;
  }
}
