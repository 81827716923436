// Deprecated file.
/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as shipmentAction from "state/shipment/shipmentAction";
import ApiService from "../../service/ApiService";
import Button from "../element/button";
import { DropdownButton } from "../element/dropdown";

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const ContainerLeft = styled.div`
  flex: 2;
  align-items: center;
  display: flex;
`;

const Headline = styled.h2`
  display: block;
  margin: 0 0 0 10px;
  font-weight: 700;
  font-size: 2.8rem;
`;

const ContainerRight = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

class TrackingActionBar extends Component {
  static propTypes = {
    trackcode: PropTypes.string.isRequired,
    tracking: PropTypes.number,
    openShipmentDetailModal: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    showNewProgressModal: PropTypes.func.isRequired
  };

  static defaultProps = {
    tracking: 0
  };

  handleBackButton = e => {
    const { history } = this.props;
    e.preventDefault();
    history.push("/scheduling");
  };

  /**
   * Call openStatusEdit function from SchedulingList component to open status edit modal.
   */
  openStatusEditAction = e => {
    e.stopPropagation();
    const { showNewProgressModal } = this.props;
    const {
      tracking: { id }
    } = this.props;
    showNewProgressModal(id);
  };

  render() {
    const { trackcode, tracking, openShipmentDetailModal, token } = this.props;

    /* eslint-disable jsx-a11y/href-no-hash */
    return (
      <Container>
        <ContainerLeft>
          <a href="#" onClick={this.handleBackButton}>
            <Button>Zurück</Button>
          </a>
          <Headline>{trackcode}</Headline>
        </ContainerLeft>
        <ContainerRight>
          <DropdownButton
            id="moreShipmentOptions"
            noCaret
            iconRight="dots-vertical"
            size="xs"
          >
            <li>
              <a onClick={openShipmentDetailModal} href="#">
                Auftragsdetails anzeigen
              </a>
            </li>
            <li>
              <a href="#" onClick={this.openStatusEditAction}>
                Auftragsstatus hinzufügen
              </a>
            </li>
            {tracking && tracking.status_id === 6 && (
              <li>
                <a
                  href={ApiService.getPodUrl(
                    token,
                    tracking.shipment_id,
                    tracking.logisticcompany_id
                  )}
                  target="_blank"
                >
                  POD ausgeben
                </a>
              </li>
            )}
          </DropdownButton>
        </ContainerRight>
      </Container>
    );
  }
}

const mapDispatchToProps = {
  showNewProgressModal: shipmentAction.showNewProgressModal
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(TrackingActionBar)
);
