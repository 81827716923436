import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";

import RBModal from "react-bootstrap/lib/Modal";
import RBModalHeader from "react-bootstrap/lib/ModalHeader";
import RBModalBody from "react-bootstrap/lib/ModalBody";
import RBModalFooter from "react-bootstrap/lib/ModalFooter";

import Button from "../button/Button";

import "./tcmodal.css";

import styleNames from "./tcmodal.local.css";

const cx = classNames.bind(styleNames);

class TCModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    backdrop: PropTypes.oneOf(["static", true, false]),
    keyboard: PropTypes.bool,
    bigModal: PropTypes.bool,
    classNameWrapper: PropTypes.string,
    handleClose: PropTypes.func,
    handleSubmit: PropTypes.func,
    title: PropTypes.any,
    footer: PropTypes.any,
    successButton: PropTypes.string,
    abortButton: PropTypes.bool,
    defaultFooter: PropTypes.bool,
    subForm: PropTypes.bool,
    children: PropTypes.any
  };

  static defaultProps = {
    backdrop: "static",
    bigModal: false,
    defaultFooter: false,
    keyboard: false,
    abortButton: false,
    successButton: "Schließen"
  };

  state = {
    showModal: false
  };

  componentWillMount() {
    // console.log("CreateEditShipment componentWillMount");
    this.setState({ showModal: this.props.show });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.show !== this.state.showModal) {
      this.setState({ showModal: nextProps.show });
    }
  }

  onClose = () => {
    this.setState({ showModal: false });
    this.props.handleClose();
  };

  onSubmit = () => {
    this.setState({ showModal: false });
    this.props.handleSubmit();
  };

  render() {
    const {
      title,
      children,
      footer,
      classNameWrapper,
      defaultFooter,
      backdrop,
      keyboard,
      bigModal,
      subForm,
      abortButton,
      successButton
    } = this.props;

    const { showModal } = this.state;

    return (
      <div className={`modal-inner-container${subForm ? " subform" : ""}`}>
        <RBModal
          show={showModal}
          onHide={this.onClose}
          container={this}
          dialogClassName={cx(classNameWrapper, { big: bigModal })}
          backdrop={backdrop}
          keyboard={keyboard}
        >
          {title && <RBModalHeader>{title}</RBModalHeader>}
          <RBModalBody>{children}</RBModalBody>
          {defaultFooter && (
            <RBModalFooter>
              {abortButton && <Button onClick={this.onClose}>Abbrechen</Button>}
              <Button
                className={cx({ right: !abortButton })}
                onClick={this.onSubmit}
                color="success"
              >
                {successButton}
              </Button>
            </RBModalFooter>
          )}
          {footer && <RBModalFooter>{footer}</RBModalFooter>}
        </RBModal>
      </div>
    );
  }
}

export default TCModal;
