/**
 * Copied by kim on 27.01.17.
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames/bind";
import { Map, mapActions, mapUtils } from "features/map";

import CreateEditShipmentForm from "../../components/shipment/CreateEditShipmentForm";
import TCModal from "../../components/element/overlay/TCModal";

import styleNames from "./createEditShipment.local.css";

const cx = classNames.bind(styleNames);

class CreateEditShowShipment extends Component {
  static propTypes = {
    showModal: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    shipmentId: PropTypes.number.isRequired,
    editMode: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired
  };

  state = {
    show: true,
    selectedTab: "shipment"
  };

  componentWillMount() {
    this.setState({ show: true });
  }

  componentDidMount() {
    const { shipment } = this.props;
    this.showDirection(shipment);
  }

  componentWillReceiveProps(nextProps) {
    const { showModal } = nextProps;

    if (showModal !== this.state.show) {
      this.setState({ show: showModal });
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;

    dispatch(mapActions.clear("shipment"));
  }

  onSelectNavigationItem = selectedItem => {
    //  event.preventDefault();
    this.setState({ selectedTab: selectedItem });
  };

  showDirection(shipment) {
    const { dispatch } = this.props;
    const { directions, pins } = mapUtils.shipmentToMapElements(shipment);

    if (!shipment.isFixedStop && directions) {
      dispatch(
        mapActions.set(
          "shipment",
          { directions },
          {
            fitBounds: true,
            zoom: 15
          }
        )
      );
    }
    if (shipment.isFixedStop && pins) {
      dispatch(
        mapActions.set(
          "shipment",
          { pins },
          {
            fitBounds: true,
            zoom: 15
          }
        )
      );
    }
  }

  handleOnDriverClicked = () => {};

  handleSenderAddressChange = senderAddress => {
    console.log("senderAddress", senderAddress);
  };

  handleRecipientAddressChange = recipientAddress => {
    console.log("recipientAddress", recipientAddress);
  };

  handleSubmitForm = () => {
    this.setState({ show: false });
    this.props.handleSubmit();
  };

  handleAbortForm = () => {
    this.setState({ show: false });
    this.props.handleClose();
  };

  handleOnReady = () => {
    this.setState({ show: false });
    this.props.handleClose();
  };

  render() {
    const {
      handleClose,
      handleSubmit,
      showModal,
      shipment,
      editMode,
      ...otherProps
    } = this.props;

    const { selectedTab } = this.state;

    return (
      <div {...otherProps}>
        <TCModal
          show={showModal}
          classNameWrapper={cx("shipment-dialog")}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          bigModal
        >
          <div className={cx("input-container")}>
            <div
              className={cx("form-container", {
                fullwidth: selectedTab !== "shipment"
              })}
            >
              <CreateEditShipmentForm
                handleSenderAddressChange={this.handleSenderAddressChange}
                handleRecipientAddressChange={this.handleRecipientAddressChange}
                handleSubmit={this.handleSubmitForm}
                handleOnDriverSelected={this.handleOnDriverSelected}
                handleAbort={this.handleAbortForm}
                shipment={shipment}
                editMode={editMode}
              />
            </div>
            <div className={cx("additional-container")}>
              <Map handleScopes={["shipment"]} />
            </div>
          </div>
        </TCModal>
      </div>
    );
  }
}

const mapStateToProps = (state, { shipmentId }) => {
  const {
    entities: {
      shipments: { entities }
    }
  } = state;
  // WORKAROUND: Support the old and the new (V2) shipment list logic.
  // After the release of V2 this is no longer needed.
  if (shipmentId) {
    return {
      shipment: entities[shipmentId]
    };
  }
  return null;
};

export default connect(mapStateToProps)(CreateEditShowShipment);
