/**
 * Gets the tour object for a shipment if exists
 * @param shipment object
 * @param tours map of tour objects
 * @return {null || tour}
 */
const getTourForShipment = (shipment, tours) => {
  const shipmentTourId = shipment.tourId;
  if (!shipmentTourId) {
    return null;
  }
  return tours[shipmentTourId];
};

/**
 * Gets the driver object for a shipment if exists
 * @param shipment object
 * @param members map of driver objects
 * @return {null || driver}
 */
const getDriverForShipment = (shipment, members) => {
  const shipmentDriverId = shipment.driverId;
  if (!shipmentDriverId) {
    return null;
  }
  return members[shipmentDriverId];
};

/**
 * Enhances a plain shipment object with its belonging tour, status, tour and driver
 * @param shipmentMap mapping object: id -> shipment
 * @param statusMap map of statusList objects
 * @param tours map of tours objects
 * @param members map of drivers objects
 * @return {Array[]} list of enhanced shipments
 */
export const enhanceShipments = (
  shipmentMap,
  statusMap,
  tours = {},
  members = {}
) =>
  Object.values(shipmentMap).map(shipment => ({
    ...shipment,
    statusLabel:
      statusMap[shipment.status_id] && statusMap[shipment.status_id].label,
    tour: getTourForShipment(shipment, tours),
    driver: getDriverForShipment(shipment, members)
  }));

/**
 * Returns shipment map
 * @param {Object} state the redux store
 * @return {Object} id -> shipment
 */
export const getShipments = state => state.entities.shipments.entities;
