import members from "./members/reducer";
import shipments from "./shipments/reducer";
import shipmentStatus from "./shipmentStatus/reducer";
import tours from "./tours/reducer";
import shipmentsV2 from "./shipmentsV2/reducer";
import folders from "./folders/reducer";
import tasks from "./tasks/reducer";

const rootReducer = (state = {}, action) => {
  let toursMap = {};
  if (state.tours && state.tours.entities) {
    toursMap = state.tours.entities;
  }
  return {
    members: members(state.members, action),
    shipments: shipments(state.shipments, { ...action, tours: toursMap }),
    shipmentStatus: shipmentStatus(state.shipmentStatus, action),
    tours: tours(state.tours, action),
    shipmentsV2: shipmentsV2(state.shipmentsV2, action),
    folders: folders(state.folders, action),
    tasks: tasks(state.tasks, action)
  };
};
export default rootReducer;
