import React, { Component } from "react";
import PropTypes from "prop-types";

import FloatingTextField from "../../components/element/form/FloatingTextField";

import "./password.css";

class Email extends Component {
  static propTypes = {
    sendEmail: PropTypes.func.isRequired
  };

  state = {
    email: ""
  };

  /*
  static propTypes = {
    user: PropTypes.object,
    loginError: PropTypes.number,
    dispatch: PropTypes.func,
    history: PropTypes.object.isRequired,
    location: PropTypes.object,
    passwordError: PropTypes.string
  };

  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired
  };

  state = {
    isLoggingIn: false,
    passLength: 0,
    email: "",
    token: "",
    password: "",
    sent: false,
    passwordMessage: null
  };

  /*
  * No login form if user logged in.

  componentWillMount() {
    const { router } = this.context;
    if (this.props.user && router) {
      router.replace("/");
    }
    else {
      if (Object.keys(this.props.location.query).length > 1) {
        this.setState({ email: this.props.location.query.user, token: this.props.location.query.token });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const { router } = this.context;
    const { isLoggingIn, sent } = this.state;

    if (isLoggingIn) {
      this.setState({ isLoggingIn: false });
    }

    // being logged in redirect to main page
    if (nextProps.user && router) {
      // TODO: use redirect param
      router.push("/");
    }
    if (nextProps.passwordError === "" && sent) {
      router.push("/login?message=newuser");
    }
  }

  getPasswordError = (loginError) => {
    if (!loginError) {
      return null;
    }

    switch (loginError) {
      case 401:
        return "Falscher Benutzername oder Passwort.";
      case 404:
        return "Bitte überprüfe deine Verbindung und probieren es noch einmal.";
      case 500:
        return "Es ist ein Fehler aufgetreten, versuch es bitte später noch einmal.";
      default:
        return "Es ist ein Fehler aufgetreten, versuch es bitte später noch einmal.";
    }
  };

  setPassword = (p) => {
    this.setState({ password: p.target.value });
  }

  sendPassword = () => {
    const { router } = this.context;
    if (this.state.password.length > 11) {
      this.setState({ sent: true });
      const password = {
        token: this.state.token,
        email: this.state.email,
        password: this.state.password
      };
      this.props.dispatch(createPassword(password));
      router.push("/login?message=newuser");
    }
    else {
      this.setState({ passwordMessage: "Wähle ein Passwort mit mindestens 12 Zeichen, bestehend aus mehr als 2 Worten, Groß-/Kleinschreibung und Zahlen." });
    }
  };

  handleKeyPress = (e) => {
    if(e.key === "Enter") {
      this.sendPassword();
    }
  };
*/

  changeEmail = e => {
    this.setState({ email: e.target.value });
  };

  handleKeyPress = e => {
    if (e.key === "Enter") {
      this.sendResetEmail();
    }
  };

  sendResetEmail = () => {
    const { sendEmail } = this.props;

    sendEmail(this.state.email);
  };

  render() {
    // const { passwordError } = this.props;
    const { email } = this.state;
    // const error = this.getPasswordError(passwordError);

    const passwordError = 200;

    return (
      <div className="form-group">
        <div className="introtext">Passwort zurücksetzen</div>
        <div className="infotext">
          Gib die Email-Adresse an, die mit deinem Konto verbunden ist, dann
          schicken wir dir einen Link, mit dem du dein Passwort zurücksetzen
          kannst.
        </div>
        <FloatingTextField
          type="text"
          value={email}
          onChange={this.changeEmail}
          onKeyPress={this.handleKeyPress}
          floatingLabel="Email"
          required
        />
        {/* eslint-disable jsx-a11y/no-static-element-interactions */}
        <div
          className="button btn btn-success"
          onClick={this.sendResetEmail}
          disabled={passwordError >= 401}
        >
          Link zum Zurücksetzen senden
        </div>
        {/* eslint-enable jsx-a11y/no-static-element-interactions */}
        {/* }
          { sent  &&
            {/*{ passwordError === null  &&
              <div className="alert alert-success">Dein Passwort wurde erfolgreich gesetzt!</div>
            }}
            { passwordError !== null  &&
              <div className="alert alert-danger">{ error }</div>
            }
          }
          { !sent  &&
            { passwordMessage  &&
              <div className="alert alert-danger">{ passwordMessage }</div>
            }
          }
        { */}
      </div>
    );
  }
}

export default Email;
