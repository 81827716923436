/**
 * Abstraction layer for API calls.
 * Changing the URL scheme of the API will only affect this service.
 */
import moment from "moment";
import URI from "urijs";
import { format } from "date-fns";
import { apiHost, apiKey } from "common/config";

import RestService from "./RestService";
import DateService from "./DateService";
import UserService from "./UserService";

function formatMoment(thisMoment) {
  return DateService.formatDatetimeForApi(moment(thisMoment));
}

export default class ApiService {
  static getPublicURI = () => new URI(apiHost).path(apiKey);

  static getCleanPublicURI = () => new URI(apiHost);

  static getAuthorizedURI = token => new URI(apiHost).path(`TOKEN/${token}`);

  static login = (username, password) => {
    const url = ApiService.getPublicURI()
      .segment("AUTH")
      .search({ source: "web" })
      .toString();
    const settings = {
      method: "get",
      headers: {
        method: "get",
        Authorization: `Basic ${Buffer.from(`${username}:${password}`).toString(
          "base64"
        )}`
      }
    };

    return RestService.get(url, settings);
  };

  static loginWithCookie = token => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("ME")
      .toString();
    return RestService.get(url);
  };

  static getManagedLogisticCompanies(token) {
    const url = ApiService.getAuthorizedURI(token)
      .segment("ME")
      .segment("MANAGED-LOGISTICCOMPANIES")
      .toString();
    return RestService.get(url);
  }

  static logout = token => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("LOGOUT")
      .toString();
    return RestService.get(url);
  };

  static getShipments = (token, { start, end, tz, filter }) => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("SHIPMENTS")
      .query({
        filter,
        groupby: "group_reference",
        start: formatMoment(start),
        end: formatMoment(end),
        tz: tz || format(end, "ZZ")
      })
      .toString();
    return RestService.get(url);

    /*
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(shipmentMockData.result);
      }, 200);
    });
    */
  };

  static getAddresses = (token, context, value) => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("ADDRESS")
      .segment(context.toUpperCase())
      .segment(value)
      .toString();
    return RestService.get(url);
  };

  static getGroups = token => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("GROUPS")
      .toString();
    return RestService.get(url);
  };

  static getMember = token => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("MEMBER")
      .toString();
    return RestService.get(url);
  };

  static createMember = (token, employee) => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("MEMBER")
      .toString();
    return RestService.post(url, employee);
  };

  static updateMember = (token, employee) => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("MEMBER")
      .toString();
    return RestService.put(url, employee);
  };

  static createPassword = send => {
    const url = ApiService.getCleanPublicURI()
      .segment(apiKey)
      .segment("RESET")
      .segment("PASSWORD")
      .toString();
    return RestService.post(url, send);
  };

  static resetPassword = email => {
    const url = ApiService.getCleanPublicURI()
      .segment(apiKey)
      .segment("RESET")
      .segment("PASSWORD")
      .search({ email })
      .toString();
    return RestService.get(url);
  };

  static createShipmentCron = (token, scheduling) => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("SCHEDULING")
      .toString();
    return RestService.post(url, scheduling);
  };

  static getShipmentStatus = token => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("STATUS")
      .segment("AVAILABLE")
      .toString();
    return RestService.get(url);
  };

  static getShipmentStatusGroups = token => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("STATUS")
      .segment("GROUPS")
      .toString();
    return RestService.get(url);
  };

  static getShipmentCron = (token, shipment) => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("SCHEDULING")
      .segment(shipment)
      .toString();
    return RestService.get(url);
  };

  static deleteMember = (token, employee) => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("MEMBER")
      .toString();
    return RestService.delete(url, employee);
  };

  static getTour = token => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("TOUR")
      .toString();
    return RestService.get(url);
  };

  static createTour = (token, tour) => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("TOUR")
      .toString();
    return RestService.post(url, tour);
  };

  static updateTour = (token, tour) => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("TOUR")
      .toString();
    return RestService.put(url, tour);
  };

  static deleteTour = (token, tour) => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("TOUR")
      .toString();
    return RestService.delete(url, tour);
  };

  static getDrivers = token => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("DRIVERS")
      .toString();
    return RestService.get(url);
  };

  static getDriversLocations = token => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("DRIVERS")
      .segment("LOCATIONS")
      .toString();
    return RestService.get(url);
  };

  static getStatus = token => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("STATUS")
      .toString();
    return RestService.get(url);
  };

  static getStatusVariations = token => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("STATUS")
      .segment("VARIATIONS")
      .toString();
    return RestService.get(url);
  };

  static getTrack = (token, track) => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("TRACK")
      .segment(track)
      .toString();
    return RestService.get(url);
  };

  static getJobs = (token, memberId) => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("JOBS")
      .segment("MEMBER")
      .segment(String(memberId))
      .toString();
    return RestService.get(url);
  };

  static getDialog = (token, memberId) => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("DIALOG")
      .segment(memberId)
      .toString();
    return RestService.get(url);
  };

  static createJobs = (token, assignment, shipments, status) => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("JOBS")
      .toString();
    const params = { shipments, status_id: status };
    if (assignment.isDriver) {
      params.to_member_id = assignment.member_id || assignment.id;
      params.responsible_member = UserService.getName(assignment);
    } else {
      params.to_tour_id = assignment.id;
      params.responsible_member = UserService.getTourLabel(assignment);
    }

    return RestService.post(url, params);
  };

  static createJobForDriver = (token, driverId, shipments, status) => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("JOBS")
      .toString();
    const params = {
      shipments,
      status_id: status,
      to_member_id: driverId
    };
    return RestService.post(url, params);
  };

  static createJobForTour = (token, tourId, shipments, status) => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("JOBS")
      .toString();
    const params = {
      shipments,
      status_id: status,
      to_tour_id: tourId
    };
    return RestService.post(url, params);
  };

  static createMessage = (token, memberId, text) => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("MESSAGE")
      .toString();
    const params = { to_member_id: memberId, text };
    return RestService.post(url, params);
  };

  static createImport = (token, shipments) => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("IMPORT")
      .toString();
    const params = { shipments };
    return RestService.put(url, params);
  };

  static createShipment = (token, shipments) => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("SHIPMENTS")
      .toString();
    const params = { shipments };
    return RestService.post(url, params);
  };

  static updateShipment = (token, shipment) => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("SHIPMENT")
      .toString();
    return RestService.put(url, shipment[0]);
  };

  static deleteShipment = (token, shipments) => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("SHIPMENT")
      .toString();
    return RestService.delete(url, shipments[0]);
  };

  static deleteShipmentAssignment = (token, shipmentId) => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("SHIPMENTS")
      .segment(`${shipmentId}`)
      .segment("ASSIGNMENT")
      .toString();
    return RestService.delete(url);
  };

  static createShipmentProgress = (token, shipmentId, data) => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("SHIPMENTS")
      .segment(`${shipmentId}`)
      .segment("PROGRESS")
      .toString();
    return RestService.post(url, data);
  };

  static deleteShipmentAssignment = (token, shipmentId) => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("SHIPMENTS")
      .segment(`${shipmentId}`)
      .segment("ASSIGNMENT")
      .toString();
    return RestService.delete(url);
  };

  static createAddress = (token, address) => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("ADDRESSES")
      .toString();
    const params = { addresses: address };
    // console.log("params", params);
    return RestService.post(url, params);
  };

  static getMapping = (token, key) => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("MAPPING")
      .segment("KEY")
      .segment(key)
      .toString();
    return RestService.get(url);
  };

  static createMapping = (token, key, mapping) => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("MAPPING")
      .toString();
    const params = { mapping, key };
    // console.log("params", params);
    return RestService.post(url, params);
  };

  static getExternalComapniesRel = token => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("EXTERNAL")
      .segment("COMPANIES")
      .segment("REL")
      .toString();
    return RestService.get(url);
  };

  static getSignatureUrl = (token, id) =>
    ApiService.getAuthorizedURI(token)
      .segment("MEDIA")
      .segment("SIGNATURE")
      .segment("ID")
      .segment(id.toString())
      .toString();

  static getIdCardUrl = (token, memberId) =>
    ApiService.getAuthorizedURI(token)
      .segment("IDCARD")
      .segment(memberId.toString())
      .toString();

  static uploadGrid = (token, files) => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("GRID")
      .toString();
    return RestService.upload(url, files);
  };

  static getGridUrl = (token, size, id) => {
    const route = `GRID${size}`;
    return ApiService.getAuthorizedURI(token)
      .segment(route)
      .segment(id.toString())
      .toString();
  };

  static getPodUrl = (token, shipmentId, logisticcompanyId = 0) => {
    const url = ApiService.getAuthorizedURI(token)
      .segment("POD")
      .segment(shipmentId.toString());
    if (logisticcompanyId > 0) {
      return url.search({ logisticcompany_id: logisticcompanyId }).toString();
    }
    return url.toString();
  };

  static getSocketUrl = () => {
    const url = ApiService.getPublicURI()
      .segment("SOCKET")
      .toString();
    return RestService.get(url);
  };

  static getAppInfo() {
    const url = ApiService.getPublicURI()
      .segment("app")
      .segment("info")
      .toString();
    return RestService.get(url);
    /*
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({});
      }, 200);
    });
    */
  }
}
