import {
  MAP_SET,
  MAP_SHOW,
  MAP_HIDE,
  MAP_CLEAR,
  MAP_CLEAR_OPTIONS
} from "./mapConstants";

const initState = {
  // drivers scope is handling map elements itself
  drivers: {
    options: {}
  }
};

const getBounds = ({ pins, drivers, directions, polygons }) => {
  const bounds = [];
  pins.forEach(({ position }) => {
    bounds.push(position);
  });
  drivers.forEach(({ position }) => {
    bounds.push(position);
  });
  directions.forEach(({ origin, destination }) => {
    if (typeof origin.location === "object") {
      bounds.push(origin.location);
    }
    if (typeof destination.location === "object") {
      bounds.push(destination.location);
    }
  });
  polygons.forEach(({ positions }) => {
    bounds.push(...positions);
  });
  return bounds;
};

export default (state = initState, action) => {
  if (!action.scope) {
    return state;
  }

  switch (action.type) {
    case MAP_SET: {
      const bounds = action.options.fitBounds ? getBounds(action) : [];
      // set visible to true when creating a new scope
      const visible = state[action.scope] ? state[action.scope].visible : true;
      return {
        ...state,
        [action.scope]: {
          visible,
          options: {
            fitBounds: action.fitBounds,
            bounds: bounds.length > 0 ? bounds : undefined,
            ...action.options
          },
          pins: action.pins,
          drivers: action.drivers,
          directions: action.directions,
          polygons: action.polygons
        }
      };
    }

    case MAP_SHOW: {
      if (!state[action.scope]) return state;
      return {
        ...state,
        [action.scope]: {
          ...state[action.scope],
          visible: true
        }
      };
    }

    case MAP_HIDE: {
      if (!state[action.scope]) return state;
      return {
        ...state,
        [action.scope]: {
          ...state[action.scope],
          visible: false
        }
      };
    }

    case MAP_CLEAR: {
      const newState = state;
      delete newState[action.scope];
      return state;
    }

    case MAP_CLEAR_OPTIONS: {
      return {
        ...state,
        [action.scope]: {
          ...state[action.scope],
          options: undefined
        }
      };
    }

    default:
      return state;
  }
};
