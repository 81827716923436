import React from "react";
import PropTypes from "prop-types";
import RBButton from "react-bootstrap/lib/Button";

import Icon from "../icon/Icon";

const Button = ({
  color,
  size,
  active,
  loading,
  disabled,
  iconLeft,
  iconRight,
  text,
  children,
  ...otherProps
}) => (
  <RBButton
    bsStyle={color}
    bsSize={size}
    active={active}
    // TODO: is it required: loading={loading}
    disabled={disabled}
    {...otherProps}
  >
    {iconLeft && <Icon name={iconLeft} />}
    {children && children}
    {!children && text}
    {iconRight && <Icon name={iconRight} />}
  </RBButton>
);

Button.propTypes = {
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  color: PropTypes.oneOf([
    "default",
    "primary",
    "info",
    "success",
    "warning",
    "danger"
  ]),
  size: PropTypes.oneOf(["lg", "sm", "xs"]),
  active: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.any
};

Button.defaultProps = {
  type: "button",
  color: "default",
  active: false,
  loading: false,
  disabled: false
};

export default Button;
