export default class LocationService {
  static cross(o, a, b) {
    return (a[0] - o[0]) * (b[1] - o[1]) - (a[1] - o[1]) * (b[0] - o[0]);
  }

  /**
   * calculate polygon points
   * @param points
   * @returns {Array.<T>}
   */
  static convexHull(points) {
    /* eslint-disable */
    points.sort((a, b) => (a[0] == b[0] ? a[1] - b[1] : a[0] - b[0]));
    /* eslint-enable */

    const lower = [];
    for (let i = 0; i < points.length; i += 1) {
      while (
        lower.length >= 2 &&
        LocationService.cross(
          lower[lower.length - 2],
          lower[lower.length - 1],
          points[i]
        ) <= 0
      ) {
        lower.pop();
      }
      lower.push(points[i]);
    }

    const upper = [];
    for (let i = points.length - 1; i >= 0; i -= 1) {
      while (
        upper.length >= 2 &&
        LocationService.cross(
          upper[upper.length - 2],
          upper[upper.length - 1],
          points[i]
        ) <= 0
      ) {
        upper.pop();
      }
      upper.push(points[i]);
    }

    upper.pop();
    lower.pop();
    return lower.concat(upper);
  }

  /**
   * calculate the distance between to points
   * @param lat1
   * @param lon1
   * @param lat2
   * @param lon2
   * @returns {string}
   */
  static getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the earth in km
    const dLat = this.deg2rad(lat2 - lat1); // deg2rad below
    const dLon = this.deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) *
        Math.cos(this.deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d.toFixed(1);
  }

  static deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  /**
   * get a lat,lng from shipment
   * @param shipment
   * @returns {{}}
   */
  static getLatLongFromShipment(shipment) {
    const result = {};
    if (!shipment.isPickedUp) {
      result.latitude = shipment.sender_address_latitude;
      result.longitude = shipment.sender_address_longitude;
    } else {
      result.latitude = shipment.recipient_address_latitude;
      result.longitude = shipment.recipient_address_longitude;
    }

    return result;
  }

  /**
   * get the corner coordinates from an latLng array
   * @param latLngList

     */
  static getCornerCoordinatesFromList(latLngList) {
    let n = 0;
    let w = 0;
    let s = 0;
    let e = 0;
    for (let i = 0; i < latLngList.length; i += 1) {
      if (n === 0 || n === null) {
        n = latLngList[i].lat;
      }
      if (w === 0 || w === null) {
        w = latLngList[i].lng;
      }
      if (s === 0 || s === null) {
        s = latLngList[i].lat;
      }
      if (e === 0 || e === null) {
        e = latLngList[i].lng;
      }

      if (latLngList[i].lat > n) {
        n = latLngList[i].lat;
      }

      if (latLngList[i].lat < s) {
        s = latLngList[i].lat;
      }

      if (latLngList[i].lng > e) {
        e = latLngList[i].lng;
      }

      if (latLngList[i].lng < w) {
        w = latLngList[i].lng;
      }
    }

    return { nw: { lat: n, lng: w }, se: { lat: s, lng: e } };
  }
}
