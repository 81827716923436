import * as apiV2 from "common/apiV2";
import {
  FOLDERS_GET_REQUEST,
  FOLDERS_GET_RECEIVE,
  FOLDERS_ERROR,
  FOLDER_SET_MEMBER_ERROR,
  FOLDER_SET_MEMBER_RECEIVE,
  FOLDER_SET_MEMBER_REQUEST
} from "./constants";
/**
 * Get all folders with jobs.
 */
export const getFolders = () => async dispatch => {
  const onSuccess = payload => {
    dispatch({ type: FOLDERS_GET_RECEIVE, payload });
    return payload;
  };

  dispatch({ type: FOLDERS_GET_REQUEST });
  try {
    const { data: folders } = await apiV2.getFolders();
    return onSuccess(folders);
  } catch (error) {
    debugger;
    console.log(error);
    dispatch({ type: FOLDERS_ERROR, error });
    return null;
  }
};

export const setFolderMember = (folderID, memberID) => async dispatch => {
  const onSuccess = payload => {
    dispatch({ type: FOLDER_SET_MEMBER_RECEIVE, payload });
    return payload;
  };

  dispatch({ type: FOLDER_SET_MEMBER_REQUEST });
  try {
    await apiV2.setFolderMember(folderID, memberID);
    return onSuccess({ folderID, memberID });
  } catch (error) {
    console.error(error);
    dispatch({ type: FOLDER_SET_MEMBER_ERROR, error });
    return null;
  }
};
