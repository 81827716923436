import React from "react";
import PropTypes from "prop-types";
import RBAlert from "react-bootstrap/lib/Alert";

const Alert = ({ children, color }) => (
  <RBAlert bsStyle={color}>{children}</RBAlert>
);

Alert.propTypes = {
  children: PropTypes.any,
  color: PropTypes.oneOf([
    "default",
    "primary",
    "info",
    "success",
    "warning",
    "danger"
  ])
};

Alert.defaultProps = {
  color: "default"
};

export default Alert;
