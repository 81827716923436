import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import classNames from "classnames/bind";
import FloatingTextField from "../../components/element/form/FloatingTextField";
import UserService from "../../service/UserService";
import Button from "../../components/element/button";
import Checkbox from "../../components/element/form/Checkbox";
import Select from "../../components/element/select/Select";

import styleNames from "./CreateEditTourSubForm.local.css";

const cx = classNames.bind(styleNames);

class CreateEditTourSubForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    handleAbort: PropTypes.func.isRequired,
    editTourItem: PropTypes.object,
    employees: PropTypes.array
  };

  state = {
    tourItem: {},
    error: {}
  };

  componentWillMount() {
    const { editTourItem } = this.props;

    if (editTourItem) {
      editTourItem.cron_time = editTourItem.cron_time
        ? moment
            .utc(editTourItem.cron_time, "HH:mm")
            .local()
            .format("HH:mm")
        : "";
      this.setState({ tourItem: editTourItem });
    }
  }

  onChangeTextField = e => {
    e.preventDefault();
    const { tourItem } = this.state;

    tourItem[e.target.name] = e.target.value;
    this.setState({ tourItem });
  };

  onSubmit = e => {
    e.preventDefault();
    const { tourItem } = this.state;

    const error = {};
    if (!tourItem.label) {
      error.label = true;
    }

    if (tourItem.cron_time) {
      tourItem.cron_time = moment(tourItem.cron_time, "HH:mm")
        .utc()
        .format("HH:mm");
    }

    if (Object.keys(error).length > 0) {
      this.setState({ error });
    } else {
      this.props.handleSubmit(tourItem);
    }
  };

  onAbort = () => {
    this.props.handleAbort();
  };

  onDelete = () => {
    const { tourItem } = this.state;

    const result = window.confirm(
      `Wollen Sie die Tour: ${tourItem.label} wirklich löschen?`
    );
    if (result) {
      // Logic to delete the item
      this.props.handleDelete(tourItem);
    }
  };

  onEmployeSelectionChange = employee => {
    const { tourItem } = this.state;

    if (employee) {
      tourItem.member_id = employee.value;
    } else {
      delete tourItem.member_id;
    }

    this.setState({ tourItem });
  };

  onClearingChanged = e => {
    e.preventDefault();
    const { tourItem } = this.state;

    if (e.target.checked) {
      tourItem[e.target.name] = e.target.value;
    } else {
      tourItem[e.target.name] = null;
    }

    // TODO: checkbox issue...!
    setTimeout(() => {
      this.setState({ tourItem });
    }, 200);
  };

  getEmployeesOptions() {
    const tempEmployees = [];
    const { employees } = this.props;

    for (let i = 0; i < employees.length; i += 1) {
      tempEmployees.push({
        value: employees[i].id,
        label: UserService.getName(employees[i])
      });
    }

    return tempEmployees;
  }

  getSelectedValue() {
    const { tourItem } = this.state;

    if (tourItem.member_id) {
      return this.getEmployeesOptions().filter(
        employee => tourItem.member_id === employee.value
      )[0];
    }
    return null;
  }

  render() {
    const { ...otherProps } = this.props;

    const { error, tourItem } = this.state;

    return (
      <div className="row" {...otherProps}>
        <div className={cx("col-sm-12", "subform")}>
          <form className={cx("tourSubForm")} onSubmit={this.onSubmit}>
            <div className={cx("form-group", "titlecontainer")}>
              {!tourItem.id && <span className={cx("title")}>Neue Tour</span>}
              {tourItem.id && (
                <span className={cx("title")}>Tour bearbeiten</span>
              )}
            </div>
            <div className={cx("form-group", "titlecontainer", "fields")}>
              <FloatingTextField
                type="text"
                name="label"
                floatingLabel="Tourbezeichnung*"
                style={error.label ? "error" : null}
                onChange={this.onChangeTextField}
                value={tourItem.label || ""}
              />
              <FloatingTextField
                type="text"
                name="ext_reference"
                floatingLabel="Externe Referenz"
                style={error.label ? "error" : null}
                onChange={this.onChangeTextField}
                value={tourItem.ext_reference || ""}
              />
              <Select
                placeholder="Nutzer"
                options={this.getEmployeesOptions()}
                onChange={this.onEmployeSelectionChange}
                value={this.getSelectedValue()}
              />
            </div>
            <div className={cx("form-group", "checks")}>
              <Checkbox
                name="cron_clearing"
                value="daily"
                checked={tourItem.cron_clearing === "daily"}
                label="Rollkarte täglich leeren"
                onChange={this.onClearingChanged}
              />
            </div>
            <div className={cx("form-group", "fields")}>
              <FloatingTextField
                type="text"
                name="cron_time"
                floatingLabel="Uhrzeit"
                placeholder="22:00"
                onChange={this.onChangeTextField}
                value={tourItem.cron_time || ""}
              />
            </div>
            <div className={cx("employee-form-footer", "modal-footer")}>
              {tourItem.id && (
                <React.Fragment>
                  <Button onClick={this.onAbort}>Abbrechen</Button>
                  {/* eslint-disable jsx-a11y/interactive-supports-focus */}
                  <div
                    role="button"
                    className={cx("textlink")}
                    onClick={this.onDelete}
                  >
                    Löschen
                  </div>
                  {/* eslint-enable jsx-a11y/interactive-supports-focus */}
                  <Button color="success" onClick={this.onSubmit}>
                    Speichern
                  </Button>
                </React.Fragment>
              )}
              {!tourItem.id && (
                <React.Fragment>
                  <Button onClick={this.onAbort}>Abbrechen</Button>
                  <Button color="success" onClick={this.onSubmit}>
                    Tour anlegen
                  </Button>
                </React.Fragment>
              )}
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default CreateEditTourSubForm;
