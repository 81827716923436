import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames/bind";
import styleNames from "./assignmentLabel.local.css";
import UserService from "../../service/UserService";

const cx = classNames.bind(styleNames);

class AssignmentLabel extends Component {
  static propTypes = {
    tours: PropTypes.shape({}).isRequired,
    members: PropTypes.shape({}).isRequired,
    shipment: PropTypes.object.isRequired
  };

  static isAssigned(shipment) {
    return (
      shipment.responsible_tour_id ||
      shipment.responsible_member_id ||
      shipment.last_responsible_member_id
    );
  }

  static isAssignedToTour(shipment) {
    return shipment.responsible_tour_id;
  }

  static isAssignedToDriver(shipment) {
    return (
      shipment.responsible_member_id || shipment.last_responsible_member_id
    );
  }

  getDriver(id) {
    const { members } = this.props;
    return members.entities[id];
  }

  getTour(id) {
    const { tours } = this.props;

    return Object.values(tours.entities).find(tourItem => id === tourItem.id);
  }

  render() {
    const { shipment } = this.props;

    if (!AssignmentLabel.isAssigned(shipment)) {
      return false;
    }

    const responsibleItem = {
      primary: "",
      secondary: null
    };

    if (AssignmentLabel.isAssignedToTour(shipment)) {
      const assignedTour = this.getTour(shipment.responsible_tour_id);
      if (!assignedTour) {
        return false;
      }
      const lastResponsibleMember = this.getDriver(
        shipment.last_responsible_member_id
      );

      // trying to get the correct driver of the shipment and
      // not the member of the current tour TC-700
      const driverName = lastResponsibleMember
        ? UserService.getName(lastResponsibleMember)
        : UserService.getDriverLabelForTour(assignedTour);

      responsibleItem.primary = UserService.getTourLabel(assignedTour);
      responsibleItem.secondary = driverName;
    } else if (AssignmentLabel.isAssignedToDriver(shipment)) {
      const assignedDriver = this.getDriver(
        AssignmentLabel.isAssignedToDriver(shipment)
      );
      if (!assignedDriver) {
        return false;
      }
      responsibleItem.primary = UserService.getDriverLabel(assignedDriver);
    }

    return (
      <div>
        <div>
          {responsibleItem.primary}
          {/* TODO: Better solution for transmission status, e.g. Facebook Messenger, WhatsApp
          { shipment.isSend  &&
            <Icon name={ shipment.isArrived ? "check-all" : "check" }
              className={ cx("ci-check-icon", shipment.isRead ? "text-primary" : "text-muted") }
              size="xs"
            />
          }
          */}
        </div>
        {responsibleItem.secondary && (
          <div className={cx("text-muted", "text-overflow-visible")}>
            {responsibleItem.secondary}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps({ entities: { members, tours } }) {
  return {
    members,
    tours
  };
}

export default connect(mapStateToProps)(AssignmentLabel);
