import { MAPPING_READ_REQUEST, MAPPING_CREATE_REQUEST } from "./index";

export const loadMapping = (token, key) => ({
  type: MAPPING_READ_REQUEST,
  payload: {
    token,
    key
  }
});

export const createMapping = (token, key, mapping) => ({
  type: MAPPING_CREATE_REQUEST,
  payload: {
    token,
    key,
    mapping
  }
});
