import React from "react";
import ReactDOM from "react-dom";
import "socket.io-client";
import { unregister as unregisterServiceWorker } from "./registerServiceWorker";
import createStore from "./store/createStore";
import Root from "./Root";

// global CSS import
import "./style/main.css";

// create store && set up sagas
const store = createStore();

ReactDOM.render(<Root store={store} />, document.getElementById("root"));
unregisterServiceWorker();
