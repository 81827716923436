import { call, put, takeLatest } from "redux-saga/effects";

import ApiService from "../../service/ApiService";
import {
  FETCH_JOBS_REQUEST,
  FETCH_JOBS,
  CREATE_JOBS_REQUEST,
  CREATE_JOBS
} from "./index";
import { SHIPMENTS_STATUS_CHANGED } from "../shipment";

function* fetchJobs({ payload }) {
  try {
    const result = yield call(
      ApiService.getJobs,
      payload.token,
      payload.member_id
    );
    yield put({
      type: FETCH_JOBS,
      payload: result.jobs
    });
  } catch (error) {
    yield put({
      type: FETCH_JOBS,
      payload: error,
      error: true
    });
  }
}

function* createJobs({ payload }) {
  try {
    const jobs = yield call(
      ApiService.createJobs,
      payload.token,
      payload.assignment,
      payload.shipments,
      payload.status
    );
    yield put({
      type: CREATE_JOBS,
      payload: jobs
    });

    yield put({
      type: SHIPMENTS_STATUS_CHANGED,
      payload: {
        shipments: payload.shipments,
        status: "pending"
      }
    });
  } catch (error) {
    yield put({
      type: CREATE_JOBS,
      payload: error,
      error: true
    });
  }
}

export const loadJobsSaga = function* loadJobsSaga() {
  yield takeLatest(FETCH_JOBS_REQUEST, fetchJobs);
};

export const createJobsSaga = function* createJobsSaga() {
  yield takeLatest(CREATE_JOBS_REQUEST, createJobs);
};
