import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as shipmentAction from "state/shipment/shipmentAction";
import { getMembers as getMembersAction } from "state/entities/members/actions";
import styled from "styled-components";
import moment from "moment";
import { getStatusVariationsNested } from "features/shipment/state/selectors";
import { getAvailableShipmentStatus } from "state/entities/shipmentStatus/selectors";
import {
  StandardModal,
  StandardButton,
  StandardSelection,
  StandardInput,
  NestedSelection
} from "@trackcode/ui";
import { StandardDateInput } from "@trackcode/datepicker";
import { ProgressMedia } from "features/shipment/components";
import ApiService from "../../../service/ApiService";

const TIME_FORMAT = "HH:mm";

/**
 * Form to add a shipment progress.
 */
class ProgressAdd extends Component {
  static propTypes = {
    shipmentId: PropTypes.number.isRequired,
    token: PropTypes.string.isRequired,
    shipments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        trackcode: PropTypes.string,
        ext_reference: PropTypes.string
      })
    ).isRequired,
    members: PropTypes.shape({}).isRequired,
    status: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.number, label: PropTypes.string })
    ).isRequired,
    loadStatusVariations: PropTypes.func.isRequired,
    loadShipmentStatus: PropTypes.func.isRequired,
    getMembers: PropTypes.func.isRequired,
    statusVariationsNested: PropTypes.arrayOf(
      PropTypes.shape({ value: PropTypes.number, content: PropTypes.string })
    ).isRequired,
    /** fired when modal closed */
    onClose: PropTypes.func.isRequired,
    /** fired when modal closed and form submitted */
    onSuccess: PropTypes.func.isRequired
  };

  /**
   * Get current time (e.g. 24:00).
   * @returns string
   */
  static getCurrentTimeFormated() {
    return moment().format(TIME_FORMAT);
  }

  /**
   * Check if the datetime is in past.
   * @param object moment
   * @returns boolean
   */
  static datetimeIsBefore(datetime) {
    // add small buffer
    return datetime.isBefore(moment().add(1, "minute"));
  }

  state = {
    statusId: 0,
    statusVariationId: 0,
    mediaType: "",
    media: {},
    mediaFiles: null,
    memberId: 0,
    clientDatetime: moment(),
    time: ProgressAdd.getCurrentTimeFormated(),
    savingForm: false,
    error: false
  };

  componentDidMount() {
    const {
      token,
      loadStatusVariations,
      loadShipmentStatus,
      members,
      getMembers
    } = this.props;

    loadStatusVariations();
    loadShipmentStatus(token);
    if (Object.keys(members.entities).length === 0) {
      getMembers(token);
    }
  }

  onClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  onChangeStatus = ({ value: statusId }) => {
    const { statusId: prevStatusId, statusVariationId } = this.state;
    // clear status variation when statusId is different
    if (statusVariationId && prevStatusId !== statusId) {
      this.setState(() => ({ statusVariationId: 0 }));
    }

    let mediaType = "";
    if (statusId === 6) {
      // ability to set signature
      mediaType = "signature";
    }
    this.setState(() => ({ statusId, mediaType }));
  };

  onChangeStatusVariation = ({ value, variationType }) => {
    const { statusId } = this.state;
    this.setState({
      statusVariationId: value,
      mediaType: statusId === 6 ? "signature" : variationType || ""
    });
  };

  onChangeDate = value => {
    const { clientDatetime } = this.state;
    const newClientDatetime = moment(value);
    newClientDatetime.set("hour", clientDatetime.get("hour"));
    newClientDatetime.set("minute", clientDatetime.get("minute"));
    this.setState(() => ({ clientDatetime: newClientDatetime }));
    if (ProgressAdd.datetimeIsBefore(clientDatetime)) {
      this.setState(() => ({ time: ProgressAdd.getCurrentTimeFormated() }));
    }
  };

  onChangeTime = e => {
    const { value = "" } = e.target;
    this.setState({ time: value });
  };

  onBlurTime = e => {
    const { clientDatetime } = this.state;
    const { value = "" } = e.target;
    const [hour, minute] = value.split(":");

    if (!hour || !minute) {
      // invalid format show current time
      this.setState({ time: ProgressAdd.getCurrentTimeFormated() });
      return;
    }

    clientDatetime.set("hour", hour);
    clientDatetime.set("minute", minute);
    if (ProgressAdd.datetimeIsBefore(clientDatetime)) {
      this.setState({
        clientDatetime,
        time: clientDatetime.format(TIME_FORMAT)
      });
    } else {
      this.setState({ time: ProgressAdd.getCurrentTimeFormated() });
    }
  };

  onChangeMember = ({ value }) => {
    this.setState({ memberId: value });
  };

  onChangeMedia = media => {
    this.setState({ media });
  };

  onChangeMediaFiles = mediaFiles => {
    this.setState({ mediaFiles });
  };

  getShipmentReference() {
    const { shipmentId, shipments } = this.props;
    if (shipments.length === 0) {
      return "";
    }
    const shipment = shipments.find(({ id }) => id === shipmentId);
    return shipment.ext_reference || shipment.trackcode;
  }

  getStatusItems() {
    const { status } = this.props;
    return status.map(({ id, label }) => ({
      value: id,
      content: label
    }));
  }

  getVariationItems() {
    const { statusVariationsNested } = this.props;
    const { statusId: statusIdSelected } = this.state;
    const list = statusVariationsNested.filter(
      ({ statusId }) => statusId === statusIdSelected
    );
    if (statusIdSelected === 6) {
      // ability to set "keine Abweichung"
      list.push({
        content: "keine Abweichung",
        parentId: null,
        signatureRequired: 0,
        statusId: 6,
        value: 0,
        variationType: "listitem"
      });
    }
    return list;
  }

  getMemberItems() {
    const {
      members: { entities }
    } = this.props;
    return Object.values(entities).map(({ id, firstname, lastname }) => ({
      value: id,
      content: `${firstname} ${lastname}`
    }));
  }

  /**
   * Check if form is valid based field values.
   */
  formIsValid() {
    const { statusId, clientDatetime, memberId } = this.state;
    return (
      // status selected
      statusId > 0 &&
      // date/time in past
      ProgressAdd.datetimeIsBefore(clientDatetime) &&
      // member selected
      memberId > 0
    );
  }

  /**
   * Save form date and close modal.
   * @param {function} action
   */
  async submitForm(action) {
    const { shipmentId, token, onSuccess } = this.props;
    const {
      statusId,
      statusVariationId,
      memberId,
      clientDatetime,
      mediaType,
      media,
      mediaFiles
    } = this.state;

    this.setState({ savingForm: true, error: false });

    try {
      if (mediaFiles) {
        const {
          grid: { files_id: uuid }
        } = await ApiService.uploadGrid(token, mediaFiles);
        media.imagenode.images[0].uuid = uuid;
      }
      await ApiService.createShipmentProgress(token, shipmentId, {
        statusId,
        statusVariationId,
        memberId,
        clientDatetime: clientDatetime.toISOString(),
        media: mediaType ? media : null
      });

      // finish and close modal
      this.setState({ savingForm: false });
      action();
      onSuccess();
    } catch (e) {
      this.setState({ savingForm: false, error: true });
    }
  }

  render() {
    const {
      statusId,
      mediaType,
      clientDatetime,
      time,
      memberId,
      savingForm,
      error
    } = this.state;

    const variationItems = this.getVariationItems();
    const formIsValid = this.formIsValid();

    return (
      <StandardModal
        size="small"
        heading="Auftragsstatus hinzufügen"
        cancelElement={action => (
          <StandardButton onClick={action}>Abbrechen</StandardButton>
        )}
        confirmElement={action => (
          <StandardButton
            disabled={!formIsValid || savingForm}
            appearance="primary"
            onClick={() => this.submitForm(action)}
          >
            Auftragsstatus speichern{savingForm ? "..." : ""}
          </StandardButton>
        )}
        onClose={this.onClose}
      >
        {error && (
          <Row>
            Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.
          </Row>
        )}
        <Row>
          <ColLabel>Referenz</ColLabel>
          <Col>{this.getShipmentReference()}</Col>
        </Row>
        <Row>
          <ColLabel>Status</ColLabel>
          <Col>
            <StandardSelection
              placeholder="Status auswählen..."
              noSuggestionsText="Keine Ergebnisse..."
              items={this.getStatusItems()}
              selectedItem={{ value: statusId }}
              onChange={this.onChangeStatus}
            />
          </Col>
        </Row>
        {variationItems.length > 0 && (
          <Row>
            <ColLabel />
            <Col>
              <NestedSelection
                // WORKAROUND: uncontrolled selection
                key={`status-${statusId}`}
                placeholder={
                  statusId === 6
                    ? "keine Abweichung"
                    : "Begründung auswählen..."
                }
                noSuggestionsText="Keine Ergebnisse..."
                items={variationItems}
                onChange={this.onChangeStatusVariation}
              />
            </Col>
          </Row>
        )}
        {mediaType.length > 0 && (
          <Row>
            <ColLabel />
            <Col>
              <ProgressMedia
                type={mediaType}
                getMedia={this.onChangeMedia}
                getFiles={this.onChangeMediaFiles}
              />
            </Col>
          </Row>
        )}
        <Row>
          <ColLabel>Datum, Uhrzeit</ColLabel>
          <Col>
            <div style={{ display: "inline-block" }}>
              <StandardDateInput
                style={{ width: "115px" }}
                lookAndFeel="button"
                iconBefore={{
                  name: "calendar-outline"
                }}
                maxDate={new Date()}
                selected={clientDatetime.toDate()}
                onChange={this.onChangeDate}
              />
            </div>
            <StandardInput
              style={{ width: "80px" }}
              lookAndFeel="button"
              iconBefore={{
                name: "clock-outline"
              }}
              value={time}
              onChange={this.onChangeTime}
              onBlur={this.onBlurTime}
            />
          </Col>
        </Row>
        <Row>
          <ColLabel>Ausgeführt von</ColLabel>
          <Col>
            <StandardSelection
              placeholder="Person auswählen..."
              noSuggestionsText="Keine Ergebnisse..."
              items={this.getMemberItems()}
              selectedItem={{ value: memberId }}
              onChange={this.onChangeMember}
            />
          </Col>
        </Row>
        {formIsValid && (
          <Note>
            Hinweis: Der hinzugefügte Status wird automatisch an die
            angebundenen Netzwerke übertragen.
          </Note>
        )}
      </StandardModal>
    );
  }
}

const mapStateToProps = state => {
  const {
    auth: { token },
    shipment: { shipments },
    entities: { members }
  } = state;
  return {
    token,
    members,
    shipments,
    status: getAvailableShipmentStatus(state),
    statusVariationsNested: getStatusVariationsNested(state)
  };
};

const mapDispatchToProps = {
  loadStatusVariations: shipmentAction.loadStatusVariations,
  loadShipmentStatus: shipmentAction.loadShipmentStatus,
  getMembers: getMembersAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProgressAdd);

const Row = styled.div`
  display: flex;
  flex: 1;
  padding-bottom: 13px;
  align-items: center;
`;

const ColLabel = styled.div`
  flex: 1;
`;

const Col = styled.div`
  flex: 3;
`;

const Note = styled.div`
  color: #777;
  font-size: 13px;
`;
