/**
 * Format status variations for a NestedSelection.
 * @param {Array} variations
 */
export const formatToStatusVariationsNested = variations => {
  // change structure for NestedSelection
  const variationsArray = variations.map(
    ({
      id: value,
      label: content,
      status_id: statusId,
      variation_type: variationType,
      signature_required: signatureRequired,
      parent_id: parentId
    }) => ({
      value,
      content,
      statusId,
      variationType,
      signatureRequired,
      parentId
    })
  );

  /**
   * Build tree with recursive function.
   * Example:
   * ```
   * [
   *  { value: 1, content: "Parent", parentId: null, ...other },
   *  { value: 2, content: "Children", parentId: 1 },
   *  { value: 3, content: "Item", parentId: null }
   * ]
   * ```
   * to ->
   * ```
   * [
   *  { value: 1, content: "Parent", parentId: null, children: [{ value: 2, content: "Children", parentId: 1 }], ...other },
   *  { value: 3, content: "Item", parentId: null }
   * ]
   * ```
   */
  const buildTree = (variations, parentId = null) => {
    const nestedVariations = [...variations];
    const branch = [];
    nestedVariations.forEach((variation, index) => {
      if (variation.parentId !== parentId) {
        return;
      }
      const children = buildTree(nestedVariations, variation.value);
      if (children) {
        nestedVariations[index].children = children;
      }
      branch.push(variation);
    });
    return branch;
  };
  return buildTree(variationsArray);
};
