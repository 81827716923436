import React from "react";
import PropTypes from "prop-types";
import ReactSelect from "react-select";

const Select = ({ autoSize, children, ...otherProps }) => (
  <ReactSelect multi={false} autosize={autoSize} {...otherProps}>
    {children}
  </ReactSelect>
);

Select.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  options: PropTypes.array, // array of options: [{label: "x", value: "y"}]
  labelKey: PropTypes.string, // key of option object which is used as label
  valueKey: PropTypes.string, // key of option object which is used as value
  searchable: PropTypes.bool, // whether the field can be filtered
  searchingText: PropTypes.string,
  searchingPromptText: PropTypes.string,
  autoSize: PropTypes.bool,
  autoBlur: PropTypes.bool, // blurs the input element after a selection has been made. Handy for lowering the keyboard on mobile devices
  clearable: PropTypes.bool,
  clearValueText: PropTypes.string,
  isLoading: PropTypes.bool, // whether the Select is loading externally or not (such as options being loaded)
  loadOptions: PropTypes.func, // function that returns a promise or calls a callback with the options: function(input)
  matchPosition: PropTypes.oneOf(["any", "start"]), // where the filter matches
  matchProperty: PropTypes.oneOf(["any", "start"]),
  mathProperty: PropTypes.oneOf(["any", "label", "value"]), // against what the filter matches
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onInputChange: PropTypes.func,
  onValueClick: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  children: PropTypes.any
};

Select.defaultProps = {
  searchable: true,
  autoSize: true,
  autoBlur: false,
  clearable: true,
  matchPosition: "any",
  matchProperty: "any"
};

export default Select;
