import { call, put, takeLatest, select } from "redux-saga/effects";

import ApiService from "../../service/ApiService";
import {
  FETCH_TRACK_REQUEST,
  FETCH_TRACK,
  RELOAD_CURRENT_TRACK
} from "./index";

function* fetchTrack({ payload }) {
  try {
    const tracking = yield call(
      ApiService.getTrack,
      payload.token,
      payload.track
    );
    yield put({
      type: FETCH_TRACK,
      payload: {
        tracking,
        code: payload.track
      }
    });
  } catch (error) {
    yield put({
      type: FETCH_TRACK,
      payload: error,
      error: true
    });
  }
}

/**
 * Reload current track.
 */
function* reloadCurrentTrack() {
  const token = yield select(state => state.auth.token);
  const code = yield select(state => state.track.code);

  if (!code) {
    // skip reload
    return;
  }

  try {
    const tracking = yield call(ApiService.getTrack, token, code);
    yield put({
      type: FETCH_TRACK,
      payload: {
        tracking,
        code
      }
    });
  } catch (error) {
    yield put({
      type: FETCH_TRACK,
      payload: error,
      error: true
    });
  }
}

export default function* loadTrackSaga() {
  yield takeLatest(FETCH_TRACK_REQUEST, fetchTrack);
  yield takeLatest(RELOAD_CURRENT_TRACK, reloadCurrentTrack);
}
