export const SHIPMENTS_SORT_BY = "SHIPMENTS_SORT_BY";
export const SHIPMENTS_SET_FILTER_DATE = "SHIPMENTS_SET_FILTER_DATE";
export const SHIPMENTS_SET_FILTER_STATUS = "SHIPMENTS_SET_FILTER_STATUS";
export const SHIPMENTS_SET_FILTER_TOURS = "SHIPMENTS_SET_FILTER_TOURS";
export const SHIPMENTS_SET_FILTER_DRIVERS = "SHIPMENTS_SET_FILTER_DRIVERS";

export const SHIPMENTS_SHOW_EDIT = "SHIPMENTS_SHOW_EDIT";
export const SHIPMENTS_HIDE_EDIT = "SHIPMENTS_HIDE_EDIT";
export const SHIPMENTS_SHOW_PROGRESS_ADD = "SHIPMENTS_SHOW_PROGRESS_ADD";
export const SHIPMENTS_HIDE_PROGRESS_ADD = "SHIPMENTS_HIDE_PROGRESS_ADD";
export const SHIPMENTS_SET_SCROLL_TOP = "SHIPMENTS_SET_SCROLL_TOP";
