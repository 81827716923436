/**
 *
 * Build map elements based on shipment object
 * @param {Object} shipment
 */
function shipmentToMapElements({
  sender_address_latitude: senderLat,
  sender_address_longitude: senderLng,
  recipient_address_latitude: recipientLat,
  recipient_address_longitude: recipientLng
}) {
  const direction = {};

  if (senderLat && senderLat) {
    direction.origin = {
      location: {
        lat: senderLat,
        lng: senderLng
      }
    };
  }

  if (recipientLat && recipientLng) {
    direction.destination = {
      location: {
        lat: recipientLat,
        lng: recipientLng
      }
    };
  }

  return {
    directions:
      direction.origin && direction.destination ? [direction] : undefined,
    pins: direction.destination
      ? [{ label: " ", position: direction.destination.location }]
      : undefined,
    pinsAB:
      direction.origin && direction.destination
        ? [
            { label: "A", position: direction.origin.location },
            { label: "B", position: direction.destination.location }
          ]
        : undefined
  };
}

export default shipmentToMapElements;
