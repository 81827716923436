import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import Helmet from "react-helmet";
import { Map, mapActions, mapUtils } from "features/map";
import { SplitScreen, SplitScreenContent } from "components";
import ApiService from "service/ApiService";
import { getStatus } from "state/entities/shipmentStatus/actions";

import ActionBarContainer from "../../components/scheduling/ActionBarContainer";
import FilterActionBar from "../../components/scheduling/FilterActionBar";
import ShipmentActionBar from "../../components/scheduling/ShipmentActionBar";
import ShipmentList from "../../components/scheduling/ShipmentList";
import RecurrenceInstanceSwitchModal, {
  InstanceMode
} from "../../components/element/overlay/RecurrenceInstanceSwitchModal";
import {
  loadShipments,
  filterShipments,
  loadShipmentStatus,
  loadShipmentStatusGroups
} from "../../state/shipment/shipmentAction";
import { createJobs } from "../../state/job/jobAction";
import CreateEditShowShipment from "../../containers/shipment/CreateEditShowShipment";

const SplitScreenContentShipment = styled(SplitScreenContent)`
  display: flex;
  flex-direction: column;
  border-right: 1px solid #d6d7da;
`;

class SchedulingList extends Component {
  static propTypes = {
    shipments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    status: PropTypes.shape({}).isRequired,
    shipmentsTotal: PropTypes.number.isRequired,
    shipmentsLoaded: PropTypes.number.isRequired,
    filterStatus: PropTypes.arrayOf(PropTypes.number).isRequired,
    filterStatusGroups: PropTypes.shape({}).isRequired,
    selectedFilters: PropTypes.shape({}).isRequired,
    selectedDate: PropTypes.shape({}).isRequired,
    token: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    shipmentsLoadError: PropTypes.shape({})
  };

  static defaultProps = {
    shipmentsLoadError: null
  };

  constructor(props) {
    super(props);
    this.init = false;
    this.toggleSelected = {};
    this.currentCheckbox = null;
    // pins which are shown in feature map
    this.mapPins = [];
  }

  state = {
    selectedAssignment: null,
    selectedShipment: [],
    shipmentsLoading: false,
    focusActionbar: false,
    showShipmentDetailModal: false,
    shipmentDetails: {},
    recurrenceModal: false
  };

  componentDidMount() {
    const { selectedDate, dispatch, token } = this.props;

    dispatch(getStatus());
    dispatch(loadShipmentStatus(token));
    dispatch(loadShipmentStatusGroups(token));

    this.handleDateChange(selectedDate.start, selectedDate.end);

    dispatch(mapActions.show("drivers"));

    dispatch(
      mapActions.setDriversOptions({
        fitBounds: true
      })
    );
  }

  componentWillReceiveProps(nextProps) {
    const newState = {};
    if (nextProps.shipmentsLoaded > this.props.shipmentsLoaded) {
      newState.shipmentsLoading = false;
    }

    const newTrackcodes = nextProps.shipments.map(
      shipment => shipment.trackcode
    );

    // Unselect Shipments that are not included in the new props;
    newState.selectedShipment = this.state.selectedShipment.filter(shipment =>
      newTrackcodes.includes(shipment.trackcode)
    );

    if (Object.keys(newState).length > 0) {
      this.setState(newState);
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(mapActions.hide("scheduling"));
  }

  onBlurActionBar = () => {
    if (this.currentCheckbox) {
      this.currentCheckbox.focus();
      this.setState({ focusActionbar: false });
    }
  };

  getSelectedShipments() {
    const { shipments } = this.props;
    const selectedShipmentIds = this.state.selectedShipment;
    return shipments.filter(shipment =>
      selectedShipmentIds.find(selectedId => shipment.trackcode === selectedId)
    );
  }

  handleDateChange = date => {
    const start = date;
    const end = moment(date).endOf("day");
    const tz = end.format("ZZ");

    this.props.dispatch(loadShipments(this.props.token, start, end, tz));
    this.setState({ shipmentsLoading: true });
  };

  isSelectedShipment(trackcode) {
    if (!this.toggleSelected[trackcode]) {
      this.toggleSelected[trackcode] = true;
      return true;
    }
    this.toggleSelected = {};
    return false;
  }

  handleShipmentSelected = (trackcode, selected) => {
    const { dispatch, shipments } = this.props;
    let selectedItems = [...this.state.selectedShipment];

    if (selected) {
      selectedItems = [trackcode];

      const shipment = shipments.find(data => data.trackcode === trackcode);

      const { pinsAB, pins } = mapUtils.shipmentToMapElements(shipment);

      if (shipment.isDirect && pinsAB) {
        this.mapPins = pinsAB;
        dispatch(
          mapActions.set(
            "scheduling",
            { pins: pinsAB },
            {
              fitBounds: true
            }
          )
        );
      }
      if (!shipment.isDirect && pins) {
        this.mapPins = pins;
        dispatch(
          mapActions.set(
            "scheduling",
            { pins },
            {
              fitBounds: true,
              zoom: 15
            }
          )
        );
      }
    } else {
      selectedItems.splice(selectedItems.indexOf(trackcode), 1);

      dispatch(mapActions.clear("scheduling"));
      dispatch(
        mapActions.setDriversOptions({
          fitBounds: true
        })
      );
    }

    this.setState({
      selectedShipment: selectedItems
    });
  };

  handleShipmentAction = (action, shipment) => {
    this.setState({ showShipmentDetailModal: true, shipmentDetails: shipment });
  };

  submitShipmentDetailModal = () => {
    this.setState({ showShipmentDetailModal: true, shipmentDetails: {} });
  };

  closeShipmentDetailModal = () => {
    this.setState({ showShipmentDetailModal: false, shipmentDetails: {} });
  };

  filterSelectedShipment(selectedItems) {
    const { shipments } = this.props;

    const shipment = shipments.filter(_shipment =>
      selectedItems.find(selectedId => _shipment.trackcode === selectedId)
    );
    if (shipment && shipment.length > 0) {
      return shipment[0];
    }

    return null;
  }

  handleFilterChange = (options = []) => {
    this.props.dispatch(
      filterShipments({
        status: options
          .filter(({ statusId }) => statusId)
          .map(({ statusId }) => statusId),
        statusGroups: options
          .filter(({ groupId }) => groupId)
          .map(({ groupId }) => groupId)
      })
    );
  };

  handleAssignment = assignment => {
    const { selectedShipment } = this.state;

    if (!assignment) {
      this.setState({ selectedAssignment: null });
      if (selectedShipment && selectedShipment[0]) {
        this.handleShipmentSelected(selectedShipment[0], true);
      } else {
        this.handleShipmentSelected(null, false);
      }
      return;
    }

    this.showDriverJobsOnMap(assignment.member_id);

    this.setState({ selectedAssignment: assignment });
  };

  /**
   * Show all jobs on map and show a polygon
   * which is the hull of all assigned shipments.
   * @param {number} memberId
   */
  async showDriverJobsOnMap(memberId) {
    const { token, dispatch } = this.props;

    let memberShipments = [];
    try {
      const {
        jobs: { shipments = [] }
      } = await ApiService.getJobs(token, memberId);
      memberShipments = shipments;
    } catch (e) {
      // nothing to show on map
      return;
    }

    let positions = [];
    // collect all positions
    memberShipments.forEach(shipment => {
      if (shipment.isPickedUp === 0) {
        positions.push({
          lat: shipment.sender_address_latitude,
          lng: shipment.sender_address_longitude
        });
      }
      positions.push({
        lat: shipment.recipient_address_latitude,
        lng: shipment.recipient_address_longitude
      });
    });

    // clean coordinates
    positions = positions.filter(
      ({ lat, lng }) =>
        typeof lat === "number" &&
        typeof lng === "number" &&
        (lat > 0.0001 || lat < -0.0001) &&
        (lng > 0.0001 || lng < -0.0001)
    );

    if (positions.length === 0) {
      // do not change map view
      return;
    }

    dispatch(
      mapActions.set(
        "scheduling",
        {
          pins: [
            ...positions.map((position, key) => ({
              key: `shipment-${key}`,
              appearance: "subtle",
              position
            })),
            ...this.mapPins.map((props, key) => ({
              key: `shipment-selected-${key}`,
              ...props
            }))
          ],
          polygons: [{ positions }]
        },
        {
          fitBounds: true
        }
      )
    );
  }

  handleOnDriverOnMapClicked = driver => {
    this.setState({ selectedAssignment: driver });
  };

  handleDriverAssignmentSumbit = () => {
    const { status, dispatch } = this.props;

    const { selectedAssignment } = this.state;

    const filteredShipments = this.getSelectedShipments();
    const statusId = status[22].id;

    if (!selectedAssignment) {
      alert("Sie haben keinen Fahrer ausgewählt");
      return;
    }

    if (filteredShipments.length === 0) {
      alert("Sie haben keine Sendung ausgewählt");
      return;
    }

    if (selectedAssignment && filteredShipments) {
      const isRecurring =
        filteredShipments.filter(shipment => shipment.is_recurring).length > 0;
      if (isRecurring) {
        this.setState({ recurrenceModal: true });
      } else {
        dispatch(
          createJobs(
            this.props.token,
            selectedAssignment,
            filteredShipments,
            statusId
          )
        );
        this.setState({ selectedAssignment: null, focusActionbar: false });
      }
      if (this.currentCheckbox) {
        this.currentCheckbox.focus();
      }

      dispatch(mapActions.clear("scheduling"));
      dispatch(
        mapActions.setDriversOptions({
          fitBounds: true
        })
      );
    }
  };

  handleDriverFilter = e => {
    // filter shipments client side
    this.props.dispatch(filterShipments({ drivers: e }));
  };

  handleShipmentKeyEvents = e => {
    // console.log("e.keyCode", e.keyCode);
    switch (e.keyCode) {
      case 38: // ArrowUp
      case 40: {
        // ArrowDown
        this.currentCheckbox = e.target;
        this.toggleSelected = {};

        const { shipments } = this.props;
        const trackcode = this.state.selectedShipment[0];
        let found = 0;

        if (e.keyCode === 38) {
          // ArrowUp
          for (let i = 0; i < shipments.length; i += 1) {
            if (shipments[i].trackcode === trackcode) {
              found = i - 1;
              break;
            }
          }
        } else if (e.keyCode === 40) {
          // ArrowDown
          for (let i = 0; i < shipments.length; i += 1) {
            if (shipments[i].trackcode === trackcode) {
              found = i + 1;
              break;
            }
          }
        }

        if (found < 0) {
          found = 0;
        } else if (found >= shipments.length) {
          found = shipments.length - 1;
        }

        this.handleShipmentSelected(shipments[found].trackcode, true);

        // this.currentCheckbox.focus();
        e.preventDefault();

        break;
      }
      case 13: // Enter
        this.handleDriverAssignmentSumbit();
        break;
      case 171: // + firefox
      case 187: // + safari
      case 107: // + numpad
        this.currentCheckbox = e.target;
        this.setState({ focusActionbar: true });
        break;
      case 27:
        this.setState({ selectedShipment: [] });
        break;

      default:
        break;
    }

    // e.target.focus();
  };

  handleRecurrenceModalAction = instancemode => {
    const filteredShipments = this.getSelectedShipments();
    this.setState({ recurrenceModal: false });
    if (instancemode === InstanceMode.CANCEL) {
      return;
    }

    if (instancemode === InstanceMode.INSTANCE) {
      for (let i = 0; i < filteredShipments.length; i += 1) {
        filteredShipments[i].changeRecurrenceInstance = true;
      }
    }

    const { status } = this.props;

    const { selectedAssignment } = this.state;

    const statusId = status[22].id;

    this.props.dispatch(
      createJobs(
        this.props.token,
        selectedAssignment,
        filteredShipments,
        statusId
      )
    );
    this.setState({ selectedAssignment: null, focusActionbar: false });
  };

  render() {
    const {
      shipments,
      shipmentsTotal,
      shipmentsLoaded,
      filterStatus,
      filterStatusGroups,
      selectedFilters,
      shipmentsLoadError,
      selectedDate,
      status
    } = this.props;
    const shipmentsFiltered = shipments.length;

    const {
      selectedShipment,
      shipmentsLoading,
      selectedAssignment,
      focusActionbar,
      showShipmentDetailModal,
      shipmentDetails,
      recurrenceModal
    } = this.state;

    const isShipmentsLoading = shipmentsLoading || shipmentsLoaded === 0;
    const isShipmentSelected = !!(
      selectedShipment.length || selectedAssignment
    );

    return (
      <Fragment>
        <Helmet title="Disposition" />
        {showShipmentDetailModal && (
          <CreateEditShowShipment
            showModal={showShipmentDetailModal}
            shipment={shipmentDetails}
            handleClose={this.closeShipmentDetailModal}
            handleSubmit={this.submitShipmentDetailModal}
            editMode={false}
            willBeEdited
          />
        )}
        {recurrenceModal && (
          <RecurrenceInstanceSwitchModal
            showRecurrenceModal={recurrenceModal}
            handleRecurrenceAction={this.handleRecurrenceModalAction}
            title="Wiederkehrenden Auftrag zuweisen"
            body="Möchtest du auch alle zukünftige Ereignisse zuweisen oder nur dieses Ereignis"
          />
        )}
        <SplitScreen>
          <SplitScreenContentShipment flex={1.2}>
            <ActionBarContainer activeBackground={isShipmentSelected}>
              {isShipmentSelected && (
                <ShipmentActionBar
                  handleAssignment={this.handleAssignment}
                  selectedShipments={this.getSelectedShipments()}
                  handleSubmit={this.handleDriverAssignmentSumbit}
                  selectedAssignment={selectedAssignment}
                  focus={focusActionbar}
                  onBlur={this.onBlurActionBar}
                />
              )}
              {!isShipmentSelected && (
                <FilterActionBar
                  loading={isShipmentsLoading}
                  shipmentsTotal={shipmentsTotal}
                  shipmentsFiltered={shipmentsFiltered}
                  filterOptions={filterStatus}
                  filterStatusGroups={filterStatusGroups}
                  filterValues={[
                    ...selectedFilters.status.map(id => `status${id}`),
                    ...selectedFilters.statusGroups.map(id => `group${id}`)
                  ]}
                  handleFilterChange={this.handleFilterChange}
                  handleDateChange={this.handleDateChange}
                  selectedDate={selectedDate}
                  handleDriverFilter={this.handleDriverFilter}
                  filterDriver={selectedFilters.drivers}
                  status={status}
                />
              )}
            </ActionBarContainer>
            <ShipmentList
              loading={isShipmentsLoading}
              shipmentsLoadError={shipmentsLoadError}
              shipments={shipments}
              selectedShipments={this.getSelectedShipments()}
              handleShipmentSelected={this.handleShipmentSelected}
              handleShipmentAction={this.handleShipmentAction}
              handleShipmentKeyEvents={this.handleShipmentKeyEvents}
            />
          </SplitScreenContentShipment>
          <SplitScreenContent>
            <Map handleScopes={["drivers", "scheduling"]} />
          </SplitScreenContent>
        </SplitScreen>
      </Fragment>
    );
  }
}

function mapStateToProps({
  auth: { token },
  shipment: {
    shipments,
    shipmentsAll,
    loaded,
    filterStatus,
    filterStatusGroups,
    selectedFilters,
    selectedDate,
    error
  },
  entities: {
    shipmentStatus: { entities: status }
  }
}) {
  return {
    token,
    status,
    shipments,
    shipmentsTotal: shipmentsAll.length,
    shipmentsLoaded: loaded,
    filterStatus,
    filterStatusGroups,
    selectedFilters,
    selectedDate,
    shipmentsLoadError: error
  };
}

export default connect(mapStateToProps)(SchedulingList);
