/* eslint-disable jsx-a11y/label-has-for */
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Flex } from "reflexbox";
import classNames from "classnames/bind";
import moment from "moment";
import { Dropdown } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import "rc-switch/assets/index.css";
import { PlacesAutocomplete } from "@trackcode/places-autocomplete";
import ShipmentAddressComplete from "../../components/element/autocomplete/ShipmentAddressComplete";
import FloatingTextField from "../../components/element/form/FloatingTextField";
import FloatingDatePicker from "../../components/element/form/FloatingDatePicker";
import TimeSlotSlider from "../../components/element/form/TimeSlotSlider";
import Checkbox from "../../components/element/form/TCCheckbox";
import Button from "../../components/element/button";
import RecurrenceInstanceSwitchModal, {
  InstanceMode
} from "../../components/element/overlay/RecurrenceInstanceSwitchModal";
import DeleteModal, {
  DeleteInstanceMode
} from "../../components/element/overlay/DeleteModal";
import Tools from "../../service/Tools";

import {
  createShipment,
  updateShipment,
  deleteShipment
} from "../../state/shipment/shipmentAction";

import "./panel.css";

import styleNames from "./createEditShipmentForm.local.css";

const cx = classNames.bind(styleNames);

const getRowsOfDescription = desc => {
  if (!desc) {
    return 1;
  }
  const splits = desc.split(/\n/);
  return splits.length > 3 ? 3 : splits.length;
};

class CreateEditShipmentForm extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    handleSenderAddressChange: PropTypes.func,
    handleRecipientAddressChange: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    handleAbort: PropTypes.func.isRequired,
    shipment: PropTypes.shape({}).isRequired,
    editMode: PropTypes.bool
  };

  static defaultProps = {
    editMode: true,
    handleRecipientAddressChange: () => {},
    handleSenderAddressChange: () => {}
  };

  /**
   * Converts a date YYYY-MM-DD HH:MM:SS to minutes without date.
   * @param {string} prop
   * @returns {number} minutes
   */
  static timeStrToMinutes(prop) {
    if (!prop) return null;
    try {
      const timeRaw = prop.split(" ")[1];
      const [hours, minutes] = timeRaw.split(":").map(val => parseInt(val, 10));
      return hours * 60 + minutes;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  static getTimeFromMinutes(t) {
    const hours = Math.trunc(t / 60);
    let minutes = t % 60;
    if (minutes.toString().length === 1) {
      minutes = `0${minutes}`;
    }
    return `${hours}:${minutes}:00`;
  }

  state = {
    selectedShipmentAdditionals: [],
    selectedSenderAdditionals: [],
    selectedRecipientAdditionals: [],
    shipmentCounterValue: 1,
    isDirect: false,
    isPickedUp: true,
    isFixedStop: false,
    isRepetitive: false,
    shipment: {},
    startMinDate: moment(),
    pickupTimeFrameStart: 60 * 0.25,
    pickupTimeFrameEnd: 60 * 23.25,
    pickupStartTime: 60 * 0.25,
    pickupEndTime: 60 * 23.25,
    pickupStartDate: null,
    pickupEndDate: null,
    pickupTimeFrameActive: false,
    pickupDateActive: false,

    checkboxes: [0, 0, 0, 0, 0, 0, 0],
    editRecurrenceModal: false,
    deleteModal: false,
    deleteRecurrenceModal: false,
    changeRecurrenceInstance: false,
    repetitiveWeekdays: 0,
    repetitionSend: false,
    recurrenceStartDate: null,
    recurrenceEndDate: null,

    repetitionDatePickerState: "off",
    pickupDatePickerState: "off",
    deliveryDatePickerState: "off",

    deliveryTimeFrameStart: 60 * 0.25,
    deliveryTimeFrameEnd: 60 * 23.25,
    deliveryStartTime: 60 * 0.25,
    deliveryEndTime: 60 * 23.25,
    deliveryStartDate: null,
    deliveryEndDate: null,
    deliveryTimeFrameActive: false,
    deliveryDateActive: false,
    newEditMode: false,
    addressChanged: true
  };

  componentDidMount() {
    const { shipment } = this.props;
    if (shipment && Object.keys(shipment).length > 0) {
      // because of React 16.3 this rule doesn't make sense
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState(this.prepareNewState(shipment));
    }
  }

  onAbort = () => {
    if (!this.state.newEditMode) {
      this.props.handleAbort();
    } else {
      this.setState({ newEditMode: false, changeRecurrenceInstance: false });
    }
  };

  onAbort = () => {
    if (!this.state.newEditMode) {
      this.props.handleAbort();
    } else {
      this.setState({ newEditMode: false, changeRecurrenceInstance: false });
    }
  };

  onEdit = () => {
    const { isRepetitive } = this.state;

    if (isRepetitive) {
      this.setState({ editRecurrenceModal: true });
    } else {
      this.setState({ newEditMode: true });
    }
  };

  onDelete = () => {
    const { isRepetitive } = this.state;

    if (isRepetitive) {
      this.setState({ deleteRecurrenceModal: true });
    } else {
      this.setState({ deleteModal: true });
    }
  };

  onSubmit = () => {
    const shipments = [];
    const addresses = [];
    const {
      shipmentCounterValue,
      shipment,
      changeRecurrenceInstance,
      isDirect,
      isPickedUp,
      isFixedStop,
      isRepetitive
    } = this.state;

    if (isFixedStop) {
      shipment.isFixedStop = isFixedStop;
      shipment.is_recurring = isRepetitive;
      shipment.isPickedUp = true;
      shipment.isDirect = false;
    } else {
      shipment.isPickedUp = isPickedUp;
      shipment.isDirect = isDirect;
    }

    // shipment.is_recurrence_template = true; //confirm("Soll CHANGEREPITITIONITEM auf TRUE gesetzt werden?");
    shipment.changeRecurrenceInstance = changeRecurrenceInstance;

    // Zeiten hinzufügen
    const pickupTime = this.getDateTimeFrame("pickup");
    const deliveryTime = this.getDateTimeFrame("delivery");

    const [pickupAppointmentFrom, pickupAppointmentTill] = pickupTime;
    const [deliveryAppointmentFrom, deliveryAppointmentTill] = deliveryTime;
    shipment.pickup_appointment_from = pickupAppointmentFrom;
    shipment.pickup_appointment_till = pickupAppointmentTill;
    shipment.delivery_appointment_from = deliveryAppointmentFrom;
    shipment.delivery_appointment_till = deliveryAppointmentTill;

    if (this.state.isRepetitive) {
      let dayofweek = "";
      for (let j = 0; j < this.state.checkboxes.length; j += 1) {
        if (this.state.checkboxes[j] > 0) {
          if (dayofweek === "") {
            dayofweek = j;
          } else {
            dayofweek = `${dayofweek},${j}`;
          }
        }
      }

      // If no weekday has been selected, the recurrence would be created, but
      // never shown, this might confuse the user, so it is the easiest to catch
      // show an error before such a recurrence may be createEditShipmentForm
      if (dayofweek === "") {
        alert(
          "Sie versuchen eine wiederkehrende Sendung anzulegen, haben aber keinen Wochentag ausgewählt, an der diese erstellt werden soll."
        );
        return;
      }

      if (!shipment.recurrence) {
        shipment.recurrence = {};
      }
      shipment.recurrence.pattern = `{ "minute": "00", "hour": "00", "dayofmonth": "*", "month": "*", "dayofweek": "${dayofweek}" }`;

      if (this.state.recurrenceStartDate) {
        shipment.recurrence.starts_at = this.state.recurrenceStartDate;
        shipment.recurrence.last_created = `${moment(
          this.state.recurrenceStartDate
        )
          .subtract(1, "days")
          .format("YYYY-MM-DD")} 00:00:00`;
      } else {
        shipment.recurrence.starts_at = `${moment().format(
          "YYYY-MM-DD"
        )} 00:00:00`;
      }

      if (this.state.recurrenceEndDate) {
        shipment.recurrence.ends_at = this.state.recurrenceEndDate.endOf("day");
      } else {
        shipment.recurrence.ends_at = "9999-01-01 00:00:00";
      }
    }

    for (let i = 0; i < shipmentCounterValue; i += 1) {
      const tempShipment = Tools.clone(shipment);
      if (shipment[`barcode_label_${i}`]) {
        tempShipment.barcode_label = shipment[`barcode_label_${i}`];
        delete tempShipment[`barcode_label_${i}`];
      }
      if (shipment[`width_${i}`]) {
        tempShipment.widht = shipment[`width_${i}`];
        delete tempShipment[`width_${i}`];
      }
      if (shipment[`length_${i}`]) {
        tempShipment.length = shipment[`length_${i}`];
        delete tempShipment[`length_${i}`];
      }
      if (shipment[`height_${i}`]) {
        tempShipment.height = shipment[`height_${i}`];
        delete tempShipment[`height_${i}`];
      }
      if (shipment[`weight_${i}`]) {
        tempShipment.weight = shipment[`weight_${i}`];
        delete tempShipment[`weight_${i}`];
      }

      shipments.push(tempShipment);
    }

    const sender = {};
    const recipient = {};
    Object.keys(shipment).forEach(key => {
      if (key.indexOf("sender_") >= 0) {
        const tempKey = key.replace("sender_", "").replace("address_", "");
        sender[tempKey] = shipment[key];
      } else if (key.indexOf("recipient_") >= 0) {
        const tempKey = key.replace("sender_", "").replace("address_", "");
        recipient[tempKey] = shipment[key];
      }
    });

    if (Object.keys(sender).length > 0) {
      addresses.push(sender);
    }

    if (Object.keys(recipient).length > 0) {
      recipient.is_recipient = 1;
      addresses.push(recipient);
    }
    if (Object.keys(shipments[0]).length > 2) {
      // TODO alert fester stop on edit
      if (this.state.newEditMode) {
        this.props.dispatch(updateShipment(this.props.token, shipments));
      } else {
        this.props.dispatch(createShipment(this.props.token, shipments));
      }
    } else {
      // alert("Sie haben weniger als 2 Felder eingegeben");
    }
    this.setState({ newEditMode: false });

    this.props.handleSubmit();
  };

  /**
   * On address is changed by Autocomplete
   * @param {string} prefix
   * @param {Object} address
   */
  onAddressSelected(prefix, address) {
    const {
      street,
      streetNumber,
      zip,
      city,
      countryShort,
      latitude,
      longitude,
      placeId
    } = address || {};

    if (prefix === "sender") {
      this.props.handleSenderAddressChange(address);
    }
    if (prefix === "recipient") {
      this.props.handleRecipientAddressChange(address);
    }

    const shipmentAddress = {
      [`${prefix}_address_street`]:
        [street, streetNumber].filter(Boolean).join(" ") || undefined,
      [`${prefix}_address_zipcode`]: zip,
      [`${prefix}_address_city`]: city,
      [`${prefix}_address_country`]: countryShort,
      [`${prefix}_address_latitude`]: latitude,
      [`${prefix}_address_longitude`]: longitude,
      [`${prefix}_address_place_id`]: placeId
    };

    this.setState(prevState => ({
      shipment: { ...prevState.shipment, ...shipmentAddress },
      addressChanged: true
    }));
  }

  onEmptyPickupDate = range => {
    if (range === "fullrange") {
      this.emptyDate("pickupStartDate");
      this.emptyDate("pickupEndDate");
    }
    if (range === "startrange") {
      this.emptyDate("pickupStartDate");
    }
    if (range === "endrange") {
      this.emptyDate("pickupEndDate");
    }
  };

  onEmptyDeliveryDate = range => {
    if (range === "fullrange") {
      this.emptyDate("deliveryStartDate");
      this.emptyDate("deliveryEndDate");
    }
    if (range === "startrange") {
      this.emptyDate("deliveryStartDate");
    }
    if (range === "endrange") {
      this.emptyDate("deliveryEndDate");
    }
  };

  onPickupSliderChange = e => {
    this.setState({ pickupStartTime: e[0] });
    this.setState({ pickupEndTime: e[1] });
    if (
      parseInt(this.state.pickupStartTime, 10) ===
        parseInt(this.state.pickupTimeFrameStart, 10) &&
      parseInt(this.state.pickupEndTime, 10) ===
        parseInt(this.state.pickupTimeFrameEnd, 10)
    ) {
      this.setState({ pickupTimeFrameActive: false });
    }
  };
  onPickupSliderDisable = () => {
    this.setState({ pickupTimeFrameActive: false });
    this.setState({ pickupStartTime: this.state.pickupTimeFrameStart });
    this.setState({ pickupEndTime: this.state.pickupTimeFrameEnd });
  };

  onDeliverySliderDisable = () => {
    this.setState({ deliveryTimeFrameActive: false });
    this.setState({ deliveryStartTime: this.state.deliveryTimeFrameStart });
    this.setState({ deliveryEndTime: this.state.deliveryTimeFrameEnd });
  };

  onDeliverySliderChange = e => {
    // this.convertValuesToTime(e);
    this.setState({ deliveryStartTime: e[0] });
    this.setState({ deliveryEndTime: e[1] });
    if (
      parseInt(this.state.deliveryStartTime, 10) ===
        parseInt(this.state.deliveryTimeFrameStart, 10) &&
      parseInt(this.state.deliveryEndTime, 10) ===
        parseInt(this.state.deliveryTimeFrameEnd, 10)
    ) {
      this.setState({ deliveryTimeFrameActive: false });
    }
  };

  onPickupSliderActive = () => {
    if (this.state.pickupTimeFrameActive === false) {
      this.setState({ pickupTimeFrameActive: true });
    }
  };

  onDeliverySliderActive = () => {
    if (this.state.deliveryTimeFrameActive === false) {
      this.setState({ deliveryTimeFrameActive: true });
    }
  };

  onPickupCheckActivate = () => {
    if (
      parseInt(this.state.pickupStartTime, 10) ===
        parseInt(this.state.pickupTimeFrameStart, 10) &&
      parseInt(this.state.pickupEndTime, 10) ===
        parseInt(this.state.pickupTimeFrameEnd, 10)
    ) {
      this.setState({ pickupTimeFrameActive: false });
    }
  };

  onDeliveryCheckActivate = () => {
    if (
      parseInt(this.state.deliveryStartTime, 10) ===
        parseInt(this.state.deliveryTimeFrameStart, 10) &&
      parseInt(this.state.deliveryEndTime, 10) ===
        parseInt(this.state.deliveryTimeFrameEnd, 10)
    ) {
      this.setState({ deliveryTimeFrameActive: false });
    }
  };

  getDateTimeFrame(type) {
    const t = [];
    let timeframestart = "";
    let timeframestop = "";
    if (this.state[`${type}TimeFrameActive`]) {
      timeframestart = CreateEditShipmentForm.getTimeFromMinutes(
        this.state[`${type}StartTime`]
      );
      timeframestop = CreateEditShipmentForm.getTimeFromMinutes(
        this.state[`${type}EndTime`]
      );
    } else {
      timeframestart = "00:00:00";
      timeframestop = "00:00:00";
    }

    if (this.state[`${type}StartDate`]) {
      t[0] = `${this.state[`${type}StartDate`].format(
        "YYYY-MM-DD"
      )} ${timeframestart}`;
    } else {
      t[0] = `0000-00-00 ${timeframestart}`;
    }

    if (this.state[`${type}EndDate`]) {
      t[1] = `${this.state[`${type}EndDate`].format(
        "YYYY-MM-DD"
      )} ${timeframestop}`;
    } else {
      t[1] = `0000-00-00 ${timeframestop}`;
    }
    return t;
  }

  handleShipmentCounterChanged = e => {
    this.setState({ shipmentCounterValue: e.target.value });
  };

  handlePickupChanged = (e, t) => {
    this.setState({ isPickedUp: !t.checked, isDirect: false });
    this.checkRepetitiveValid(t.checked);
  };

  handleDirectChanged = (e, t) => {
    this.setState({ isDirect: t.checked, isPickedUp: !t.checked });
    this.checkRepetitiveValid(t.checked);
  };

  handleDeliveryChanged = (e, t) => {
    this.setState({ isFixedStop: !t.checked });
    this.checkRepetitiveValid();
    if (
      (this.state.isDirect || this.state.isPickedUp) &&
      this.state.isRepetitive
    ) {
      this.setState({ isRepetitive: true });
    } else {
      this.checkRepetitiveValid(false);
    }
  };

  handleFixedStopChanged = (e, t) => {
    this.setState({ isFixedStop: t.checked });
  };

  handleRepetitiveChanged = (e, t) => {
    this.setState({ isRepetitive: t.checked });
  };

  checkRepetitiveValid = e => {
    if (!e) {
      this.setState({ isRepetitive: false });
    }
  };

  changeDate = (name, e) => {
    this.setState({ [name]: e });
  };

  emptyDate = element => {
    this.setState({ [element]: null });
  };

  handleInputItemChanged = e => {
    const { shipment } = this.state;
    shipment[e.target.name] = e.target.value;
    this.setState({ shipment });
  };

  handleWeekDayKeyPress = e => {
    if (e.key === " " || e.key === "Enter") {
      this.handleDayChange(e);
    }
  };
  handleDayChange = e => {
    if (this.props.editMode || this.state.newEditMode) {
      const id = e.target.getAttribute("data-id");
      // const value = e.target.getAttribute("data-value");
      const checks = this.state.checkboxes;
      e.target.blur();
      if (checks[id] > 0) {
        checks[id] = 0;
      } else {
        checks[id] = 1;
      }

      // days = checks.reduce((a, b) => a + b, 0);
      this.setState({ checkboxes: checks });
    }
  };

  changeCounryCode = (e, data) => {
    const { shipment } = this.state;
    shipment[data.name] = data.value;
    this.setState({ shipment });
  };

  handleEditRecurrenceModalAction = instancemode => {
    switch (instancemode) {
      case InstanceMode.SERIES:
        this.setState({
          editRecurrenceModal: false,
          newEditMode: true,
          changeRecurrenceInstance: false
        });
        break;
      case InstanceMode.INSTANCE:
        this.setState({
          editRecurrenceModal: false,
          newEditMode: true,
          changeRecurrenceInstance: true
        });
        break;
      default:
        this.setState({ editRecurrenceModal: false });
        break;
    }
  };

  handleDeleteRecurrenceModalAction = instancemode => {
    const { shipment } = this.state;
    const shipments = [];

    switch (instancemode) {
      case InstanceMode.SERIES:
        shipment.deleteRecurrenceInstance = false;
        shipments.push(shipment);

        this.props.dispatch(deleteShipment(this.props.token, shipments));
        this.props.handleSubmit();
        // As it doesnt make sense to keep the modal open after the shipment got
        // deleted, we can close it
        this.props.handleAbort();

        this.setState({ deleteRecurrenceModal: false });
        break;
      case InstanceMode.INSTANCE:
        shipment.deleteRecurrenceInstance = true;
        shipments.push(shipment);

        this.props.dispatch(deleteShipment(this.props.token, shipments));
        this.props.handleSubmit();
        // As it doesnt make sense to keep the modal open after the shipment got
        // deleted, we can close it
        this.props.handleAbort();

        this.setState({ deleteRecurrenceModal: false });
        break;
      default:
        this.setState({ deleteRecurrenceModal: false });
        break;
    }
  };

  handleDeleteAction = instancemode => {
    const { shipment } = this.state;
    const shipments = [];

    switch (instancemode) {
      case DeleteInstanceMode.DELETE:
        shipments.push(shipment);
        this.props.dispatch(deleteShipment(this.props.token, shipments));
        this.props.handleSubmit();
        // As it doesnt make sense to keep the modal open after the shipment got
        // deleted, we can close it
        this.props.handleAbort();

        this.setState({ deleteModal: false });
        break;

      default:
        this.setState({ deleteModal: false });
        break;
    }
  };

  prepareNewState(shipment) {
    const newState = {};

    newState.isDirect = !!shipment.isDirect;
    newState.isPickedUp = !!shipment.isPickedUp;
    newState.isFixedStop = !!shipment.isFixedStop;
    newState.isRepetitive = !!shipment.is_recurring;
    newState.shipment =
      Object.keys(this.state.shipment) > 0 ? this.state.shipment : shipment;

    if (shipment.recurrence && shipment.recurrence.pattern) {
      const weekdays = [0, 0, 0, 0, 0, 0, 0];
      const dow = JSON.parse(shipment.recurrence.pattern);

      if (dow.dayofweek.includes("-")) {
        // Range
        const days = dow.dayofweek.split("-");
        for (let i = days[0]; i <= days[1]; i += 1) {
          weekdays[i] = 1;
        }
      } else if (dow.dayofweek.includes(",")) {
        // Individual days
        const days = dow.dayofweek.split(",");
        days.forEach(entry => {
          weekdays[parseInt(entry, 10)] = 1;
        });
      } else {
        // Only one item
        weekdays[parseInt(dow.dayofweek, 10)] = 1;
      }

      const recurrenceStartDate = moment
        .utc(shipment.recurrence.starts_at)
        .local();
      const recurrenceEndDate = moment.utc(shipment.recurrence.ends_at).local();

      newState.checkboxes = weekdays;
      newState.recurrenceStartDate = recurrenceStartDate;
      newState.recurrenceEndDate = recurrenceEndDate;
    }
    // timeframe setzen
    const pickupStartTime = CreateEditShipmentForm.timeStrToMinutes(
      shipment.pickup_appointment_from
    );
    const pickupEndTime = CreateEditShipmentForm.timeStrToMinutes(
      shipment.pickup_appointment_till
    );

    const deliveryStartTime = CreateEditShipmentForm.timeStrToMinutes(
      shipment.delivery_appointment_from
    );
    const deliveryEndTime = CreateEditShipmentForm.timeStrToMinutes(
      shipment.delivery_appointment_till
    );

    if (pickupEndTime < 1439) {
      newState.pickupStartTime =
        pickupStartTime < this.state.pickupTimeFrameStart
          ? this.state.pickupTimeFrameStart
          : pickupStartTime;
      newState.pickupEndTime =
        pickupEndTime === 0 || pickupEndTime > this.state.pickupTimeFrameEnd
          ? this.state.pickupTimeFrameEnd
          : pickupEndTime;
      newState.pickupTimeFrameActive = true;
    }

    if (deliveryEndTime < 1439) {
      newState.deliveryStartTime =
        deliveryStartTime < this.state.pickupTimeFrameStart
          ? this.state.pickupTimeFrameStart
          : deliveryStartTime;
      newState.deliveryEndTime =
        deliveryEndTime === 0 || deliveryEndTime > this.state.pickupTimeFrameEnd
          ? this.state.pickupTimeFrameEnd
          : deliveryEndTime;
      newState.deliveryTimeFrameActive = true;
    }

    // datum setzen
    let pickupDateFrom = null;
    let pickupState = "off";
    if (shipment.pickup_appointment_from) {
      if (
        shipment.pickup_appointment_from.split(" ")[0] !== "0000-00-00" &&
        shipment.pickup_appointment_from.split(" ")[0] !== "2000-00-00"
      ) {
        pickupDateFrom = moment(shipment.pickup_appointment_from.split(" ")[0]);
        pickupState = "startrange";
      }
    }
    let pickupDateTill = null;
    if (shipment.pickup_appointment_till) {
      if (
        shipment.pickup_appointment_till.split(" ")[0] !== "0000-00-00" &&
        shipment.pickup_appointment_till.split(" ")[0] !== "2000-00-00"
      ) {
        pickupDateTill = moment(shipment.pickup_appointment_till.split(" ")[0]);
        if (pickupState === "startrange") {
          pickupState = "fullrange";
        } else {
          pickupState = "endrange";
        }
      }
    }

    let deliveryState = "off";
    let deliveryDateFrom = null;
    if (shipment.delivery_appointment_from) {
      if (
        shipment.delivery_appointment_from.split(" ")[0] !== "0000-00-00" &&
        shipment.delivery_appointment_from.split(" ")[0] !== "2000-00-00"
      ) {
        deliveryDateFrom = moment(
          shipment.delivery_appointment_from.split(" ")[0]
        );
        deliveryState = "startrange";
      }
    }

    let deliveryDateTill = null;
    if (shipment.delivery_appointment_till) {
      if (
        shipment.delivery_appointment_till.split(" ")[0] !== "0000-00-00" &&
        shipment.delivery_appointment_till.split(" ")[0] !== "2000-00-00"
      ) {
        deliveryDateTill = moment(
          shipment.delivery_appointment_till.split(" ")[0]
        );
        if (deliveryState === "startrange") {
          deliveryState = "fullrange";
        } else {
          deliveryState = "endrange";
        }
      }
    }

    newState.deliveryDatePickerState = deliveryState;
    newState.deliveryStartDate = deliveryDateFrom;
    newState.deliveryEndDate = deliveryDateTill;

    newState.repetitionDatePickerState = pickupState;
    newState.pickupDatePickerState = pickupState;
    newState.pickupStartDate = pickupDateFrom;
    newState.pickupEndDate = pickupDateTill;

    return newState;
  }

  /**
   * Get data for Autocomplete.
   * @param {string} key
   */
  parseAddressForAutocomplete(prefix) {
    const { shipment } = this.state;

    const address = {
      street: shipment[`${prefix}_address_street`],
      city: shipment[`${prefix}_address_city`],
      country: shipment[`${prefix}_address_country`],
      zip: shipment[`${prefix}_address_zipcode`]
    };

    const validFields = Object.values(address).filter(Boolean);
    if (validFields.length > 0) {
      return address;
    }
    return null;
  }

  render() {
    const { editMode } = this.props;

    const {
      shipment,
      isDirect,
      isPickedUp,
      isFixedStop,
      startMinDate,
      deleteModal,

      isRepetitive,
      checkboxes,
      editRecurrenceModal,
      deleteRecurrenceModal,
      recurrenceStartDate,
      recurrenceEndDate,
      changeRecurrenceInstance,

      pickupTimeFrameStart,
      pickupTimeFrameEnd,
      pickupStartDate,
      pickupEndDate,
      pickupTimeFrameActive,

      pickupStartTime,
      pickupEndTime,

      deliveryTimeFrameStart,
      deliveryTimeFrameEnd,
      deliveryStartDate,
      deliveryEndDate,
      deliveryTimeFrameActive,

      deliveryStartTime,
      deliveryEndTime,
      newEditMode
    } = this.state;

    let timeSlotSliderWrapperClass = "";
    if (isRepetitive) {
      timeSlotSliderWrapperClass = "single";
    } else {
      timeSlotSliderWrapperClass = "";
    }

    const showRecurrenceFields =
      isFixedStop &&
      isRepetitive &&
      ((!changeRecurrenceInstance && editMode) || newEditMode);

    const editor = newEditMode || editMode;

    const countryCode = [
      { text: "+49", value: "0049" },
      { text: "+43", value: "0043" }
    ];

    return (
      <Fragment>
        {editRecurrenceModal && (
          <RecurrenceInstanceSwitchModal
            showRecurrenceModal={editRecurrenceModal}
            handleRecurrenceAction={this.handleEditRecurrenceModalAction}
            title="Wiederkehrenden Auftrag bearbeiten"
            body="Möchtest du auch alle zukünftige Ereignisse bearbeiten oder nur dieses Ereignis?"
          />
        )}

        {deleteRecurrenceModal && (
          <RecurrenceInstanceSwitchModal
            showRecurrenceModal={deleteRecurrenceModal}
            handleRecurrenceAction={this.handleDeleteRecurrenceModalAction}
            title="Wiederkehrenden Auftrag löschen"
            body="Möchtest du auch alle zukünftige Ereignisse löschen oder nur dieses Ereignis?"
          />
        )}

        {deleteModal && (
          <DeleteModal
            showModal={deleteModal}
            handleAction={this.handleDeleteAction}
            title="Auftrag löschen"
            body={
              <div>
                <div>
                  Möchtest du diesen Auftrag wirklich endgültig löschen?
                </div>
                <div>Diese Aktion kann nicht widerrufen werden.</div>
              </div>
            }
          />
        )}
        <form className={cx("order-form")}>
          <div className="additional-info">
            {/* Switch für Sendung/Fester Stopp */}
            <div className="form-inline">
              <Checkbox
                radio
                className={cx("switch", "left", "checkboxTop")}
                disabled={!editor}
                name="deliveryType"
                label="Sendung"
                checked={isFixedStop === false}
                onChange={this.handleDeliveryChanged}
              />
              <Checkbox
                radio
                className={cx("switch", "checkboxTop")}
                disabled={!editor}
                name="deliveryType"
                label="Fester Stopp"
                checked={isFixedStop === true}
                onChange={this.handleFixedStopChanged}
                tabIndex="0"
              />
              {/* extrainfo abhängig von sendung/fester Stopp */}
              <div className={cx("checkbox_wrapper")}>
                {!isFixedStop && (
                  <span>
                    <Checkbox
                      label="mit Abholung"
                      checked={!isPickedUp && !isDirect}
                      disabled={!editor}
                      tabIndex="0"
                      onChange={this.handlePickupChanged}
                    />
                    &nbsp; &nbsp;
                    <Checkbox
                      label="Direktfahrt"
                      checked={isDirect}
                      disabled={!editor}
                      tabIndex="0"
                      onChange={this.handleDirectChanged}
                    />
                  </span>
                )}
                {isFixedStop && (
                  <Checkbox
                    toggle
                    className={cx(
                      "repetitive_switch",
                      !editor ? "disabled" : ""
                    )}
                    checked={isRepetitive}
                    onChange={this.handleRepetitiveChanged}
                    label="wiederkehrend"
                    tabIndex="0"
                    disabled={!editor}
                  />
                )}
              </div>
            </div>
            {/* Wiederholungsmaske mit Datumsspanne */}
            {showRecurrenceFields && (
              <React.Fragment>
                <div className={cx("repetition-wrapper")}>
                  {/* <Dropdown placeholder="wiederholung" selection defaultValue={ "week" } options={ stateOptions } className={ cx("repetition-dropdown") } /> */}
                  <span
                    className={cx(
                      "repetition-dropdown-fixed",
                      !editor ? "disabled" : "enabled"
                    )}
                  >
                    Jede Woche
                  </span>
                  <div
                    className={cx("weekdays", !editor ? "disabled" : "enabled")}
                  >
                    <div
                      role="button"
                      className={cx(
                        "day_chooser_label",
                        checkboxes[1] > 0 ? "day_chooser_label_checked" : ""
                      )}
                      data-id="1"
                      data-active={checkboxes[1]}
                      tabIndex="0"
                      onClick={this.handleDayChange}
                      onKeyPress={this.handleWeekDayKeyPress}
                    >
                      Mo
                    </div>
                    <div
                      role="button"
                      className={cx(
                        "day_chooser_label",
                        checkboxes[2] > 0 ? "day_chooser_label_checked" : ""
                      )}
                      data-id="2"
                      data-active={checkboxes[2]}
                      tabIndex="0"
                      onClick={this.handleDayChange}
                      onKeyPress={this.handleWeekDayKeyPress}
                    >
                      Di
                    </div>
                    <div
                      role="button"
                      className={cx(
                        "day_chooser_label",
                        checkboxes[3] > 0 ? "day_chooser_label_checked" : ""
                      )}
                      data-id="3"
                      data-active={checkboxes[3]}
                      tabIndex="0"
                      onClick={this.handleDayChange}
                      onKeyPress={this.handleWeekDayKeyPress}
                    >
                      Mi
                    </div>
                    <div
                      role="button"
                      className={cx(
                        "day_chooser_label",
                        checkboxes[4] > 0 ? "day_chooser_label_checked" : ""
                      )}
                      data-id="4"
                      data-active={checkboxes[4]}
                      tabIndex="0"
                      onClick={this.handleDayChange}
                      onKeyPress={this.handleWeekDayKeyPress}
                    >
                      Do
                    </div>
                    <div
                      role="button"
                      className={cx(
                        "day_chooser_label",
                        checkboxes[5] > 0 ? "day_chooser_label_checked" : ""
                      )}
                      data-id="5"
                      data-active={checkboxes[5]}
                      tabIndex="0"
                      onClick={this.handleDayChange}
                      onKeyPress={this.handleWeekDayKeyPress}
                    >
                      Fr
                    </div>
                    <div
                      role="button"
                      className={cx(
                        "day_chooser_label",
                        checkboxes[6] > 0 ? "day_chooser_label_checked" : ""
                      )}
                      data-id="6"
                      data-active={checkboxes[6]}
                      tabIndex="0"
                      onClick={this.handleDayChange}
                      onKeyPress={this.handleWeekDayKeyPress}
                    >
                      Sa
                    </div>
                    <div
                      role="button"
                      className={cx(
                        "day_chooser_label",
                        checkboxes[0] > 0 ? "day_chooser_label_checked" : ""
                      )}
                      data-id="0"
                      data-active={checkboxes[0]}
                      tabIndex="0"
                      onClick={this.handleDayChange}
                      onKeyPress={this.handleWeekDayKeyPress}
                    >
                      So
                    </div>
                  </div>
                </div>
                <div className={cx("timeslot-single", "timeslot-hover")}>
                  <FloatingDatePicker
                    startMoment={recurrenceStartDate}
                    startMomentChanged={e =>
                      this.changeDate("recurrenceStartDate", e)
                    }
                    startLabel="Startdatum"
                    startMinDate={startMinDate}
                    endMoment={recurrenceEndDate}
                    endMomentChanged={e =>
                      this.changeDate("recurrenceEndDate", e)
                    }
                    endLabel="Enddatum"
                    dateRange
                    editMode={editor}
                  />
                </div>
              </React.Fragment>
            )}
            {!isFixedStop && (
              <div style={{ paddingTop: isFixedStop ? "11px" : "10px" }}>
                {!editor && (shipment.company_info || "").length > 0 ? (
                  <div className="label-custom">Auftragsbeschreibung</div>
                ) : null}
                <textarea
                  className={`additional-info-field ${
                    !editor ? "disabled" : ""
                  }`}
                  rows={
                    !editor ? getRowsOfDescription(shipment.company_info) : 3
                  }
                  placeholder="Auftragsbeschreibung"
                  name="company_info"
                  value={shipment.company_info}
                  onChange={this.handleInputItemChanged}
                  disabled={!editor}
                ></textarea>
              </div>
            )}
            {isFixedStop && (
              <div className={`form-group ${cx("meta-container")}`}>
                <FloatingTextField
                  className="top-right-border"
                  name="ext_reference"
                  type="text"
                  floatingLabel="Externe Referenz"
                  value={shipment.ext_reference}
                  onChange={this.handleInputItemChanged}
                  disabled={!editor}
                />
              </div>
            )}
            {!isFixedStop && (
              <Flex className="ext1-ext2-group">
                <div className="form-group">
                  <FloatingTextField
                    name="ext_reference"
                    type="text"
                    floatingLabel="Referenz 1"
                    value={shipment.ext_reference}
                    onChange={this.handleInputItemChanged}
                    disabled={!editor}
                  />
                </div>
                <div className="form-group">
                  <FloatingTextField
                    name="ext_additional_reference"
                    type="text"
                    floatingLabel="Referenz 2"
                    value={shipment.ext_additional_reference}
                    onChange={this.handleInputItemChanged}
                    disabled={!editor}
                  />
                </div>
              </Flex>
            )}
          </div>

          {/* Logik für Sender, wird nur angezeigt bei einer Sendung */}
          {!isFixedStop && (
            <div className={cx("sender-address", "sender-address-plain")}>
              <div className="form-group">
                <label htmlFor="sender_company">Absender</label>
              </div>
              <div className={`form-group ${cx("name-and-number-group")}`}>
                <FloatingTextField
                  className="form-control top-left-border"
                  name="sender_name"
                  type="text"
                  floatingLabel="Name"
                  value={shipment.sender_name}
                  onChange={this.handleInputItemChanged}
                  disabled={!editor}
                />
                <div className={cx("dropdown-container")}>
                  <Dropdown
                    placeholder="Ländercode"
                    name="sender_area_code"
                    selection
                    defaultValue="0049"
                    options={countryCode}
                    className={cx("country-dropdown")}
                    onChange={this.changeCounryCode}
                    disabled={!editor}
                  />
                  <div className={cx("hiddenlabel-sup")}>Land</div>
                </div>
                <FloatingTextField
                  className="top-right-border"
                  name="sender_phone"
                  type="number"
                  floatingLabel="Mobilnummer"
                  value={shipment.sender_phone}
                  onChange={this.handleInputItemChanged}
                  disabled={!editor}
                />
                <ShipmentAddressComplete
                  addressRel="sender_address"
                  className="form-control"
                  addressContext="company"
                  placeholder="Firma"
                  name="sender_company"
                  value={shipment.sender_company}
                  onSelected={this.onTCAddressSelected}
                  onChange={this.handleInputItemChanged}
                  disabled={!editor}
                />
              </div>
              <div style={{ position: "relative" }}>
                <PlacesAutocomplete
                  autocorrect="off"
                  autoComplete="off"
                  width="100%"
                  displayMode={!editor}
                  selectedItem={this.parseAddressForAutocomplete("sender")}
                  onChange={address =>
                    this.onAddressSelected("sender", address)
                  }
                />
              </div>
              {(!isPickedUp || isDirect || isFixedStop) && (
                <div className={cx("timeslot", "timeslot-hover")}>
                  <TimeSlotSlider
                    label="Zeitfenster für Abholung"
                    startInitTime={pickupTimeFrameStart}
                    endInitTime={pickupTimeFrameEnd}
                    startTime={pickupStartTime}
                    endTime={pickupEndTime}
                    margin={120}
                    step={60}
                    isActive={pickupTimeFrameActive}
                    onChange={this.onPickupSliderChange}
                    activate={this.onPickupSliderActive}
                    disable={this.onPickupSliderDisable}
                    wrapperClass={timeSlotSliderWrapperClass}
                    checkActivate={this.onPickupCheckActivate}
                    disabled={!editor}
                  />
                  {!isRepetitive && (
                    <FloatingDatePicker
                      startMoment={pickupStartDate}
                      startMomentChanged={e =>
                        this.changeDate("pickupStartDate", e)
                      }
                      startLabel="Fälligkeitsdatum"
                      endMoment={pickupEndDate}
                      endMomentChanged={e =>
                        this.changeDate("pickupEndDate", e)
                      }
                      endLabel="Spätestens bis"
                      editMode={editor}
                    />
                  )}
                </div>
              )}
            </div>
          )}

          {/* Empfänger - gibt es immer */}
          <div className={cx("recipient-address", "recipient-address-plain")}>
            <div className="form-group">
              <label>{isFixedStop ? "Stopp" : "Empfänger"}</label>
            </div>
            <div className={`form-group ${cx("name-and-number-group")}`}>
              <FloatingTextField
                className="form-control top-left-border"
                name="recipient_name"
                type="text"
                floatingLabel="Name"
                value={shipment.recipient_name}
                onChange={this.handleInputItemChanged}
                disabled={!editor}
              />
              <div className={cx("dropdown-container")}>
                <Dropdown
                  placeholder="Ländercode"
                  name="recipient_area_code"
                  selection
                  defaultValue="0049"
                  options={countryCode}
                  className={cx("country-dropdown")}
                  onChange={this.changeCounryCode}
                  disabled={!editor}
                />
                <div className={cx("hiddenlabel-sup")}>Land</div>
              </div>
              <FloatingTextField
                className="top-right-border"
                name="recipient_email"
                type="number"
                floatingLabel="Mobilnummer"
                value={shipment.recipient_email}
                onChange={this.handleInputItemChanged}
                disabled={!editor}
              />
              <ShipmentAddressComplete
                addressRel="recipient_address"
                className="form-control"
                addressContext="company"
                placeholder="Firma"
                name="recipient_company"
                value={shipment.recipient_company}
                onSelected={this.onTCAddressSelected}
                onChange={this.handleInputItemChanged}
                disabled={!editor}
              />
            </div>
            <div style={{ position: "relative" }}>
              <PlacesAutocomplete
                autocorrect="off"
                autoComplete="off"
                width="100%"
                displayMode={!editor}
                selectedItem={this.parseAddressForAutocomplete("recipient")}
                onChange={address =>
                  this.onAddressSelected("recipient", address)
                }
              />
            </div>
            <div className={cx("timeslot", "timeslot-hover")}>
              <TimeSlotSlider
                label={
                  isFixedStop
                    ? "Zeitfenster für Stopp"
                    : "Zeitfenster für Zustellung"
                }
                startInitTime={deliveryTimeFrameStart}
                endInitTime={deliveryTimeFrameEnd}
                startTime={deliveryStartTime}
                endTime={deliveryEndTime}
                margin={15}
                step={5}
                isActive={deliveryTimeFrameActive}
                onChange={this.onDeliverySliderChange}
                activate={this.onDeliverySliderActive}
                disable={this.onDeliverySliderDisable}
                wrapperClass={timeSlotSliderWrapperClass}
                checkActivate={this.onDeliveryCheckActivate}
                disabled={!editor}
              />

              {!(isRepetitive && !changeRecurrenceInstance && editor) && (
                <FloatingDatePicker
                  startMoment={deliveryStartDate}
                  startMomentChanged={e =>
                    this.changeDate("deliveryStartDate", e)
                  }
                  startLabel="Fälligkeitsdatum"
                  endMoment={deliveryEndDate}
                  endMomentChanged={e => this.changeDate("deliveryEndDate", e)}
                  endLabel="Spätestens bis"
                  editMode={editor}
                />
              )}
            </div>
          </div>

          {/* Footer buttons */}
          <div className="shipment-form-footer modal-footer">
            {editMode && !newEditMode && (
              <React.Fragment>
                <Button onClick={this.onAbort}>Abbrechen</Button>
                <Button color="success" onClick={this.onSubmit}>
                  Auftrag anlegen
                </Button>
              </React.Fragment>
            )}
            {!editMode && newEditMode && (
              <React.Fragment>
                <Button onClick={this.onAbort}>Abbrechen</Button>
                <Button color="success" onClick={this.onSubmit}>
                  Speichern
                </Button>
              </React.Fragment>
            )}
            {!editMode && !newEditMode && (
              <React.Fragment>
                <Button onClick={this.onEdit}>Bearbeiten</Button>
                {/* If status not in process show delete button */}
                {(shipment.status_id <= 2 || shipment.status_id >= 21) && (
                  <Fragment>
                    {/* eslint-disable  */}
                    <div className={cx("textlink")} onClick={this.onDelete}>
                      Löschen
                    </div>
                    {/* eslint-enable  */}
                  </Fragment>
                )}
                <Button color="success" onClick={this.onAbort}>
                  Schließen
                </Button>
              </React.Fragment>
            )}
          </div>
        </form>
      </Fragment>
    );
  }
}

function mapStateToProps({ auth }) {
  return {
    token: auth.token
  };
}

export default connect(mapStateToProps)(CreateEditShipmentForm);
