import { call, put, takeLatest, select } from "redux-saga/effects";
import ApiService from "../../service/ApiService";
import { FETCH_DRIVER_REQUEST, FETCH_DRIVER, DRIVERS_LOCATIONS } from "./index";

function* fetchDrivers({ payload }) {
  const token = yield select(state => state.auth.token);
  try {
    const drivers = yield call(ApiService.getDrivers, token, payload.filter);
    yield put({
      type: FETCH_DRIVER,
      payload: drivers
    });
    // load locations
    const { locations = [] } = yield call(
      ApiService.getDriversLocations,
      token
    );
    yield put({
      type: DRIVERS_LOCATIONS,
      locations
    });
  } catch (error) {
    yield put({
      type: FETCH_DRIVER,
      payload: error,
      error: true
    });
  }
}

export default function* loadDriversSaga() {
  yield takeLatest(FETCH_DRIVER_REQUEST, fetchDrivers);
}
