// Deprecated file.
/* eslint-disable */
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classNames from "classnames/bind";
import * as shipmentAction from "state/shipment/shipmentAction";
import DeleteModal from "components/element/overlay/DeleteModal";
import { DropdownButton } from "../element/dropdown";
import Address from "../address/Address";
import AssignmentLabel from "./AssignmentLabel";
import DirectAddress from "../address/DirectAddress";
import AddressService from "../../service/AddressService";
import ShipmentService from "../../service/ShipmentService";
import AppointmentRow from "./ShipmentItemAppointment";
import styleNames from "./shipmentItem.local.css";

const DELIVERY = "Zustellung";
const PICKUP = "Abholung";
const DIRECT = "Direktfahrt";
const FIXEDSTOPP = "Fester Stopp";

const cx = classNames.bind(styleNames);

class ShipmentItem extends Component {
  static propTypes = {
    status: PropTypes.shape({}).isRequired,
    shipment: PropTypes.object.isRequired,
    selected: PropTypes.bool,
    handleShipmentSelected: PropTypes.func.isRequired,
    handleShipmentAction: PropTypes.func.isRequired,
    handleShipmentKeyEvents: PropTypes.func,
    handleOutsideSelect: PropTypes.func,
    /** dispatch: delete shipment assignment by id */
    deleteAssignment: PropTypes.func.isRequired,
    /** dispatch: show new progress modal to change status */
    showNewProgressModal: PropTypes.func.isRequired
  };

  state = {
    selected: false,
    hovering: false,
    confirmDeleteAssignment: false
  };

  componentWillReceiveProps(nextProps) {
    const { selected } = nextProps;

    this.handleOutsideSelection(selected);
  }

  handleOutsideSelection(selected) {
    const { handleOutsideSelect } = this.props;

    if (selected !== this.state.selected) {
      this.setState({ selected });
      if (selected) {
        handleOutsideSelect(this.checkbox);
      }
    }
  }

  handleSelected = e => {
    if (e.target.tagName === "SPAN" || this.state.showShipmentModal) {
      return;
    }

    const { handleShipmentSelected, shipment } = this.props;
    const selected = !this.state.selected;
    // only change event on checkbox
    // or click event on anything else should change selection state
    if (e.type === "change" || e.target.getAttribute("type") !== "checkbox") {
      this.setState({ selected });
      handleShipmentSelected(shipment.trackcode, selected);

      // trigger focus on checkbox, so that keyboard navigation works
      // from selected shipment
      if (selected) {
        this.checkbox.focus();
      }
    }
  };

  handleOnHover = () => {
    const { hovering } = this.state;

    if (!hovering) {
      this.setState({ hovering: true });
    }
  };

  handleOnLeave = () => {
    const { hovering } = this.state;

    if (hovering) {
      this.setState({ hovering: false });
    }
  };

  handleKeyDown = e => {
    if (this.state.showShipmentModal) {
      return;
    }
    const { handleShipmentKeyEvents } = this.props;
    handleShipmentKeyEvents(e);
  };

  handleEditAction = e => {
    e.preventDefault();
    e.stopPropagation();
    const { handleShipmentAction, shipment } = this.props;
    handleShipmentAction("edit", shipment);
  };

  handleTrackingAction = e => {
    const { history } = this.props;
    e.preventDefault();
    e.stopPropagation();
    const { shipment } = this.props;
    history.push(`/track/${shipment.trackcode}`);
  };

  /**
   * Show delete modal
   * @param {object} e event
   */
  handleDeleteAssignment = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ confirmDeleteAssignment: true });
  };

  /**
   * Delete assignment or cancel it
   * @param {string} action
   */
  handleDeleteAssignmentAction = action => {
    const {
      shipment: { id },
      deleteAssignment
    } = this.props;
    this.setState({ confirmDeleteAssignment: false });
    if (action === "DELETE") {
      deleteAssignment(id);
    }
  };

  /**
   * Call openStatusEdit function from SchedulingList component to open status edit modal.
   */
  openStatusEditAction = e => {
    e.stopPropagation();
    const { showNewProgressModal } = this.props;
    const {
      shipment: { id }
    } = this.props;
    showNewProgressModal(id);
  };

  render() {
    const {
      shipment,
      shipment: { isDirect },
      handleShipmentAction,
      handleOutsideSelect,
      handleShipmentKeyEvents,
      handleShipmentSelected,
      deleteAssignment,
      status: statusEntities,
      ...otherProps
    } = this.props;
    const { hovering, selected, confirmDeleteAssignment } = this.state;

    const isAssigned =
      shipment.responsible_member_id > 0 || shipment.responsible_tour_id > 0;
    const isDirectBool = isDirect === 1;
    const isDelivery = shipment.isPickedUp;
    const isRecurring = shipment.is_recurring;
    const { isFixedStop } = shipment;
    const showSender = isDelivery === 0;
    const showRecipient = isDelivery === 1;
    const senderAddress = AddressService.getSendeFromShipment(shipment);
    const recipientAddress = AddressService.getRecipientFromShipment(shipment);
    const deliveryAppointment = ShipmentService.getDeliveryAppointment(
      shipment
    );
    const pickupAppointment = ShipmentService.getPickupAppointment(shipment);
    const status = statusEntities[shipment.status_id];

    /* eslint-disable jsx-a11y/href-no-hash */
    return (
      <Fragment>
        <DeleteModal
          showModal={confirmDeleteAssignment}
          title="Auftragszuweisung entfernen"
          confirmText="Entfernen"
          handleAction={this.handleDeleteAssignmentAction}
          body={
            <div>Möchtest du die Auftragszuweisung wirklich entfernen?</div>
          }
        />
        {/* eslint-disable jsx-a11y/interactive-supports-focus */}
        {/* eslint-disable jsx-a11y/mouse-events-have-key-events */}
        <div
          role="button"
          key={`shipment-item-${
            shipment.trackcode ? shipment.trackcode : shipment.socket_id
          }`}
          className={cx("shipment", { selected })}
          onClick={this.handleSelected}
          onMouseOver={this.handleOnHover}
          onMouseLeave={this.handleOnLeave}
          onKeyDown={this.handleKeyDown}
          {...otherProps}
        >
          {/* eslint-enable jsx-a11y/interactive-supports-focus */}
          {/* eslint-enable jsx-a11y/mouse-events-have-key-events */}
          <div className={cx("shipment-selection")}>
            <input
              id={`shipment-checkbox-${shipment.trackcode}`}
              type="checkbox"
              checked={selected}
              className="js-shipment-selection"
              onChange={this.handleSelected}
              ref={c => {
                this.checkbox = c;
              }}
            />
          </div>
          <div className={cx("shipment-category")}>
            <div className={cx("text-overflow-visible")}>
              {/* eslint-disable no-nested-ternary */}
              {isFixedStop
                ? FIXEDSTOPP
                : isDirectBool
                ? DIRECT
                : isDelivery
                ? DELIVERY
                : PICKUP}
              {shipment.vehicle_type && (
                <span className="text-muted">
                  <br />#{shipment.vehicle_type}{" "}
                </span>
              )}
              {/* eslint-enable no-nested-ternary */}
            </div>
          </div>
          {isDirectBool && (
            <div className={cx("shipment-address")}>
              <DirectAddress
                senderAddress={senderAddress}
                recipientAddress={recipientAddress}
                shortenName
                addLocationIcon
                isPickedUp
              />
            </div>
          )}
          {!isDirectBool && (
            <React.Fragment>
              {showSender && (
                <div className={cx("shipment-sender")}>
                  <Address
                    address={senderAddress}
                    shortenName
                    addLocationIcon
                  />
                </div>
              )}
              {showRecipient && (
                <div className={cx("shipment-recipient")}>
                  <Address
                    address={recipientAddress}
                    shortenName
                    addLocationIcon
                  />
                </div>
              )}
            </React.Fragment>
          )}
          <div className={cx("shipment-notes")}>
            {isDirectBool && (
              <AppointmentRow
                showRecurring={isRecurring}
                appointment={pickupAppointment}
                address={senderAddress}
              />
            )}
            <AppointmentRow
              showRecurring={!isDirectBool && isRecurring}
              muteClock={isDirectBool}
              appointment={
                isDirectBool || isDelivery
                  ? deliveryAppointment
                  : pickupAppointment
              }
              address={recipientAddress}
            />
          </div>
          <div className={cx("shipment-responsible")}>
            <AssignmentLabel shipment={shipment} />
          </div>
          <div className={cx("shipment-status")}>
            <span className={`label label-default ${cx("status-label")}`}>
              {status ? status.label : ""}
            </span>
          </div>
          {hovering && (
            <div className={cx("shipment-action", "pull-right")}>
              <DropdownButton
                id="moreShipmentOptions"
                className={cx("more-button")}
                pullRight
                noCaret
                iconRight="dots-vertical"
                size="xs"
                menuHeight={65}
              >
                <li>
                  <a href="#" onClick={this.handleEditAction}>
                    Auftragsdetails anzeigen
                  </a>
                </li>
                <li>
                  <a href="#" onClick={this.openStatusEditAction}>
                    Auftragsstatus hinzufügen
                  </a>
                </li>
                <li>
                  <a href="#" onClick={this.handleTrackingAction}>
                    Sendung verfolgen
                  </a>
                </li>
                {isAssigned && (
                  <li>
                    <a href="#" onClick={this.handleDeleteAssignment}>
                      Auftragszuweisung entfernen
                    </a>
                  </li>
                )}
              </DropdownButton>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  entities: {
    shipmentStatus: { entities: status }
  }
}) => ({
  status
});

const mapDispatchToProps = {
  deleteAssignment: shipmentAction.deleteShipmentAssignment,
  showNewProgressModal: shipmentAction.showNewProgressModal
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ShipmentItem)
);
