import { LOGISTICCOMPANY_PREPARE_CHANGE } from "state/auth";
import {
  STATUS_GET_REQUEST,
  STATUS_GET_RECEIVE,
  STATUS_GET_ERROR,
  STATUS_AVAILABLE_GET_RECEIVE,
  STATUS_GROUPS_GET_RECEIVE
} from "./constants";

const DEFAULT_STATUS_IDS = [
  1, // created
  11, // data_update
  20, // damage_documentation
  21, // imported
  22, // pending
  23, // assigned
  24, // rejected
  25 // unassigned
];

const initialState = {
  entities: {
    // 6: { id: 6, label: "Zugestellt", ... }
  },
  availableIds: DEFAULT_STATUS_IDS,
  groups: {
    // 3: { id: 3, name: "Offene Aufträge", status: [1, 7, ...], ... }
  },
  loaded: false,
  errors: {}
};

const arrayToObject = array =>
  array.reduce((acc, curr) => {
    acc[curr.id] = curr;
    return acc;
  }, {});

export default function membersReducer(state = initialState, action = {}) {
  const { type, payload, error } = action;

  switch (type) {
    case STATUS_GET_REQUEST: {
      return {
        ...state,
        loaded: false,
        error: {}
      };
    }

    case STATUS_GET_RECEIVE: {
      const entities = arrayToObject(payload);
      return {
        ...state,
        entities,
        loaded: true,
        errors: {}
      };
    }

    case STATUS_AVAILABLE_GET_RECEIVE: {
      return {
        ...state,
        availableIds: [...DEFAULT_STATUS_IDS, ...payload]
      };
    }

    case STATUS_GROUPS_GET_RECEIVE: {
      const groups = arrayToObject(payload);
      return {
        ...state,
        groups
      };
    }

    case STATUS_GET_ERROR: {
      return {
        ...state,
        loaded: false,
        errors: error
      };
    }

    case LOGISTICCOMPANY_PREPARE_CHANGE:
      return initialState;

    default:
      return state;
  }
}
