import React from "react";

const NotFound = () => (
  <div className="container">
    <h1>404!</h1>
    <p>Oops, no such page exists.</p>
  </div>
);

export default NotFound;
