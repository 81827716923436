import ApiService from "service/ApiService";
import {
  TOURS_GET_REQUEST,
  TOURS_GET_RECEIVE,
  TOURS_GET_ERROR,
  TOUR_CREATE_REQUEST,
  TOUR_CREATE_RECEIVE,
  TOUR_CREATE_ERROR,
  TOUR_UPDATE_REQUEST,
  TOUR_UPDATE_RECEIVE,
  TOUR_UPDATE_ERROR,
  TOUR_DELETE_REQUEST,
  TOUR_DELETE_RECEIVE,
  TOUR_DELETE_ERROR
} from "./constants";

/**
 * Get all tours.
 */
export const getTours = () => async (dispatch, getState) => {
  const onSuccess = payload => {
    dispatch({ type: TOURS_GET_RECEIVE, payload });
    return payload;
  };
  dispatch({ type: TOURS_GET_REQUEST });
  try {
    const {
      auth: { token }
    } = getState();
    const { tour } = await ApiService.getTour(token);
    // the api returns an empty list as "null"
    return onSuccess(tour || []);
  } catch (error) {
    dispatch({ type: TOURS_GET_ERROR, error });
    return null;
  }
};

/**
 * Create member.
 * @param {object} body
 */
export const createTour = body => async (dispatch, getState) => {
  const onSuccess = payload => {
    dispatch({ type: TOUR_CREATE_RECEIVE, payload });
    return payload;
  };
  dispatch({ type: TOUR_CREATE_REQUEST });
  try {
    const {
      auth: { token }
    } = getState();
    const { tour = {} } = await ApiService.createTour(token, body);
    return onSuccess(tour);
  } catch (error) {
    dispatch({ type: TOUR_CREATE_ERROR, error });
    return null;
  }
};

/**
 * Update tour.
 * @param {object} body
 */
export const updateTour = body => async (dispatch, getState) => {
  const onSuccess = payload => {
    dispatch({ type: TOUR_UPDATE_RECEIVE, payload });
    return payload;
  };
  dispatch({ type: TOUR_UPDATE_REQUEST });
  try {
    const {
      auth: { token }
    } = getState();
    const { tour = {} } = await ApiService.updateTour(token, body);
    return onSuccess(tour);
  } catch (error) {
    dispatch({ type: TOUR_UPDATE_ERROR, error });
    return null;
  }
};

/**
 * Delete tour.
 * @param {object} body
 */
export const deleteTour = body => async (dispatch, getState) => {
  const onSuccess = payload => {
    dispatch({ type: TOUR_DELETE_RECEIVE, payload });
    return payload;
  };
  dispatch({ type: TOUR_DELETE_REQUEST });
  try {
    const {
      auth: { token }
    } = getState();
    const { tour = {} } = await ApiService.deleteTour(token, body);
    return onSuccess(tour);
  } catch (error) {
    dispatch({ type: TOUR_DELETE_ERROR, error });
    return null;
  }
};
