import {
  MAP_SET,
  MAP_SHOW,
  MAP_HIDE,
  MAP_CLEAR,
  MAP_CLEAR_OPTIONS
} from "./mapConstants";

/**
 * Set Pins, Drivers, Directions with a scope on map
 * @param {string} scope Define a scope to easily show/hide/reset elements on map
 * @param {Object} elements
 * @param {Object[]} elements.pins
 * @param {Object[]} elements.drivers
 */
const set = (
  scope,
  { pins = [], drivers = [], directions = [], polygons = [] },
  { fitBounds = false, zoom } = {}
) => ({
  type: MAP_SET,
  scope,
  options: {
    fitBounds,
    zoom
  },
  pins,
  drivers,
  directions,
  polygons
});

const setDriversOptions = options => set("drivers", {}, options);

/**
 * Show a scope
 * @param {string} scope
 */
const show = scope => ({
  type: MAP_SHOW,
  scope
});

/**
 * Hide a scope
 * @param {string} scope
 */
const hide = scope => ({
  type: MAP_HIDE,
  scope
});

/**
 * Clear all information in a scope
 * @param {string} scope
 */
const clear = scope => ({
  type: MAP_CLEAR,
  scope
});

const clearOptions = scope => ({
  type: MAP_CLEAR_OPTIONS,
  scope
});

export default {
  set,
  setDriversOptions,
  show,
  hide,
  clear,
  clearOptions
};
