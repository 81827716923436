import React from "react";
import styled, { keyframes } from "styled-components";

const Loading = () => (
  <Wrapper>
    <Content>
      <h2>Inhalte werden geladen...</h2>
    </Content>
  </Wrapper>
);

export default Loading;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const rotate360 = keyframes`
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.4;
  }
`;

const Content = styled.div`
  animation: ${rotate360} 3s linear infinite;
`;
