// TODO: REWRITE!
/* eslint-disable react/no-find-dom-node */
/* eslint-disable react/no-string-refs */
import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactDom from "react-dom";
import { connect } from "react-redux";
import classNames from "classnames/bind";
import UserService from "../../service/UserService";
import "./messageBox.css";
import MessageBoxItemHeader from "./MessageBoxItemHeader";
import MessageBoxItemFooter from "./MessageBoxItemFooter";
import MessageItem from "./MessageItem";
import styleNames from "./messageBoxItem.local.css";
import { loadDialog, createMessage } from "../../state/dialog/dialogAction";

const cx = classNames.bind(styleNames);

class MessageBoxItem extends Component {
  static propTypes = {
    interlocutor: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    messages: PropTypes.array.isRequired,
    token: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired
  };

  static needToLoadMessagesViaApi(messages) {
    if (messages.length === 0) {
      return true;
    }
    for (let i = 0; i < messages.length; i += 1) {
      if (messages[i].id) {
        return false;
      }
    }
    return true;
  }

  componentDidMount() {
    const { interlocutor, messages } = this.props;

    if (MessageBoxItem.needToLoadMessagesViaApi(messages)) {
      this.props.dispatch(
        loadDialog(this.props.token, {
          member_id: UserService.getId(interlocutor).toString()
        })
      );
    }
  }

  componentDidUpdate() {
    const node = ReactDom.findDOMNode(this.refs["chat-messages"]);
    node.scrollTop = node.scrollHeight;
  }

  onCloseClicked = () => {
    const { interlocutor, onClose } = this.props;
    onClose(interlocutor);
  };

  onSubmitted = textMessage => {
    const { interlocutor } = this.props;
    this.props.dispatch(
      createMessage(
        this.props.token,
        UserService.getId(interlocutor).toString(),
        textMessage
      )
    );
  };

  render() {
    const { interlocutor, messages } = this.props;
    // TODO rendering (text state)!
    // console.log("interlocutor", interlocutor);
    // console.log("messages", messages);

    return (
      <div
        id={`messagebox-${interlocutor.id}`}
        className={cx("messagebox", "pull-right")}
      >
        {/* DIRECT CHAT PRIMARY */}
        <div className="box box-primary direct-chat direct-chat-primary">
          <MessageBoxItemHeader
            interlocutor={interlocutor}
            onClose={this.onCloseClicked}
          />
          {/* /.box-header */}
          <div className="box-body">
            {/* Conversations are loaded here */}
            <div ref="chat-messages" className="direct-chat-messages">
              {/* Message. Default to the left */}
              {messages &&
                messages.map(message => (
                  <MessageItem
                    key={message.socket_id}
                    message={message}
                    interlocutor={interlocutor}
                  />
                ))}
              {/* /.direct-chat-msg */}
            </div>
            {/* /.direct-chat-messages */}
          </div>
          {/* /.box-body */}
          <MessageBoxItemFooter onSubmit={this.onSubmitted} />
          {/* /.box-footer */}
        </div>
        {/* /.direct-chat */}
      </div>
    );
  }
}

function mapStateToProps({ auth }) {
  return {
    token: auth.token
  };
}

export default connect(mapStateToProps)(MessageBoxItem);
