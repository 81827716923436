import { fork, all } from "redux-saga/effects";
import { mapReducer } from "features/map";
import shipmentReducerV2 from "features/shipment/state/reducer";
import folderReducer from "features/folder/state/reducer";

import entityReducer from "./entities";

import authSaga from "./auth/authSaga";
import authReducer from "./auth/authReducer";
import shipmentSaga from "./shipment/shipmentSaga";
import shipmentReducer from "./shipment/shipmentReducer";

import driverSaga from "./driver/driverSaga";
import driverReducer from "./driver/driverReducer";

import { loadAddressSaga, createAddressSaga } from "./address/addressSaga";
import addressReducer from "./address/addressReducer";

import statusSaga from "./status/statusSaga";
import statusReducer from "./status/statusReducer";

import trackSaga from "./track/trackSaga";
import trackReducer from "./track/trackReducer";

import { loadDialogSaga, createMessageSaga } from "./dialog/dialogSaga";
import dialogReducer from "./dialog/dialogReducer";

import createImportSaga from "./import/importSaga";
import importReducer from "./import/importReducer";

import { createJobsSaga, loadJobsSaga } from "./job/jobSaga";
import jobReducer from "./job/jobReducer";

import { createMappingSaga, loadMappingSaga } from "./mapping/mappingSaga";
import mappingReducer from "./mapping/mappingReducer";

import locationReducer from "./location/locationReducer";

import externalSaga from "./external/externalSaga";
import externalReducer from "./external/externalReducer";

import groupSaga from "./group/groupSaga";
import groupReducer from "./group/groupReducer";

import { createPasswordSaga, resetPasswordSaga } from "./password/passwordSaga";
import passwordReducer from "./password/passwordReducer";

import {
  readShipmentCronSaga,
  createShipmentCronSaga
} from "./shipmentCron/shipmentCronSaga";
import shipmentCronReducer from "./shipmentCron/shipmentCronReducer";

// TODO: Not a good pattern
export const rootSagaWrapper = store =>
  function* rootSaga() {
    yield all([
      fork(authSaga, store),
      fork(shipmentSaga),
      fork(driverSaga),
      fork(loadAddressSaga),
      fork(createAddressSaga),
      fork(trackSaga),
      fork(groupSaga),
      fork(loadDialogSaga),
      fork(createMessageSaga),
      fork(createJobsSaga),
      fork(loadJobsSaga),
      fork(createMappingSaga),
      fork(loadMappingSaga),
      fork(createImportSaga),
      fork(externalSaga),
      fork(statusSaga),
      fork(readShipmentCronSaga),
      fork(createShipmentCronSaga),
      fork(createPasswordSaga),
      fork(resetPasswordSaga)
    ]);
  };

export const reducers = {
  auth: authReducer,
  shipment: shipmentReducer,
  shipmentV2: shipmentReducerV2,
  folder: folderReducer,
  driver: driverReducer,
  entities: entityReducer,
  address: addressReducer,
  dialog: dialogReducer,
  track: trackReducer,
  group: groupReducer,
  job: jobReducer,
  mapping: mappingReducer,
  external: externalReducer,
  location: locationReducer,
  imported: importReducer,
  status: statusReducer,
  shipmentCron: shipmentCronReducer,
  password: passwordReducer,
  featureMap: mapReducer
};
