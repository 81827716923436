import React, {
  Fragment,
  useEffect,
  useState,
  useMemo,
  useCallback
} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import ContentLoader from "react-content-loader";
import styled from "styled-components";
import { Flex, Box } from "reflexbox";
import { mapActions, mapUtils } from "features/map";
import { StandardButton, StandardDropdown, Icon } from "@trackcode/ui";
import CI from "@trackcode/ci";
import { ActionBar } from "features/app/components/ActionBar";
import { cleanAndPrepareShipment } from "state/entities/shipments/reducer";
import ApiService from "../../service/ApiService";
import LongAddress from "./LongAddress";
import CreateEditShowShipment from "../../containers/shipment/CreateEditShowShipment";
import { getEnhancedShipmentByTrackcode } from "./state/selectors";
import ProgressTimeline from "./ProgressTimeline";
import ProgressAdd from "./ProgressAdd";

const Container = styled.div`
  flex: 5;
  display: flex;
  min-height: 0px;
`;

const ContainerScroll = styled.div`
  flex: 1;
  overflow-y: auto;
  background: #fff;
  min-width: 650px;
`;

const Element = styled(Flex)`
  padding: 15px;
  box-sizing: border-box;
  border-bottom: 1.5px dotted ${CI.color.gray};
`;

const Column = styled(Box)`
  padding: 15px;
`;

const Headline = styled.h2`
  display: block;
  margin: 0 0 0 10px;
  color: #333333;
  font-size: 14px;
`;

const AlignedIcon = props => (
  // eslint-disable-next-line react/prop-types
  <Icon {...props} style={{ verticalAlign: "middle", ...props.style }} />
);

const Pieces = ({ pieces }) =>
  pieces.map((piece, key) => (
    <Fragment key={key}>
      {piece.barcode_label} &nbsp;&nbsp;
      <span className="label label-success">{piece.translation}</span>
      <br />
    </Fragment>
  ));

Pieces.propTypes = {
  pieces: PropTypes.array.isRequired
};

const PackagingUnits = ({ progress }) => {
  // status id 4 (fetched) and 5 (loaded)
  const loadingProgress = progress
    .filter(scan => scan.status_id === 4 || scan.status_id === 5)
    .sort((a, b) => a.client_datetime.localeCompare(b.client_datetime) * -1)[0];

  if (!loadingProgress) {
    return null;
  }

  const packingUnitsMediaItem = loadingProgress.media.find(
    mediaItem => mediaItem.label === "packing_unit_counter"
  );

  if (!packingUnitsMediaItem) {
    return null;
  }

  return Object.keys(packingUnitsMediaItem.data)
    .map(key => ({
      unit: key,
      counter: packingUnitsMediaItem.data[key]
    }))
    .map(packingUnit => (
      <Fragment>
        {packingUnit.counter}x {packingUnit.unit}
        <br />
      </Fragment>
    ));
};

PackagingUnits.propTypes = {
  progress: PropTypes.array.isRequired
};

const TimelinePlaceholder = props => {
  const random = useMemo(() => Math.random() * (1 - 0.9) + 0.9, []);
  return (
    <div
      style={{ padding: "30px", borderBottom: `1.5px dotted ${CI.color.gray}` }}
    >
      <ContentLoader
        style={{
          width: "100%",
          height: "50px"
        }}
        primaryColor="rgb(238, 238, 238)"
        secondaryColor="rgb(221, 221, 221)"
        {...props}
      >
        <rect x="3" y="0" width={45 * random} height="40" />
        <rect x="88" y="4" width="65" height="30" />
        <rect x="178" y="4" width={75 * random} height="30" />
        <rect x="3" y="95" width={30 * random} height="30" />
      </ContentLoader>
    </div>
  );
};

const InfoLoader = props => (
  <ContentLoader
    height={65}
    width={350}
    primaryColor="rgb(238, 238, 238)"
    secondaryColor="rgb(221, 221, 221)"
    {...props}
  >
    <rect x="3" y="6" width="200" height="14" />
    <rect x="3" y="28" width="160" height="14" />
  </ContentLoader>
);

/**
 * Hook to load tracking information.
 * @param {Object} initial Shipment object
 * @returns {[Object, func, boolean]}
 *
 */
function useGetTracking(initial) {
  const [data, setData] = useState(initial);
  const [loaded, setLoaded] = useState(false);

  const load = useCallback(async (token, trackCode) => {
    try {
      const { tracking } = await ApiService.getTrack(token, trackCode);
      setData(cleanAndPrepareShipment(tracking[0]));
    } catch (err) {
      console.error("Could not load tracking information", err);
    }
    setLoaded(true);
  }, []);
  return [data, load, loaded];
}

const Tracking = ({ dispatch, shipment, token, match, history }) => {
  const [trackedShipment, fetchTrackedShipment, loaded] = useGetTracking(
    shipment
  );
  const [isDetailModalOpen, setDetailModalOpen] = useState(false);
  const {
    params: { code: trackCode }
  } = match;

  useEffect(() => {
    fetchTrackedShipment(token, trackCode);
  }, [fetchTrackedShipment, token, trackCode]);

  if (trackedShipment) {
    const { pins } = mapUtils.shipmentToMapElements(trackedShipment);

    if (pins) {
      dispatch(
        mapActions.set(
          "scheduling",
          { pins },
          {
            fitBounds: true,
            zoom: 15
          }
        )
      );
    }
  }

  // NOTE: Could be handled easier by react-router v4 with render prop.
  const { pathname } = history.location;
  const showProgressAdd = pathname.includes("/progress");
  const basePath = pathname.replace(/(\/progress\/*|\/)+$/, "");

  const masterTrackCode = trackedShipment
    ? trackedShipment.group_reference || shipment.trackcode
    : "...";

  return (
    <Fragment>
      <Helmet title={`Tracking ${masterTrackCode}`} />
      {isDetailModalOpen && (
        <CreateEditShowShipment
          showModal
          shipment={trackedShipment}
          handleClose={() => setDetailModalOpen(false)}
          editMode={false}
          willBeEdited
        />
      )}
      {trackedShipment && (
        <Fragment>
          <ActionBar style={{ backgroundColor: "#f2f2f2" }}>
            <Box>
              <StandardButton
                appearance="subtle"
                onClick={() => history.push("/scheduling")}
                iconBefore={{ name: "angle-left" }}
                spaceAfter={false}
              >
                Zurück
              </StandardButton>
            </Box>
            <div
              style={{
                width: "1px",
                height: "14px",
                margin: "0 4px 0 1px",
                backgroundColor: CI.color.grayDark
              }}
            ></div>
            <Box>
              <Headline>{masterTrackCode}</Headline>
            </Box>
          </ActionBar>

          <Container>
            <ContainerScroll>
              <Element
                style={{ borderBottomStyle: "solid", borderWidth: "1px" }}
              >
                {!trackedShipment.isFixedStop && (
                  <>
                    <Column flex={1}>
                      <h4>Absender</h4>
                      <LongAddress
                        name={trackedShipment.sender.name}
                        address={trackedShipment.sender.address}
                        company={trackedShipment.sender.company}
                        avis={trackedShipment.sender.avis}
                      />
                    </Column>
                    <Column flex={1}>
                      <h4>Empfänger</h4>
                      <LongAddress
                        name={trackedShipment.recipient.name}
                        address={trackedShipment.recipient.address}
                        company={trackedShipment.recipient.company}
                        avis={trackedShipment.recipient.avis}
                      />
                    </Column>
                  </>
                )}
                {trackedShipment.isFixedStop && (
                  <Column flex={1}>
                    <h4>Stopp</h4>
                    <LongAddress
                      name={trackedShipment.recipient.name}
                      address={trackedShipment.recipient.address}
                      company={trackedShipment.recipient.company}
                      avis={trackedShipment.recipient.avis}
                    />
                  </Column>
                )}
                <Column flex={1}>
                  <h4>Weitere Informationen</h4>
                  {!loaded && <InfoLoader />}
                  {loaded && trackedShipment.pieces && (
                    <Pieces pieces={trackedShipment.pieces} />
                  )}
                  {loaded && trackedShipment.progress && (
                    <PackagingUnits progress={trackedShipment.progress} />
                  )}
                  {loaded && trackedShipment.weight && (
                    <span>Gewicht: {trackedShipment.weight} kg</span>
                  )}
                </Column>
                <Column style={{ textAlign: "right" }}>
                  <div style={{ margin: "-6px -6px 0 0" }}>
                    <StandardDropdown
                      placement="bottomRight"
                      renderButton={({ toggle }) => (
                        <StandardButton
                          appearance="subtle"
                          spaceAfter={false}
                          onClick={toggle}
                          style={{
                            padding: "0px 6px 0px 6px"
                          }}
                        >
                          <AlignedIcon name="more-vertical" size="md" />
                        </StandardButton>
                      )}
                    >
                      {Item => [
                        <Item
                          key="showDetails"
                          onClick={() => setDetailModalOpen(true)}
                        >
                          Auftragsdetails anzeigen
                        </Item>,
                        trackedShipment && trackedShipment.status_id === 6 && (
                          <a
                            key="pod"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={ApiService.getPodUrl(
                              token,
                              trackedShipment.shipment_id,
                              trackedShipment.logisticcompany_id
                            )}
                            style={{ color: "#333", textDecoration: "none" }}
                          >
                            <Item>POD ausgeben</Item>
                          </a>
                        )
                      ]}
                    </StandardDropdown>
                  </div>
                </Column>
              </Element>

              <Element>
                {/* add relative to fix Selections */}
                <Column flex={1} style={{ position: "relative" }}>
                  {!showProgressAdd && (
                    <StandardButton
                      onClick={() => history.replace(`${basePath}/progress`)}
                    >
                      Auftragsstatus hinzufügen
                    </StandardButton>
                  )}
                  {showProgressAdd && (
                    <ProgressAdd
                      shipment={trackedShipment}
                      shipmentPieces={trackedShipment.pieces || []}
                      onClose={() => history.replace(basePath)}
                      onSuccess={() => {
                        fetchTrackedShipment(token, trackCode);
                        history.replace(basePath);
                      }}
                    />
                  )}
                </Column>
              </Element>

              {loaded ? (
                <ProgressTimeline progress={trackedShipment.progress} />
              ) : (
                [...Array(2).keys()].map(num => (
                  <TimelinePlaceholder key={num} />
                ))
              )}
            </ContainerScroll>
          </Container>
        </Fragment>
      )}
    </Fragment>
  );
};

Tracking.propTypes = {
  token: PropTypes.string.isRequired,
  shipment: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {
    token: state.auth.token,
    shipment: getEnhancedShipmentByTrackcode(state, ownProps.match.params.code)
  };
};

export default connect(mapStateToProps)(Tracking);
