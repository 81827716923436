import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import thunk from "redux-thunk";
import throttle from "lodash/throttle";
import { LOGOUT } from "state/auth";
import { setApiV2Token, setApiV2LogisticCompany } from "common/apiV2";
import { restServiceMiddleware } from "state/auth/authUtils";
import { loadState, saveState, removeState } from "./persistState";
import apiErrorMiddleware from "./apiErrorMiddleware";
import { reducers, rootSagaWrapper } from "../state";
import RestService from "../service/RestService";

// add a key to persist data
const persistWhitelist = ["auth"];

// ability to place middleware for development only
const middlewareDev = process.env.NODE_ENV === "development" ? [] : [];
const middleware = [...middlewareDev, apiErrorMiddleware];

// Redux DevTools Extension (available for Chrome and Firefox)
// https://github.com/zalmoxisus/redux-devtools-extension
// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/**
 * Configure store with reducers, initialState, sagas.
 *
 * @returns {object} store
 */
export default function() {
  const appReducer = combineReducers(reducers);

  // see https://stackoverflow.com/a/35641992
  const rootReducer = (state, action) => {
    if (action.type === LOGOUT) {
      // eslint-disable-next-line no-param-reassign
      state = undefined;
      // remove state in local storage
      removeState();
    }
    return appReducer(state, action);
  };

  const state = loadState();
  if (typeof state === "object" && state.auth) {
    const { logisticCompanyId, token } = state.auth;

    // overwrite/register middleware to place `X-Logisticcompany-ID` in header
    // for every request
    if (logisticCompanyId) {
      RestService.registerMiddleware(
        "logisticCompany",
        restServiceMiddleware({ logisticCompanyId })
      );
    }

    // set token and logisticCompany headers for API V2 requests:
    setApiV2Token(token);
    setApiV2LogisticCompany(logisticCompanyId);
  }

  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    rootReducer,
    state,
    composeEnhancers(applyMiddleware(...middleware, sagaMiddleware, thunk))
  );

  sagaMiddleware.run(rootSagaWrapper(store));

  // save states every second to localStorage
  store.subscribe(
    throttle(() => {
      saveState(store.getState(), persistWhitelist);
    }, 1000)
  );

  return store;
}
