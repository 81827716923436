import {
  SHIPMENTS_SET_FILTER_DATE,
  SHIPMENTS_SET_FILTER_STATUS,
  SHIPMENTS_SET_FILTER_TOURS,
  SHIPMENTS_SET_FILTER_DRIVERS,
  SHIPMENTS_SHOW_EDIT,
  SHIPMENTS_HIDE_EDIT,
  SHIPMENTS_HIDE_PROGRESS_ADD,
  SHIPMENTS_SHOW_PROGRESS_ADD,
  SHIPMENTS_SET_SCROLL_TOP
} from "./constants";

export const setFilterDate = date => ({
  type: SHIPMENTS_SET_FILTER_DATE,
  payload: date
});

export const setFilterStatus = statusIds => ({
  type: SHIPMENTS_SET_FILTER_STATUS,
  payload: statusIds
});

export const setFilterTours = tourIds => ({
  type: SHIPMENTS_SET_FILTER_TOURS,
  payload: tourIds
});

export const setFilterDriver = driverIds => ({
  type: SHIPMENTS_SET_FILTER_DRIVERS,
  payload: driverIds
});

export const showShipmentEdit = shipmentId => ({
  type: SHIPMENTS_SHOW_EDIT,
  payload: shipmentId
});

export const hideShipmentEdit = () => ({
  type: SHIPMENTS_HIDE_EDIT
});

export const showProgressAdd = shipmentId => ({
  type: SHIPMENTS_SHOW_PROGRESS_ADD,
  payload: shipmentId
});

export const hideProgressAdd = () => ({
  type: SHIPMENTS_HIDE_PROGRESS_ADD
});

/**
 * @param {number} scrollTop
 */
export const setScrollTop = scrollTop => ({
  type: SHIPMENTS_SET_SCROLL_TOP,
  payload: scrollTop
});
