import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MessageBoxItem from "./MessageBoxItem";
import "./messageBoxList.local.css";

class MessageBoxList extends Component {
  static propTypes = {
    chatPartner: PropTypes.object,
    hide: PropTypes.bool,
    messages: PropTypes.array
  };

  constructor(props) {
    super(props);
    this.toggle = [];
  }

  state = {
    interlocutors: []
  };

  componentWillReceiveProps(nextProps) {
    const { chatPartner, hide } = nextProps;

    const { interlocutors } = this.state;
    if (chatPartner && chatPartner.id) {
      const show = !hide || !this.toggle.includes(chatPartner.id);

      if (show && !interlocutors.includes(chatPartner)) {
        interlocutors.push(chatPartner);
        this.toggle.push(chatPartner.id);
      } else if (!show && interlocutors.includes(chatPartner)) {
        interlocutors.splice(interlocutors.indexOf(chatPartner), 1);
        this.toggle.splice(this.toggle.indexOf(chatPartner.id), 1);
      }

      this.setState({ interlocutors });
    }
  }

  getMessageByUser(id) {
    const { messages } = this.props;
    const userMessages = [];
    if (messages) {
      for (let i = 0; i < messages.length; i += 1) {
        if (
          Number(messages[i].from_member_id) === Number(id) ||
          Number(messages[i].to_member_id) === Number(id)
        ) {
          userMessages.push(messages[i]);
        }
      }
    }

    return userMessages;
  }

  messageBoxOnClose = chatPartner => {
    const { interlocutors } = this.state;
    const currentInterlocutors = interlocutors.filter(
      interlocutor => interlocutor.id !== chatPartner.id
    );

    this.setState({ interlocutors: currentInterlocutors });
    this.toggle.splice(this.toggle.indexOf(chatPartner.id), 1);
  };

  render() {
    const { interlocutors } = this.state;
    // console.log("interlocutors", interlocutors);

    return (
      <span>
        {interlocutors.length > 0 && (
          <div className="messageBoxes">
            {interlocutors.map(interlocutor => (
              <MessageBoxItem
                key={interlocutor.id}
                interlocutor={interlocutor}
                messages={this.getMessageByUser(interlocutor.id)}
                onClose={this.messageBoxOnClose}
              />
            ))}
          </div>
        )}
      </span>
    );
  }
}

const mapStateToProps = ({ dialog }) => ({
  messages: dialog.messages
});

export default connect(mapStateToProps)(MessageBoxList);
