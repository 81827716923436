import ApiService from "service/ApiService";
import {
  MEMBERS_GET_REQUEST,
  MEMBERS_GET_RECEIVE,
  MEMBERS_GET_ERROR,
  MEMBER_CREATE_REQUEST,
  MEMBER_CREATE_RECEIVE,
  MEMBER_CREATE_ERROR,
  MEMBER_UPDATE_REQUEST,
  MEMBER_UPDATE_RECEIVE,
  MEMBER_UPDATE_ERROR,
  MEMBER_DELETE_REQUEST,
  MEMBER_DELETE_RECEIVE,
  MEMBER_DELETE_ERROR
} from "./constants";

/**
 * Get all members.
 */
export const getMembers = () => async (dispatch, getState) => {
  const onSuccess = payload => {
    dispatch({ type: MEMBERS_GET_RECEIVE, payload });
    return payload;
  };
  dispatch({ type: MEMBERS_GET_REQUEST });
  try {
    const {
      auth: { token }
    } = getState();
    const { member = [] } = await ApiService.getMember(token);
    return onSuccess(member);
  } catch (error) {
    dispatch({ type: MEMBERS_GET_ERROR, error });
    return null;
  }
};

/**
 * Create member.
 * @param {object} body
 */
export const createMember = body => async (dispatch, getState) => {
  const onSuccess = payload => {
    dispatch({ type: MEMBER_CREATE_RECEIVE, payload });
    return payload;
  };
  dispatch({ type: MEMBER_CREATE_REQUEST });
  try {
    const {
      auth: { token }
    } = getState();
    const { member = {} } = await ApiService.createMember(token, body);
    return onSuccess(member);
  } catch (error) {
    dispatch({ type: MEMBER_CREATE_ERROR, error });
    return null;
  }
};

/**
 * Update member.
 * @param {object} body
 */
export const updateMember = body => async (dispatch, getState) => {
  const onSuccess = payload => {
    dispatch({ type: MEMBER_UPDATE_RECEIVE, payload });
    return payload;
  };
  dispatch({ type: MEMBER_UPDATE_REQUEST });
  try {
    const {
      auth: { token }
    } = getState();
    const { member = {} } = await ApiService.updateMember(token, body);
    return onSuccess(member);
  } catch (error) {
    dispatch({ type: MEMBER_UPDATE_ERROR, error });
    return null;
  }
};

/**
 * Delete member.
 * @param {object} body
 */
export const deleteMember = body => async (dispatch, getState) => {
  const onSuccess = payload => {
    dispatch({ type: MEMBER_DELETE_RECEIVE, payload });
    return payload;
  };
  dispatch({ type: MEMBER_DELETE_REQUEST });
  try {
    const {
      auth: { token }
    } = getState();
    const { member = {} } = await ApiService.deleteMember(token, body);
    return onSuccess(member);
  } catch (error) {
    dispatch({ type: MEMBER_DELETE_ERROR, error });
    return null;
  }
};
