import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Panel = ({ state, header, children, className, ...otherProps }) => (
  <div
    className={classNames(`panel panel-${state}`, className)}
    {...otherProps}
  >
    <div className="panel-heading">
      <h2 className="panel-title">{header}</h2>
    </div>
    <div className="panel-body">{children}</div>
  </div>
);

Panel.propTypes = {
  header: PropTypes.node,
  state: PropTypes.oneOf([
    "success",
    "warning",
    "danger",
    "info",
    "default",
    "primary"
  ]),
  children: PropTypes.any,
  className: PropTypes.string
};

Panel.defaultProps = {
  state: "default",
  className: ""
};

export default Panel;
