import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { login } from "../../state/auth/authAction";
import LoginForm from "./LoginForm";
import LoginLayout from "./LoginLayout";

/**
 * Login Page
 * Specialty: Own Layout component since standard layout cannot and should not be used without user info.
 * Redirects to home, if user is still logged in.
 * */
class Login extends Component {
  static propTypes = {
    user: PropTypes.object,
    loginError: PropTypes.number,
    dispatch: PropTypes.func,
    location: PropTypes.object
  };

  state = {
    isLoggingIn: false,
    message: null
  };

  /*
   * No login form if user logged in.
   */
  componentWillMount() {
    const { history } = this.props;
    if (this.props.user) {
      history.replace("/");
    }
    if (this.props.location.search.includes("createPassword")) {
      this.setState({ message: "Dein Passwort wurde erfolgreich gesetzt!" });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { history } = this.props;
    const { isLoggingIn } = this.state;

    if (isLoggingIn) {
      this.setState({ isLoggingIn: false });
    }

    // being logged in redirect to main page
    if (nextProps.user) {
      // TODO: use redirect param
      history.push("/");
    }
  }

  getLoginError = loginError => {
    if (!loginError) {
      return null;
    }

    switch (loginError) {
      case 401:
        return "Falscher Benutzername oder Passwort.";
      case 403:
        return "Dein Login ist nur für die TrackCode App gültig. Falls du diese nicht installiert hast, kontaktiere bitte einen Administrator oder Disponenten in deinem Unternehmen.";
      case 404:
        return "Bitte überprüfe deine Verbindung und probieren es noch einmal.";
      case 500:
        return "Es ist ein Fehler aufgetreten, versuch es bitte später noch einmal.";
      default:
        return "Es ist ein Fehler aufgetreten, versuch es bitte später noch einmal.";
    }
  };

  handleUsername = event => {
    this.username = event.target.value;
  };

  handlePassword = event => {
    this.password = event.target.value;
  };

  handleLogin = () => {
    // event.preventDefault();
    const { dispatch } = this.props;
    // dispatch async login action
    dispatch(login(this.username, this.password));
    // trigger loading state
    this.setState({ isLoggingIn: true });
  };

  render() {
    const { loginError } = this.props;
    const { isLoggingIn, message } = this.state;
    const error = this.getLoginError(loginError);

    return (
      <LoginLayout>
        <LoginForm
          message={message}
          error={error}
          handleLogin={this.handleLogin}
          handleUsername={this.handleUsername}
          handlePassword={this.handlePassword}
          isLoggingIn={isLoggingIn}
        />
      </LoginLayout>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
  language: auth.language,
  loginError: auth.loginError
});

export default connect(mapStateToProps)(Login);
