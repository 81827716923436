import React, { Component } from "react";
import PropTypes from "prop-types";
import TextField from "../element/form/TextField";

class MessageBoxItemFooter extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired
  };

  state = {
    textmessage: ""
  };

  onMessageTextChange = e => {
    this.setState({ textmessage: e.target.value });
  };

  onSubmitClicked = e => {
    e.preventDefault();
    const { onSubmit } = this.props;
    onSubmit(this.state.textmessage);
    this.setState(() => ({ textmessage: "" }));
  };

  render() {
    return (
      <div className="box-footer">
        <form method="post" onSubmit={this.onSubmitClicked}>
          <TextField
            autoFocus
            onChange={this.onMessageTextChange}
            className="form-control"
            placeholder="Verfasse eine Nachricht"
            value={this.state.textmessage}
          />
        </form>
      </div>
    );
  }
}

export default MessageBoxItemFooter;
