import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Pin, Directions, Polygon } from "@trackcode/map";

class Scopes extends Component {
  static propTypes = {
    /** clear scope options */
    handleOptions: PropTypes.func.isRequired,
    handleScopes: PropTypes.arrayOf(PropTypes.string).isRequired,
    scopes: PropTypes.shape({})
  };

  static defaultProps = {
    scopes: {}
  };

  filterHandleScopes = scopeName => {
    const { handleScopes } = this.props;

    if (handleScopes.length > 0 && !handleScopes.includes(scopeName)) {
      // do not render this scope
      return false;
    }

    // ignore drivers by default because this scope is handle in <ScopeDrivers />
    return scopeName !== "drivers";
  };

  render() {
    const { scopes, handleOptions } = this.props;

    // get scope names
    const scopeNames = Object.keys(scopes).filter(
      scopeName => scopes[scopeName] && scopes[scopeName].visible
    );

    handleOptions(scopeNames, scopes);

    return scopeNames.filter(this.filterHandleScopes).map(scopeName => (
      <Fragment>
        {scopes[scopeName].polygons.map(props => (
          <Polygon key={`${scopeName}-polygon`} {...props} />
        ))}
        {scopes[scopeName].pins.map(props => (
          <Pin key={`${scopeName}-pin`} {...props} />
        ))}
        {scopes[scopeName].directions.map(props => (
          <Directions key={`${scopeName}-direction`} {...props} />
        ))}
      </Fragment>
    ));
  }
}

const mapStateToProps = ({ featureMap: scopes }) => ({
  scopes
});

export default connect(mapStateToProps)(Scopes);
