/**
 * Created by kim on 08.01.17.
 */
import { call, put, takeLatest } from "redux-saga/effects";

import ApiService from "../../service/ApiService";
import {
  PASSWORD_CREATE_REQUEST,
  PASSWORD_CREATE,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET
} from "./index";

function* createPassword({ payload }) {
  try {
    const password = yield call(
      ApiService.createPassword,
      payload.token,
      payload.password
    );
    yield put({
      type: PASSWORD_CREATE,
      payload: password
    });
  } catch (error) {
    yield put({
      type: PASSWORD_CREATE,
      payload: error,
      error: true
    });
  }
}

function* resetPassword({ payload }) {
  try {
    const password = yield call(ApiService.resetPassword, payload.email);
    yield put({
      type: PASSWORD_RESET,
      payload: password
    });
  } catch (error) {
    yield put({
      type: PASSWORD_RESET,
      payload: error,
      error: true
    });
  }
}

export const createPasswordSaga = function* createPasswordSaga() {
  yield takeLatest(PASSWORD_CREATE_REQUEST, createPassword);
};

export const resetPasswordSaga = function* resetPasswordSaga() {
  yield takeLatest(PASSWORD_RESET_REQUEST, resetPassword);
};
