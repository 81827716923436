import { LOGOUT } from "state/auth";

/**
 * Middleware to detect api errors.
 */
export default store => next => action => {
  const { error, payload } = action;

  if (!error) return next(action);

  // In V2 we're using `error` in actions
  if (error && (payload === 401 || error === 401)) {
    // session timeout
    store.dispatch({ type: LOGOUT });
  }

  return next(action);
};
