import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import ApiService from "../../../service/ApiService";
import Button from "../button/Button";
import { DropdownButton } from "../../element/dropdown";
import styleNames from "./scrollList.local.css";

const cx = classNames.bind(styleNames);

class ScrollList extends Component {
  static propTypes = {
    height: PropTypes.number,
    // Array like [{id: 1, title: 'Jon', subTitle: '+49 124 212313'}]
    entries: PropTypes.array.isRequired,
    className: PropTypes.string,
    clear: PropTypes.bool,
    // returns the entry element of entries
    onEdit: PropTypes.func,
    onAdd: PropTypes.func,
    selectedGroup: PropTypes.number.isRequired,
    token: PropTypes.string.isRequired
  };

  static defaultProps = {
    height: 250
  };

  state = {
    selectedEntryIndex: undefined,
    selectedEntry: {},
    hoveringEntryIndex: -1
  };

  componentWillReceiveProps(nextProps) {
    const { clear } = nextProps;
    /*
    if( this.state.showModal !== showModal ) {
      this.setState({ showModal: showModal });
    }
    */

    if (clear) {
      this.setState({ selectedEntryIndex: undefined, selectedEntry: {} });
    }
  }

  /*
   * On click edit.
   */
  onEdit = () => {
    // call prop function
    if (this.props.onEdit) {
      const { selectedEntry } = this.state;
      delete selectedEntry.title;
      delete selectedEntry.subTitle;
      delete selectedEntry.action;
      this.props.onEdit(selectedEntry);
    }
  };

  /*
   * On click add.
   */
  onAdd = () => {
    // call prop function
    if (this.props.onAdd) {
      this.props.onAdd();
    }
  };

  /*
   * On select a entry.
   *@param {number} index - Index of entries array.
   */
  selectedEntry(entry, index) {
    this.setState({ selectedEntry: entry, selectedEntryIndex: index });
  }

  render() {
    const {
      entries,
      height,
      className,
      selectedGroup,
      token,
      ...other
    } = this.props;

    const { selectedEntryIndex, hoveringEntryIndex } = this.state;

    return (
      <span>
        <div className={cx("scroll", className)} {...other} style={{ height }}>
          {/* Entries list */}
          {/* eslint-disable jsx-a11y/interactive-supports-focus */}
          {/* eslint-disable react/no-array-index-key */}
          {entries.map((entry, index) => (
            <div
              role="button"
              key={index}
              className={cx("entry", {
                entrySelected: selectedEntryIndex === index
              })}
              onClick={() => this.selectedEntry(entry, index)}
              onMouseEnter={() =>
                this.setState(() => ({ hoveringEntryIndex: index }))
              }
              onMouseLeave={() =>
                this.setState(() => ({ hoveringEntryIndex: -1 }))
              }
            >
              {entry.action && (
                <div className={cx("action")}>{entry.action}</div>
              )}
              <div className={cx("title")}>{entry.title}</div>
              {entry.subTitle && (
                <div className={cx("subTitle")}>{entry.subTitle}</div>
              )}
              {selectedGroup &&
                selectedGroup === 4 &&
                hoveringEntryIndex === index && (
                  <div className={cx("menu")}>
                    <DropdownButton
                      id="moreShipmentOptions"
                      className={cx("more-button")}
                      pullRight
                      noCaret
                      iconRight="dots-vertical"
                      size="xs"
                      menuHeight={40}
                    >
                      <li>
                        <a
                          href={ApiService.getIdCardUrl(token, entry.id)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Ausweis anzeigen
                        </a>
                      </li>
                    </DropdownButton>
                  </div>
                )}
              <div className={cx("clear")} />
            </div>
          ))}
          {/* eslint-enable jsx-a11y/interactive-supports-focus */}
          {/* eslint-enable react/no-array-index-key */}
        </div>

        {/* Navigation */}
        <div className={cx("navigationRow")}>
          <Button
            onClick={this.onEdit}
            disabled={selectedEntryIndex === undefined}
          >
            Bearbeiten
          </Button>
          <Button iconRight="plus" onClick={this.onAdd} />
        </div>
      </span>
    );
  }
}

export default ScrollList;
