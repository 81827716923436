import s from "string";
import hash from "object-hash";

const delimters = [";", ",", "|"];

export default class ParsingService {
  static csvConvert(string) {
    return new Promise((resolve, reject) => {
      let delimter = ",";
      let count = 0;
      for (let i = 0; i < delimters.length; i += 1) {
        const tempCount = s(string).count(delimters[i]);
        if (tempCount > count) {
          count = tempCount;
          delimter = delimters[i];
        }
      }

      try {
        const tempItems = s(string).parseCSV(delimter, '"', "\\", "\n");
        const items = [];
        const properties = { header: false, hashedHeader: null };
        let hasHeader = true;
        for (let i = 0; i < tempItems.length; i += 1) {
          // detect header
          if (i === 0) {
            for (let j = 0; j < tempItems[i].length; j += 1) {
              if (!hasHeader) {
                break;
              }
              hasHeader = Number.isNaN(tempItems[i][j]);
            }
          }
          // check for empty lines
          if (tempItems[i].length > 1) {
            items.push(tempItems[i]);
          }
        }

        properties.header = hasHeader;
        if (hasHeader) {
          properties.hashedHeader = hash(items[0]);
        }

        resolve({ properties, items });
      } catch (e) {
        reject(e);
      }
    });
  }
}
