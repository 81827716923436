import moment from "moment";
import AddressService from "./AddressService";

const PICKUP = "pickup_";
const DELIVERY = "delivery_";

const SENDER = "sender_";
const RECIPIENT = "recipient_";

function getAppointmentValue(type, shipment) {
  const startRaw = shipment[`${type}appointment_from`];
  const endRaw = shipment[`${type}appointment_till`];

  if (!startRaw || !endRaw) {
    // prevent error with empty appointment TC-593
    return "";
  }

  const start = startRaw.split(" ")[1];
  const end = endRaw.split(" ")[1];
  if (start === "00:00:00" && end === "00:00:00") {
    return "";
  }
  const appointment = `${moment(start, "HH:mm:ss").format("H:mm")} - ${moment(
    end,
    "HH:mm:ss"
  ).format("H:mm")}`;
  return appointment;
}

function getLatLng(type, shipment) {
  const latitude = parseFloat(shipment[`${type}address_latitude`]);
  const longitude = parseFloat(shipment[`${type}address_longitude`]);
  if (Number.isNaN(latitude) && Number.isNaN(longitude)) {
    return { lat: null, lng: null };
  }

  return { lat: latitude, lng: longitude };
}

export default class ShipmentService {
  static getPickupAppointment(shipment) {
    return getAppointmentValue(PICKUP, shipment);
  }

  static getDeliveryAppointment(shipment) {
    return getAppointmentValue(DELIVERY, shipment);
  }

  static getPickupLatLng(shipment) {
    return getLatLng(SENDER, shipment);
  }

  static getDeliveryLatLng(shipment) {
    return getLatLng(RECIPIENT, shipment);
  }

  /*
   * get a lat,lng from shipment
   * @param shipment
   * @returns {{}}
   */
  static getLatLongFromShipment(shipment) {
    return AddressService.getLatLongFromShipment(shipment);
  }
}
