import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames/bind";
import { Map, mapActions } from "features/map";

import CreateEditShipmentForm from "../../components/shipment/CreateEditShipmentForm";
import ImportProgress from "../../components/shipment/ImportProgress";
import TCModal from "../../components/element/overlay/TCModal";
import Navigation from "../../components/element/navigation/Navigation";
import NavigationItem from "../../components/element/navigation/NavigationItem";

import styleNames from "./createEditShipment.local.css";

const cx = classNames.bind(styleNames);

class CreateEditShipment extends Component {
  static propTypes = {
    showModal: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired
  };

  state = {
    direction: {},
    selectedTab: "shipment",
    repetition: {}
  };

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(mapActions.clear("shipment"));
  }

  onSelectNavigationItem = selectedItem => {
    //  event.preventDefault();
    this.setState({ selectedTab: selectedItem });
  };

  handleSenderAddressChange = senderAddress => {
    this.senderAddress = senderAddress;
    this.showOnMap();
  };

  handleRecipientAddressChange = recipientAddress => {
    this.recipientAddress = recipientAddress;
    this.showOnMap();
  };

  showOnMap() {
    const { dispatch } = this.props;
    if (this.senderAddress && this.recipientAddress) {
      dispatch(
        mapActions.set(
          "shipment",
          {
            directions: [
              {
                origin: {
                  location: {
                    lat: this.senderAddress.latitude,
                    lng: this.senderAddress.longitude
                  }
                },
                destination: {
                  location: {
                    lat: this.recipientAddress.latitude,
                    lng: this.recipientAddress.longitude
                  }
                }
              }
            ]
          },
          {
            fitBounds: true,
            zoom: 15
          }
        )
      );
    } else if (this.senderAddress || this.recipientAddress) {
      const { latitude: lat, longitude: lng } =
        this.senderAddress || this.recipientAddress;
      dispatch(
        mapActions.set(
          "shipment",
          {
            pins: [
              {
                position: {
                  lat,
                  lng
                }
              }
            ]
          },
          {
            fitBounds: true,
            zoom: 15
          }
        )
      );
    }
  }

  calcDistanceFromDirection() {
    const direction = { ...this.state.direction };
    if (direction && direction.origin) {
      const result = {
        latitude: direction.origin.lat,
        longitude: direction.origin.lng
      };
      // console.log("calc this.state.direction", result);
      return result;
    }
    return null;
  }

  handleSubmitForm = () => {
    this.props.handleSubmit();
  };

  handleAbortForm = () => {
    this.props.handleClose();
  };

  handleOnReady = () => {
    this.props.handleClose();
  };

  render() {
    const { handleClose, handleSubmit, showModal, ...otherProps } = this.props;

    const { selectedTab, repetition } = this.state;

    return (
      <div {...otherProps}>
        <TCModal
          show={showModal}
          classNameWrapper={cx("shipment-dialog")}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          bigModal
        >
          <Navigation
            className={cx("navigation-tabs")}
            onSelect={this.onSelectNavigationItem}
          >
            <NavigationItem
              eventKey="shipment"
              className={cx({ selected: selectedTab === "shipment" })}
            >
              Manuell eingeben
            </NavigationItem>
            <NavigationItem
              eventKey="import"
              className={cx({ selected: selectedTab === "import" })}
            >
              Importieren
            </NavigationItem>
          </Navigation>

          <div className={cx("input-container")}>
            <div
              className={cx("form-container", {
                fullwidth: selectedTab !== "shipment"
              })}
            >
              {selectedTab === "shipment" && (
                <CreateEditShipmentForm
                  handleSenderAddressChange={this.handleSenderAddressChange}
                  handleRecipientAddressChange={
                    this.handleRecipientAddressChange
                  }
                  handleSubmit={this.handleSubmitForm}
                  handleAbort={this.handleAbortForm}
                  calcDistanceFrom={this.calcDistanceFromDirection()}
                  repetition={repetition}
                />
              )}
              {selectedTab === "import" && (
                <ImportProgress
                  handleSubmit={this.handleSubmitForm}
                  handleAbort={this.handleAbortForm}
                  handleOnReady={this.handleOnReady}
                />
              )}
            </div>
            {selectedTab === "shipment" && (
              <div className={cx("additional-container")}>
                <Map handleScopes={["shipment"]} />
              </div>
            )}
          </div>
        </TCModal>
      </div>
    );
  }
}

export default connect()(CreateEditShipment);
