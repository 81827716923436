import React from "react";
import PropTypes from "prop-types";
import { Checkbox } from "semantic-ui-react";

import "./checkbox.css";

const TCCheckbox = ({
  value,
  disabled,
  readOnly,
  checked,
  label,
  tabIndex,
  ...otherProps
}) => (
  <Checkbox
    checked={checked}
    value={value}
    readOnly={readOnly}
    disabled={disabled}
    label={label}
    tabIndex={tabIndex}
    {...otherProps}
  />
);

TCCheckbox.propTypes = {
  value: PropTypes.any,
  // style: PropTypes.oneOf(["success", "warning", "error"]),
  // size: PropTypes.oneOf(["small", "medium", "large"]),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  checked: PropTypes.bool,
  label: PropTypes.string,
  tabIndex: PropTypes.string
};

export default TCCheckbox;
