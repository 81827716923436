import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import classNames from "classnames/bind";
import DatePicker from "react-datepicker";
import Icon from "../../../components/element/icon/Icon";

import styleNames from "./calendar.local.css";

const cx = classNames.bind(styleNames);

class Calendar extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    handleOnChange: PropTypes.func,
    onChange: PropTypes.func,
    selectedDate: PropTypes.object
  };

  static defaultProps = {
    selectedDate: { start: new Date() }
  };

  constructor(props) {
    super(props);
    this.input = null;
    this.toggle = false;
  }

  handleOnChange = val => {
    const { handleOnChange } = this.props;
    handleOnChange(val);
  };

  render() {
    const { id, selectedDate, handleOnChange, ...otherProps } = this.props;

    const startDate = moment(selectedDate.start);

    return (
      <div id={id} {...otherProps}>
        <Icon name="calendar" className={cx("calendar-icon")} />
        <div className={cx("datePickerWrapper")}>
          <DatePicker
            className={cx("calendar-input")}
            onChange={this.handleOnChange}
            locale="de-DE"
            selected={startDate}
            customInput={<input type="test" />}
          />
        </div>
      </div>
    );
  }
}

export default Calendar;
