/* eslint no-constant-condition: 0  */
import { call, put, takeLatest, select, all } from "redux-saga/effects";
import ApiService from "../../service/ApiService";
import RestService from "../../service/RestService";
import {
  LOGIN_REQUEST,
  LOGIN,
  LOGOUT_REQUEST,
  LOGOUT,
  LOGISTICCOMPANY_PREPARE_CHANGE,
  MANAGED_LOGISTICCOMPANIES
} from "./index";
import * as authAction from "./authAction";
import { restServiceMiddleware } from "./authUtils";

function* loadManagedLogisticCompanies({ token }) {
  const { managedLogisticcompanies: payload } = yield call(
    ApiService.getManagedLogisticCompanies,
    token
  );
  yield put({
    type: MANAGED_LOGISTICCOMPANIES,
    payload
  });
}

function setServiceMiddleware({ logisticCompanyId }) {
  RestService.registerMiddleware(
    "logisticCompany",
    restServiceMiddleware({ logisticCompanyId })
  );
}

function* login({ payload: { username, password } }) {
  try {
    // reset middleware
    setServiceMiddleware({ logisticCompanyId: 0 });

    const auth = yield call(ApiService.login, username, password);
    yield loadManagedLogisticCompanies(auth);

    yield put({
      type: LOGIN,
      payload: auth
    });
  } catch (error) {
    yield put({
      type: LOGIN,
      payload: error,
      error: true
    });
  }
}

function* logout() {
  const token = yield select(state => state.auth.token);
  try {
    yield call(ApiService.logout, token);

    yield put({
      type: LOGOUT
    });
  } catch (error) {
    yield put({
      type: LOGOUT,
      payload: error,
      error: true
    });
  }
  // reset middleware
  setServiceMiddleware({ logisticCompanyId: 0 });
}

/**
 * Source: https://github.com/redux-saga/redux-saga/blob/master/docs/introduction/BeginnerTutorial.md
 * @param {number} ms
 */
const delay = ms => new Promise(res => setTimeout(res, ms));

/**
 * Change logisticCompanyId.
 * @param {object} params
 * @param {object} socket
 */
function* changeLogisticCompany({ logisticCompanyId }) {
  yield delay(400);
  // wait a bit because of ui changes
  // overwrite/register middleware to place `X-Logisticcompany-ID` in header
  // for every request
  setServiceMiddleware({ logisticCompanyId });
  yield put(authAction.logisticCompanyChanged());
}

export default function* sagas(store) {
  yield all([
    takeLatest(LOGIN_REQUEST, login),
    takeLatest(LOGOUT_REQUEST, logout),
    takeLatest(LOGISTICCOMPANY_PREPARE_CHANGE, changeLogisticCompany)
  ]);
}
