import {
  isBefore,
  isEqual,
  startOfToday,
  endOfToday,
  isSameDay
} from "date-fns";

import { createSelector } from "reselect";
import {
  enhanceShipments,
  getShipments
} from "state/entities/shipments/selectors";
import { getTours } from "state/entities/tours/selectors";
import { getStatus } from "state/entities/shipmentStatus/selectors";
import { getMembers } from "state/entities/members/selectors";

/**
 * Get status variations prepared for a NestedSelection.
 * @param {object} state
 */
export const getStatusVariationsNested = ({
  shipment: { statusVariations }
}) => {
  // change structure for NestedSelection
  const variationsArray = Object.values(statusVariations).map(
    ({
      id: value,
      label: content,
      status_id: statusId,
      variation_type: variationType,
      signature_required: signatureRequired,
      parent_id: parentId
    }) => ({
      value,
      content,
      statusId,
      variationType,
      signatureRequired,
      parentId
    })
  );

  /**
   * Build tree with recursive function.
   * Example:
   * ```
   * [
   *  { value: 1, content: "Parent", parentId: null, ...other },
   *  { value: 2, content: "Children", parentId: 1 },
   *  { value: 3, content: "Item", parentId: null }
   * ]
   * ```
   * to ->
   * ```
   * [
   *  { value: 1, content: "Parent", parentId: null, children: [{ value: 2, content: "Children", parentId: 1 }], ...other },
   *  { value: 3, content: "Item", parentId: null }
   * ]
   * ```
   */
  const buildTree = (variations, parentId = null) => {
    const nestedVariations = [...variations];
    const branch = [];
    nestedVariations.forEach((variation, index) => {
      if (variation.parentId !== parentId) {
        return;
      }
      const children = buildTree(nestedVariations, variation.value);
      if (children) {
        nestedVariations[index].children = children;
      }
      branch.push(variation);
    });
    return branch;
  };
  return buildTree(variationsArray);
};

/**
 * Compares two names
 * @param {String} a
 * @param {String} b
 * @return {number}
 */
const compareName = (a, b) => {
  const nameA = a.toUpperCase();
  const nameB = b.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};

/**
 * Compare two timewindows if both have at least one time set
 * @param {{from: Date | null, till: Date|null}} timeSlotA
 * @param {{from: Date | null, till: Date|null}} timeSlotB
 * @return {number}
 */
const compareExistingTimeWindow = (timeSlotA, timeSlotB) => {
  const { from: fromA, till: tillA } = timeSlotA;
  const { from: fromB, till: tillB } = timeSlotB;
  if ((fromA || tillA) && (!fromB && !tillB)) {
    return -1;
  }
  if ((fromB || tillB) && (!fromA && !tillA)) {
    return 1;
  }
  return 0;
};

/**
 * Compare two datewindows for earliest start with earliest end as a tiebreaker
 * @param {{start: Date | null, end: Date|null}} dateWindowA
 * @param {{start: Date | null, end: Date|null}} dateWindowB
 * @return {number}
 */
const compareDateWindow = (dateWindowA, dateWindowB) => {
  // if time starttime differs we can sort
  if (!isEqual(dateWindowA.start, dateWindowB.start)) {
    return isBefore(dateWindowA.start, dateWindowB.start) ? -1 : 1;
  }

  // same start time, sort by till time
  if (!isEqual(dateWindowA.end, dateWindowB.end)) {
    return isBefore(dateWindowA.end, dateWindowB.end) ? -1 : 1;
  }
  return 0;
};

const sortShipmentsByTime = shipmentList => {
  const begin = startOfToday();
  const end = endOfToday();
  return [...shipmentList].sort((shipmentA, shipmentB) => {
    // choose recipient time slot if shipment is already picked up
    const timeA = shipmentA.isPickedUp
      ? shipmentA.recipient.time
      : shipmentA.sender.time;
    const timeB = shipmentB.isPickedUp
      ? shipmentB.recipient.time
      : shipmentB.sender.time;

    // shipments with date go before shipments without any date
    const existingTimewindowDiff = compareExistingTimeWindow(timeA, timeB);
    if (existingTimewindowDiff !== 0) {
      return existingTimewindowDiff;
    }

    // compare dateWindows by start, then end
    const dateWindowA = {
      start: timeA.from || begin,
      end: timeA.till || end
    };
    const dateWindowB = {
      start: timeB.from || begin,
      end: timeB.till || end
    };
    const timeWindowDiff = compareDateWindow(dateWindowA, dateWindowB);
    if (timeWindowDiff !== 0) {
      return timeWindowDiff;
    }

    // equal timeWindows, sort by zip
    if (shipmentA.recipient.address.zip !== shipmentB.recipient.address.zip) {
      return shipmentA.recipient.address.zip - shipmentB.recipient.address.zip;
    }

    // sort by street name
    return compareName(
      shipmentA.recipient.address.street,
      shipmentB.recipient.address.street
    );
  });
};

/**
 *
 * @param {[object]} shipmentList list of shipment objects
 * @return {[{Object}]}
 */
const sortShipmentsByAddress = shipmentList =>
  [...shipmentList].sort((shipmentA, shipmentB) => {
    if (shipmentA.recipient.address.zip === shipmentB.recipient.address.zip) {
      return compareName(
        shipmentA.recipient.address.street,
        shipmentB.recipient.address.street
      );
    }
    return shipmentA.recipient.address.zip - shipmentB.recipient.address.zip;
  });

/**
 *
 * @param {Object} shipments shipment map: id -> shipment
 * @param {String} sortBy
 * @return {[{Object}]}
 */
export const sortShipments = (shipments, sortBy) => {
  const shipmentList = Object.values(shipments);
  if (sortBy === "address") {
    return sortShipmentsByAddress(shipmentList);
  }
  if (sortBy === "time") {
    return sortShipmentsByTime(shipmentList);
  }
  return shipmentList;
};

/**
 * Filters for a given date
 * @param {Object} shipment shipment map: id -> shipment
 * @param {Date} date
 * @return {boolean}
 */
const dateFilter = (shipment, date) =>
  date === null || isSameDay(date, shipment.dueDate);

/**
 * Filters for given status Ids
 * @param {Object} shipment
 * @param {Array[]} statusIds
 * @return {boolean}
 */
const statusFilter = (shipment, statusIds) =>
  statusIds.length === 0 || statusIds.includes(shipment.status_id);

/**
 * Filters for given tour Ids
 * @param {Object} shipment shipment
 * @param {Array[]} tourIds
 * @return {boolean}
 */
const tourFilter = (shipment, tourIds) =>
  tourIds.length === 0 || tourIds.includes(shipment.tourId);

/**
 * Filters for given driver Ids
 * @param {Object} shipment shipment
 * @param {Array[]} driverIds
 * @return {boolean}
 */
const driverFilter = (shipment, driverIds) =>
  driverIds.length === 0 || driverIds.includes(shipment.driverId);

/**
 * Applies various filter for a shipment map
 * @param {Object} shipments map of id -> shipment
 * @param tourIds
 * @param statusIds
 * @param date
 * @param driverIds
 * @return {Object}
 */
export const applyFilters = (
  shipments,
  tourIds = [],
  statusIds = [],
  date,
  driverIds = []
) =>
  Object.values(shipments)
    .filter(
      shipment =>
        dateFilter(shipment, date) &&
        statusFilter(shipment, statusIds) &&
        tourFilter(shipment, tourIds) &&
        driverFilter(shipment, driverIds)
    )
    .reduce(
      (acc, shipment) => ({
        ...acc,
        [shipment.uid]: shipment
      }),
      {}
    );

/**
 * Map packages or shipments to shipment objects for view
 * @param {Array} shipments
 * @return {Array} list of grouped shipments
 */
export const groupShipments = shipments => {
  const grouped = {};
  shipments.forEach(shipment => {
    const { group_reference: groupReference } = shipment;

    if (!grouped[groupReference]) {
      grouped[groupReference] = {
        groupReference,
        shipmentIds: [],
        ...shipment
      };
    }
    grouped[groupReference].shipmentIds.push(shipment.uid);
  });

  return Object.values(grouped);
};

const getSortBy = state => state.shipmentV2.sortBy;
const getSelectedDate = state => state.shipmentV2.selectedDate;
const getSelectedStatusIds = state => state.shipmentV2.selectedStatusIds;
const getSelectedStatusIdsWithGroups = state => {
  const groups = state.entities.shipmentStatus.groups;
  const idsArrays = getSelectedStatusIds(state).map(id => {
    if (String(id).startsWith("g")) {
      return groups[id.substring(1)].status;
    }
    return id;
  });
  // flatten array
  return [].concat(...idsArrays);
};
const getSelectedTourIds = state => state.shipmentV2.selectedTourIds;
const getSelectedDriverIds = state => state.shipmentV2.selectedDriverIds;

/**
 * Returns shipment map filtered by several properties
 * @param {Object} state the redux store
 * @return {Object} id -> shipment
 */
export const getFilteredShipments = createSelector(
  [
    getShipments,
    getSelectedTourIds,
    getSelectedStatusIdsWithGroups,
    getSelectedDate,
    getSelectedDriverIds
  ],
  applyFilters
);

/**
 * Returns enhanced shipment map filtered by several properties
 * @param {Object} state the redux store
 * @return {Object} id -> shipment
 */
export const getEnhancedFilteredShipments = createSelector(
  [getFilteredShipments, getStatus, getTours, getMembers],
  enhanceShipments
);

export const getAllEnhancedShipments = createSelector(
  [getShipments, getStatus, getTours, getMembers],
  enhanceShipments
);

/**
 * Returns enhanced and sorted shipment map filtered by several properties
 * @param {Object} state the redux store
 * @return {Object} id -> shipment
 */
export const getSortedShipments = createSelector(
  [getEnhancedFilteredShipments, getSortBy],
  sortShipments
);

/**
 * Returns enhanced, sorted and grouped shipment map filtered by several properties
 * @param {Object} state the redux store
 * @return {Object} id -> shipment
 */
export const getShipmentListShipments = createSelector(
  [getSortedShipments],
  groupShipments
);

/**
 * Returns amount of grouped shipments due for a given day
 * @param {Object} state the redux store
 * @return {Object} id -> shipment
 */
export const getCurrentShipmentCount = createSelector(
  [getShipments, getSelectedDate],
  (shipments, date) => {
    const shipmentMap = applyFilters(shipments, [], [], date);
    return groupShipments(Object.values(shipmentMap)).length;
  }
);

const getShipment = (state, props) =>
  state.entities.shipments.entities[props.shipmentId];

// see https://github.com/reduxjs/redux/blob/master/docs/recipes/ComputingDerivedData.md#sharing-selectors-across-multiple-components
export const makeGetEnhancedShipment = () =>
  createSelector(
    [getShipment, getTours, getStatus, getMembers],
    (shipment, tours, status, members) => ({
      ...shipment,
      tour: shipment.tourId ? tours[shipment.tourId] : null,
      statusLabel:
        status[shipment.status_id] && status[shipment.status_id].label,
      driver: shipment.driverId ? members[shipment.driverId] : null
    })
  );

/**
 * Returns  sorted shipment map filtered by several properties
 * @param {Object} state the redux store
 * @return {Object} id -> shipment
 */
const getSortedAndFilteredShipments = createSelector(
  [getFilteredShipments, getSortBy],
  sortShipments
);

/**
 * Returns enhanced, sorted and grouped shipment map filtered by several properties
 * @param {Object} state the redux store
 * @return {Array<Number>} uids array
 */
export const getSortedAndFilteredShipmentIds = createSelector(
  [getSortedAndFilteredShipments],
  shipments => groupShipments(shipments).map(({ uid }) => uid)
);
