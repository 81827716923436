import { LOGISTICCOMPANY_PREPARE_CHANGE } from "state/auth";
import {
  MEMBERS_GET_REQUEST,
  MEMBERS_GET_RECEIVE,
  MEMBERS_GET_ERROR
} from "./constants";

const GROUP_ID_DISPATCHER = 5;
const GROUP_ID_DRIVER = 4;

const initialState = {
  entities: {
    // 1: { id: 1, firstname: "Jon", ... }
  },
  dispatcherIds: [
    // id's of dispatchers
  ],
  driverIds: [
    // id's of drivers
  ],
  errors: {},
  loaded: false
};

const getMemberIdsByGroupId = (array, groupId) =>
  array
    .filter(
      ({ groups = [] }) =>
        // different types because of the legacy api-php
        groups.includes(groupId) || groups.includes(String(groupId))
    )
    .map(({ id }) => id);

const arrayToObject = array =>
  array.reduce((acc, curr) => {
    acc[curr.id] = curr;
    return acc;
  }, {});

const sortByName = entities => (a, b) => {
  const nameA = `${entities[a].firstname} ${entities[a].lastname}`;
  const nameB = `${entities[b].firstname} ${entities[b].lastname}`;
  return nameA.localeCompare(nameB);
};

export default function membersReducer(state = initialState, action = {}) {
  const { type, payload, error } = action;

  switch (type) {
    case MEMBERS_GET_REQUEST: {
      return {
        ...state,
        loaded: false,
        error: {}
      };
    }

    case MEMBERS_GET_RECEIVE: {
      const entities = arrayToObject(payload);
      const dispatcherIds = getMemberIdsByGroupId(payload, GROUP_ID_DISPATCHER);
      const driverIds = getMemberIdsByGroupId(payload, GROUP_ID_DRIVER);
      dispatcherIds.sort(sortByName(entities));
      driverIds.sort(sortByName(entities));
      return {
        ...state,
        entities,
        dispatcherIds,
        driverIds,
        loaded: true,
        errors: {}
      };
    }

    case MEMBERS_GET_ERROR: {
      return {
        ...state,
        loaded: false,
        errors: error
      };
    }

    case LOGISTICCOMPANY_PREPARE_CHANGE:
      return initialState;

    default:
      return state;
  }
}
