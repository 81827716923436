import styled from "styled-components";
import PropTypes from "prop-types";

/**
 * Split screen to easily show a list and a map on a screen.
 */
export const SplitScreen = styled.div`
  display: flex;
  flex: 1;
  ${props => props.height && `height: ${props.height}`};
`;

SplitScreen.propTypes = {
  height: PropTypes.string
};
SplitScreen.defaultProps = {
  height: "100%"
};

export const SplitScreenContent = styled.div`
  flex: ${props => props.flex || 1};
`;
