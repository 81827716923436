import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import moment from "moment";
import "moment/locale/de";
import * as authAction from "state/auth/authAction";
import SocketHandler from "./SocketHandler";
import Layout from "./Layout";
import { Loading } from "./components";

class App extends Component {
  static propTypes = {
    user: PropTypes.shape({
      name: PropTypes.string
    }),
    locale: PropTypes.string.isRequired,
    logisticCompanyPrepareChange: PropTypes.bool.isRequired,
    logoutAction: PropTypes.func.isRequired,
    /** handle different layouts */
    layoutV2: PropTypes.bool,
    children: PropTypes.node.isRequired
  };

  static defaultProps = {
    user: null,
    layoutV2: false
  };

  state = {
    isLoggingOut: false
  };

  componentWillMount() {
    moment.locale(this.props.locale);
  }

  handleLogout = () => {
    const { logoutAction } = this.props;
    logoutAction();
    this.setState({ isLoggingOut: true });
  };

  render() {
    const {
      user,
      children,
      logisticCompanyPrepareChange,
      layoutV2
    } = this.props;
    const { isLoggingOut } = this.state;

    // unmount components (reload)
    const reload = logisticCompanyPrepareChange;

    return (
      <div>
        {!user && <Redirect to="/login" />}
        {isLoggingOut && <Loading />}
        {user && !isLoggingOut ? (
          <Fragment>
            <SocketHandler />
            <Layout
              layoutV2={layoutV2}
              reload={reload}
              user={user.name}
              handleLogout={this.handleLogout}
            >
              {children}
            </Layout>
          </Fragment>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = ({
  auth: { user, locale, logisticCompanyPrepareChange }
}) => ({
  user,
  locale,
  logisticCompanyPrepareChange
});

const mapDispatchToProps = {
  logoutAction: authAction.logout
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
