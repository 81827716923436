import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import Dropzone from "react-dropzone";
import ParsingService from "../../service/ParsingService";
import Loading from "../Loading/Loading";
import styleNames from "./importShipment.local.css";

const cx = classNames.bind(styleNames);

class ImportShipmentData extends Component {
  static propTypes = {
    handleUploaded: PropTypes.func.isRequired
  };

  state = {
    files: []
  };

  onDropFile = files => {
    this.setState({
      files
    });

    const reader = new FileReader();

    reader.onload = e => {
      const { result } = e.target;

      ParsingService.csvConvert(result).then(csvData => {
        this.props.handleUploaded(csvData);
      });
    };

    if (files.length > 0) {
      reader.readAsBinaryString(files[0]);
    }
  };

  render() {
    const { files } = this.state;

    return (
      <div>
        <Dropzone
          onDrop={this.onDropFile}
          accept=".csv,.txt"
          className={cx("uploadbox")}
        >
          <div>
            Try dropping some files here, or click to select files to upload.
          </div>
        </Dropzone>
        {files.length > 0 && (
          <div>
            <Loading />
          </div>
        )}
      </div>
    );
  }
}

export default ImportShipmentData;
