import { LOGISTICCOMPANY_PREPARE_CHANGE } from "state/auth";
import { EXTERNAL_READ } from "./index";

const initialState = {
  result: {},
  loaded: false,
  error: null
};

export default function auth(state = initialState, action = {}) {
  const { type, payload, error } = action;

  if (error) {
    return {
      ...state,
      error: payload
    };
  }

  switch (type) {
    case EXTERNAL_READ:
      return {
        ...state,
        result: payload,
        loaded: true,
        error: null
      };

    case LOGISTICCOMPANY_PREPARE_CHANGE:
      return initialState;

    default:
      return state;
  }
}
