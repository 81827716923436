export const SHIPMENTS_READ_REQUEST = "SHIPMENTS_READ_REQUEST";
export const SHIPMENTS_READ = "SHIPMENTS_READ";

export const SHIPMENTS_CREATE = "SHIPMENTS_CREATE";
export const SHIPMENTS_CREATE_REQUEST = "SHIPMENTS_CREATE_REQUEST";

export const SHIPMENTS_SOCKET_CREATE = "SHIPMENTS_CREATE";
export const SHIPMENTS_SOCKET_UPDATE = "SHIPMENTS_UPDATE";
export const SHIPMENTS_SOCKET_DELETE = "SHIPMENTS_DELETE";

export const FILTER_SHIPMENTS_REQUEST = "FILTER_SHIPMENTS_REQUEST";
export const FILTER_SHIPMENTS = "FILTER_SHIPMENTS";

export const SHIPMENTS_STATUS_CHANGED = "SHIPMENTS_STATUS_CHANGED";

export const SHIPMENT_UPDATE_REQUEST = "SHIPMENT_UPDATE_REQUEST";
export const SHIPMENT_UPDATE = "SHIPMENT_UPDATE";

export const SHIPMENT_DELETE_REQUEST = "SHIPMENT_DELETE_REQUEST";
export const SHIPMENT_DELETE = "SHIPMENT_DELETE";

export const SHIPMENT_STATUS_REQUEST = "SHIPMENT_STATUS_REQUEST";
export const SHIPMENT_STATUS = "SHIPMENT_STATUS";

export const SHIPMENT_STATUS_GROUPS_REQUEST = "SHIPMENT_STATUS_GROUPS_REQUEST";
export const SHIPMENT_STATUS_GROUPS = "SHIPMENT_STATUS_GROUPS";

export const SHIPMENT_ASSIGNMENT_DELETE_REQUEST =
  "SHIPMENT_ASSIGNMENT_DELETE_REQUEST";
export const SHIPMENT_ASSIGNMENT_DELETE = "SHIPMENT_ASSIGNMENT_DELETE";

export const SHIPMENT_STATUS_VARIATIONS_REQUEST =
  "SHIPMENT_STATUS_VARIATIONS_REQUEST";
export const SHIPMENT_STATUS_VARIATIONS = "SHIPMENT_STATUS_VARIATIONS";

export const SHIPMENT_NEW_PROGRESS_MODAL = "SHIPMENT_NEW_PROGRESS_MODAL";
