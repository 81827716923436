import React, { Component } from "react";
import PropTypes from "prop-types";
import Nouislider from "react-nouislider";

import "./timeslotslider.css";
import timeIcon from "../../../asset/image/ui/time.svg";

class TimeSlotSlider extends Component {
  static propTypes = {
    label: PropTypes.string,
    startInitTime: PropTypes.number.isRequired,
    endInitTime: PropTypes.number.isRequired,
    startTime: PropTypes.number.isRequired,
    endTime: PropTypes.number.isRequired,
    margin: PropTypes.number.isRequired,
    step: PropTypes.number.isRequired,
    onChange: PropTypes.func,
    isActive: PropTypes.bool,
    activate: PropTypes.func,
    disable: PropTypes.func,
    checkActivate: PropTypes.func,
    wrapperClass: PropTypes.string,
    disabled: PropTypes.bool
  };

  static defaultProps = {
    isActive: false
  };

  state = {
    status: "off",
    startLabel: "",
    endLabel: ""
  };

  componentWillMount() {
    this.convertValuesToTime([this.props.startTime, this.props.endTime]);
  }
  componentWillReceiveProps(nextProps) {
    this.convertValuesToTime([nextProps.startTime, nextProps.endTime]);
  }

  onFocus = () => {
    this.props.activate();
  };

  onBlur = () => {
    if (
      parseInt(this.props.startTime, 10) ===
        parseInt(this.props.startInitTime, 10) &&
      parseInt(this.props.endTime, 10) === parseInt(this.props.endInitTime, 10)
    ) {
      this.props.checkActivate();
    }
  };

  convertValuesToTime = values => {
    let hours0 = 0;
    let hours1 = 0;
    let minutes0 = 0;
    let minutes1 = 0;
    let label0 = "";
    let label1 = "";

    hours0 = this.convertToHour(values[0]);
    hours1 = this.convertToHour(values[1]);
    minutes0 = this.convertToMinute(values[0], hours0);
    minutes1 = this.convertToMinute(values[1], hours1);

    if (
      Number.parseInt(values[0], 10) === this.props.startInitTime &&
      Number.parseInt(values[1], 10) === this.props.endInitTime
    ) {
      label0 = "den ganzen Tag";
    } else {
      if (
        values[0] === 0 ||
        Number.parseInt(values[0], 10) === this.props.startInitTime
      ) {
        label0 = "";
      } else {
        label0 = `${this.formatHoursAndMinutes(hours0, minutes0)} Uhr `;
        if (
          values[1] === 0 ||
          Number.parseInt(values[1], 10) === this.props.endInitTime
        ) {
          label0 = `ab ${label0}`;
        }
      }
      if (
        values[1] === 0 ||
        Number.parseInt(values[1], 10) === this.props.endInitTime
      ) {
        label1 = "";
      } else {
        label1 = `bis ${this.formatHoursAndMinutes(hours1, minutes1)} Uhr`;
      }
    }

    this.setState({ startLabel: label0, endLabel: label1 });
  };

  convertToHour = value => Math.floor(value / 60);

  convertToMinute = (value, hour) => value - hour * 60;

  formatHoursAndMinutes = (hours, minutes) => {
    let lhours = hours;
    let lminutes = minutes;
    if (hours.toString().length === 1) {
      lhours = `0${hours}`;
    }
    if (minutes.toString().length === 1) {
      lminutes = `0${minutes}`;
    }
    return `${lhours}:${lminutes}`;
  };

  render() {
    const {
      label,
      startInitTime,
      endInitTime,
      startTime,
      endTime,
      margin,
      step,
      onChange,
      isActive,
      wrapperClass,
      disable,
      disabled
    } = this.props;

    const { status, startLabel, endLabel } = this.state;

    const wrapperClassConst = `${disabled ? "disabled" : ""} ${
      isActive ? "" : "inactive"
    } timeperiod-wrapper ${wrapperClass}`;

    return (
      <div
        role="button"
        className={wrapperClassConst}
        tabIndex="0"
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onClick={this.onFocus}
      >
        {isActive === false && (
          <div className="link">
            {status === "off" && (
              <React.Fragment>
                <img src={timeIcon} width="14" height="15" alt="time icon" />
                <span>{label}</span>
              </React.Fragment>
            )}
          </div>
        )}

        {isActive === true && (
          <React.Fragment>
            <input
              className={disabled ? "disabled" : "enabled"}
              type="text"
              value={`${startLabel + endLabel}`}
              disabled
            />
            <span className="hiddenlabel-sup">{label}</span>
            <Nouislider
              range={{ min: startInitTime, max: endInitTime }}
              start={[startTime, endTime]}
              connect
              behaviour="drag"
              margin={margin}
              step={step}
              onSlide={onChange}
              disabled={disabled}
            />
            {/* eslint-disable jsx-a11y/interactive-supports-focus */}
            <span role="button" className="clearbutton" onClick={disable}>
              ×
            </span>
            {/* eslint-enable jsx-a11y/interactive-supports-focus */}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default TimeSlotSlider;
