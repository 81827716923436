import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import { getMembers } from "state/entities/members/actions";
import { getTours } from "state/entities/tours/actions";
import { MultiSelect } from "../element/select";
import UserService from "../../service/UserService";
import Calendar from "../element/date/Calendar";
import { ASSIGNMENT_FILTER_TYPE } from "../../state/shipment/shipmentReducer";

const ContainerFilter = styled.div`
  flex: 5;
  display: flex;
`;

const ContainerFilterEntry = styled.div`
  flex: 1;
  margin-right: 4px;
`;

const ContainerFilterDate = styled(ContainerFilterEntry)`
  min-width: 150px;
  max-width: 150px;
  margin-right: 4px;
`;

const ContainerInformation = styled.div`
  flex: 1;
  min-width: 100px;
  max-width: 100px;
  text-align: right;
  align-self: center;
`;

class FilterActionBar extends Component {
  static propTypes = {
    filterOptions: PropTypes.array.isRequired,
    filterStatusGroups: PropTypes.shape({}).isRequired,
    filterValues: PropTypes.array.isRequired,
    handleFilterChange: PropTypes.func.isRequired,
    handleDateChange: PropTypes.func.isRequired,
    shipmentsTotal: PropTypes.number.isRequired,
    shipmentsFiltered: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
    selectedDate: PropTypes.object,
    drivers: PropTypes.array,
    members: PropTypes.shape({}).isRequired,
    tours: PropTypes.shape({}).isRequired,
    handleDriverFilter: PropTypes.func,
    filterDriver: PropTypes.array,
    status: PropTypes.shape({}).isRequired
  };

  componentDidMount() {
    this.props.dispatch(getMembers());
    this.props.dispatch(getTours());
  }

  /**
   * Get status options for MultiSelect.
   * @returns {array}
   */
  getStatusOptions() {
    const { filterOptions, filterStatusGroups, status } = this.props;
    if (!filterOptions || Object.keys(status).length === 0) return [];

    // status groups
    const groupOptions = Object.values(filterStatusGroups)
      .map(({ id, name }) => ({
        label: name,
        value: `group${id}`,
        groupId: id
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    // status
    const statusOptions = filterOptions
      .map(
        statusId =>
          status[statusId] && {
            label: status[statusId].label,
            value: `status${statusId}`,
            statusId
          }
      )
      .filter(Boolean)
      .sort((a, b) => a.label.localeCompare(b.label));

    // show groups above status
    return [...groupOptions, ...statusOptions];
  }

  loadTour() {
    this.props.dispatch(getTours());
  }

  render() {
    const {
      filterValues,
      handleFilterChange,
      shipmentsTotal,
      shipmentsFiltered,
      handleDateChange,
      selectedDate,
      drivers,
      handleDriverFilter,
      filterDriver,
      members,
      tours
    } = this.props;

    const statusOptions = this.getStatusOptions();

    const assignOptions = [];
    if (drivers && drivers.length) {
      assignOptions.push({ label: "Fahrer", value: "", disabled: true });
      for (let i = 0, len = drivers.length; i < len; i += 1) {
        assignOptions.push({
          label: UserService.getExtendedDriverLabel(drivers[i]),
          value: drivers[i].id,
          id: drivers[i].id,
          type: ASSIGNMENT_FILTER_TYPE.MEMBER
        });
      }
    }

    if (
      Object.keys(tours.entities).length &&
      Object.keys(members.entities).length
    ) {
      assignOptions.push({ label: "Touren", value: "", disabled: true });
      Object.values(tours.entities).forEach(tour => {
        assignOptions.push({
          label: UserService.getTourLabel(tour),
          value: tour.id,
          id: tour.id,
          type: ASSIGNMENT_FILTER_TYPE.TOUR
        });
      });
    }

    return (
      <Fragment>
        <ContainerFilter>
          <ContainerFilterDate>
            <Calendar
              id="dateRangePicker"
              selectedDate={selectedDate}
              handleOnChange={handleDateChange}
            />
          </ContainerFilterDate>

          <ContainerFilterEntry>
            <MultiSelect
              placeholder="Nach Status filtern"
              options={statusOptions}
              onChange={handleFilterChange}
              value={filterValues}
            />
          </ContainerFilterEntry>

          <ContainerFilterEntry>
            <MultiSelect
              placeholder="Nach Fahrer / Tour filtern"
              options={assignOptions}
              onChange={handleDriverFilter}
              value={filterDriver}
            />
          </ContainerFilterEntry>
        </ContainerFilter>

        <ContainerInformation>
          {shipmentsFiltered} von {shipmentsTotal}
        </ContainerInformation>
      </Fragment>
    );
  }
}

function mapStateToProps({ driver, entities: { members, tours } }) {
  return {
    drivers: driver.drivers,
    members,
    tours
  };
}

export default connect(mapStateToProps)(FilterActionBar);
