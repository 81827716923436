import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ProgressAdd } from "features/shipment";
import * as shipmentAction from "state/shipment/shipmentAction";
import * as trackAction from "state/track/trackAction";
import CreateEditShipment from "../shipment/CreateEditShipment";
import SettingsModal from "../settings/SettingsModal";

class ModalPage extends Component {
  static propTypes = {
    showShipmentModal: PropTypes.bool,
    showAdditionalModal: PropTypes.string,
    handleOnClose: PropTypes.func,
    newProgressModalShipmentId: PropTypes.number.isRequired,
    showNewProgressModal: PropTypes.func.isRequired,
    reloadCurrentTrack: PropTypes.func.isRequired
  };

  /**
   * Close progress modal.
   */
  onCloseProgressAdd = () => {
    const { showNewProgressModal } = this.props;
    showNewProgressModal(0);
  };

  /**
   * Handle reloading of tracking page.
   * A bit hacky and not the best place to do this here.
   */
  onSuccessProgressAdd = () => {
    const { reloadCurrentTrack } = this.props;
    const { pathname } = window.location;
    if (pathname.includes("/track/")) {
      reloadCurrentTrack();
    }
  };

  handleOnModalClose = () => {
    // console.log("handleOnModalClose");
    if (this.props.handleOnClose) {
      this.props.handleOnClose();
    }
  };

  handleOnModalSubmit = () => {
    // console.log("handleOnModalSubmit");
    if (this.props.handleOnClose) {
      this.props.handleOnClose();
    }
  };

  render() {
    const {
      showShipmentModal,
      showAdditionalModal,
      newProgressModalShipmentId
    } = this.props;

    return (
      <React.Fragment>
        {showShipmentModal && (
          <CreateEditShipment
            showModal={showShipmentModal}
            handleClose={this.handleOnModalClose}
            handleSubmit={this.handleOnModalSubmit}
          />
        )}
        {showAdditionalModal === "settings" && (
          <SettingsModal
            showModal={showAdditionalModal === "settings"}
            handleClose={this.handleOnModalClose}
            handleSubmit={this.handleOnModalSubmit}
          />
        )}
        {newProgressModalShipmentId > 0 && (
          <ProgressAdd
            shipmentId={newProgressModalShipmentId}
            onClose={this.onCloseProgressAdd}
            onSuccess={this.onSuccessProgressAdd}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ shipment: { newProgressModalShipmentId } }) => ({
  newProgressModalShipmentId
});

const mapDispatchToProps = {
  showNewProgressModal: shipmentAction.showNewProgressModal,
  reloadCurrentTrack: trackAction.reloadCurrentTrack
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalPage);
