import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getMembers,
  createMember,
  updateMember,
  deleteMember
} from "state/entities/members/actions";

import TCModal from "../../components/element/overlay/TCModal";
import CreateEditEmployeeSubForm from "./CreateEditEmployeeSubForm";

import ScrollList from "../element/scrollList/ScrollList";
import UserService from "../../service/UserService";
import { resetPassword } from "../../state/password/passwordAction";
import Loading from "../Loading/Loading";

class CreateEditEmployeeForm extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    selectedGroup: PropTypes.number.isRequired,
    handleOnAdd: PropTypes.func,
    handleOnEdit: PropTypes.func,
    dispatch: PropTypes.func.isRequired,
    members: PropTypes.shape({}).isRequired
  };

  state = {
    editEmployeeItem: null,
    showModal: false,
    clearSelection: false
  };

  componentDidMount() {
    this.refreshMembers();
  }

  onAddClicked = () => {
    this.setState({ editEmployeeItem: null, showModal: true });
    /*
    if( this.props.handleOnAdd ) {
      this.props.handleOnAdd(this.state.selectedGroup);
    }
    */
  };

  onEditClicked = entry => {
    this.setState({ editEmployeeItem: entry, showModal: true });
    /*
    if(Object.keys(entry).length > 0) {
      if( this.props.handleOnEdit ) {
        this.props.handleOnEdit( entry, this.state.selectedGroup );
      }
    }
    */
  };

  getEmployeesByGroups() {
    const {
      selectedGroup,
      members: { entities, dispatcherIds, driverIds }
    } = this.props;

    const currentMemberIds = selectedGroup === 4 ? driverIds : dispatcherIds;
    const output = currentMemberIds.map(memberId => {
      const member = entities[memberId];

      const subTitle = [member.email, member.ext_id && `ID: ${member.ext_id}`]
        .filter(e => e)
        .join("  |  ");

      return {
        ...member,
        title: UserService.getName(member),
        subTitle
      };
    });
    return output;
  }

  refreshMembers() {
    this.props.dispatch(getMembers());
  }

  handleClose = () => {
    this.setState({ showModal: false });
  };

  handleSubmit = employeeItem => {
    const {
      members: { entities }
    } = this.props;
    const matchExtID = Object.values(entities)
      .filter(({ ext_id: extId }) => typeof extId === "number")
      .filter(({ id }) => id !== employeeItem.id)
      .filter(({ ext_id: extId }) => extId === employeeItem.ext_id);

    if (matchExtID.length > 0) {
      return "DUPLICATE_EXT_ID";
    }

    this.setState({ showModal: false });
    if (employeeItem.id) {
      this.props.dispatch(updateMember(employeeItem)).then(() => {
        this.refreshMembers();
      });
    } else {
      this.props.dispatch(createMember(employeeItem)).then(() => {
        this.refreshMembers();
      });
    }
    return "";
  };

  handleReset = employeeEmail => {
    this.props.dispatch(resetPassword(employeeEmail));
  };

  handleDelete = employeeItem => {
    this.setState({ showModal: false });
    this.props.dispatch(deleteMember(employeeItem)).then(() => {
      this.refreshMembers();
    });
  };

  handleAbort = () => {
    this.setState({ showModal: false, clearSelection: true });
  };

  render() {
    const { members, selectedGroup, token, ...otherProps } = this.props;
    const { editEmployeeItem, showModal, clearSelection } = this.state;

    return (
      <div {...otherProps}>
        {!members.loaded && <Loading />}
        {members.loaded && (
          <React.Fragment>
            <ScrollList
              height={400 - 33} // except button height
              entries={this.getEmployeesByGroups()}
              onEdit={this.onEditClicked}
              onAdd={this.onAddClicked}
              clear={clearSelection}
              selectedGroup={selectedGroup}
              token={token}
            />
            <TCModal show={showModal} subForm>
              <CreateEditEmployeeSubForm
                selectedGroup={selectedGroup}
                editEmployeeItem={editEmployeeItem}
                handleSubmit={this.handleSubmit}
                handleReset={this.handleReset}
                handleAbort={this.handleAbort}
                handleDelete={this.handleDelete}
              />
            </TCModal>
          </React.Fragment>
        )}
      </div>
    );
  }
}

function mapStateToProps({ auth, entities: { members } }) {
  return {
    token: auth.token,
    members
  };
}

export default connect(mapStateToProps)(CreateEditEmployeeForm);
