import { call, put, takeLatest } from "redux-saga/effects";

import ApiService from "../../service/ApiService";
import { EXTERNAL_READ_REQUEST, EXTERNAL_READ } from "./index";

function* fetchExternalCompniesRel({ payload }) {
  try {
    const result = yield call(
      ApiService.getExternalComapniesRel,
      payload.token
    );
    yield put({
      type: EXTERNAL_READ,
      payload: result.companies
    });
  } catch (error) {
    yield put({
      type: EXTERNAL_READ,
      payload: error,
      error: true
    });
  }
}

export default function* loadExternalCompaniesRelSaga() {
  yield takeLatest(EXTERNAL_READ_REQUEST, fetchExternalCompniesRel);
}
