import { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import io from "socket.io-client";
import { apiHost } from "common/config";
import {
  SHIPMENTS_SOCKET_CREATE,
  SHIPMENTS_SOCKET_UPDATE,
  SHIPMENTS_SOCKET_DELETE
} from "state/shipment";
import { MESSAGE_UPDATE } from "state/dialog";
import {
  MEMBER_CONNECTIVITY_CHANGE,
  DRIVER_LOCATION_UPDATE
} from "state/driver";

// emit events
const EVENT_EMIT_AUTHENTICATE = "authenticate";

// listener events
const EVENT_SHIPMENTS_CREATE = "shipments_create";
const EVENT_SHIPMENTS_UPDATE = "shipments_update";
const EVENT_SHIPMENTS_DELETE = "shipments_delete";
const EVENT_CHATMESSAGE_CREATE = "chatmessage_create";
const EVENT_CHATMESSAGE_UPDATE = "chatmessage_update";
const EVENT_LOCATION_CREATE = "location_create";
const EVENT_MEMBER_CONNECTIVITY_CHANGE = "member_connectivity_change";

/**
 * Handles socket connection and calls redux events to update the state.
 */
const SocketHandler = ({ token, logisticcompanyId, dispatch }) => {
  const [connected, setConnected] = useState(false);

  // The “ref” object is a generic container whose current property
  // is mutable and can hold any value, similar to an instance property on a class.
  // See https://reactjs.org/docs/hooks-faq.html#is-there-something-like-instance-variables
  const socket = useRef(null);

  useEffect(() => {
    // create only one socket instance
    socket.current = io(apiHost);

    // initialize event listeners
    socket.current.on("connect", () => {
      console.log("Socket connected");
      setConnected(true);
    });
    socket.current.on("disconnect", () => {
      console.log("Socket disconnected");
      setConnected(false);
    });
    socket.current.on(EVENT_SHIPMENTS_CREATE, ({ content }) => {
      console.log(EVENT_SHIPMENTS_CREATE, content);
      dispatch({
        type: SHIPMENTS_SOCKET_CREATE,
        payload: content
      });
    });
    socket.current.on(EVENT_SHIPMENTS_UPDATE, ({ content }) => {
      console.log(EVENT_SHIPMENTS_UPDATE, content);
      dispatch({
        type: SHIPMENTS_SOCKET_UPDATE,
        payload: content
      });
    });
    socket.current.on(EVENT_SHIPMENTS_DELETE, ({ content }) => {
      console.log(EVENT_SHIPMENTS_DELETE, content);
      dispatch({
        type: SHIPMENTS_SOCKET_DELETE,
        payload: content
      });
    });
    socket.current.on(EVENT_CHATMESSAGE_CREATE, message => {
      console.log(EVENT_CHATMESSAGE_CREATE, message);
      dispatch({
        type: MESSAGE_UPDATE,
        payload: message
      });
    });
    socket.current.on(EVENT_CHATMESSAGE_UPDATE, message => {
      console.log(EVENT_CHATMESSAGE_UPDATE, message);
      dispatch({
        type: MESSAGE_UPDATE,
        payload: message
      });
    });
    socket.current.on(EVENT_LOCATION_CREATE, ({ content }) => {
      console.log(EVENT_LOCATION_CREATE, content);
      dispatch({
        type: DRIVER_LOCATION_UPDATE,
        payload: content
      });
    });
    socket.current.on(EVENT_MEMBER_CONNECTIVITY_CHANGE, ({ content }) => {
      console.log(EVENT_MEMBER_CONNECTIVITY_CHANGE, content);
      dispatch({
        type: MEMBER_CONNECTIVITY_CHANGE,
        payload: content
      });
    });

    return () => {
      // close socket on end of lifetime
      socket.current.close();
    };
    // Guarantee that this effect is only running once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (connected) {
      // when connection, token or logisticcompanyId changed, emit authenticate
      console.log("Socket authenticate");
      socket.current.emit(EVENT_EMIT_AUTHENTICATE, {
        token,
        logisticcompanyId
      });
    }
  }, [connected, token, logisticcompanyId]);

  return null;
};

const mapStateToProps = ({
  auth: { token, logisticCompanyId: logisticcompanyId }
}) => ({
  token,
  logisticcompanyId
});

// export it for testing
export const SocketHandlerComponent = SocketHandler;

export default connect(mapStateToProps)(SocketHandler);
