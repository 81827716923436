import React, { Component } from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import styled from "styled-components";
import { Box } from "reflexbox";
import {
  StandardSelection,
  StandardInput,
  StandardButton
} from "@trackcode/ui";
import CI from "@trackcode/ci";

/**
 * Build media object for shipment progress.
 */
class ProgressMedia extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    getMedia: PropTypes.func.isRequired,
    getFiles: PropTypes.func.isRequired
  };

  state = {
    signatureSalutation: "tcl_mr",
    signatureName: "",
    imageDescription: "",
    files: null
  };

  componentWillMount() {
    const { getMedia, getFiles } = this.props;
    getMedia({});
    getFiles(null);
  }

  /**
   * Handle changes for Selections and Inputs.
   */
  onChangeField = fieldName => event => {
    const value = event.value ? event.value : event.target.value;
    this.setState({ [fieldName]: value }, () => {
      // update media object after state changed
      this.updateMedia();
    });
  };

  onDrop = files => {
    const { getFiles } = this.props;
    this.setState({ files }, () => {
      this.updateMedia();
    });
    getFiles(files);
  };

  clearFiles = e => {
    const { getFiles } = this.props;
    e.stopPropagation();
    this.setState({ files: null, imageDescription: "" }, () => {
      this.updateMedia();
    });
    getFiles(null);
  };

  /**
   * Build media object based on states.
   * ```
   * {
   * signature_name: "",
   * signature_salutation: "Frau",
   * imagenode: {
   *   description: "",
   *   images: [
   *     {
   *       name: "",
   *       uuid: ""
   *     }
   *   ]
   *  }
   * }
   * ```
   */
  updateMedia() {
    const { getMedia } = this.props;
    const {
      signatureSalutation,
      signatureName,
      files,
      imageDescription
    } = this.state;

    const media = {};
    if (signatureSalutation && signatureName) {
      media.signature_salutation = signatureSalutation;
      media.signature_name = signatureName;
    }
    if (files) {
      const { name } = files[0];
      media.imagenode = {
        description: imageDescription,
        images: [
          {
            name
          }
        ]
      };
    }
    // valid media object and pass it to parent
    getMedia(media);
  }

  render() {
    const { type } = this.props;
    const {
      signatureSalutation,
      signatureName,
      files,
      imageDescription
    } = this.state;

    return (
      <Wrapper>
        {type === "signature" && (
          <Row>
            <StandardSelection
              noSuggestionsText="Keine Ergebnisse..."
              items={[
                { value: "tcl_mr", content: "Herr" },
                { value: "tcl_mrs", content: "Frau" }
              ]}
              selectedItem={{ value: signatureSalutation }}
              onChange={this.onChangeField("signatureSalutation")}
              showSearchFilter={false}
            />
            <StandardInput
              shouldFitContainer
              placeholder="Vor- & Nachname"
              value={signatureName}
              onChange={this.onChangeField("signatureName")}
            />
          </Row>
        )}
        <Row>
          <Dropzone
            multiple={false}
            accept=".png,.jpg,.jpeg,.pdf,.gif"
            onDrop={this.onDrop}
            style={{
              display: "flex",
              flex: 1,
              marginRight: "7px",
              padding: "8px",
              cursor: "pointer",
              border: "1px dashed #777777",
              borderRadius: "2px",
              fontSize: "13px",
              textAlign: "center",
              background: files ? "#fff" : "none",
              minHeight: "80px",
              alignItems: "center"
            }}
            activeStyle={{
              fontSize: "13px",
              borderColor: CI.color.brand
            }}
          >
            {!files && (
              <Box flex={1}>
                <div>
                  Bild zum Anhängen hier ablegen oder{" "}
                  <span style={{ color: "#7eb73d" }}>durchsuchen.</span>
                </div>
                <div>(png, jpg, jpeg, pdf, gif)</div>
              </Box>
            )}
            {files && files[0] && (
              <Box flex={1}>
                <div>{files[0].name}</div>
                <div>
                  <StandardButton
                    style={{ marginBottom: "-8px" }}
                    appearance="subtle-link"
                    onClick={this.clearFiles}
                  >
                    Auswahl löschen
                  </StandardButton>
                </div>
              </Box>
            )}
          </Dropzone>
        </Row>
        {/* description for image */}
        {files && (
          <Row>
            <StandardInput
              shouldFitContainer
              placeholder="Beschreibung"
              value={imageDescription}
              onChange={this.onChangeField("imageDescription")}
            />
          </Row>
        )}
      </Wrapper>
    );
  }
}

export default ProgressMedia;

const Wrapper = styled.div``;

const Row = styled.div`
  display: flex;
  flex: 1;
  padding-bottom: 13px;
  align-items: center;
`;
