import React, { Component } from "react";
import PropTypes from "prop-types";
import RBFormGroup from "react-bootstrap/lib/FormGroup";
import RBFormControl from "react-bootstrap/lib/FormControl";
import RBFormControlLabel from "react-bootstrap/lib/ControlLabel";
import RBInputGroup from "react-bootstrap/lib/InputGroup";
import RBInputGroupAddon from "react-bootstrap/lib/InputGroupAddon";
import RBInputGroupButton from "react-bootstrap/lib/InputGroupButton";
import classNames from "classnames";

import "./floatingtextfield.css";

class FloatingTextField extends Component {
  static propTypes = {
    value: PropTypes.any,
    placeholder: PropTypes.string,
    type: PropTypes.oneOf(["text", "number", "email", "password"]),
    style: PropTypes.oneOf(["success", "warning", "error"]),
    size: PropTypes.oneOf(["small", "medium", "large"]),
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    buttonLeft: PropTypes.element,
    buttonRight: PropTypes.element,
    addonLeft: PropTypes.node,
    addonRight: PropTypes.node,
    label: PropTypes.string,
    classNameWrapper: PropTypes.string,
    classNameInput: PropTypes.string,
    classNameLabel: PropTypes.string,
    floatingLabel: PropTypes.string,
    defaultValue: PropTypes.string
  };

  static defaultProps = {
    type: "text"
  };

  state = {
    status: "off",
    focus: "blur"
  };

  componentWillMount() {
    if (
      this.props.value ||
      (this.props.placeholder && this.props.placeholder.length > 0)
    ) {
      setTimeout(() => {
        this.setState({ status: "on" });
      }, 300);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.value ||
      (nextProps.placeholder && nextProps.placeholder.length > 0)
    ) {
      setTimeout(() => {
        this.setState({ status: "on" });
      }, 50);
    }
  }
  toggleFocus() {
    if (this.state.focus === "focus") {
      this.setState({ focus: "blur" });
    } else {
      this.setState({ focus: "focus" });
    }
  }

  handleChange = () => {
    this.toggleFocus();
    if (this.state.status === "off") {
      this.setState({ status: "on" });
    } else if (
      (this.props.value === "" ||
        this.props.value === null ||
        this.props.value === undefined) &&
      !this.props.placeholder
    ) {
      this.setState({ status: "off" });
    }
  };

  render() {
    const {
      style,
      size,
      buttonLeft,
      buttonRight,
      addonLeft,
      addonRight,
      label,
      classNameLabel,
      classNameWrapper,
      floatingLabel,
      disabled,
      ...otherProps
    } = this.props;

    const { status, focus } = this.state;

    const input = <RBFormControl disabled={disabled} {...otherProps} />;

    return (
      <RBFormGroup
        bsSize={size}
        bsStyle={style}
        className={`${status} floatingtextfield ${classNameWrapper} ${focus}`}
        onFocus={this.handleChange}
        onBlur={this.handleChange}
      >
        {label && (
          <RBFormControlLabel className={classNameLabel}>
            {label}
          </RBFormControlLabel>
        )}

        {(buttonLeft || buttonRight || addonLeft || addonRight) && (
          <RBInputGroup>
            {buttonLeft && (
              <RBInputGroupButton>{buttonLeft}</RBInputGroupButton>
            )}
            {addonLeft && <RBInputGroupAddon>{addonLeft}</RBInputGroupAddon>}
            {input}
            {buttonRight && (
              <RBInputGroupButton>{buttonRight}</RBInputGroupButton>
            )}
            {addonRight && <RBInputGroupAddon>{addonRight}</RBInputGroupAddon>}
          </RBInputGroup>
        )}
        {!(buttonLeft || buttonRight || addonLeft || addonRight) && input}
        <div className="hiddenlabel-sup"> {floatingLabel} </div>
        <div
          className={classNames("hiddenlabel-main", {
            "hiddenlabel-main-small": size === "small",
            "hiddenlabel-main-large": size === "large"
          })}
        >
          {" "}
          {floatingLabel}{" "}
        </div>
      </RBFormGroup>
    );
  }
}

export default FloatingTextField;
