import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import Panel from "./Panel";

import styleNames from "./trackPanel.local.css";

const cx = classNames.bind(styleNames);

const TrackPanel = ({ header, footer, children, ...otherProps }) => (
  <div className={cx("ci-trackpanel-container")} {...otherProps}>
    <Panel header={header} footer={footer} className={cx("ci-trackpanel")}>
      {children}
    </Panel>
  </div>
);

TrackPanel.propTypes = {
  header: PropTypes.node,
  footer: PropTypes.node,
  children: PropTypes.any
};

export default TrackPanel;
