import { FETCH_JOBS_REQUEST, CREATE_JOBS_REQUEST } from "./index";

export const loadJobs = (token, filter) => ({
  type: FETCH_JOBS_REQUEST,
  payload: {
    token,
    ...filter
  }
});

export const createJobs = (token, assignment, shipments, status) => ({
  type: CREATE_JOBS_REQUEST,
  payload: {
    token,
    assignment,
    shipments,
    status
  }
});
