import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { Flex, Box } from "reflexbox";
import ReactTooltip from "react-tooltip";
import { format } from "date-fns";
import CI from "@trackcode/ci";
import { Icon, StandardDropdown, StandardButton } from "@trackcode/ui";
import { TASK_TYPES } from "state/entities/tasks/constants";
import { showShipmentEdit } from "features/shipment/state/action";
import { getTasks } from "state/entities/tasks/selectors";
import { getShipments } from "state/entities/shipmentsV2/selectors";

// import goLogo from "asset/image/go.png";
import gelLogo from "asset/image/gel.png";

const mutedColor = "#777777";

export const TaskItem = React.memo(
  ({ isSelected, select, setMultiSelect, isRangeSelect, taskID, style }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const { [taskID]: task } = useSelector(getTasks);
    if (!task) {
      return null;
    }
    const { [task.shipmentID]: shipment } = useSelector(getShipments);
    if (!shipment) {
      return null;
    }

    const isPickUp =
      task.type === TASK_TYPES.PICKUP || task.type === TASK_TYPES.PICKUP_DIRECT;
    const isCompleted = task.completed !== null;
    const destination = isPickUp ? shipment.sender : shipment.recipient;

    return (
      <ShipmentItemContent
        selected={isSelected}
        onClick={() => {
          select(taskID);
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{ minHeight: "24px", paddingBottom: "3px", ...style }}
      >
        <Box ml="6px" width="25px">
          {isHovered || isDropdownOpen || isSelected ? (
            <input
              style={{
                margin: "0",
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)"
              }}
              type="checkbox"
              checked={isSelected}
              readOnly
              onClick={() => !isRangeSelect && setMultiSelect(true)}
              onBlur={() => setMultiSelect(false)}
            />
          ) : (
            <div style={{ width: "12px", color: "#777777" }}>0</div>
          )}
        </Box>
        <EllipsisBox flex={4}>
          <ShowAddress destination={destination} />
        </EllipsisBox>
        <Flex flex={2} flexDirection="row" alignItems="center">
          <Box mr="6px">{shipment.pieces.length}</Box>
          <Box mr="6px">
            <AlignedIcon name="parcel" size="sm" fill="#777777" />
          </Box>
          <Box mr="6px">
            {isPickUp && <>{isCompleted ? "abgeholt" : "abholen"}</>}
            {!isPickUp && <>{isCompleted ? "zugestellt" : "zustellen"}</>}
          </Box>
          <Box>
            <img
              src={gelLogo}
              alt="netzwerk"
              style={{
                filter: "grayscale(100%)",
                opacity: 0.5
              }}
              width="27px"
            />
          </Box>
        </Flex>
        <EllipsisBox flex={1.2}>
          <ShowDate date={destination.appointment_from} />
        </EllipsisBox>
        <EllipsisBox flex={1.2}>
          <ShowTime
            start={destination.appointment_from}
            end={destination.appointment_till}
          />
          <ShowAvis avis={destination.avis} />
        </EllipsisBox>
        <Flex flex={0.5}>
          {isCompleted && (
            <CheckIcon
              width={18}
              height={18}
              stroke={CI.color.brand}
              style={{ marginRight: "6px" }}
            />
          )}
        </Flex>
        {isHovered || isDropdownOpen ? (
          <ShipmentItemActions
            shipment={shipment}
            isSelected={isSelected}
            isRangeSelect={isRangeSelect}
            onDropdownToggle={setIsDropdownOpen}
          />
        ) : null}
      </ShipmentItemContent>
    );
  }
);

export const TaskItemHeader = () => {
  return (
    <Flex
      style={{
        backgroundColor: "#f2f2f2",
        borderTop: "1px solid #CDD1D0",
        fontSize: "11px"
      }}
      pt="4px"
      pb="0px"
    >
      <EllipsisBox flex={4} ml="30px">
        Ort&nbsp;
        <span style={{ color: "#777" }}>(Straße, PLZ, Stadt)</span>
      </EllipsisBox>
      <EllipsisBox flex={2.0}>Aufgabe</EllipsisBox>
      <EllipsisBox flex={1.2}>Fälligkeitsdatum</EllipsisBox>
      <EllipsisBox flex={1.2}>Zeitfenster</EllipsisBox>
      <EllipsisBox flex={0.5}></EllipsisBox>
    </Flex>
  );
};

const ShowAddress = ({ destination }) => {
  const {
    address_street: street,
    address_zipcode: zip,
    address_city: city,
    address_state: state
  } = destination;
  const inlineAddress = [street, [zip, city].filter(Boolean).join(" "), state]
    .filter(Boolean)
    .join(", ");
  return <AlignedText>{inlineAddress}</AlignedText>;
};

ShowAddress.propTypes = {
  destination: PropTypes.shape({
    address_street: PropTypes.string,
    address_zipcode: PropTypes.number,
    address_city: PropTypes.string,
    address_state: PropTypes.string,
    address_country: PropTypes.string
  }).isRequired
};

const ShowDate = ({ date }) => {
  if (!date) {
    return null;
  }

  const currYear = format(new Date(), "YY");
  const year = format(date, "YY");
  const dayMonth = format(date, "DD.MM.");

  return (
    <>
      {dayMonth}
      {currYear !== year ? year : ""}
    </>
  );
};

const ShowTime = ({ start, end }) => {
  if (!start && !end) {
    return "";
  }
  const startString = start ? format(start, "HH:mm") : "";
  const endString = end ? format(end, "HH:mm") : "";
  let timeString = "";
  if (end && start) {
    timeString = ` ${startString} - ${endString} `;
  } else if (start && !end) {
    timeString = ` ab ${startString} `;
  } else {
    timeString = ` bis ${endString} `;
  }
  return <AlignedText>{timeString.trim()}</AlignedText>;
};

ShowTime.propTypes = {
  start: PropTypes.instanceOf(Date),
  end: PropTypes.instanceOf(Date)
};

const ShowAvis = ({ avis }) => {
  if (!avis) {
    return "";
  }
  return (
    <>
      <AlignedIcon
        name="message-text"
        size="sm"
        data-tip={avis}
        fill={mutedColor}
      />
      <ReactTooltip place="top" type="dark" effect="solid" />
    </>
  );
};

const LinkWrapper = props => (
  <Link style={{ color: "#333", textDecoration: "none" }} {...props} />
);

const ShipmentItemActions = connect()(
  ({ shipment, isSelected, onDropdownToggle, dispatch }) => (
    <ShipmentItemActionsWrapper onClick={e => e.stopPropagation()}>
      <StandardDropdown
        placement="bottomRight"
        renderButton={({ toggle, isOpen }) => {
          onDropdownToggle(isOpen);
          return (
            <StandardButtonAction
              spaceAfter={false}
              isOpen={isOpen}
              isSelected={isSelected}
              onClick={() => toggle()}
            >
              <AlignedIcon name="more-vertical" size="md" />
            </StandardButtonAction>
          );
        }}
      >
        {Item => [
          <Item
            onClick={() => dispatch(showShipmentEdit(shipment.id))}
            key="showDetails"
          >
            Auftragsdetails anzeigen
          </Item>,

          <LinkWrapper
            to={`/track/${shipment.group_reference}/progress`}
            key="addProgress"
          >
            <Item>Auftragsstatus hinzufügen</Item>
          </LinkWrapper>,
          <LinkWrapper
            to={`/track/${shipment.group_reference}`}
            key="trackShipment"
          >
            <Item>Sendung verfolgen</Item>
          </LinkWrapper>
        ]}
      </StandardDropdown>
    </ShipmentItemActionsWrapper>
  )
);

const EllipsisBox = styled(Box)`
  overflow: hidden;
  white-space: no-wrap;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  color: #444;
  &::after {
    content: " ";
    white-space: ${({ children }) => (children ? "pre" : "normal")};
  }
`;

const AlignedIcon = props => (
  // eslint-disable-next-line react/prop-types
  <Icon {...props} style={{ verticalAlign: "middle", ...props.style }} />
);

const AlignedText = styled.span`
  vertical-align: middle;
  line-height: 1;
`;

const ShipmentItemActionsWrapper = styled.div`
  position: absolute;
  right: 4px;
  top: 2px;
`;

const ShipmentItemContent = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  background: ${({ selected }) => (selected ? "#ffffcc" : "none")};
  cursor: pointer;
  border-top: 1.5px dotted ${CI.color.gray};
  &:first-child {
    padding-top: 4px;
    border: none;
  }
`;

const StandardButtonAction = styled(StandardButton)`
  min-height: 26px;
  background: ${({ isOpen }) => (isOpen ? CI.color.grayLight : "#fff")};
  border: 0;
  box-shadow: ${({ isOpen, isSelected }) =>
    isOpen || isSelected ? "inset 0px 0px 2px 0px rgba(0,0,0,0.2)" : "none"};
  padding: 0px 6px 0px 6px;
`;

const dashAnimation = keyframes`
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
`;

const CheckIconPolyline = styled.polyline`
  fill: transparent;
  stroke: ${({ stroke }) => stroke || "#fff"};
  stroke-width: 11;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: ${dashAnimation} 0s ease-in-out forwards;
  animation-iteration-count: 1;
  animation-delay: 0s;
`;

const CheckIcon = props => (
  <svg viewBox="0 0 130 130" {...props}>
    <CheckIconPolyline
      points="22.7,69.3 48.8,95.2 110,34"
      stroke={props.stroke}
    />
  </svg>
);
