import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import Spinkit from "react-spinner";
import "react-spinner/react-spinner.css";
import styleNames from "./spinner.local.css";

const cx = classNames.bind(styleNames);
/**
 * this class is a loading placeholder
 */
const Loading = ({ className, ...otherProps }) => {
  const cname = cx("alert", "alert-info", "ci-spinner-container", className);
  return (
    <div className={cname} role="alert" {...otherProps}>
      <Spinkit className={cx("ci-spinner")} />
    </div>
  );
};

Loading.propTypes = {
  className: PropTypes.string
};

export default Loading;
