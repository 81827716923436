import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { AppBar } from "@trackcode/ui";
import logoTrackCode from "asset/image/trackcode-logo.png";
import "./loginlayout.css";

const LoginLayout = ({ children, ...otherProps }) => (
  <div className="loginLayout" {...otherProps}>
    <Header role="navigation">
      <AppBar
        showMenu={false}
        logo={
          <img
            src={logoTrackCode}
            alt="TrackCode Logo"
            style={{
              height: "32px",
              padding: "5px 10px"
            }}
          />
        }
      />
    </Header>
    <div className="container">
      <br />
      <br />
      <div className="row">
        <div className="col-md-4 col-md-offset-4">{children}</div>
      </div>
    </div>
  </div>
);

LoginLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default LoginLayout;

const Header = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;
