import { createSelector } from "reselect";
import {
  getShipments,
  enhanceShipments
} from "state/entities/shipments/selectors";
import { getTours } from "state/entities/tours/selectors";
import { getStatus } from "state/entities/shipmentStatus/selectors";
import { getMembers } from "state/entities/members/selectors";

const getShipmentByTrackcode = (state, trackcode) => {
  const shipmentMap = getShipments(state);
  return Object.values(shipmentMap).find(
    shipment => shipment.trackcode === trackcode
  );
};

export const getEnhancedShipmentByTrackcode = createSelector(
  [getShipmentByTrackcode, getStatus, getTours, getMembers],
  (shipment, status, tours, members) => {
    if (!shipment) return null;
    const enhancedShipments = enhanceShipments(
      [shipment],
      status,
      tours,
      members
    );
    return enhancedShipments.length === 1 ? enhancedShipments[0] : null;
  }
);
