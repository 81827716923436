import React from "react";
import PropTypes from "prop-types";

const LoginMessage = ({ error, ...otherProps }) => (
  <div {...otherProps}>
    {error && <div className="alert alert-danger">{error}</div>}
  </div>
);

LoginMessage.propTypes = {
  error: PropTypes.string
};

export default LoginMessage;
