import {
  SHIPMENTS_READ_REQUEST,
  SHIPMENTS_CREATE_REQUEST,
  FILTER_SHIPMENTS_REQUEST,
  SHIPMENT_UPDATE_REQUEST,
  SHIPMENT_DELETE_REQUEST,
  SHIPMENT_STATUS_REQUEST,
  SHIPMENT_STATUS_GROUPS_REQUEST,
  SHIPMENT_ASSIGNMENT_DELETE_REQUEST,
  SHIPMENT_STATUS_VARIATIONS_REQUEST,
  SHIPMENT_NEW_PROGRESS_MODAL
} from "./index";

export const loadShipments = (token, start, end, tz) => ({
  type: SHIPMENTS_READ_REQUEST,
  payload: {
    token,
    dateRange: {
      start,
      end,
      tz
    }
  }
});

/**
 * DEPRECATED
 * Load available shipment status
 * @param {string} token
 */
export const loadShipmentStatus = token => ({
  type: SHIPMENT_STATUS_REQUEST,
  payload: { token }
});

/**
 * DEPRECATED
 * Load  shipment status groups
 * @param {string} token
 */
export const loadShipmentStatusGroups = token => ({
  type: SHIPMENT_STATUS_GROUPS_REQUEST,
  payload: { token }
});

/**
 * Load  status variations.
 * @param {string} token
 */
export const loadStatusVariations = () => ({
  type: SHIPMENT_STATUS_VARIATIONS_REQUEST
});

export const filterShipments = filter => ({
  type: FILTER_SHIPMENTS_REQUEST,
  payload: filter
});

export const createShipment = (token, shipments) => ({
  type: SHIPMENTS_CREATE_REQUEST,
  payload: {
    token,
    shipments
  }
});

export const updateShipment = (token, shipments) => ({
  type: SHIPMENT_UPDATE_REQUEST,
  payload: {
    token,
    shipments
  }
});

/**
 * Delete shipment
 * @param {string} token DEPRECATED
 * @param {object} shipments
 */
export const deleteShipment = (token, shipments) => ({
  type: SHIPMENT_DELETE_REQUEST,
  shipments
});

/**
 * Delete shipment assignment
 * @param {Number} shipmentId
 */
export const deleteShipmentAssignment = shipmentId => ({
  type: SHIPMENT_ASSIGNMENT_DELETE_REQUEST,
  shipmentId
});

/**
 * Show or hide new progress modal.
 * Set to 0 to hide modal.
 * @param number shipmentId
 */
export const showNewProgressModal = shipmentId => ({
  type: SHIPMENT_NEW_PROGRESS_MODAL,
  shipmentId
});
