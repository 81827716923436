export const MEMBERS_GET_REQUEST = "MEMBERS_GET_REQUEST";
export const MEMBERS_GET_RECEIVE = "MEMBERS_GET_RECEIVE";
export const MEMBERS_GET_ERROR = "MEMBERS_GET_ERROR";

export const MEMBER_CREATE_REQUEST = "MEMBER_CREATE_REQUEST";
export const MEMBER_CREATE_RECEIVE = "MEMBER_CREATE_RECEIVE";
export const MEMBER_CREATE_ERROR = "MEMBER_CREATE_ERROR";

export const MEMBER_UPDATE_REQUEST = "MEMBER_UPDATE_REQUEST";
export const MEMBER_UPDATE_RECEIVE = "MEMBER_UPDATE_RECEIVE";
export const MEMBER_UPDATE_ERROR = "MEMBER_UPDATE_ERROR";

export const MEMBER_DELETE_REQUEST = "MEMBER_DELETE_REQUEST";
export const MEMBER_DELETE_RECEIVE = "MEMBER_DELETE_RECEIVE";
export const MEMBER_DELETE_ERROR = "MEMBER_DELETE_ERROR";
