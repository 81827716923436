import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import moment from "moment";
import Icon from "../element/icon/Icon";
import styleNames from "./messageItem.local.css";

const cx = classNames.bind(styleNames);

const MessageItem = ({ message, interlocutor }) => (
  <Fragment>
    {interlocutor.id === message.from_member_id && (
      <div key={message.socket_id} className={cx("direct-chat-msg")}>
        <div className={cx("direct-chat-info", "clearfix")}>
          <span className={cx("direct-chat-name", "pull-left")}>
            {/* message.from.membername */}
          </span>
        </div>
        <div className={cx("direct-chat-text")}>{message.text}</div>
        <div className={cx("message-time", "left")}>
          <span className={cx("direct-chat-timestamp", "pull-right")}>
            {moment(message.send_datetime).format("HH:mm")}
          </span>
        </div>
      </div>
    )}
    {interlocutor.id !== message.from_member_id && (
      <div className={cx("direct-chat-msg", "right-msg")}>
        <div className={cx("direct-chat-info", "clearfix")}>
          <span className={cx("direct-chat-name", "pull-right")} />
        </div>
        {/* /.direct-chat-info */}
        <div className={cx("direct-chat-text")}>{message.text}</div>
        <div className={cx("message-time", "right")}>
          <span className={cx("direct-chat-timestamp", "pull-right")}>
            {moment(message.send_datetime).format("HH:mm")}
            <span className={cx("message-transaction-icons")}>
              <Icon
                name={message.isArrived ? "check-all" : "check"}
                className={cx(
                  "ci-check-icon",
                  message.isRead ? "text-primary" : "text-muted"
                )}
                size="xs"
              />
            </span>
          </span>
        </div>
      </div>
    )}
  </Fragment>
);

MessageItem.propTypes = {
  message: PropTypes.object,
  interlocutor: PropTypes.object.isRequired
};

export default MessageItem;
