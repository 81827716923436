import React from "react";
import PropTypes from "prop-types";
import RBFormGroup from "react-bootstrap/lib/FormGroup";
import RBFormControl from "react-bootstrap/lib/FormControl";
import RBFormControlLabel from "react-bootstrap/lib/ControlLabel";
import RBInputGroup from "react-bootstrap/lib/InputGroup";
import RBInputGroupAddon from "react-bootstrap/lib/InputGroupAddon";
import RBInputGroupButton from "react-bootstrap/lib/InputGroupButton";

import "./textfield.css";

const TextField = ({
  style,
  size,
  loading,
  buttonLeft,
  buttonRight,
  addonLeft,
  addonRight,
  label,
  classNameLabel,
  classNameWrapper,
  floatingLabel,
  ...otherProps
}) => {
  const input = <RBFormControl {...otherProps} />;

  return (
    <RBFormGroup bsSize={size} bsStyle={style} className={classNameWrapper}>
      {label && (
        <RBFormControlLabel className={classNameLabel}>
          {label}
        </RBFormControlLabel>
      )}
      {(buttonLeft || buttonRight || addonLeft || addonRight) && (
        <RBInputGroup>
          {buttonLeft && <RBInputGroupButton>{buttonLeft}</RBInputGroupButton>}
          {addonLeft && <RBInputGroupAddon>{addonLeft}</RBInputGroupAddon>}
          {input}
          {buttonRight && (
            <RBInputGroupButton>{buttonRight}</RBInputGroupButton>
          )}
          {addonRight && <RBInputGroupAddon>{addonRight}</RBInputGroupAddon>}
        </RBInputGroup>
      )}
      {!(buttonLeft || buttonRight || addonLeft || addonRight) && input}
    </RBFormGroup>
  );
};

TextField.propTypes = {
  value: PropTypes.any,
  type: PropTypes.oneOf(["text", "number", "email", "password"]),
  style: PropTypes.oneOf(["success", "warning", "error"]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  buttonLeft: PropTypes.element,
  buttonRight: PropTypes.element,
  addonLeft: PropTypes.node,
  addonRight: PropTypes.node,
  label: PropTypes.string,
  classNameWrapper: PropTypes.string,
  classNameInput: PropTypes.string,
  classNameLabel: PropTypes.string,
  floatingLabel: PropTypes.string
};

TextField.defaultProps = {
  type: "text"
};

export default TextField;
