export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN = "LOGIN";
export const LOGIN_COOKIE_SUCCESS = "LOGIN_COOKIE_SUCCESS";
export const LOGIN_COOKIE_FAILURE = "LOGIN_COOKIE_FAILURE";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT = "LOGOUT";

export const LOGISTICCOMPANY_PREPARE_CHANGE = "LOGISTICCOMPANY_PREPARE_CHANGE";
export const LOGISTICCOMPANY_CHANGED = "LOGISTICCOMPANY_CHANGED";
export const MANAGED_LOGISTICCOMPANIES = "MANAGED_LOGISTICCOMPANIES";
