import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import MessageBoxList from "../../chat/MessageBoxList";

import styles from "./footer.local.css";

const cx = classNames.bind(styles);

const Footer = ({ chatPartner, hide }) => (
  <footer className={cx("footer")}>
    <MessageBoxList chatPartner={chatPartner} hide={hide} />
  </footer>
);

Footer.propTypes = {
  chatPartner: PropTypes.object,
  hide: PropTypes.bool
};

export default Footer;
