import React from "react";
import PropTypes from "prop-types";
import RBCheckbox from "react-bootstrap/lib/Checkbox";
import "./checkbox.css";

const Checkbox = ({
  value,
  style,
  size,
  disabled,
  readOnly,
  checked,
  label,
  ...otherProps
}) => (
  <RBCheckbox
    checked={checked}
    value={value}
    readOnly={readOnly}
    bsSize={size}
    bsStyle={style}
    disabled={disabled}
    {...otherProps}
  >
    <span>{label}</span>
  </RBCheckbox>
);

Checkbox.propTypes = {
  value: PropTypes.any,
  style: PropTypes.oneOf(["success", "warning", "error"]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  checked: PropTypes.bool,
  label: PropTypes.string
};

export default Checkbox;
