import React, { Component } from "react";
import PropTypes from "prop-types";

import TCModal from "../../components/element/overlay/TCModal";
import CreateEditEmployeeForm from "../../components/employee/CreateEditEmployeeForm";
import CreateEditTourForm from "../../components/tour/CreateEditTourForm";
import Navigation from "../../components/element/navigation/Navigation";
import NavigationItem from "../../components/element/navigation/NavigationItem";

import "./settingsModal.css";

class SettingsModal extends Component {
  static propTypes = {
    showModal: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
  };

  state = {
    clickedNavigationItem: "courier",
    defaultFooter: true,
    show: true,
    bigModal: true
  };

  componentWillMount() {
    // console.log("CreateEditShipment componentWillMount");
    this.setState({ show: true });
  }

  componentWillReceiveProps(nextProps) {
    // console.log("CreateEditShipment componentWillReceiveProps", nextProps);
    if (nextProps.showModal !== this.state.show) {
      this.setState({ show: nextProps.showModal });
    }
  }

  onSelectNavigationItem = clicked => {
    this.setState({ clickedNavigationItem: clicked });
  };

  handleClose = () => {
    this.setState({ show: false });
    this.props.handleClose();
  };

  render() {
    const { ...otherProps } = this.props;

    const { show, bigModal, defaultFooter, clickedNavigationItem } = this.state;

    return (
      <div {...otherProps}>
        <TCModal
          defaultFooter={defaultFooter}
          show={show}
          bigModal={bigModal}
          handleClose={this.handleClose}
          handleSubmit={this.handleClose}
        >
          <div className="row" {...otherProps}>
            <div className="col-sm-2 rowNav">
              <Navigation
                className="nav nav-pills nav-stacked"
                onSelect={this.onSelectNavigationItem}
              >
                <NavigationItem
                  eventKey="courier"
                  className={
                    clickedNavigationItem === "courier" ? "selected" : ""
                  }
                >
                  <span>Kuriere</span>
                </NavigationItem>
                <NavigationItem
                  eventKey="dispatcher"
                  className={
                    clickedNavigationItem === "dispatcher" ? "selected" : ""
                  }
                >
                  <span>Disponenten</span>
                </NavigationItem>
                <NavigationItem
                  eventKey="tour"
                  className={clickedNavigationItem === "tour" ? "selected" : ""}
                >
                  <span>Touren / Gebiete</span>
                </NavigationItem>
              </Navigation>
            </div>
            <div className="col-sm-10 rowList">
              {clickedNavigationItem === "courier" && (
                <CreateEditEmployeeForm selectedGroup={4} />
              )}
              {clickedNavigationItem === "dispatcher" && (
                <CreateEditEmployeeForm selectedGroup={5} />
              )}
              {clickedNavigationItem === "tour" && <CreateEditTourForm />}
            </div>
          </div>
        </TCModal>
      </div>
    );
  }
}

export default SettingsModal;
