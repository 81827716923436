/**
 * Returns a valid function for the RestService middleware.
 * @param {object} options
 * @param {number} options.logisticCompanyId
 * @returns {function}
 */
export const restServiceMiddleware = ({ logisticCompanyId }) => ({
  url,
  settings = { headers: null }
}) => ({
  url,
  settings: {
    ...settings,
    // do not set header if logisticCompanyId is 0
    headers:
      logisticCompanyId === 0
        ? settings.headers
        : {
            ...(settings.headers || null),
            "X-Logisticcompany-ID": logisticCompanyId
          }
  }
});
