/**
 * Format an address field.
 */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";

import Icon from "../element/icon/Icon";
import styleNames from "./address.local.css";

const cx = classNames.bind(styleNames);

// @Note: don't use address field
// => https://developer.mozilla.org/de/docs/Web/HTML/Element/address
const Address = ({
  address,
  shortenName,
  className,
  addExtra,
  addLocationIcon,
  children,
  ...otherProps
}) => (
  <div className={cx("ci-address", className)} {...otherProps}>
    {addLocationIcon && (
      <Icon name="map-marker" className={cx("ci-icon")} size="xs" />
    )}
    <div className={cx({ "ci-address-data": addLocationIcon })}>
      <div
        className={cx("ci-address-location", "ci-ellipsis", "ci-icon-width")}
      >
        {" "}
        {address.street}, {address.zipcode} {address.city}
      </div>
    </div>

    {addExtra && (
      <Fragment>
        {address.additional && (
          <div className={cx("ci-address-additional")}>
            {address.additional}
          </div>
        )}
        {address.avis && (
          <div className={cx("ci-address-avis")}>{address.avis}</div>
        )}
        {address.country && (
          <div className={cx("ci-address-country")}>{address.country}</div>
        )}
      </Fragment>
    )}
    {children}
  </div>
);

Address.propTypes = {
  address: PropTypes.object.isRequired,
  shortenName: PropTypes.bool,
  addExtra: PropTypes.bool,
  addLocationIcon: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node
};

export default Address;
