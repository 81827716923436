import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames/bind";
import Loading from "../../components/Loading/Loading";
import Alert from "../../components/element/alert/Alert";
import Button from "../../components/element/button/Button";

import { createImport } from "../../state/import/importAction";

import styleNames from "./importTransmit.local.css";

const cx = classNames.bind(styleNames);

class ImportTransmit extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    uploadItems: PropTypes.array.isRequired,
    onRestart: PropTypes.func.isRequired,
    imported: PropTypes.object.isRequired,
    onReady: PropTypes.func.isRequired
  };

  state = {
    isTransmitted: false
  };

  componentWillMount() {
    const { token, uploadItems } = this.props;

    this.props.dispatch(createImport(token, uploadItems));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.imported.loaded) {
      this.setState({ isTransmitted: true });
    }
  }

  render() {
    const { uploadItems, onRestart, onReady } = this.props;

    const { isTransmitted } = this.state;

    return (
      <div>
        {!isTransmitted && <Loading />}
        {isTransmitted && (
          <React.Fragment>
            <Alert color="success" className={cx("import-alert")}>
              {uploadItems.length} Aufträge importiert.
            </Alert>
            <div
              className={`${cx(
                "shipment-form-footer"
              )}shipment-form-footer modal-footer`}
            >
              <Button onClick={onRestart}>weiterer Import</Button>
              <Button color="success" onClick={onReady}>
                Fertig
              </Button>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

function mapStateToProps({ auth, imported }) {
  return {
    imported,
    token: auth.token
  };
}

export default connect(mapStateToProps)(ImportTransmit);
