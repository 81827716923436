/**
 * Created by kim on 23.11.16.
 */
import { SHIPMENT_CRON_READ, SHIPMENT_CRON_CREATE } from "./index";

const initialState = {
  result: [],
  loaded: false,
  error: null
};

export default function auth(state = initialState, action = {}) {
  const { type, payload, error } = action;

  if (error) {
    return {
      ...state,
      error: payload
    };
  }

  switch (type) {
    case SHIPMENT_CRON_READ:
      return {
        ...state,
        result: payload,
        loaded: true,
        error: null
      };

    case SHIPMENT_CRON_CREATE:
      return {
        ...state,
        loaded: true,
        error: null
      };

    default:
      return state;
  }
}
