export const SHIPMENTS_GET_REQUEST = "SHIPMENTS_GET_REQUEST";
export const SHIPMENTS_GET_RECEIVE = "SHIPMENTS_GET_RECEIVE";
export const SHIPMENTS_GET_ERROR = "SHIPMENTS_GET_ERROR";
export const SHIPMENTS_GET_CACHED = "SHIPMENTS_GET_CACHED";
export const SHIPMENT_ASSIGN_DRIVER_REQUEST = "SHIPMENT_ASSIGN_DRIVER_REQUEST";
export const SHIPMENT_ASSIGN_DRIVER_SUCCESS = "SHIPMENT_ASSIGN_DRIVER_SUCCESS";
export const SHIPMENT_ASSIGN_DRIVER_ERROR = "SHIPMENT_ASSIGN_DRIVER_ERROR";
export const SHIPMENT_ASSIGN_TOUR_REQUEST = "SHIPMENT_ASSIGN_TOUR_REQUEST";
export const SHIPMENT_ASSIGN_TOUR_SUCCESS = "SHIPMENT_ASSIGN_TOUR_SUCCESS";
export const SHIPMENT_ASSIGN_TOUR_ERROR = "SHIPMENT_ASSIGN_TOUR_ERROR";
export const SHIPMENT_DRIVER_JOBS_MAPPING = "SHIPMENT_DRIVER_JOBS_MAPPING";
export const SHIPMENT_CLEAR_ASSIGNMENT_REQUEST =
  "SHIPMENT_CLEAR_ASSIGNMENT_REQUEST";
export const SHIPMENT_CLEAR_ASSIGNMENT_SUCCESS =
  "SHIPMENT_CLEAR_ASSIGNMENT_SUCCESS";
export const SHIPMENT_CLEAR_ASSIGNMENT_ERROR =
  "SHIPMENT_CLEAR_ASSIGNMENT_ERROR";
export const SHIPMENTS_FROM_JOBS = "SHIPMENTS_FROM_JOBS";

export const SHIPMENT_TYPES = {
  DELIVERY: "DELIVERY",
  PICKUP: "PICKUP",
  DIRECT: "DIRECT",
  STOP: "STOP"
};
