import _ from "lodash";
import moment from "moment";
import {
  setHours,
  setMinutes,
  setSeconds,
  isBefore,
  isEqual,
  startOfToday,
  endOfToday,
  isValid,
  isSameDay
} from "date-fns";
import { LOGISTICCOMPANY_PREPARE_CHANGE } from "state/auth";
import {
  SHIPMENTS_READ,
  SHIPMENTS_CREATE,
  SHIPMENTS_SOCKET_CREATE,
  SHIPMENTS_SOCKET_UPDATE,
  SHIPMENTS_SOCKET_DELETE,
  FILTER_SHIPMENTS,
  SHIPMENTS_STATUS_CHANGED,
  SHIPMENT_UPDATE,
  SHIPMENT_DELETE,
  SHIPMENT_STATUS,
  SHIPMENT_STATUS_GROUPS,
  SHIPMENT_ASSIGNMENT_DELETE,
  SHIPMENT_STATUS_VARIATIONS,
  SHIPMENT_NEW_PROGRESS_MODAL
} from "./index";

export const ASSIGNMENT_FILTER_TYPE = {
  MEMBER: "MEMBER",
  TOUR: "TOUR"
};

const DEFAULT_FILTER = { status: [], statusGroups: [], drivers: [] };

function getInitialSelectedDate() {
  return {
    start: moment()
      .startOf("day")
      .format(),
    end: moment()
      .endOf("day")
      .format()
  };
}

const initialState = {
  newProgressModalShipmentId: 0,
  shipmentsAll: [],
  shipments: [],
  filterStatusLoaded: false,
  filterStatus: [
    1, // created
    11, // data_update
    20, // damage_documentation
    21, // imported
    22, // pending
    23, // assigned
    24, // rejected
    25 // unassigned
  ],
  filterStatusGroupsLoaded: false,
  filterStatusGroups: {},
  statusVariationsLoaded: false,
  statusVariations: [],
  selectedFilters: DEFAULT_FILTER,
  selectedDate: getInitialSelectedDate(),
  loaded: 0,
  error: null
};

/**
 * Apply given filters to given shipment list.
 * @param {array} shipments [list of all shipments]
 * @param {object} dateRange [start and end time]
 * @param {object} filters
 * @param {array} [filters.status] shipment status id
 * @param {object} normalized object with status groups
 * @return {array} [filtered list of shipments]
 */
function filterShipments(
  shipments,
  dateRange,
  filters = {},
  filterStatusGroups
) {
  // TODO cleaning => SK

  let result = shipments;

  // list of selected status and group status
  let statusList = [];

  // add status id's of all selected groups
  if (filters.statusGroups && filters.statusGroups.length > 0) {
    filters.statusGroups.forEach(groupId => {
      if (filterStatusGroups[groupId] && filterStatusGroups[groupId].status) {
        statusList = [
          ...new Set([...statusList, ...filterStatusGroups[groupId].status])
        ];
      }
    });
  }

  // add selected status id's to statusList
  if (filters.status && filters.status.length > 0) {
    statusList = [...new Set([...statusList, ...filters.status])];
  }

  // filter shipment list with statusList
  if (statusList.length > 0) {
    result = result.filter(shipment => statusList.includes(shipment.status_id));
  }

  if (filters.drivers && filters.drivers.length > 0) {
    result = result.filter(shipment => {
      const responsibleMemberFields = [
        shipment.last_responsible_member_id,
        shipment.responsible_member_id
      ];

      const responsibleTourFields = [
        shipment.last_responsible_tour_id,
        shipment.responsible_tour_id
      ];

      for (let i = 0; i < filters.drivers.length; i += 1) {
        const assignment = filters.drivers[i];

        const isMemberAssignment =
          assignment.type === ASSIGNMENT_FILTER_TYPE.MEMBER;
        const isTourAssignment =
          assignment.type === ASSIGNMENT_FILTER_TYPE.TOUR;

        if (
          isMemberAssignment &&
          responsibleMemberFields.includes(assignment.id)
        ) {
          return true;
        }

        if (isTourAssignment && responsibleTourFields.includes(assignment.id)) {
          return true;
        }
      }

      return false;
    });
  }

  return result;
}

/**
 * Sort shipments.
 * IMPORTANT: This function is a copy from feature/shipment.
 * This reducer will be deprecated in few month.
 * @param {array} shipments
 */
function sortShipments(shipments) {
  const getTime = value => {
    const [, time] = value.split(" ");
    if (time) {
      const [hours, minutes] = time.split(":").map(val => parseInt(val, 10));
      if (hours === 0 && minutes === 0) {
        return null;
      }
      const date = new Date();
      return setHours(setMinutes(setSeconds(date, 0), minutes), hours);
    }
    return null;
  };

  /**
   * Compares two names
   * @param {String} a
   * @param {String} b
   * @return {number}
   */
  const compareName = (a, b) => {
    const nameA = a.toUpperCase();
    const nameB = b.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  };

  /**
   * Compare two timewindows if both have at least one time set
   * @param {{from: Date | null, till: Date|null}} timeSlotA
   * @param {{from: Date | null, till: Date|null}} timeSlotB
   * @return {number}
   */
  const compareExistingTimeWindow = (timeSlotA, timeSlotB) => {
    const { from: fromA, till: tillA } = timeSlotA;
    const { from: fromB, till: tillB } = timeSlotB;
    if ((fromA || tillA) && (!fromB && !tillB)) {
      return -1;
    }
    if ((fromB || tillB) && (!fromA && !tillA)) {
      return 1;
    }
    return 0;
  };

  /**
   * Compare two datewindows for earliest start with earliest end as a tiebreaker
   * @param {{start: Date | null, end: Date|null}} dateWindowA
   * @param {{start: Date | null, end: Date|null}} dateWindowB
   * @return {number}
   */
  const compareDateWindow = (dateWindowA, dateWindowB) => {
    // if time starttime differs we can sort
    if (!isEqual(dateWindowA.start, dateWindowB.start)) {
      return isBefore(dateWindowA.start, dateWindowB.start) ? -1 : 1;
    }

    // same start time, sort by till time
    if (!isEqual(dateWindowA.end, dateWindowB.end)) {
      return isBefore(dateWindowA.end, dateWindowB.end) ? -1 : 1;
    }
    return 0;
  };

  const sortShipmentsByTime = shipmentList => {
    const begin = startOfToday();
    const end = endOfToday();
    return [...shipmentList].sort((shipmentA, shipmentB) => {
      // choose recipient time slot if shipment is already picked up
      const timeA = shipmentA.isPickedUp
        ? {
            from: getTime(shipmentA.delivery_appointment_from),
            till: getTime(shipmentA.delivery_appointment_till)
          }
        : {
            from: getTime(shipmentA.pickup_appointment_from),
            till: getTime(shipmentA.pickup_appointment_till)
          };
      const timeB = shipmentB.isPickedUp
        ? {
            from: getTime(shipmentB.delivery_appointment_from),
            till: getTime(shipmentB.delivery_appointment_till)
          }
        : {
            from: getTime(shipmentB.pickup_appointment_from),
            till: getTime(shipmentB.pickup_appointment_till)
          };

      // shipments with date go before shipments without any date
      const existingTimewindowDiff = compareExistingTimeWindow(timeA, timeB);
      if (existingTimewindowDiff !== 0) {
        return existingTimewindowDiff;
      }

      // compare dateWindows by start, then end
      const dateWindowA = {
        start: timeA.from || begin,
        end: timeA.till || end
      };
      const dateWindowB = {
        start: timeB.from || begin,
        end: timeB.till || end
      };
      const timeWindowDiff = compareDateWindow(dateWindowA, dateWindowB);
      if (timeWindowDiff !== 0) {
        return timeWindowDiff;
      }

      // equal timeWindows, sort by zip
      if (
        shipmentA.recipient_address_zipcode !==
        shipmentB.recipient_address_zipcode
      ) {
        return (
          shipmentA.recipient_address_zipcode -
          shipmentB.recipient_address_zipcode
        );
      }

      // sort by street name
      return compareName(
        shipmentA.recipient_address_street,
        shipmentB.recipient_address_street
      );
    });
  };

  return sortShipmentsByTime(shipments);
}

function uniqueFunction(shipment) {
  return shipment.socket_id;
}

function sortFunction(shipment) {
  return new Date(shipment.update_datetime).getTime() * -1;
}

function addShipments(currentShipments, newShipments) {
  if (!currentShipments.length && !newShipments) {
    return [];
  } else if (!currentShipments.length) {
    return newShipments;
  } else if (!newShipments || !newShipments.length) {
    return currentShipments;
  }

  let result = currentShipments.concat(...newShipments);
  result = _.uniqBy(result, uniqueFunction);
  result = _.sortBy(result, sortFunction);

  return result;
}

function mergeShipments(currentShipments, newShipments) {
  const updates = [];
  if (!newShipments) {
    return currentShipments;
  }

  if (
    newShipments.constructor !== Array &&
    Object.keys(newShipments).length > 0
  ) {
    updates.push(newShipments);
  } else {
    updates.push(...newShipments);
  }
  // console.log("updateShipment", newShipments);
  if (!currentShipments.length) {
    return [];
  } else if (!updates || !updates.length) {
    return currentShipments;
  }

  const resultShipments = currentShipments.map(curShipment => {
    const updatedShipment = updates.find(
      update => update.socket_id === curShipment.socket_id
    );
    if (updatedShipment) {
      return updatedShipment;
    }

    return curShipment;
  });

  return resultShipments;
}

function deleteShipments(currentShipments, deletedShipment) {
  return currentShipments.filter(
    curShipment => curShipment.socket_id !== deletedShipment.socket_id
  );
}

function changeStatus(shipmentsAll, shipments, status) {
  const all = [...shipmentsAll];
  return all.map(shipment => {
    // shipments.find((selectedShipment) => shipment.trackcode === selectedShipment.trackcode)
    if (
      shipments.find(
        selectedShipment => shipment.trackcode === selectedShipment.trackcode
      )
    ) {
      return { ...shipment, status };
    }
    return shipment;
  });
}

/**
 * Parse a shipment time to a valid day if possible
 * @param value
 * @return {null|Date}
 */
const getDate = value => {
  if (!value) {
    return null;
  }
  const d = new Date(value);
  if (!isValid(d)) {
    return null;
  }
  return d;
};

const getPossibleDueDate = ({
  isPickedUp,
  delivery_appointment_from,
  pickup_appointment_from,
  creation_datetime
}) => {
  const possibleDueDate =
    isPickedUp === 1 || isPickedUp === "1"
      ? getDate(delivery_appointment_from)
      : getDate(pickup_appointment_from);
  return possibleDueDate || getDate(creation_datetime);
};

const guaranteePayloadArray = payload => {
  if (Array.isArray(payload)) {
    return payload;
  }
  return typeof payload === "object" ? [payload] : [];
};

export default function shipmentReducer(state = initialState, action = {}) {
  const { type, payload, error } = action;
  let allShipments;

  if (error) {
    return {
      ...state,
      error: payload
    };
  }

  switch (type) {
    case SHIPMENTS_READ: {
      allShipments = payload.shipments || [];
      const shipments = sortShipments(
        filterShipments(
          payload.shipments,
          payload.dateRange,
          state.selectedFilters,
          state.filterStatusGroups
        )
      );

      return {
        ...state,
        shipmentsAll: allShipments,
        shipments,
        selectedDate: payload.dateRange,
        loaded: state.loaded + 1,
        error: null
      };
    }

    case SHIPMENTS_SOCKET_CREATE: {
      const { start } = state.selectedDate;

      const filteredPayload = guaranteePayloadArray(payload).filter(shipment =>
        isSameDay(getPossibleDueDate(shipment), start)
      );
      allShipments = addShipments(state.shipmentsAll, filteredPayload);

      return {
        ...state,
        shipmentsAll: allShipments,
        shipments: sortShipments(
          filterShipments(
            allShipments,
            {},
            state.selectedFilters,
            state.filterStatusGroups
          )
        )
      };
    }

    case SHIPMENTS_SOCKET_UPDATE: {
      const { start } = state.selectedDate;

      const filteredPayload = guaranteePayloadArray(payload).filter(shipment =>
        isSameDay(getPossibleDueDate(shipment), start)
      );
      allShipments = mergeShipments(state.shipmentsAll, filteredPayload);

      return {
        ...state,
        shipmentsAll: allShipments,
        shipments: sortShipments(
          filterShipments(
            allShipments,
            {},
            state.selectedFilters,
            state.filterStatusGroups
          )
        )
      };
    }

    case SHIPMENTS_SOCKET_DELETE: {
      const { start } = state.selectedDate;

      const filteredPayload = guaranteePayloadArray(payload).filter(shipment =>
        isSameDay(getPossibleDueDate(shipment), start)
      );
      allShipments = deleteShipments(state.shipmentsAll, filteredPayload);

      return {
        ...state,
        shipmentsAll: allShipments,
        shipments: sortShipments(
          filterShipments(
            allShipments,
            {},
            state.selectedFilters,
            state.filterStatusGroups
          )
        )
      };
    }

    case SHIPMENTS_CREATE: {
      return {
        ...state,
        loaded: 1,
        error: null
      };
    }

    case FILTER_SHIPMENTS: {
      const newFilters = { ...state.selectedFilters, ...payload };
      return {
        ...state,
        selectedFilters: newFilters,
        shipments: sortShipments(
          filterShipments(
            state.shipmentsAll,
            state.selectedDate,
            newFilters,
            state.filterStatusGroups
          )
        )
      };
    }

    case SHIPMENTS_STATUS_CHANGED: {
      const shipmentsAll = changeStatus(
        state.shipmentsAll,
        payload.shipments,
        payload.status
      );
      return {
        ...state,
        shipments: sortShipments(
          filterShipments(
            shipmentsAll,
            state.selectedDate,
            state.selectedFilters,
            state.filterStatusGroups
          )
        ),
        shipmentsAll
      };
    }

    case SHIPMENT_UPDATE: {
      return {
        ...state,
        refresh: true,
        loaded: 1,
        error: null
      };
    }

    case SHIPMENT_DELETE: {
      const deletedShipment = payload[0];
      delete deletedShipment.deleteRecurrenceInstance;

      return {
        ...state,
        shipmentsAll: state.shipmentsAll.filter(
          shipment => !_.isEqual(shipment, deletedShipment)
        ),
        shipments: state.shipments.filter(
          shipment => !_.isEqual(shipment, deletedShipment)
        ),
        refresh: true,
        loaded: 1,
        error: null
      };
    }

    case SHIPMENT_STATUS: {
      return {
        ...state,
        filterStatusLoaded: true,
        filterStatus: [...new Set([...state.filterStatus, ...payload])]
      };
    }

    case SHIPMENT_STATUS_GROUPS: {
      const normalizedGroups = payload.reduce((acc, group) => {
        acc[group.id] = group;
        return acc;
      }, {});
      return {
        ...state,
        filterStatusGroupsLoaded: true,
        filterStatusGroups: normalizedGroups
      };
    }

    case SHIPMENT_STATUS_VARIATIONS: {
      const normalized = payload.reduce((acc, entry) => {
        acc[entry.id] = entry;
        return acc;
      }, {});
      return {
        ...state,
        statusVariationsLoaded: true,
        statusVariations: normalized
      };
    }

    case SHIPMENT_ASSIGNMENT_DELETE: {
      const removeAssignment = shipment => {
        if (payload.shipmentId === shipment.id) {
          return {
            ...shipment,
            responsible_member_id: 0,
            responsible_tour_id: 0
          };
        }
        return shipment;
      };
      const shipmentsAll = state.shipmentsAll.map(removeAssignment);
      const shipments = state.shipments.map(removeAssignment);

      return {
        ...state,
        shipmentsAll,
        shipments
      };
    }

    case LOGISTICCOMPANY_PREPARE_CHANGE:
      return initialState;

    case SHIPMENT_NEW_PROGRESS_MODAL: {
      return {
        ...state,
        newProgressModalShipmentId: action.shipmentId
      };
    }

    default: {
      return state;
    }
  }
}
