const SENDER = "sender";
const RECIPIENT = "recipient";

function mapShipmentToAddress(type, shipment) {
  const address = {};

  Object.keys(shipment)
    .filter(key => key.indexOf(type) >= 0)
    .reduce((theAddress, key) => {
      const addressKey = key.replace(`${type}_`, "").replace("address_", "");
      // eslint-disable-next-line no-param-reassign
      theAddress[addressKey] = shipment[key];
      return theAddress;
    }, address);

  // console.log("address", address)
  return address;
}

export default class AddressService {
  static getSendeFromShipment(shipment) {
    return mapShipmentToAddress(SENDER, shipment);
  }
  static getRecipientFromShipment(shipment) {
    return mapShipmentToAddress(RECIPIENT, shipment);
  }

  /**
   * get a lat,lng from shipment
   * @param shipment
   * @returns {{}}
   */
  static getLatLongFromShipment(shipment) {
    const result = {};
    if (!shipment.isPickedUp) {
      result.latitude = shipment.sender_address_latitude;
      result.longitude = shipment.sender_address_longitude;
    } else {
      result.latitude = shipment.recipient_address_latitude;
      result.longitude = shipment.recipient_address_longitude;
    }

    return result;
  }
}
