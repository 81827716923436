import { FETCH_ADDRESS, CREATE_ADDRESS } from "./index";

const initialState = {
  addresses: [],
  loaded: false,
  error: null
};

export default function auth(state = initialState, action = {}) {
  const { type, payload, error } = action;

  if (error) {
    return {
      ...state,
      error: payload
    };
  }

  switch (type) {
    case FETCH_ADDRESS:
      return {
        ...state,
        addresses: payload.address,
        loaded: true,
        error: null
      };

    case CREATE_ADDRESS:
      return {
        ...state,
        loaded: true,
        error: null
      };

    default:
      return state;
  }
}
