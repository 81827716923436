import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";

import styleNames from "../../components/address/address.local.css";

const cx = classNames.bind(styleNames);

// @Note: don't use address field
// => https://developer.mozilla.org/de/docs/Web/HTML/Element/address
const LongAddress = ({
  address,
  avis,
  company,
  name,
  children,
  ...otherProps
}) =>
  console.log(address) || (
    <div className={cx("ci-address")} {...otherProps}>
      <div className={cx("ci-address-name")}>
        {company} {name}
      </div>
      {address.additional ? <div>{address.additional}</div> : null}
      <div>
        <div className={cx("ci-address-location")}> {address.street}</div>
        <div className={cx("ci-address-location")}>
          {" "}
          {address.zip} {address.city}
        </div>
      </div>
      <Fragment>
        {avis && <div className={cx("ci-address-avis")}>{avis}</div>}
        {address.country && (
          <div className={cx("ci-address-country")}>{address.country}</div>
        )}
      </Fragment>
      {children}
    </div>
  );

LongAddress.propTypes = {
  address: PropTypes.object.isRequired,
  company: PropTypes.string,
  avis: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.node
};

export default LongAddress;
