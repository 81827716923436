// READ
export const TASKS_GET_REQUEST = "TASKS_GET_REQUEST";
export const TASKS_GET_RECEIVE = "TASKS_GET_RECEIVE";

// ASSIGN
export const TASKS_ASSIGN_REQUEST = "TASKS_ASSIGN_REQUEST";
export const TASKS_ASSIGN_RECEIVE = "TASKS_ASSIGN_RECEIVE";
export const TASKS_UNASSIGN_REQUEST = "TASKS_UNASSIGN_REQUEST";
export const TASKS_UNASSIGN_RECEIVE = "TASKS_UNASSIGN_RECEIVE";

// Error
export const TASKS_ERROR = "TASKS_ERROR";

export const TASK_TYPES = {
  PICKUP: "pickup",
  DELIVERY: "delivery",
  PICKUP_DIRECT: "pickup_direct",
  DELIVERY_DIRECT: "delivery_direct"
};
