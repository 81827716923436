import React, { Component } from "react";
import PropTypes from "prop-types";
import { MultiSelect } from "../../components/element/select";

class MappingItem extends Component {
  static propTypes = {
    exampleMapItem: PropTypes.array.isRequired,
    handleOnChange: PropTypes.func.isRequired,
    preselectValues: PropTypes.array,
    name: PropTypes.string,
    placeholder: PropTypes.string
  };

  static parseToOptionItem(mapItem) {
    const options = [];
    for (let i = 0; i < mapItem.length; i += 1) {
      options.push({ value: i, label: mapItem[i] });
    }

    return options;
  }

  state = {
    filterValues: []
  };
  componentDidMount() {
    const { preselectValues, exampleMapItem } = this.props;

    if (preselectValues && preselectValues.length) {
      const selected = [];
      for (let i = 0; i < preselectValues.length; i += 1) {
        const key = preselectValues[i];
        selected.push({ value: key, label: exampleMapItem[key] });
      }
      this.handleOnItemChanged(selected);
    }
  }

  handleOnItemChanged = i => {
    let values = [];

    if (i && i.length) {
      values = [...i];
      this.setState({ filterValues: values });
    } else {
      this.setState({ filterValues: values });
    }

    this.props.handleOnChange(this.props.name, values);
  };

  render() {
    const { exampleMapItem, name, placeholder } = this.props;

    const { filterValues } = this.state;

    return (
      <div className="form-group">
        <div className="col-sm-6">
          <MultiSelect
            name={name}
            placeholder={placeholder}
            options={MappingItem.parseToOptionItem(exampleMapItem)}
            onChange={this.handleOnItemChanged}
            value={filterValues}
          />
        </div>
      </div>
    );
  }
}

export default MappingItem;
