/*
 * Persist redux state to local web storage.
 * Source: https://egghead.io/lessons/javascript-redux-persisting-the-state-to-the-local-storage
 */

const storageKey = "state";

/**
 * Get state from local storage
 * @returns {object|undefined} state
 */
export const loadState = () => {
  let state;
  try {
    const serializedState = localStorage.getItem(storageKey);
    if (serializedState === null) return undefined;
    state = JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
  return state;
};

/**
 * Save state to local storage
 *
 * @param {object} state
 * @param {array} whitelist
 */
export const saveState = (state, whitelist = []) => {
  const persistState = {};
  Object.keys(state).forEach(key => {
    if (whitelist.includes(key)) {
      persistState[key] = state[key];
    }
  });

  try {
    const serializedState = JSON.stringify(persistState);
    localStorage.setItem(storageKey, serializedState);
  } catch (err) {
    // ignore write errors
  }
};

/**
 * Remove state from local storage
 */
export const removeState = () => {
  try {
    localStorage.removeItem(storageKey);
  } catch (err) {
    // Ignore write errors.
  }
};
