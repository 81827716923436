import * as apiV2 from "common/apiV2";
import {
  SHIPMENTS_V2_GET_RECEIVE,
  SHIPMENTS_V2_GET_REQUEST,
  SHIPMENTS_V2_GET_ERROR
} from "./constants";

/**
 * Get all shipments by date range or by ids.
 * @param {object} params
 * @param {number[]} params.ids
 * @param {Date} params.start
 * @param {Date} params.end
 * @return {Function}
 */
export const getShipments = params => async (dispatch, getState) => {
  const onSuccess = payload => {
    dispatch({ type: SHIPMENTS_V2_GET_RECEIVE, payload });
    return payload;
  };

  dispatch({ type: SHIPMENTS_V2_GET_REQUEST });
  try {
    const { data: shipments } = await apiV2.getShipments(params);
    return onSuccess(shipments);
  } catch (error) {
    dispatch({ type: SHIPMENTS_V2_GET_ERROR, error });
    return null;
  }
};
